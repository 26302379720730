import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { ReactComponent as Instagram } from '../../images/instagram.svg';

interface InstagramIconProps extends SvgIconProps {
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

export const InstagramIcon = (props: InstagramIconProps) => (
  <SvgIcon
    {...props}
    component={Instagram}
    sx={{
      ...(props?.size === 'sm'
        ? { width: '16px', height: '16px' }
        : props?.size === 'lg'
        ? { width: '32px', height: '32px' }
        : props?.size === 'xl'
        ? { width: '40px', height: '40px' }
        : ''),
    }}
  />
);

export default InstagramIcon;
