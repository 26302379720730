import { Button, Typography } from '@frontend/components/ui';
import {
  useCanvasGet,
  useCanvasUpdate,
  useGetProjectID,
} from '@frontend/editor/data-access';
import {
  BaseElement,
  TextButtonCellType,
  TextButtonCellTypesEnum,
} from '@frontend/editor/interface';
import { useGetProject } from '@frontend/sorghum/data-access';
import { SocialTypeEnum } from '@frontend/sorghum/interface';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { Box, ClickAwayListener, Popper } from '@mui/material';
import { PropsWithChildren, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ConnectorBlockIcon from '../icons/connector-block-icon';
import { BlockSetting } from './block-setting';
import { FlowSetting } from './flow-setting';
import { PhoneSetting } from './phone-setting';
import {
  CheckWrapper,
  CloseButtonWrapper,
  ContainerWrapper,
  IconWrapper,
  SelectedIconWrapper,
  ToggleWrapper,
} from './styles';
import { UrlSetting } from './url-setting';

interface TextButtonCellModalProps {
  outputID: string;
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  elementID: string;
  handleCheckErrorWhenPopupIsClosed: () => void;
}

export interface ToggleIconType {
  getTargetElement: (id: string) => BaseElement;
  elementID: string;
}

const ToggleIcon = ({
  type,
  label,
  isSelected,
  children,
  toggle,
}: PropsWithChildren<{
  type: TextButtonCellTypesEnum;
  label: string;
  isSelected: boolean;
  toggle: (type: TextButtonCellTypesEnum) => void;
}>) => {
  return isSelected ? (
    <SelectedIconWrapper>
      {children}
      <Box ml={1}>
        <Typography variant="body2">{label}</Typography>
      </Box>
      <CheckWrapper
        checked={true}
        checkedIcon={
          <CheckCircle sx={{ background: 'white', borderRadius: '50%' }} />
        }
        sx={{
          position: 'absolute',
          top: '-20px',
          right: '-20px',
          backgroundColor: 'transparent',
          borderRadius: '50%',
        }}
      />
    </SelectedIconWrapper>
  ) : (
    <IconWrapper
      onClick={() => {
        toggle(type);
      }}
    >
      {children}
    </IconWrapper>
  );
};

//TODO: input 改成 debounce
export const TextButtonCellModal = ({
  // element output ID
  outputID,
  anchorEl,
  open,
  // text button cell 的 ID
  elementID,
  handleClose,
  handleCheckErrorWhenPopupIsClosed,
}: TextButtonCellModalProps) => {
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const { data: project } = useGetProject(projectID);
  const { canvasUpdateData } = useCanvasUpdate();
  const { getTargetElement } = useCanvasGet();

  const { id: flowID } = useParams();

  const textButtonCellData = useMemo(
    () => getTargetElement(elementID) as TextButtonCellType,
    [elementID, getTargetElement],
  );

  const toggle = (type: TextButtonCellTypesEnum) => {
    canvasUpdateData(elementID, 'buttonType', type);
    canvasUpdateData(elementID, 'targetID', '');
  };

  const handleDone = () => {
    handleCheckErrorWhenPopupIsClosed();
    handleClose();
  };

  // Instagram 只開放 url / block
  const buttonTypeRule = (type: TextButtonCellTypesEnum) => {
    if (project?.socialType === SocialTypeEnum.FACEBOOK) return true;
    if (
      type === TextButtonCellTypesEnum.URL ||
      type === TextButtonCellTypesEnum.BLOCK
    )
      return true;
    return false;
  };

  return (
    <ClickAwayListener onClickAway={handleCheckErrorWhenPopupIsClosed}>
      <Popper
        id={outputID}
        placement="right-start"
        sx={{ zIndex: 30, marginRight: '30px! important' }}
        anchorEl={anchorEl}
        open={open}
      >
        <ContainerWrapper>
          <CloseButtonWrapper>
            <Button color="inherit" variant="text" sx={{ paddingRight: 0 }}>
              <CloseIcon
                onClick={() => {
                  handleClose();
                }}
                fontSize="small"
              />
            </Button>
          </CloseButtonWrapper>
          {
            <ToggleWrapper>
              {buttonTypeRule(TextButtonCellTypesEnum.BLOCK) && (
                <ToggleIcon
                  type={TextButtonCellTypesEnum.BLOCK}
                  toggle={toggle}
                  isSelected={
                    textButtonCellData.buttonType ===
                    TextButtonCellTypesEnum.BLOCK
                  }
                  label={t('canvas.modal.editTextButton.block')}
                >
                  <ConnectorBlockIcon fontSize="small" />
                </ToggleIcon>
              )}
              {buttonTypeRule(TextButtonCellTypesEnum.FLOW) && (
                <ToggleIcon
                  type={TextButtonCellTypesEnum.FLOW}
                  toggle={toggle}
                  isSelected={
                    textButtonCellData.buttonType ===
                    TextButtonCellTypesEnum.FLOW
                  }
                  label={t('canvas.modal.editTextButton.flow')}
                >
                  <AccountTreeOutlinedIcon fontSize="small" />
                </ToggleIcon>
              )}
              {buttonTypeRule(TextButtonCellTypesEnum.URL) && (
                <ToggleIcon
                  type={TextButtonCellTypesEnum.URL}
                  toggle={toggle}
                  isSelected={
                    textButtonCellData.buttonType ===
                    TextButtonCellTypesEnum.URL
                  }
                  label={t('canvas.modal.editTextButton.url')}
                >
                  <AddLinkOutlinedIcon fontSize="small" />
                </ToggleIcon>
              )}
              {buttonTypeRule(TextButtonCellTypesEnum.PHONE) && (
                <ToggleIcon
                  type={TextButtonCellTypesEnum.PHONE}
                  toggle={toggle}
                  isSelected={
                    textButtonCellData.buttonType ===
                    TextButtonCellTypesEnum.PHONE
                  }
                  label={t('canvas.modal.editTextButton.call')}
                >
                  <LocalPhoneOutlinedIcon fontSize="small" />
                </ToggleIcon>
              )}
            </ToggleWrapper>
          }
          {textButtonCellData.buttonType === TextButtonCellTypesEnum.BLOCK && (
            <BlockSetting
              outputID={outputID}
              elementID={elementID}
              handleClose={handleClose}
              getTargetElement={getTargetElement}
              onValidateWhenCreateNewBlock={handleCheckErrorWhenPopupIsClosed}
              socialType={project?.socialType}
            />
          )}
          {textButtonCellData.buttonType === TextButtonCellTypesEnum.FLOW && (
            <FlowSetting
              elementID={elementID}
              getTargetElement={getTargetElement}
              excludeFlow={flowID as string}
            />
          )}
          {textButtonCellData.buttonType === TextButtonCellTypesEnum.URL && (
            <UrlSetting
              elementID={elementID}
              getTargetElement={getTargetElement}
            />
          )}
          {textButtonCellData.buttonType === TextButtonCellTypesEnum.PHONE && (
            <PhoneSetting
              elementID={elementID}
              getTargetElement={getTargetElement}
            />
          )}
          <CloseButtonWrapper>
            <Button
              sx={{
                marginTop: 2,
              }}
              onClick={handleDone}
              color="primary"
              variant="contained"
            >
              {t('common.done')}
            </Button>
          </CloseButtonWrapper>
        </ContainerWrapper>
      </Popper>
    </ClickAwayListener>
  );
};

export default memo(TextButtonCellModal);
