import { TrendingStatusEnum } from '@frontend/components/interface';
import { Button, TrendingNumber, Typography } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { usePostDataDashboardSourceFlowData } from '@frontend/sorghum/data-access';
import {
  DashboardSourceFlowData,
  PostDataDashboardProps,
} from '@frontend/sorghum/interface';
import {
  PAGE_DASHBOARD_FLOW_COMPARE,
  PAGE_FLOW_VIEW,
  usePath,
} from '@frontend/sorghum/utils';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { isNumber } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type DataDashboardSourceFlowTableProps = PostDataDashboardProps;

const TableContainerStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette['grey']['white'],
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: '20px',
  padding: '24px',
  boxShadow: theme.shadows[2],
  borderRadius: '8px',
  width: '100%',
  height: '100%',
  minHeight: '380px',
}));

const TitleStyled = styled(Box)(({ theme }) => ({
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const DetailButtonStyled = styled(Button)(({ theme }) => ({
  height: '32px',
  borderRadius: '4px',
  border: `1px solid ${theme.palette['bluegrey']['400']}`,
}));

const InfoIconStyled = styled(InfoIcon)({
  margin: '0 0 -4px 4px',
});

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette['grey'][100],
  },
}));

export const DataDashboardSourceFlowTable: FC<
  DataDashboardSourceFlowTableProps
> = ({ startDate, endDate, condition }: DataDashboardSourceFlowTableProps) => {
  const [t] = useTranslation();
  const { navigateToProjectPage } = usePath();
  const { data: projectID } = useGetProjectID();
  const [data, setData] = useState<DashboardSourceFlowData[]>([]);

  const { mutate: queryData } = usePostDataDashboardSourceFlowData(
    projectID as string,
  );

  const handleFlowClick = useCallback(
    (flowID: string) => {
      navigateToProjectPage({
        path: `${PAGE_FLOW_VIEW}/${flowID}`,
      });
    },
    [navigateToProjectPage],
  );

  const handleButtonClick = useCallback(() => {
    navigateToProjectPage({ path: `${PAGE_DASHBOARD_FLOW_COMPARE}` });
  }, [navigateToProjectPage]);

  useEffect(() => {
    if (startDate && endDate && projectID) {
      queryData(
        {
          startDate,
          endDate,
          condition,
        },
        {
          onSuccess: (_data: DashboardSourceFlowData[]) => {
            setData(_data);
          },
        },
      );
    }
  }, [condition, endDate, projectID, queryData, startDate, t]);

  return (
    <TableContainerStyled>
      <TitleStyled>
        <Typography variant="subtitle2" color="grey.800">
          {t('dataDashboard.sourceAnalysis.flows.list')}
        </Typography>
        <DetailButtonStyled onClick={handleButtonClick}>
          <Typography variant="buttonSmall" color="bluegrey.500">
            {t('dataDashboard.sourceAnalysis.flows.button')}
          </Typography>
        </DetailButtonStyled>
      </TitleStyled>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="left">
              <Typography variant="body3" color="grey.500">
                {t('dataDashboard.sourceAnalysis.flows.flow')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body3" color="grey.500">
                {t('dataDashboard.sourceAnalysis.flows.users')}
                <Tooltip
                  placement="top"
                  title={t(
                    'dataDashboard.source.table.column.newUsers.tooltip',
                  )}
                >
                  <InfoIconStyled fontSize="small" />
                </Tooltip>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body3" color="grey.500">
                {t('dataDashboard.sourceAnalysis.flows.period')}
                <Tooltip
                  placement="top"
                  title={t(
                    'dataDashboard.source.table.column.lastPeriod.tooltip',
                  )}
                >
                  <InfoIconStyled fontSize="small" />
                </Tooltip>
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((i, index) => {
            let numberStatus = TrendingStatusEnum.NULL;
            if (isNumber(i.uniqEnterUsersTrend)) {
              if (i.lastUniqEnterUsers > 0) {
                numberStatus = TrendingStatusEnum.UP;
              } else if (i.lastUniqEnterUsers < 0) {
                numberStatus = TrendingStatusEnum.DOWN;
              } else {
                numberStatus = TrendingStatusEnum.TIE;
              }
            }
            return (
              <TableRowStyled
                key={`source_${index}`}
                onClick={() => handleFlowClick(i.flowId)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>
                  <Typography variant="body3" color="grey.500">
                    {index + 1}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="body3" color="info.main">
                    {i.flowName ? (
                      <Tooltip title={i.flowName} placement="top-start">
                        <Box
                          sx={{
                            maxWidth: '160px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {i.flowName}
                        </Box>
                      </Tooltip>
                    ) : (
                      t('dataDashboard.deletedFlow')
                    )}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body3" color="grey.700">
                    {i.uniqEnterUsers}
                  </Typography>
                </TableCell>
                <TableCell>
                  <TrendingNumber
                    status={numberStatus}
                    percentage={i.uniqEnterUsersTrend}
                    total={i.lastUniqEnterUsers}
                  />
                </TableCell>
              </TableRowStyled>
            );
          })}
        </TableBody>
      </Table>
    </TableContainerStyled>
  );
};

export default DataDashboardSourceFlowTable;
