import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import { queryClient } from '@frontend/sorghum/external-providers';
import {
  PostCommentReplyActiveV2Request,
  PostCommentReplyActiveV2Response,
} from '@frontend/sorghum/interface';
import { COMMENT_REPLY_ACTIVE_V2 } from '../apis';

async function postCommentReplyActiveV2(
  props: PostCommentReplyActiveV2Request,
): Promise<PostCommentReplyActiveV2Response> {
  return axios
    .post(COMMENT_REPLY_ACTIVE_V2, props)
    .then((res) => {
      if (res.data.code !== 20000) return res.data.data;
      return res.data;
    })
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostCommentReplyActiveV2() {
  return useMutation({
    mutationKey: ['post-comment-reply-active-v2'],
    mutationFn: (props: PostCommentReplyActiveV2Request) =>
      postCommentReplyActiveV2(props),
    onSuccess: (data, props: PostCommentReplyActiveV2Request) => {
      queryClient.refetchQueries(['get-comment-reply', { uuid: props.id }]);
    },
  });
}

export default usePostCommentReplyActiveV2;
