import { Button, Typography } from '@frontend/components/ui';
import {
  copyToClipboard,
  FACEBOOK_TUTORIAL_EN,
  FACEBOOK_TUTORIAL_ZH,
  GOSKY_USE_CASE,
  GOSKY_USE_CASE_EN,
} from '@frontend/sorghum/utils';
import { Box, styled } from '@mui/material';
import Logo from 'assets/images/logo.svg';
import i18next from 'i18next';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import DesktopImage from '../../images/desktop.png';

const DesktopOnlyModalStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  padding: '20px',
}));

const TopWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}));

const LogoWrapperStyled = styled(Box)(({ theme }) => ({
  padding: '14px 0',
  marginBottom: '40px',
}));

const BottomWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '12px',
}));

export function DesktopOnlyModal() {
  const [t] = useTranslation();

  const copyLink = useCallback(() => {
    copyToClipboard(window.location.href);
  }, []);

  return (
    <DesktopOnlyModalStyled>
      <TopWrapperStyled>
        <LogoWrapperStyled>
          <img src={Logo} alt="logo" />
        </LogoWrapperStyled>

        <img
          style={{
            marginBottom: '24px',
          }}
          src={DesktopImage}
          alt="desktop-only"
        />
        <Typography
          sx={{
            marginBottom: '16px',
          }}
          variant="h4"
          color="grey.800"
        >
          {t('desktopOnly.title')}
        </Typography>
        <Typography variant="body1" color="grey.800">
          {t('desktopOnly.description')}
        </Typography>
        <Typography variant="body1" color="grey.800">
          {t('desktopOnly.description2')}
        </Typography>
      </TopWrapperStyled>
      <BottomWrapperStyled>
        <Button
          onClick={copyLink}
          fullWidth
          variant="contained"
          color="primary"
        >
          {t('desktopOnly.copy')}
        </Button>
        <Button
          onClick={() =>
            window.open(
              i18next.language.includes('en')
                ? GOSKY_USE_CASE_EN
                : GOSKY_USE_CASE,
              '_blank',
            )
          }
          fullWidth
          variant="outlined"
          color="primary"
        >
          {t('desktopOnly.case')}
        </Button>
        <Button
          onClick={() =>
            window.open(
              i18next.language.includes('en')
                ? FACEBOOK_TUTORIAL_EN
                : FACEBOOK_TUTORIAL_ZH,
              '_blank',
            )
          }
          fullWidth
          variant="outlined"
          color="primary"
        >
          {t('desktopOnly.tutorial')}
        </Button>
      </BottomWrapperStyled>
    </DesktopOnlyModalStyled>
  );
}

export default DesktopOnlyModal;
