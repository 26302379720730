import {
  NotificationContext,
  NotificationTypeEnum,
} from '@frontend/sorghum/interface';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';

export const NotificationCtx = createContext<NotificationContext>(
  {} as NotificationContext,
);

// 提供提示 NoticeBar 內容的 context
export const NotificationProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  // 累積了至少 1 個 rn 用戶，但未曾發過推播
  const [NEW_RN_RECEIVER, setNEW_RN_RECEIVER] = useState<boolean>(false);
  // 專案已成功發過至少 1 則推播以及未曾發布過 Flow
  const [FLOW_UNPUBLISHED, setFLOW_UNPUBLISHED] = useState<boolean>(false);
  // 沒有綁定 fb 粉專 or threads 帳號
  const [FAN_PAGE_DISCONNECT, setFAN_PAGE_DISCONNECT] =
    useState<boolean>(false);
  // 扣款失敗
  const [PAYMENT_ERROR, setPAYMENT_ERROR] = useState<boolean>(false);
  // Trial Plan 倒數
  const [TRIAL_PLAN_COUNTDOWN, setTRIAL_PLAN_COUNTDOWN] =
    useState<boolean>(false);
  // Trial 結束 (Facebook)
  const [TRIAL_END, setTRIAL_END] = useState<boolean>(false);
  // 人數抵達上限 Trial 結束 (Threads / Instagram)
  const [TRIAL_REACHED_LIMIT, setTRIAL_REACHED_LIMIT] =
    useState<boolean>(false);
  // Viewer Mode
  const [VIEWER, setVIEWER] = useState<boolean>(false);
  // 專案 Admin 權限變更
  const [PERMISSION_CHANGED, setPERMISSION_CHANGED] = useState<boolean>(false);

  // 修改對應的通知狀態
  const updateNoticeBarType = useCallback(
    (val: NotificationTypeEnum, status: boolean) => {
      switch (val) {
        case NotificationTypeEnum.PERMISSION_CHANGED: {
          setPERMISSION_CHANGED(!!status);
          break;
        }
        case NotificationTypeEnum.VIEWER: {
          setVIEWER(!!status);
          break;
        }
        case NotificationTypeEnum.PAYMENT_ERROR: {
          setPAYMENT_ERROR(!!status);
          break;
        }
        case NotificationTypeEnum.TRIAL_PLAN_COUNTDOWN: {
          setTRIAL_PLAN_COUNTDOWN(!!status);
          break;
        }
        case NotificationTypeEnum.TRIAL_END: {
          setTRIAL_END(!!status);
          break;
        }
        case NotificationTypeEnum.TRIAL_REACHED_LIMIT: {
          setTRIAL_REACHED_LIMIT(!!status);
          break;
        }
        case NotificationTypeEnum.FAN_PAGE_DISCONNECT: {
          setFAN_PAGE_DISCONNECT(!!status);
          break;
        }
        case NotificationTypeEnum.NEW_RN_RECEIVER: {
          setNEW_RN_RECEIVER(!!status);

          if (status) {
            setFLOW_UNPUBLISHED(false);
          }
          break;
        }
        case NotificationTypeEnum.FLOW_UNPUBLISHED: {
          setFLOW_UNPUBLISHED(!!status);

          if (status) {
            setNEW_RN_RECEIVER(false);
          }
          break;
        }
      }
    },
    [],
  );

  const clear = useCallback(() => {
    Object.values(NotificationTypeEnum).forEach(
      (_values: NotificationTypeEnum) => {
        updateNoticeBarType(_values, false);
      },
    );
  }, [updateNoticeBarType]);

  const { noticeBarType } = useMemo(() => {
    const newList = [];
    // 決定提示的優先級越上面越先顯示
    if (VIEWER) {
      newList.push(NotificationTypeEnum.VIEWER);
    }
    if (PERMISSION_CHANGED) {
      newList.push(NotificationTypeEnum.PERMISSION_CHANGED);
    }
    if (PAYMENT_ERROR) {
      newList.push(NotificationTypeEnum.PAYMENT_ERROR);
    }
    if (TRIAL_PLAN_COUNTDOWN) {
      newList.push(NotificationTypeEnum.TRIAL_PLAN_COUNTDOWN);
    }
    if (TRIAL_END) {
      newList.push(NotificationTypeEnum.TRIAL_END);
    }
    if (TRIAL_REACHED_LIMIT) {
      newList.push(NotificationTypeEnum.TRIAL_REACHED_LIMIT);
    }
    if (FAN_PAGE_DISCONNECT) {
      newList.push(NotificationTypeEnum.FAN_PAGE_DISCONNECT);
    }
    if (NEW_RN_RECEIVER) {
      newList.push(NotificationTypeEnum.NEW_RN_RECEIVER);
    }
    if (FLOW_UNPUBLISHED) {
      newList.push(NotificationTypeEnum.FLOW_UNPUBLISHED);
    }

    return {
      noticeBarType:
        // 只會顯示一個 notibar，取第一順位
        newList.length > 0 ? newList[0] : null,
    };
  }, [
    VIEWER,
    PERMISSION_CHANGED,
    PAYMENT_ERROR,
    TRIAL_PLAN_COUNTDOWN,
    TRIAL_END,
    TRIAL_REACHED_LIMIT,
    FAN_PAGE_DISCONNECT,
    NEW_RN_RECEIVER,
    FLOW_UNPUBLISHED,
  ]);

  return (
    <NotificationCtx.Provider
      value={{
        noticeBarType,
        clear,
        addNoticeBarType: (val: NotificationTypeEnum) =>
          updateNoticeBarType(val, true),
        removeNoticeBarType: (val: NotificationTypeEnum) =>
          updateNoticeBarType(val, false),
      }}
    >
      {children}
    </NotificationCtx.Provider>
  );
};
