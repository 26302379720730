import { SocialTypeEnum } from '@frontend/sorghum/interface';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { Badge, BadgeProps, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, useMemo } from 'react';
import { FacebookIcon, InstagramIcon, ThreadsIcon } from '../icons';

const BadgeStyled = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    minWidth: '16px',
  },
}));

const FanPageImageStyled = styled(Box)<{
  $picture: string | undefined;
  $size: 'small' | 'medium';
}>(({ theme, $picture, $size }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: $size === 'small' ? '24px' : '28px',
  height: $size === 'small' ? '24px' : '28px',
  borderRadius: '4px',
  backgroundColor:
    $size === 'small'
      ? theme.palette['bluegrey'][500]
      : theme.palette['bluegrey'][300],
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  ...($picture && {
    backgroundImage: `url(${$picture})`,
  }),
}));

export interface FanPageImageProps {
  size: 'small' | 'medium';
  picture: string | undefined;
  socialType: SocialTypeEnum | undefined;
}

export const FanPageImage: FC<FanPageImageProps> = ({
  size,
  picture,
  socialType,
}) => {
  const badgeContent = useMemo(() => {
    switch (socialType) {
      case SocialTypeEnum.FACEBOOK:
        return <FacebookIcon size="sm" />;
      case SocialTypeEnum.INSTAGRAM:
        return <InstagramIcon size="sm" />;
      case SocialTypeEnum.THREADS:
        return <ThreadsIcon size="sm" />;
      default:
        return <FacebookIcon size="sm" />;
    }
  }, [socialType]);

  return (
    <BadgeStyled badgeContent={badgeContent}>
      <FanPageImageStyled $size={size} $picture={picture}>
        {!picture && <FlagOutlinedIcon sx={{ color: 'bluegrey.700' }} />}
      </FanPageImageStyled>
    </BadgeStyled>
  );
};

export default FanPageImage;
