import {
  AutocompleteWithNestedOptionType,
  AutocompleteWithNestedOptions,
  Button,
  CompoundSelector,
  CompoundSelectorItemProps,
} from '@frontend/components/ui';
import {
  useCanvasUpdate,
  useGetCategoryValue,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  CategoryValueTypesEnum,
  ConditionCellType,
  DrawerTypesEnum,
  ElementTypesEnum,
  Rules,
} from '@frontend/editor/interface';
import { useGetAttributeKey } from '@frontend/sorghum/data-access';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { isEmpty } from 'lodash';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { EditorDrawer } from '../editor-drawer/editor-drawer';

const ContainerStyled = styled('div')(({ theme }) => ({
  whiteSpace: 'break-spaces',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '24px',
}));

const TopWrapperStyled = styled('div')(({ theme }) => ({}));

export const DrawerCondition = () => {
  const { data: projectID } = useGetProjectID();
  const [t] = useTranslation();
  const {
    onFocusCellID,
    getElement,
    setIsDrawerUpdate,
    setIsDrawerFullyUpdate,
    setOnFocusCellID,
  } = useContext(EditorCtx);
  const { setDrawerType, drawerType, closeModal } = useContext(UICtx);
  const { canvasUpdateData } = useCanvasUpdate();

  const { element } = useMemo(() => {
    const element = getElement(onFocusCellID) as ConditionCellType;

    return {
      element,
    };
  }, [getElement, onFocusCellID]);

  const [categoryType, setCategoryType] = useState<
    CategoryValueTypesEnum | null | undefined | string
  >(null);
  const [attributeValue, setAttributeValue] = useState<string>('');
  const [operator, setOperator] = useState<Rules>(1);
  const [optionValue, setOptionValue] = useState<string>('');

  const { data: attributeKeys } = useGetAttributeKey(projectID as string);

  const useGetCategoryValueType =
    categoryType === CategoryValueTypesEnum.RN_TOPIC ||
    categoryType === CategoryValueTypesEnum.TAG ||
    categoryType === CategoryValueTypesEnum.RN_TITLE
      ? (categoryType as CategoryValueTypesEnum)
      : CategoryValueTypesEnum.ATTRIBUTE;

  const { data: categoryValues } = useGetCategoryValue({
    projectID: projectID as string,
    type: useGetCategoryValueType ?? CategoryValueTypesEnum.ATTRIBUTE,
    ...(attributeValue && { attributeID: attributeValue }),
  });

  const categoryOption: AutocompleteWithNestedOptionType[] = useMemo(() => {
    const filterOptionList = [
      {
        group: t('canvas.condition.drawer.rn'),
        label: t('canvas.condition.drawer.topic'),
        value: CategoryValueTypesEnum.RN_TOPIC,
      },
      {
        group: t('canvas.condition.drawer.rn'),
        label: t('canvas.condition.drawer.title'),
        value: CategoryValueTypesEnum.RN_TITLE,
      },
      {
        group: t('canvas.condition.drawer.all'),
        label: t('canvas.condition.drawer.userTag'),
        value: CategoryValueTypesEnum.TAG,
      },
    ] as AutocompleteWithNestedOptionType[];

    if (!isEmpty(attributeKeys) && attributeKeys && attributeKeys?.length > 0) {
      attributeKeys.map((item) =>
        filterOptionList.push({
          value: item.id,
          label: item.key,
          group: t('canvas.condition.drawer.attribute'),
        }),
      );
    }
    return filterOptionList;
  }, [attributeKeys, t]);

  const categoryValueOption = useMemo(() => {
    if (categoryType === CategoryValueTypesEnum.RN_TOPIC) {
      return [
        { label: t('canvas.condition.drawer.subscribed'), value: 'subscribed' },
      ];
    } else if (categoryValues) {
      return categoryValues.map((i) => ({
        label: i.value,
        value: i.id,
      }));
    } else {
      return [];
    }
  }, [categoryType, categoryValues, t]);

  const isOpen = useMemo(() => {
    return element && element.elementType === ElementTypesEnum.CONDITION_CELL;
  }, [element]);

  const shouldApplyButtonDisabled = useMemo(() => {
    let shouldDisabled = false;

    if (!categoryType || !optionValue || !operator) {
      shouldDisabled = true;
    }
    return shouldDisabled;
  }, [categoryType, optionValue, operator]);

  const handleApply = useCallback(
    ({
      updatedCategoryType,
      updatedAttributeValue,
      updatedOperator,
      updatedOptionValue,
    }: {
      updatedCategoryType?: string | CategoryValueTypesEnum | null | undefined;
      updatedAttributeValue?: string;
      updatedOperator?: Rules;
      updatedOptionValue?: string;
    } = {}) => {
      const usedCategoryType = updatedCategoryType ?? categoryType;
      const usedAttributeValue = updatedAttributeValue ?? attributeValue;
      const usedOperator = updatedOperator ?? operator;
      const usedOptionValue = updatedOptionValue ?? optionValue;

      switch (usedCategoryType) {
        case CategoryValueTypesEnum.RN_TITLE:
        case CategoryValueTypesEnum.TAG:
        case CategoryValueTypesEnum.RN_TOPIC: {
          canvasUpdateData(element.id, 'categoryType', usedCategoryType);
          canvasUpdateData(
            element.id,
            'categoryTypeName',
            categoryOption?.find((i) => i.value === usedCategoryType)?.label,
          );
          break;
        }
        default: {
          canvasUpdateData(
            element.id,
            'categoryType',
            CategoryValueTypesEnum.ATTRIBUTE,
          );
          canvasUpdateData(element.id, 'attributeID', usedAttributeValue);
          canvasUpdateData(
            element.id,
            'categoryTypeName',
            categoryOption?.find((i) => i.value === usedAttributeValue)?.label,
          );
        }
      }

      canvasUpdateData(element.id, 'rule', usedOperator);
      canvasUpdateData(element.id, 'categoryValue', usedOptionValue);
      canvasUpdateData(
        element.id,
        'categoryValueName',
        categoryValueOption?.find((i) => i.value === usedOptionValue)?.label,
      );

      setIsDrawerUpdate(false);
      setIsDrawerFullyUpdate(true);
      setOnFocusCellID('');
      setDrawerType(DrawerTypesEnum.CLOSE);
      closeModal();
    },
    [
      categoryType,
      optionValue,
      operator,
      element,
      canvasUpdateData,
      categoryValueOption,
      categoryOption,
      attributeValue,
      setIsDrawerUpdate,
      setIsDrawerFullyUpdate,
      setOnFocusCellID,
      setDrawerType,
      closeModal,
    ],
  );

  const operatorOptions = useMemo(() => {
    return [
      { label: t('canvas.condition.drawer.is'), value: 1 },
      { label: t('canvas.condition.drawer.isNot'), value: 2 },
    ];
  }, [t]);

  const onOptionChange = useCallback(
    (
      _operator: CompoundSelectorItemProps | undefined,
      _option: CompoundSelectorItemProps | undefined,
    ) => {
      setOperator(_operator?.value as number);
      setOptionValue(_option?.value as string);
      setIsDrawerUpdate(true, () =>
        handleApply({
          updatedOperator: _operator?.value as number,
          updatedOptionValue: _option?.value as string,
        }),
      );
    },
    [setIsDrawerUpdate, handleApply],
  );

  useEffect(() => {
    if (element && drawerType === DrawerTypesEnum.CONDITION_CELL) {
      if (
        element.categoryType === CategoryValueTypesEnum.ATTRIBUTE &&
        element.attributeID
      ) {
        setCategoryType(element.attributeID);
        if (element.attributeID) {
          setAttributeValue(element.attributeID);
        } else {
          setAttributeValue('');
        }
      } else {
        setCategoryType(element.categoryType);
      }
      setOperator(element.rule);
      setOptionValue(element.categoryValue ? element.categoryValue : '');
    } else {
      setCategoryType(null);
      setAttributeValue('');
      setOperator(1);
      setOptionValue('');
    }
    setIsDrawerUpdate(false);
  }, [element, drawerType, setIsDrawerUpdate]);

  useEffect(() => {
    if (shouldApplyButtonDisabled) {
      setIsDrawerFullyUpdate(false);
    } else {
      setIsDrawerFullyUpdate(true);
    }
  }, [shouldApplyButtonDisabled, setIsDrawerFullyUpdate]);

  const onCategoryChange = useCallback(
    (_: object, newValue: AutocompleteWithNestedOptionType | null) => {
      if (!newValue) {
        setCategoryType(null);
        setAttributeValue('');
        setOptionValue('');
        return;
      }

      if (
        newValue.value !== CategoryValueTypesEnum.RN_TITLE &&
        newValue.value !== CategoryValueTypesEnum.RN_TOPIC &&
        newValue.value !== CategoryValueTypesEnum.TAG
      ) {
        setCategoryType(newValue.value);
        setAttributeValue(newValue.value ? (newValue.value as string) : '');
        setOptionValue('');
        setIsDrawerUpdate(true, () =>
          handleApply({
            updatedCategoryType: newValue.value,
            updatedAttributeValue: newValue.value
              ? (newValue.value as string)
              : '',
            updatedOptionValue: '',
          }),
        );
      } else {
        setCategoryType(newValue.value);
        setAttributeValue('');
        setOptionValue('');
        setIsDrawerUpdate(true, () =>
          handleApply({
            updatedCategoryType: newValue.value,
            updatedAttributeValue: '',
            updatedOptionValue: '',
          }),
        );
      }
    },
    [setIsDrawerUpdate, handleApply],
  );

  if (isOpen) {
    return (
      <EditorDrawer drawerType={DrawerTypesEnum.CONDITION_CELL}>
        <ContainerStyled>
          <TopWrapperStyled>
            <Typography
              sx={{
                mb: '12px',
              }}
              variant="h6"
              color="grey.900"
            >
              {t('canvas.condition.title')}
            </Typography>
            <Typography
              sx={{
                mb: '24px',
              }}
              variant="subtitle2"
              color="grey.900"
            >
              {t('canvas.condition.drawer.subtitle')}
            </Typography>
            <AutocompleteWithNestedOptions
              errorMessage={
                !categoryType ? t('canvas.condition.drawer.categoryEmpty') : ''
              }
              label={t('canvas.condition.drawer.category')}
              value={categoryType}
              required
              placeholder={t('canvas.condition.drawer.category')}
              options={categoryOption}
              onChange={onCategoryChange}
              isShowTooltip
            />
            <Box
              sx={{
                mt: '12px',
                width: '100%',
              }}
            >
              <CompoundSelector
                required
                label={t('canvas.condition.drawer.value')}
                disabled={!categoryType}
                operatorValue={operator}
                value={optionValue}
                operatorOptions={operatorOptions}
                valueOptions={categoryValueOption}
                onChange={onOptionChange}
                error={!optionValue}
                helperText={
                  !optionValue ? t('canvas.condition.drawer.valueEmpty') : ''
                }
                isShowTooltip
              />
            </Box>
          </TopWrapperStyled>
          <Button
            onClick={() => handleApply()}
            size="large"
            variant="contained"
            fullWidth
            disabled={shouldApplyButtonDisabled}
            sx={{ marginTop: '24px' }}
          >
            {t('common.save')}
          </Button>
        </ContainerStyled>
      </EditorDrawer>
    );
  } else {
    return null;
  }
};

export default memo(DrawerCondition);
