import { Button } from '@frontend/components/ui';
import {
  useCanvasUpdate,
  useGetProjectID,
  useGetSequenceOption,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  ActionCellTypesEnum,
  ActionSequenceType,
  AutocompleteOptionType,
  DrawerTypesEnum,
  SequenceOption,
} from '@frontend/editor/interface';
import { PAGE_SEQUENCE, usePath } from '@frontend/sorghum/utils';
import { Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { get, isEmpty } from 'lodash';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from '../autocomplete/autocomplete';
import { EditorDrawer } from '../editor-drawer/editor-drawer';

const ContainerStyled = styled('div')(({ theme }) => ({
  width: '360px',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '24px',
}));

const TopWrapperStyled = styled('div')(({ theme }) => ({
  whiteSpace: 'break-spaces',
}));

export const DrawerActionSequence = () => {
  const { data: projectID } = useGetProjectID();
  const { navigateToProjectPage } = usePath();
  const [t] = useTranslation();
  const {
    onFocusCellID,
    getElement,
    setIsDrawerUpdate,
    setIsDrawerFullyUpdate,
    setOnFocusCellID,
  } = useContext(EditorCtx);
  const { setDrawerType, closeModal } = useContext(UICtx);
  const { canvasUpdateData } = useCanvasUpdate();

  const { elementID, actionType, sequenceData, isSequence } = useMemo(() => {
    const element = getElement(onFocusCellID) as ActionSequenceType;
    const actionType = get(element, 'actionType', '');
    const sequenceData: SequenceOption | undefined = get(element, 'sequence');

    const isSequence =
      actionType === ActionCellTypesEnum.SUBSCRIBE_SEQUENCE ||
      actionType === ActionCellTypesEnum.UNSUBSCRIBE_SEQUENCE;
    return {
      elementID: element?.id,
      elementSequenceID: element?.sequence?.id,
      actionType,
      sequenceData,
      isSequence,
    };
  }, [getElement, onFocusCellID]);

  const [sequence, setSequence] = useState<SequenceOption | null | undefined>();

  const { data: sequenceOptions } = useGetSequenceOption({
    projectID: projectID as string,
  });

  const options = useMemo(() => {
    const newOptions: AutocompleteOptionType[] = [];
    if (
      !isEmpty(sequenceOptions) &&
      sequenceOptions &&
      sequenceOptions.length > 0
    ) {
      sequenceOptions.forEach((item: SequenceOption) => {
        newOptions.push({
          value: item.id,
          label: item.name,
        });
      });
    }
    return newOptions;
  }, [sequenceOptions]);

  const isOpen = useMemo(() => {
    return elementID && isSequence;
  }, [elementID, isSequence]);

  const shouldApplyButtonDisabled = useMemo(() => {
    let shouldDisabled = false;

    if (!sequence) {
      shouldDisabled = true;
    }
    return shouldDisabled;
  }, [sequence]);

  const handleApply = useCallback(
    ({
      updatedSequence,
    }: {
      updatedSequence?: SequenceOption | null | undefined;
    } = {}) => {
      const usedSequence = updatedSequence ?? sequence;

      canvasUpdateData(elementID, 'sequence', usedSequence);

      setIsDrawerUpdate(false);
      setIsDrawerFullyUpdate(true);
      setOnFocusCellID('');
      setDrawerType(DrawerTypesEnum.CLOSE);
      closeModal();
    },
    [
      canvasUpdateData,
      elementID,
      sequence,
      setIsDrawerUpdate,
      setIsDrawerFullyUpdate,
      setOnFocusCellID,
      setDrawerType,
      closeModal,
    ],
  );

  const handleOptionChange = useCallback(
    (option: AutocompleteOptionType | null) => {
      if (option) {
        setSequence({
          id: option.value as string,
          name: option.label,
        });
        setIsDrawerUpdate(true, () =>
          handleApply({
            updatedSequence: {
              id: option.value as string,
              name: option.label,
            },
          }),
        );
      } else {
        setSequence(null);
      }
    },
    [setIsDrawerUpdate, handleApply],
  );

  useEffect(() => {
    if (sequenceData) {
      setSequence(sequenceData);
    } else {
      setSequence(null);
    }
    setIsDrawerUpdate(false);
  }, [sequenceData, setIsDrawerUpdate]);

  useEffect(() => {
    if (shouldApplyButtonDisabled) {
      setIsDrawerFullyUpdate(false);
    } else {
      setIsDrawerFullyUpdate(true);
    }
  }, [shouldApplyButtonDisabled, setIsDrawerFullyUpdate]);

  if (isOpen) {
    return (
      <EditorDrawer drawerType={DrawerTypesEnum.ACTION_SEQUENCE}>
        <ContainerStyled>
          <TopWrapperStyled>
            <Typography
              sx={{
                mb: '20px',
              }}
              variant="h6"
              color="grey.900"
            >
              {actionType === ActionCellTypesEnum.SUBSCRIBE_SEQUENCE
                ? t('canvas.action.subscribe.title')
                : t('canvas.action.unsubscribe.title')}
            </Typography>
            <Typography
              sx={{
                mb: '24px',
              }}
              variant="body2"
              color="grey.black"
            >
              {actionType === ActionCellTypesEnum.SUBSCRIBE_SEQUENCE
                ? t('canvas.action.subscribe.info')
                : t('canvas.action.unsubscribe.info')}
              &nbsp;&nbsp;
              {actionType === ActionCellTypesEnum.SUBSCRIBE_SEQUENCE && (
                <Link
                  color="info.main"
                  onClick={() =>
                    navigateToProjectPage({ path: `${PAGE_SEQUENCE}` })
                  }
                >
                  {t('canvas.action.subscribe.link')}
                </Link>
              )}
            </Typography>
            <Autocomplete
              required
              placeholder={t('canvas.action.drawer.sequencePlaceholder')}
              label={t('canvas.action.drawer.sequenceName')}
              value={sequence?.id}
              options={options}
              onChange={handleOptionChange}
              error={
                !sequence?.id ? t('canvas.action.drawer.sequenceRequired') : ''
              }
              isShowTooltip
            />
          </TopWrapperStyled>
          <Button
            onClick={() => handleApply()}
            size="large"
            variant="contained"
            fullWidth
            disabled={shouldApplyButtonDisabled}
            sx={{ marginTop: '24px' }}
          >
            {t('common.save')}
          </Button>
        </ContainerStyled>
      </EditorDrawer>
    );
  } else {
    return null;
  }
};

export default memo(DrawerActionSequence);
