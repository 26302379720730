import {
  PostDataUserCollectAnswerExportParams,
  PostDataUserCollectAnswerExportResponse,
  ResponseWithData,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_USER_COLLECT_ANSWER_EXPORT } from '../apis';

async function postDataUserCollectAnswerExport(
  params: PostDataUserCollectAnswerExportParams,
): Promise<ResponseWithData<PostDataUserCollectAnswerExportResponse>> {
  return axios
    .post(DATA_USER_COLLECT_ANSWER_EXPORT, params)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostDataUserCollectAnswerExport() {
  return useMutation({
    mutationKey: ['post-data-user-collect-answer-export'],
    mutationFn: async (props: PostDataUserCollectAnswerExportParams) => {
      const data = await postDataUserCollectAnswerExport(props);
      return data.data.fileUrl;
    },
  });
}

export default usePostDataUserCollectAnswerExport;
