import {
  useCanvasPublish,
  useCanvasView,
  useProjectPermission,
} from '@frontend/editor/data-access';
import {
  EditorCtx,
  UICtx as EditorUICtx,
} from '@frontend/editor/external-providers';
import {
  DrawerTypesEnum,
  ModalTypesEnum as EditorModalTypesEnum,
} from '@frontend/editor/interface';
import {
  useGetCommentReply,
  useGetCommentReplyCondition,
  usePatchCommentReplyCondition,
  useProjectLinked,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  CommentReplyPostType,
  ModalTypesEnum,
  SocialTypeEnum,
} from '@frontend/sorghum/interface';
import { useTimer } from '@frontend/sorghum/utils';
import { get } from 'lodash';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactFlow, { useStoreApi } from 'reactflow';
import 'reactflow/dist/style.css';
import ConfirmCloseDrawerModal from '../confirm-close-drawer-modal/confirm-close-drawer-modal';
import ConnectionLineComponent from '../connection-line-component/connection-line-component';
import Control from '../control/control';
import DrawerCommentReplyConditions from '../drawer-comment-reply-conditions/drawer-comment-reply-conditions';
import DrawerThreadsCommentReplyPeriod from '../drawer-threads-comment-reply-period/drawer-threads-comment-reply-period';
import DrawerThreadsCommentReplyTo from '../drawer-threads-comment-reply-to/drawer-threads-comment-reply-to';
import EditorCanvasContainer from '../editor-canvas-container/editor-canvas-container';
import {
  CustomFocusMarker,
  CustomMarker,
} from '../editor-custom-edge/editor-custom-edge';
import EditorLoadingModal from '../editor-loading-modal/editor-loading-modal';
import NodeBlock from '../node-block/node-block';
import NodePublicReplyBlock from '../node-public-reply-block/node-public-reply-block';
import NodeThreadsCommentReplyBlock from '../node-threads-comment-reply-block/node-threads-comment-reply-block';

const nodeTypes = {
  BLOCK: NodeBlock,
  THREADS_BLOCK: NodeThreadsCommentReplyBlock,
  PUBLIC_REPLY: NodePublicReplyBlock,
};

interface Props {
  commentAutoReplyID: string;
  mode?: 'template';
  postType?: CommentReplyPostType;
}

// threads 專用
export const FlowCommentAutoReply = ({ commentAutoReplyID }: Props) => {
  const socialType = SocialTypeEnum.THREADS;
  const store = useStoreApi();
  const { nodeInternals } = store.getState();
  const {
    drawerType,
    openModal: openEditorModal,
    modalType,
    closeDrawer,
    closeModal,
  } = useContext(EditorUICtx);
  const {
    isDrawerUpdate,
    isDrawerFullyUpdate,
    checkDataUpdated,
    setIsUpdated,
    setOnFocusCellID,
    drawerCallback,
  } = useContext(EditorCtx);
  const { openModal } = useContext(UICtx);
  const { isViewer } = useProjectPermission();

  const { data: commentAutoReplyData } = useGetCommentReply(commentAutoReplyID);

  const { data: commentAutoReplyCondition } = useGetCommentReplyCondition(
    get(commentAutoReplyData, 'replyCondition.data.0.id', ''),
  );

  const { mutate: updateCondition } = usePatchCommentReplyCondition();
  const { getPublishData } = useCanvasPublish();
  const { data: linked } = useProjectLinked();
  const { focusNodeByReactFlowID } = useCanvasView();
  const [isFirstFocus, setIsFirstFocus] = useState(false);
  const [panOnDrag, setPanOnDrag] = useState(true);
  const isActive = commentAutoReplyData?.isActive;

  //如果沒有連線會阻擋點擊行為並開啟 modal
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (!linked?.threads?.id) {
        e.preventDefault();
        e.stopPropagation();
        openModal(ModalTypesEnum.CONNECT_THREADS_MODAL);
      }
      // 點擊畫布會解除 focus 進而關閉 text-cell-button-modal
      setOnFocusCellID('');
    },
    [linked?.threads?.id, openModal, setOnFocusCellID],
  );

  const handleCanvasClick = useCallback(
    (e: React.MouseEvent) => {
      if (isDrawerUpdate) {
        e.preventDefault();
        e.stopPropagation();
        openEditorModal(EditorModalTypesEnum.CLOSE_UNSAVED_DRAWER);
      } else {
        closeDrawer();
      }
    },
    [closeDrawer, isDrawerUpdate, openEditorModal],
  );

  const handleCloseAndSaveDrawer = useCallback(() => {
    if (drawerCallback) {
      drawerCallback();
    }
  }, [drawerCallback]);

  const { initialNodes, initialEdges } = useMemo(() => {
    return {
      initialNodes: [
        {
          id: '1',
          type: 'THREADS_BLOCK',
          data: { commentAutoReplyID, socialType },
          position: {
            x: 0,
            y: 0,
          },
        },
        {
          id: '2',
          type: 'PUBLIC_REPLY',
          data: {
            commentAutoReplyID,
            socialType,
          },
          position: {
            x: 400,
            y: 0,
          },
        },
      ],
      initialEdges: [
        {
          source: '1',
          target: '2',
          id: '1',
        },
      ],
    };
  }, [commentAutoReplyID, socialType]);

  const Drawer = useMemo(() => {
    if (isActive) return null;
    switch (drawerType) {
      case DrawerTypesEnum.THREADS_COMMENT_REPLY_TO:
        return DrawerThreadsCommentReplyTo;
      case DrawerTypesEnum.THREADS_COMMENT_REPLY_PERIOD:
        return DrawerThreadsCommentReplyPeriod;
      case DrawerTypesEnum.COMMENT_REPLY_CONDITIONS:
        return DrawerCommentReplyConditions;
    }
    return null;
  }, [drawerType, isActive]);

  const updateFlowChart = useCallback(() => {
    if (isViewer) return;
    const publishData = getPublishData();
    const block = get(publishData.blocks, '0', null);
    if (commentAutoReplyID && commentAutoReplyCondition && block) {
      updateCondition(
        {
          id: commentAutoReplyID,
          isAutoSave: true,
          replyCondition: [
            {
              ...commentAutoReplyCondition,
              isWarning: false,
              privateReplyContent: {
                id: commentAutoReplyCondition.privateReplyContent?.id || '',
                block,
              },
            },
          ],
        },
        {
          onSuccess: () => setIsUpdated(false),
        },
      );
    }
  }, [
    commentAutoReplyCondition,
    commentAutoReplyID,
    getPublishData,
    isViewer,
    setIsUpdated,
    updateCondition,
  ]);

  useEffect(() => {
    const nodes = Array.from(nodeInternals).map(([, node]) => node);

    if (nodes.length > 0 && !isFirstFocus) {
      setTimeout(() => {
        focusNodeByReactFlowID('1');
      });
      setIsFirstFocus(true);
    }
  }, [focusNodeByReactFlowID, isFirstFocus, nodeInternals]);

  useTimer(2, checkDataUpdated(), updateFlowChart);

  return (
    <Fragment>
      <EditorCanvasContainer onClickCapture={handleClick}>
        <EditorLoadingModal />
        {Drawer && <Drawer />}
        <ReactFlow
          onClickCapture={handleCanvasClick}
          nodes={initialNodes}
          edges={initialEdges}
          nodeTypes={nodeTypes}
          minZoom={0.1}
          connectionLineComponent={ConnectionLineComponent}
          nodesDraggable={false}
          panOnDrag={panOnDrag}
          onNodeMouseEnter={() => setPanOnDrag(false)}
          onNodeMouseLeave={() => setPanOnDrag(true)}
          preventScrolling={true}
          deleteKeyCode={null}
          zoomOnDoubleClick={false}
        >
          <Control showSelectionMode={false} />
        </ReactFlow>
        <CustomMarker />
        <CustomFocusMarker />
      </EditorCanvasContainer>
      <ConfirmCloseDrawerModal
        open={modalType === EditorModalTypesEnum.CLOSE_UNSAVED_DRAWER}
        handleCancel={closeModal}
        handleConfirm={handleCloseAndSaveDrawer}
        disableConfirmButton={!isDrawerFullyUpdate}
      />
    </Fragment>
  );
};
