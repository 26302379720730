import { ResponseWithData } from '@frontend/editor/interface';
import {
  PostCommentReplyRequest,
  PostCommentReplyResponse,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_AUTO_REPLY } from '../apis';

async function postCommentReply(
  props: PostCommentReplyRequest,
): Promise<ResponseWithData<PostCommentReplyResponse>> {
  return axios
    .post(`${COMMENT_AUTO_REPLY}`, props)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostCommentReply() {
  return useMutation({
    mutationKey: ['post-comment-reply'],
    mutationFn: (props: PostCommentReplyRequest) => postCommentReply(props),
  });
}

export default usePostCommentReply;
