import { Response } from '@frontend/editor/interface';
import { FlowChart } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ADMIN_FLOW_TEMPLATE } from '../apis';

interface Props {
  // 0 - 空白模板 1 - RN教學模板, 2 - 自動回覆模板, 3 - 創意互動模板
  chartTemplateID: number;
  language: 'en_us' | 'zh_tw';
  chart: FlowChart;
  description?: string;
}

async function postAdminFlowTemplate({
  chartTemplateID,
  language,
  chart,
  description,
}: Props): Promise<Response> {
  return axios
    .post(`${ADMIN_FLOW_TEMPLATE}`, {
      chartTemplateId: chartTemplateID,
      language: language,
      chart: chart,
      description: description,
    })
    .then((res) => res.data);
}

export function usePostAdminFlowTemplate() {
  return useMutation({
    mutationKey: ['post-admin-flow-template'],
    mutationFn: (props: Props) => postAdminFlowTemplate(props),
  });
}

export default usePostAdminFlowTemplate;
