import { Box, BoxProps, TextField } from '@mui/material';
import { styled } from '@mui/system';

interface InputFieldTypeStyled extends BoxProps {
  $focus: boolean;
  $error: boolean;
  $readOnly: boolean;
}

export const InputFieldStyled = styled(TextField)<InputFieldTypeStyled>(
  ({ theme, $focus, $error, $readOnly }) => ({
    backgroundColor: '#fff',
    borderRadius: '4px',
    width: '100%',
    '& input': {
      padding: '10px 15px',
      textAlign: 'center',
    },
    cursor: 'initial',
    ...($error && {
      border: `1px solid ${theme.palette['error']['main']}`,
    }),
    ...($focus && {
      border: `1px solid ${theme.palette['info']['main']}`,
    }),

    fieldset: {
      border: 0,
    },

    '&.jump-to-flow': {
      height: '60px',

      ...($focus && {
        border: `1px solid ${theme.palette['info']['main']}`,
      }),
    },

    '& .Mui-disabled': {
      ...($readOnly && {
        '-webkit-text-fill-color': theme.palette['grey'][900],
      }),
    },
  }),
);

export const TooltipStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '0',
  bottom: '-30px',
}));

export const FlowNameContainerStyled = styled(Box)(({ theme }) => ({
  display: 'block',
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));
