import {
  GetBillingPlanEnum,
  GetBillingResponseType,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { BILLING } from '../apis';

const defaultValue: GetBillingResponseType = {
  plan: GetBillingPlanEnum.EXPIRY,
  country: 'TW',
};

async function getGetBilling(): Promise<GetBillingResponseType> {
  return axios
    .get(`${BILLING}`)
    .then((res) => res.data.data)
    .catch((err) => defaultValue);
}

export function useGetBilling() {
  const projectID = sessionStorage.getItem('project-id');
  return useQuery<GetBillingResponseType>({
    queryKey: ['get-billing', { projectID }],
    queryFn: () => {
      if (projectID) return getGetBilling();
      else return defaultValue;
    },
  });
}

export default useGetBilling;
