import { globalTheme } from '@frontend/components/external-providers';
import { Button } from '@frontend/components/ui';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  DrawerTypesEnum,
  ModalTypesEnum as EditorModalTypesEnum,
} from '@frontend/editor/interface';

import {
  useGetCommentReply,
  usePatchCommentReplyPosts,
} from '@frontend/sorghum/data-access';
import {
  CommentReplyPostType,
  GetCommentAutoReplyPost,
  PostType,
  SocialTypeEnum,
} from '@frontend/sorghum/interface';
import { dayjs } from '@frontend/sorghum/utils';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PostImage from '../../../images/post-image.svg';
import CommentReplyPostModal from '../comment-reply-post-modal/comment-reply-post-modal';
import { EditorDrawer } from '../editor-drawer/editor-drawer';
import {
  ApplyButtonStyled,
  ContainerStyled,
  ContentWrapperStyled,
  ReplyContentStyled,
  ReplyPostRowDescriptionStyled,
  ReplyPostRowStyled,
  ReplyPostsWrapperStyled,
  ReplyTitleWrapper,
  ReplyWrapperStyled,
  TitleWrapper,
} from './style';

type SelectedPostType = PostType | GetCommentAutoReplyPost;

export const DrawerInstagramCommentReplyTo: FC = () => {
  const { commentAutoReplyID } = useParams();
  const [t] = useTranslation('instagram');

  const { data: commentReplyDataFromAPI } = useGetCommentReply(
    commentAutoReplyID as string,
  );
  const { mutate: updateCommentReplyPosts } = usePatchCommentReplyPosts();

  const { drawerType, openModal, closeModal, setDrawerType } =
    useContext(UICtx);
  const { setIsDrawerUpdate, setIsDrawerFullyUpdate } = useContext(EditorCtx);

  const [replyToRadio, setReplyToRadio] = useState<number>(2);
  const [selectPosts, setSelectPosts] = useState<SelectedPostType[]>([]);

  const handleApply = useCallback(
    ({ radio, posts }: { radio?: number; posts?: SelectedPostType[] } = {}) => {
      if (commentAutoReplyID) {
        const replyType = radio ?? replyToRadio;
        const replyPosts = posts ?? selectPosts;

        updateCommentReplyPosts({
          id: commentAutoReplyID,
          isAutoSave: false,
          replyType,
          ...(replyType === 1 && {
            posts: replyPosts.map((i) => ({
              id: i.id,
              permalinkUrl: i.permalinkUrl,
              message: i.message,
              createdTime: i.createdTime,
            })),
          }),
        });
      }

      setIsDrawerUpdate(false);
      setIsDrawerFullyUpdate(true);
      setDrawerType(DrawerTypesEnum.CLOSE);
      closeModal();
    },
    [
      commentAutoReplyID,
      setDrawerType,
      setIsDrawerUpdate,
      setIsDrawerFullyUpdate,
      closeModal,
      updateCommentReplyPosts,
      replyToRadio,
      selectPosts,
    ],
  );

  const handleRadioChange = useCallback(
    (val: number) => {
      setReplyToRadio(val);
      setIsDrawerUpdate(true, () => handleApply({ radio: val, posts: [] }));
    },
    [setIsDrawerUpdate, handleApply],
  );

  const handlePostSelect = useCallback(
    (newValue: GetCommentAutoReplyPost[]) => {
      setSelectPosts(newValue);
      setIsDrawerUpdate(true, () => handleApply({ radio: 1, posts: newValue }));
    },
    [setIsDrawerUpdate, handleApply],
  );

  const shouldApplyButtonDisabled = useMemo(() => {
    let shouldDisabled = false;
    if (replyToRadio === 1 && selectPosts.length === 0) {
      shouldDisabled = true;
    }
    return shouldDisabled;
  }, [replyToRadio, selectPosts]);

  const { title, radio1 } = useMemo(() => {
    switch (commentReplyDataFromAPI?.postType) {
      case CommentReplyPostType.POST: {
        return {
          title: t('commentAutoReply.drawer.title.post'),
          radio1: t('commentAutoReply.drawer.replyTo.post.radio1'),
        };
      }
      case CommentReplyPostType.STORY: {
        return {
          title: t('commentAutoReply.drawer.title.story'),
          radio1: t('commentAutoReply.drawer.replyTo.story.radio1'),
        };
      }
      case CommentReplyPostType.LIVE:
      default: {
        return {
          title: t('commentAutoReply.drawer.title.live'),
          radio1: t('commentAutoReply.drawer.replyTo.live.radio1'),
        };
      }
    }
  }, [commentReplyDataFromAPI?.postType, t]);

  useEffect(() => {
    if (drawerType === DrawerTypesEnum.INSTAGRAM_COMMENT_REPLY_TO) {
      setReplyToRadio(
        commentReplyDataFromAPI?.selectPosts
          ? commentReplyDataFromAPI.selectPosts.replyType
          : 2,
      );
      setSelectPosts(
        commentReplyDataFromAPI?.selectPosts
          ? commentReplyDataFromAPI.selectPosts.posts
          : [],
      );
      setIsDrawerUpdate(false);
    }
  }, [commentReplyDataFromAPI, drawerType, setIsDrawerUpdate]);

  useEffect(() => {
    if (shouldApplyButtonDisabled) {
      setIsDrawerFullyUpdate(false);
    } else {
      setIsDrawerFullyUpdate(true);
    }
  }, [shouldApplyButtonDisabled, setIsDrawerFullyUpdate]);

  return (
    <EditorDrawer drawerType={DrawerTypesEnum.INSTAGRAM_COMMENT_REPLY_TO}>
      <ContainerStyled>
        <ContentWrapperStyled>
          <TitleWrapper>
            <Typography variant="h6" color="grey.900">
              {title}
            </Typography>
          </TitleWrapper>

          <ReplyContentStyled>
            <ReplyWrapperStyled>
              <ReplyTitleWrapper>
                <Typography variant="subtitle2" color="black">
                  {t('commentAutoReply.drawer.replyTo.title')}
                </Typography>
              </ReplyTitleWrapper>
              <RadioGroup row value={replyToRadio}>
                <FormControlLabel
                  value="2"
                  control={<Radio size="small" />}
                  label={<Typography variant="body3">{radio1}</Typography>}
                  onClick={() => handleRadioChange(2)}
                />
                {commentReplyDataFromAPI?.postType ===
                  CommentReplyPostType.POST && (
                  <FormControlLabel
                    value="1"
                    control={<Radio size="small" />}
                    label={
                      <Typography variant="body3">
                        {t('commentAutoReply.drawer.replyTo.post.radio2')}
                      </Typography>
                    }
                    onClick={() => handleRadioChange(1)}
                  />
                )}
              </RadioGroup>
            </ReplyWrapperStyled>

            {replyToRadio === 1 && (
              <ReplyWrapperStyled>
                <ReplyTitleWrapper>
                  <Typography variant="subtitle2" color="black">
                    {t('commentAutoReply.drawer.replyTo.subtitle')}
                  </Typography>
                </ReplyTitleWrapper>
                {selectPosts.length > 0 && (
                  <ReplyPostsWrapperStyled>
                    {selectPosts.map((post) => (
                      <ReplyPostRowStyled key={post.id}>
                        <img src={post.fullPicture || PostImage} alt="" />
                        <ReplyPostRowDescriptionStyled
                          onClick={() =>
                            window.open(post.permalinkUrl, '_blank')
                          }
                        >
                          <Typography
                            component="div"
                            color="grey.900"
                            variant="body3"
                          >
                            {post.message
                              ? post.message
                              : t('commentAutoReply.drawer.replyTo.postNoText')}
                          </Typography>
                          <Typography color="grey.500" variant="notoSans">
                            {dayjs(post.createdTime)
                              .tz()
                              .format('MMMM D, YYYY [at] h:mm A')}
                          </Typography>
                        </ReplyPostRowDescriptionStyled>
                      </ReplyPostRowStyled>
                    ))}
                  </ReplyPostsWrapperStyled>
                )}
                <Button
                  onClick={() => {
                    openModal(EditorModalTypesEnum.COMMENT_REPLY_POST);
                  }}
                  size="medium"
                  variant="outlined"
                  fullWidth
                  sx={{
                    color: globalTheme.palette?.['bluegrey']?.[500],
                    border: `1px solid ${globalTheme.palette?.['bluegrey']?.[400]}`,
                  }}
                >
                  {t('commentAutoReply.drawer.replyTo.button')}
                </Button>
              </ReplyWrapperStyled>
            )}
          </ReplyContentStyled>

          <ApplyButtonStyled
            onClick={() => handleApply()}
            size="large"
            variant="contained"
            fullWidth
            disabled={shouldApplyButtonDisabled}
            sx={{ height: '48px' }}
          >
            {t('commentAutoReply.drawer.saveButton')}
          </ApplyButtonStyled>
        </ContentWrapperStyled>
      </ContainerStyled>

      <CommentReplyPostModal
        title={t('commentAutoReply.drawer.replyTo.modal.title')}
        commentAutoReplyID={commentAutoReplyID as string}
        selectedValue={selectPosts}
        handleSubmit={handlePostSelect}
        socialType={SocialTypeEnum.INSTAGRAM}
      />
    </EditorDrawer>
  );
};

export default memo(DrawerInstagramCommentReplyTo);
