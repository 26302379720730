import { Dialog, Typography } from '@frontend/components/ui';
import { usePostProject } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  ModalTypesEnum,
  ProjectListItem,
  SocialTypeEnum,
} from '@frontend/sorghum/interface';
import { PAGE_MAIN, sendGAEvent, usePath } from '@frontend/sorghum/utils';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FacebookIcon, InstagramIcon, ThreadsIcon } from '../icons';
import {
  BetaChipStyled,
  ChannelOptionStyled,
  ChannelOptionWrapper,
} from './styles';

const ChannelOption = ({
  channel,
  title,
  hasBeta = false,
  isDisabled = false,
  isSelected,
  onClick,
  icon,
  id,
}: {
  channel: SocialTypeEnum;
  title: string;
  hasBeta?: boolean;
  isDisabled?: boolean;
  isSelected: boolean;
  onClick: () => void;
  icon: JSX.Element;
  id: string;
}) => {
  const [t] = useTranslation();

  return (
    <ChannelOptionStyled
      id={id}
      onClick={onClick}
      $isDisabled={isDisabled}
      $isSelected={isSelected}
    >
      {icon}
      <Typography variant="h6">{title}</Typography>
      {hasBeta && (
        <BetaChipStyled>
          <Typography variant="body3" color="primary">
            {t('flows.leftSidebar.selectChannelModal.beta')}
          </Typography>
        </BetaChipStyled>
      )}
    </ChannelOptionStyled>
  );
};

interface SelectChannelProps {
  projectList: ProjectListItem[] | never[];
}

export const SelectChannelModal: FC<SelectChannelProps> = ({ projectList }) => {
  const [t] = useTranslation();
  const { modalType, closeModal, openUpgradeProjectModal } = useContext(UICtx);
  const { navigateToProjectPage } = usePath();
  const { mutate: createProject } = usePostProject();

  const [selectedChannel, setSelectedChannel] = useState(0);

  const { hasFaceBookProject, hasThreadsProject, hasInstagramProject } =
    useMemo(() => {
      let hasFaceBookProject = false;
      let hasThreadsProject = false;
      let hasInstagramProject = false;

      const projectSocialTypeList = projectList.map((item) => item.socialType);

      if (projectSocialTypeList.includes(SocialTypeEnum.FACEBOOK)) {
        hasFaceBookProject = true;
      }
      if (projectSocialTypeList.includes(SocialTypeEnum.THREADS)) {
        hasThreadsProject = true;
      }
      if (projectSocialTypeList.includes(SocialTypeEnum.INSTAGRAM)) {
        hasInstagramProject = true;
      }

      return { hasFaceBookProject, hasThreadsProject, hasInstagramProject };
    }, [projectList]);

  const handleClose = useCallback(() => {
    closeModal();
    setSelectedChannel(0);
  }, [closeModal]);

  const handleConfirm = useCallback(() => {
    switch (selectedChannel) {
      case SocialTypeEnum.FACEBOOK: {
        if (hasFaceBookProject) {
          closeModal();
          openUpgradeProjectModal(SocialTypeEnum.FACEBOOK);
        } else {
          closeModal();
          createProject(
            {
              name: t('flows.defaultProjectName'),
              socialType: SocialTypeEnum.FACEBOOK,
            },
            {
              onSuccess: (data) => {
                sendGAEvent(
                  'Select a channel_project',
                  'Select and create a facebook',
                  'Select a channel_project-facebook-create',
                  '',
                );
                navigateToProjectPage({
                  path: `${PAGE_MAIN}?shortCode=${data.data.shortCode}`,
                  backToMain: true,
                });
              },
            },
          );
        }

        setSelectedChannel(0);
        break;
      }
      case SocialTypeEnum.THREADS: {
        if (hasThreadsProject) {
          closeModal();
          openUpgradeProjectModal(SocialTypeEnum.THREADS);
        } else {
          closeModal();
          createProject(
            {
              name: t('flows.defaultProjectName'),
              socialType: SocialTypeEnum.THREADS,
              commentReplyName: t(
                'commentAutoReply.list.defaultCommentAutoReplyName',
              ),
              conditionName: t('commentAutoReply.list.defaultConditionName'),
            },
            {
              onSuccess: (data) => {
                sendGAEvent(
                  'Select a channel_project',
                  'Select and create a threads',
                  'Select a channel_project-threads-create',
                  '',
                );
                navigateToProjectPage({
                  path: `${PAGE_MAIN}?shortCode=${data.data.shortCode}`,
                  backToMain: true,
                });
              },
            },
          );
        }

        setSelectedChannel(0);
        break;
      }
      case SocialTypeEnum.INSTAGRAM: {
        if (hasInstagramProject) {
          closeModal();
          openUpgradeProjectModal(SocialTypeEnum.INSTAGRAM);
        } else {
          closeModal();
          createProject(
            {
              name: t('flows.defaultProjectName'),
              socialType: SocialTypeEnum.INSTAGRAM,
              commentReplyName: t(
                'commentAutoReply.list.defaultInstagramCommentAutoReplyName',
              ),
              conditionName: t('commentAutoReply.list.defaultConditionName'),
            },
            {
              onSuccess: (data) => {
                sendGAEvent(
                  'Select a channel_project',
                  'Select and create a instagram',
                  'Select a channel_project-instagram-create',
                  '',
                );
                navigateToProjectPage({
                  path: `${PAGE_MAIN}?shortCode=${data.data.shortCode}`,
                  backToMain: true,
                });
              },
            },
          );
        }

        setSelectedChannel(0);
        break;
      }
      default:
        closeModal();
        setSelectedChannel(0);
    }
  }, [
    selectedChannel,
    closeModal,
    hasFaceBookProject,
    openUpgradeProjectModal,
    createProject,
    t,
    navigateToProjectPage,
    hasThreadsProject,
    hasInstagramProject,
  ]);

  return (
    <Dialog
      size="xs"
      open={modalType === ModalTypesEnum.SELECT_CHANNEL}
      title={t('flows.leftSidebar.selectChannelModal.title')}
      handleClose={handleClose}
      cancelBtnText={t('common.cancel')}
      confirmBtnText={t('common.add')}
      handleConfirm={handleConfirm}
      closeBtn={false}
      disableConfirmButton={selectedChannel === 0}
    >
      <ChannelOptionWrapper>
        <ChannelOption
          id="select_channel_instagram"
          channel={SocialTypeEnum.INSTAGRAM}
          title={t('flows.leftSidebar.selectChannelModal.ig')}
          isSelected={selectedChannel === SocialTypeEnum.INSTAGRAM}
          onClick={() => setSelectedChannel(SocialTypeEnum.INSTAGRAM)}
          icon={<InstagramIcon size="lg" />}
        />
        <ChannelOption
          id="select_channel_threads"
          channel={SocialTypeEnum.THREADS}
          title={t('flows.leftSidebar.selectChannelModal.threads')}
          isSelected={selectedChannel === SocialTypeEnum.THREADS}
          onClick={() => setSelectedChannel(SocialTypeEnum.THREADS)}
          icon={<ThreadsIcon size="lg" />}
        />
        <ChannelOption
          id="select_channel_facebook"
          channel={SocialTypeEnum.FACEBOOK}
          title={t('flows.leftSidebar.selectChannelModal.messenger')}
          isSelected={selectedChannel === SocialTypeEnum.FACEBOOK}
          onClick={() => {
            setSelectedChannel(SocialTypeEnum.FACEBOOK);
          }}
          icon={<FacebookIcon size="lg" />}
        />
      </ChannelOptionWrapper>
    </Dialog>
  );
};

export default SelectChannelModal;
