import { useAuth } from '@frontend/sorghum/data-access';
import { useInstagram as useInstagramPeople } from '@frontend/sorghum/features/people';
import { useInstagramSetting } from '@frontend/sorghum/features/setting';
import { SocialTypeEnum } from '@frontend/sorghum/interface';
import {
  COMMENT_AUTO_REPLY,
  DASHBOARD,
  IG,
  PAYMENT_DETAILS,
  PEOPLE,
  SETTING,
  TEMPLATE,
  TEMPLATE_SELECTION,
  withPageBoundary,
} from '@frontend/sorghum/utils';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { useRoutes } from './useRoutes';

const Dashboard = lazy(() =>
  import('@frontend/sorghum/features/dashboard').then(
    ({ SorghumFeaturesDashboard: Page }) => ({
      default: Page,
    }),
  ),
);

const People = lazy(() =>
  import('@frontend/sorghum/features/people').then(
    ({ SorghumFeaturesPeople: Page }) => ({
      default: Page,
    }),
  ),
);

const Setting = lazy(() =>
  import('@frontend/sorghum/features/setting').then(
    ({ SorghumFeaturesSetting: Page }) => ({
      default: Page,
    }),
  ),
);

const PaymentDetail = lazy(() =>
  import('@frontend/sorghum/features/payment-details').then(
    ({ SorghumFeaturesPaymentDetails: Page }) => ({ default: Page }),
  ),
);

const CommentReplyDetail = lazy(() =>
  import('@frontend/sorghum/features/instagram-comment-reply-detail').then(
    ({ SorghumFeaturesInstagramCommentReplyDetail: Page }) => ({
      default: Page,
    }),
  ),
);

const CommentReplyList = lazy(() =>
  import('@frontend/sorghum/features/instagram-comment-reply-list').then(
    ({ SorghumFeaturesInstagramCommentReplyList: Page }) => ({
      default: Page,
    }),
  ),
);

const DataDashboard = lazy(() =>
  import('@frontend/sorghum/features/instagram-data-dashboard').then(
    ({ SorghumFeaturesInstagramDataDashboard: Page }) => ({
      default: Page,
    }),
  ),
);

const CommentReplyTemplateSelection = lazy(() =>
  import(
    '@frontend/sorghum/features/instagram-comment-reply-template-selection'
  ).then(({ SorghumFeaturesInstagramCommentReplyTemplateSelection: Page }) => ({
    default: Page,
  })),
);

const InstagramCommentReplyTemplate = lazy(() =>
  import('@frontend/sorghum/features/instagram-comment-reply-template').then(
    ({ SorghumFeaturesInstagramCommentReplyTemplate: Page }) => ({
      default: Page,
    }),
  ),
);
const CollectUserAnswerDashboard = lazy(() =>
  import('@frontend/sorghum/features/collect-user-answer-dashboard').then(
    ({ SorghumFeaturesCollectUserAnswerDashboard: Page }) => ({
      default: Page,
    }),
  ),
);

export const IGRoutes = () => {
  const {
    facebookAppID,
    mode,
    permission,
    googleStorageEndpoint,
    tapPayAppID,
    igAppID,
    platformEndpoint,
  } = useRoutes();
  return (
    <Route
      path={IG}
      element={withPageBoundary(
        <Dashboard
          facebookAppID={facebookAppID}
          mode={mode}
          googleStorageEndpoint={googleStorageEndpoint}
          tapPayAppID={tapPayAppID}
          useAuth={useAuth}
          socialType={SocialTypeEnum.INSTAGRAM}
        />,
      )}
    >
      <Route path=":projectID">
        <Route index element={<Navigate to={COMMENT_AUTO_REPLY} replace />} />
        <Route path={COMMENT_AUTO_REPLY}>
          <Route index element={<CommentReplyList />} />
          <Route path=":commentAutoReplyID">
            <Route index element={<CommentReplyDetail />} />
            <Route
              path={TEMPLATE}
              element={<InstagramCommentReplyTemplate />}
            />
          </Route>
          <Route
            path={TEMPLATE_SELECTION}
            element={<CommentReplyTemplateSelection />}
          />
        </Route>
        <Route path={DASHBOARD}>
          <Route index element={<DataDashboard />} />
          <Route
            path=":commentAutoReplyID"
            element={
              <CollectUserAnswerDashboard platformEndpoint={platformEndpoint} />
            }
          />
        </Route>

        <Route
          path={PEOPLE}
          element={
            <People
              googleStorageEndpoint={googleStorageEndpoint}
              usePages={useInstagramPeople}
            />
          }
        />
        <Route
          path={SETTING}
          element={
            <Setting
              appID={igAppID}
              permission={permission}
              mode={mode}
              usePage={useInstagramSetting}
            />
          }
        />
        {/* setting - payment */}
        <Route
          path={`${SETTING}/${PAYMENT_DETAILS}`}
          element={<PaymentDetail />}
        />
      </Route>
    </Route>
  );
};
