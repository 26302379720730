import {
  Button,
  MenuButton,
  Tooltip,
  Typography,
} from '@frontend/components/ui';
import { Alert } from '@frontend/components/utils';
import { useProjectPermission } from '@frontend/editor/data-access';
import { formatNumberWithCommas, uuid } from '@frontend/editor/utils';
import {
  usePostContactsExport,
  usePostPeopleList,
} from '@frontend/sorghum/data-access';
import { UICtx, WebSocketCtx } from '@frontend/sorghum/external-providers';
import {
  FilterConditionsType,
  FilterConditionTypeEnum,
  NotificationType,
  OrderByEnum,
  PeopleTableFilterConditionPropsType,
  SocialTypeEnum,
  Tag,
  UsePagePeopleReturn,
  WebSocketEventNameEnum,
} from '@frontend/sorghum/interface';
import {
  CustomerCountBlock,
  LabelDisplayedRows,
  PeopleBulkEditModal,
  PeopleDetailModal,
  PeopleFilterChips,
  PeopleOnboardingModal,
  PeopleTableFilterButton,
} from '@frontend/sorghum/ui';
import {
  openExportFile,
  sendGAEvent,
  useDocumentTitle,
} from '@frontend/sorghum/utils';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { differenceBy, isArrayLike, isEmpty, isNull, omit } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ActionButtonContainerStyled,
  DividerStyled,
  EmptyContentStyled,
  FilterContainerStyled,
  PaginationStyled,
  PaperContainerStyled,
  PeopleContainerStyled,
  TableContainerStyled,
} from './styles';

enum SortByEnum {
  JOINED = 'joined',
  LAST_ACTIVE = 'lastActive',
}

interface SorghumFeaturesPeopleProps {
  googleStorageEndpoint: string;
  usePages: () => UsePagePeopleReturn;
}

const defaultFilter: FilterConditionsType = {
  limit: 50,
  sortBy: SortByEnum.JOINED,
  orderBy: OrderByEnum.DESC,
  filterCriteria: 1,
  offset: 0,
};

export const formatOutputTagData = (
  // tagNameList: 所有的 tag, originUserTagData: 使用者原始的 tag, tagList: 目前所有可以選擇的 tag
  newTagArray: Tag[],
  originTagArray: Tag[],
) => {
  // 取得新增加的陣列
  const addedTags = differenceBy(newTagArray, originTagArray, 'id');
  // 取得移除的陣列
  const deleteTags = differenceBy(originTagArray, newTagArray, 'id').map(
    (i) => i.id,
  );

  // 移除不必要的 props
  return {
    createTags: addedTags.map((i) => omit(i, 'type')),
    deleteTags: deleteTags,
  };
};

export const convertTopicString = (topicList: NotificationType[]): string => {
  if (!isArrayLike(topicList)) {
    return '';
  }

  return topicList.map((topics) => topics.name.trim()).join(', ');
};

export function SorghumFeaturesPeople({
  googleStorageEndpoint,
  usePages,
}: SorghumFeaturesPeopleProps) {
  const { demoMode, removeLoadingStatus } = useContext(UICtx);
  const socketState = useContext(WebSocketCtx);

  const { socialType, t, PeopleTable } = usePages();

  const urlParams = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, []);
  // filter 帶入 query
  const [checkList, setCheckList] = useState<string[]>([]);
  const [isQueryFromURL, setIsQueryFromURL] = useState(false);

  const [filter, setFilter] = useState<FilterConditionsType>(defaultFilter);

  const [conditions, setConditions] = useState<
    PeopleTableFilterConditionPropsType[]
  >([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [isOpenPeopleDetailModal, setIsOpenPeopleDetailModal] = useState(false);
  const [isOpenPeopleBulkEditModal, setIsOpenPeopleBulkEditModal] =
    useState(false);
  //1: attribute 2: tag
  const [bulkEditType, setBulkEditType] = useState<1 | 2>(1);
  const [contactsID, setContactsID] = useState('');

  const { data: peopleData, isFetching } = usePostPeopleList({
    ...filter,
    condition: conditions.map((i) => {
      const { id, ...props } = i;
      return {
        ...props,
      };
    }),
    fake: demoMode,
    googleStorageEndpoint,
  });
  const { isViewer } = useProjectPermission();
  const { mutate: exportPeopleList } = usePostContactsExport();

  const navigate = useNavigate();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setFilter({ ...filter, offset: newPage * rowsPerPage });
  };

  const handleSort = useCallback(
    (sortBy: SortByEnum) => {
      if (filter.sortBy === sortBy) {
        setFilter({
          ...filter,
          sortBy,
          orderBy:
            filter.orderBy === OrderByEnum.DESC
              ? OrderByEnum.ASC
              : OrderByEnum.DESC,
        });
      } else {
        setFilter({ ...filter, sortBy, orderBy: OrderByEnum.DESC });
      }

      switch (sortBy) {
        case SortByEnum.JOINED: {
          sendGAEvent(
            'Customers',
            'Joined - sort',
            'Customers - table - Joined - sort - click',
            '',
          );
          break;
        }
        case SortByEnum.LAST_ACTIVE: {
          sendGAEvent(
            'Customers',
            'Last active - sort',
            'Customers - table - Last active - sort - click',
            '',
          );
          break;
        }
      }
    },
    [filter],
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleExport = useCallback(() => {
    sendGAEvent(
      'Customers',
      'Export',
      'Customers - export - click',
      checkList.length.toString(),
    );
    exportPeopleList(
      {
        ...filter,
        condition: conditions,
        contactsId: checkList,
      },
      {
        onSuccess: (fileUrl) => {
          const token = localStorage.getItem('token');
          const projectId = sessionStorage.getItem('project-id') as string;
          if (token) {
            openExportFile({ file: fileUrl, projectId, token, category: 1 });
          }
          sendGAEvent(
            'Customers',
            'Export',
            'customers-successfully exported',
            '',
          );
        },
      },
    );
  }, [checkList, conditions, exportPeopleList, filter]);

  const filterCount = useMemo(() => {
    return peopleData?.filterCount ?? 0;
  }, [peopleData?.filterCount]);

  const totalCount = useMemo(() => {
    return peopleData?.totalCount ?? 0;
  }, [peopleData?.totalCount]);

  const handleFilterSubmit = useCallback(
    (criteria: 1 | 2, val: PeopleTableFilterConditionPropsType[]) => {
      setFilter((prev) => {
        return {
          ...prev,
          offset: 0,
          filterCriteria: criteria,
        };
      });
      setConditions(val);
      setPage(0);
    },
    [],
  );

  const handleClearFilter = useCallback(() => {
    setIsQueryFromURL(false);
    setFilter(defaultFilter);
    setConditions([]);
    // 追蹤點擊 filter 中 clear filter 的數量
    sendGAEvent(
      'Customers',
      'Filter - clear filter',
      'Customers - filter - clear filter - click',
      checkList.length.toString(),
    );
    const newUrl = `${window.location.pathname}`;
    navigate(newUrl, { replace: true });
  }, [checkList.length, navigate]);

  const handleChecked = useCallback(
    (id: string, checked: boolean) => {
      const checkListSet = new Set(checkList);
      if (checked) {
        checkListSet.add(id);
      } else {
        checkListSet.delete(id);
      }
      setCheckList([...checkListSet]);
    },
    [checkList, setCheckList],
  );

  const handleTableRowClick = useCallback(
    (id: string, socialType: SocialTypeEnum) => {
      if (!isOpenPeopleDetailModal && socialType === SocialTypeEnum.FACEBOOK) {
        setIsOpenPeopleDetailModal(true);
        setContactsID(id);
        // 追蹤點擊 table 任一 user 成功開啟明細彈窗的數量，點一次 +1
        sendGAEvent(
          'Customers',
          'User_Detail_click',
          'Customers - User- Detail - open',
          '',
        );
      }
    },
    [isOpenPeopleDetailModal],
  );
  const bulkEditButtonMenuList = useMemo(() => {
    return [
      {
        content: t('people.bulkEditModal.addAttribute'),
        onClick: () => {
          setIsOpenPeopleBulkEditModal(true);
          setBulkEditType(1);
          sendGAEvent(
            'Customers',
            'Add attribute',
            'Customers - Bulk action - add attribute - click',
            checkList.length.toString(),
          );
        },
      },
      {
        content: t('people.bulkEditModal.addTag'),
        onClick: () => {
          setIsOpenPeopleBulkEditModal(true);
          setBulkEditType(2);
          sendGAEvent(
            'Customers',
            'Add tag',
            'Customers - Bulk action - add tag - click',
            checkList.length.toString(),
          );
        },
      },
    ];
  }, [checkList.length, t]);

  // 批次修改後關閉 Loading
  useEffect(() => {
    const callback = socketState.addMessageEventListener(
      WebSocketEventNameEnum.BULK_EDITING,
      (data) => {
        removeLoadingStatus('bulk-edit');
        if (data.code === 20000) {
          Alert.success(t('people.bulkEditModal.success'));
        } else {
          Alert.error(t('people.bulkEditModal.fail'));
        }
      },
    );

    return () => {
      socketState.removeMessageEventListener(
        WebSocketEventNameEnum.BULK_EDITING,
        callback,
      );
    };
  }, [removeLoadingStatus, socketState, t]);

  // set chip and condition filter from url
  const SortIcon = filter.orderBy === OrderByEnum.ASC ? NorthIcon : SouthIcon;
  // set condition filter from url
  useEffect(() => {
    if (urlParams.has('searchFilter')) {
      switch (urlParams.get('searchFilter')) {
        case FilterConditionTypeEnum.COMMENT_REPLY: {
          setConditions([
            {
              id: uuid(),
              searchFilter: FilterConditionTypeEnum.COMMENT_REPLY,
              key: urlParams.get('key') || '',
              search: urlParams.get('search') || '',
              operator: 1,
            },
          ]);
          break;
        }
        case FilterConditionTypeEnum.BROADCAST: {
          setConditions([
            {
              id: uuid(),
              searchFilter: FilterConditionTypeEnum.BROADCAST,
              key: urlParams.get('key') || '',
              search: urlParams.get('search') || '',
              operator: !isNull(urlParams.get('operator'))
                ? parseInt(urlParams.get('operator') || '', 10)
                : 1,
            },
          ]);
          break;
        }
        case FilterConditionTypeEnum.FLOW_BLOCK: {
          if (!isEmpty(urlParams.get('flowID'))) {
            setConditions([
              {
                id: uuid(),
                searchFilter: FilterConditionTypeEnum.FLOW_BLOCK,
                key: urlParams.get('blockID') || '',
                search: urlParams.get('status') || '',
                operator: 1,
                flowId: urlParams.get('flowID') || '',
                ...(urlParams.get('entryID') && {
                  entryId: urlParams.get('entryID') || '',
                }),
                entryType: !isNull(urlParams.get('entryType'))
                  ? parseInt(urlParams.get('entryType') || '', 10)
                  : 1,
              },
            ]);
          }
          break;
        }
      }
      setIsQueryFromURL(true);
    }
  }, [urlParams]);

  useDocumentTitle(t('title.people'));

  return (
    <>
      <PeopleContainerStyled>
        <Typography variant="h5">{t('people.title')}</Typography>
        {(socialType === SocialTypeEnum.THREADS ||
          socialType === SocialTypeEnum.INSTAGRAM) && <CustomerCountBlock />}
        {(socialType === SocialTypeEnum.FACEBOOK ||
          socialType === SocialTypeEnum.INSTAGRAM) && (
          <Tooltip
            disabled={!isQueryFromURL}
            placement="top"
            title={t('people.filters.clearTooltip')}
          >
            <FilterContainerStyled>
              <PeopleTableFilterButton
                conditions={conditions}
                disabled={isQueryFromURL}
                onSubmit={handleFilterSubmit}
                setCheckList={setCheckList}
                checkList={checkList}
                peopleData={peopleData}
                socialType={socialType}
                t={t}
              />
              <DividerStyled />
              <PeopleFilterChips
                conditions={conditions}
                filterCriteria={filter.filterCriteria}
                socialType={socialType}
                t={t}
              />
              {conditions.length > 0 && (
                <Button
                  sx={{
                    whiteSpace: 'nowrap',
                  }}
                  variant="text"
                  color="primary"
                  onClick={handleClearFilter}
                >
                  {t('people.filters.clearFilter')}
                </Button>
              )}
            </FilterContainerStyled>
          </Tooltip>
        )}
        <ActionButtonContainerStyled>
          {socialType === SocialTypeEnum.FACEBOOK && (
            <>
              <Typography variant="body1" color="grey.700">
                {t('people.selected', {
                  total: formatNumberWithCommas(filterCount),
                  selected: formatNumberWithCommas(checkList.length),
                })}
              </Typography>
              <MenuButton
                menuList={bulkEditButtonMenuList}
                buttonText={t('people.bulk')}
                length={checkList.length}
                disabled={!totalCount || isViewer}
              />
            </>
          )}
          <Button
            variant="outlined"
            color="bluegrey500"
            onClick={handleExport}
            disabled={!filterCount || isViewer}
          >
            {t('people.export')}
          </Button>
        </ActionButtonContainerStyled>
        <PaperContainerStyled elevation={0}>
          <TableContainerStyled>
            <PeopleTable
              data={peopleData?.contacts || []}
              handleTableRowClick={handleTableRowClick}
              filter={filter}
              setFilter={setFilter}
              checkList={checkList}
              setCheckList={setCheckList}
              isFetching={isFetching}
            />
          </TableContainerStyled>
          {totalCount === 0 && !isFetching && (
            <EmptyContentStyled>
              {t('people.table.emptyContent')}
            </EmptyContentStyled>
          )}
          {totalCount > 0 && filterCount <= 0 && !isFetching && (
            <EmptyContentStyled>
              {t('people.table.searchNoResult')}
            </EmptyContentStyled>
          )}
          {totalCount > 0 && (
            <PaginationStyled
              page={page}
              rowsPerPageOptions={[50]}
              component="div"
              count={filterCount}
              rowsPerPage={50}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={LabelDisplayedRows}
            />
          )}
        </PaperContainerStyled>
      </PeopleContainerStyled>
      {isOpenPeopleDetailModal && (
        <PeopleDetailModal
          isOpenModal={isOpenPeopleDetailModal}
          setIsOpenModal={setIsOpenPeopleDetailModal}
          contactsID={contactsID}
        />
      )}
      <PeopleBulkEditModal
        isOpenModal={isOpenPeopleBulkEditModal}
        setIsOpenModal={setIsOpenPeopleBulkEditModal}
        bulkEditType={bulkEditType}
        peopleIDList={checkList}
        filterConditions={conditions}
        filterCriteria={filter.filterCriteria}
        filterCount={filterCount}
      />
      <PeopleOnboardingModal googleStorageEndpoint={googleStorageEndpoint} />
    </>
  );
}

export default SorghumFeaturesPeople;
