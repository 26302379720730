import { PlacementEnum } from '@frontend/components/interface';
import { Dialog, Typography, VideoModal } from '@frontend/components/ui';
import {
  usePostAccountTutorial,
  usePostPeopleList,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import {
  dayjs,
  PAGE_COMMENT_AUTO_REPLY,
  usePath,
} from '@frontend/sorghum/utils';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Step1After from '../../images/onboarding/onboarding-customer-step1-after.png';
import Step1Before from '../../images/onboarding/onboarding-customer-step1-before.png';
import Step2After from '../../images/onboarding/onboarding-customer-step2-after.png';
import Step2Before from '../../images/onboarding/onboarding-customer-step2-before.png';
import Step3After from '../../images/onboarding/onboarding-customer-step3-after.png';
import Step3Before from '../../images/onboarding/onboarding-customer-step3-before.png';

interface PeopleOnboardingModalProps {
  googleStorageEndpoint: string;
}

const PeopleOnboardingModalContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

const ImageRowWrapperStyled = styled(Box)(({ theme }) => ({
  zIndex: 100,
  display: 'inline-flex',
  gap: '60px',
  alignSelf: 'center',
  padding: '12px 0',
}));

const StepWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: '200px',
  textAlign: 'center',
  '& > img': {
    width: '200px',
    height: '200px',
    marginBottom: '16px',
  },
}));

export const PeopleOnboardingModal: FC<PeopleOnboardingModalProps> = ({
  googleStorageEndpoint,
}: PeopleOnboardingModalProps) => {
  const [t, i18next] = useTranslation();
  const { navigateToProjectPage } = usePath();
  const { data: peopleData } = usePostPeopleList({
    limit: 20,
    fake: true,
    googleStorageEndpoint,
  });

  const [isShowVideo, setIsShowVideo] = useState(false);
  const [isVideoEnded, setIsVideoEnded] = useState(false);

  const [videoUrl, setVideoUrl] = useState('');
  const [isCompleteOnboarding, setIsCompleteOnboarding] = useState(false);
  const {
    modalType,
    openModal,
    setIsTouring,
    setTour,
    nextTour,
    closeDemoMode,
    closeTour,
    openDemoMode,
    closeModal,
  } = useContext(UICtx);
  const { mutate: updateAccountTutorial } = usePostAccountTutorial();

  const leaveMission = useCallback(() => {
    closeTour();
    closeDemoMode();
    setIsShowVideo(false);
    closeModal();
  }, [closeDemoMode, closeTour, closeModal]);

  const handleCloseOnboardingModal = useCallback(() => {
    if (!isCompleteOnboarding) {
      updateAccountTutorial(
        {
          marketingGuru: {
            customer: {
              hasInteracted: true,
            },
          },
        },
        {
          onSuccess: () => {
            closeModal();
          },
        },
      );
    } else {
      leaveMission();
      closeModal();
    }
  }, [closeModal, isCompleteOnboarding, updateAccountTutorial, leaveMission]);

  const { Pu, Maria } = useMemo(() => {
    let Pu;
    let Maria;
    if (peopleData?.contacts) {
      Pu = peopleData.contacts.find((person) => person.name === 'Pu Chipu');
      Maria = peopleData.contacts.find(
        (person) => person.name === 'Maria Luzon',
      );
    }
    return {
      Pu,
      Maria,
    };
  }, [peopleData?.contacts]);

  const openVideoModal = useCallback(
    (video: 'profile' | 'filter' | 'batch') => {
      const lang = i18next.language.includes('en') ? 'en' : 'zh';
      setIsShowVideo(true);
      setIsTouring(false);
      setIsVideoEnded(false);
      switch (video) {
        case 'profile': {
          setVideoUrl(
            `${googleStorageEndpoint}/static/video/onboarding/customer-profile-${lang}.mp4`,
          );
          break;
        }
        case 'filter': {
          setVideoUrl(
            `${googleStorageEndpoint}/static/video/onboarding/customer-filter-${lang}.mp4`,
          );
          break;
        }
        case 'batch': {
          setVideoUrl(
            `${googleStorageEndpoint}/static/video/onboarding/customer-batch-${lang}.mp4`,
          );
          break;
        }
      }
    },
    [googleStorageEndpoint, i18next.language, setIsTouring],
  );

  const startMission = useCallback(() => {
    updateAccountTutorial(
      {
        marketingGuru: {
          customer: {
            hasInteracted: true,
          },
        },
      },
      {
        onSuccess: () => {
          closeModal();
          openDemoMode(leaveMission);
          setIsShowVideo(false);
          setIsTouring(true);
        },
      },
    );

    if (Pu && Maria) {
      setTour([
        {
          targets: [`table-row-${Pu.id}`, `table-row-${Maria.id}`],
          isTransparentMaskClickable: true,
          tooltipProps: {
            title: t('people.onboarding.step1.title'),
            description: t('people.onboarding.step1.description'),
            placement: PlacementEnum.BOTTOM,
            pagination: `1 ${t('common.of')} 5`,
            nextButtonText: t('people.onboarding.next'),
            nextButtonClick: nextTour,
          },
        },
        {
          targets: [`table-row-${Pu.id}`],
          highlight: `table-row-${Pu.id}`,
          isTransparentMaskClickable: true,
          highlightType: 'dropdown',
          onHighlightClick: () => openVideoModal('profile'),
          tooltipProps: {
            description: t('people.onboarding.step2.description'),
            placement: PlacementEnum.BOTTOM_END,
            pagination: `2 ${t('common.of')} 5`,
          },
        },
        {
          targets: ['people-table-filter-button'],
          highlight: 'people-table-filter-button',
          highlightType: 'button',
          onHighlightClick: () => openVideoModal('filter'),
          tooltipProps: {
            title: t('people.onboarding.step3.title'),
            description: t('people.onboarding.step3.description'),
            placement: PlacementEnum.RIGHT_START,
            pagination: `3 ${t('common.of')} 5`,
          },
        },
        {
          targets: [`table-row-${Pu.id}`, `table-row-${Maria.id}`],
          isTransparentMaskClickable: true,
          tooltipProps: {
            title: t('people.onboarding.step4.title'),
            description: t('people.onboarding.step4.description'),
            placement: PlacementEnum.BOTTOM,
            pagination: `4 ${t('common.of')} 5`,
            nextButtonText: t('people.onboarding.next'),
            nextButtonClick: nextTour,
          },
        },
        {
          targets: [`checkbox-${Pu.id}`],
          highlight: `checkbox-${Pu.id}`,
          onTransparentMaskClick: () => {
            openVideoModal('batch');
            setIsCompleteOnboarding(true);
          },
          isTransparentMaskClickable: true,
          tooltipProps: {
            title: t('people.onboarding.step4.title'),
            description: t('people.onboarding.step4.description'),
            placement: PlacementEnum.RIGHT,
            pagination: `5 ${t('common.of')} 5`,
          },
        },
      ]);
    }
  }, [
    Maria,
    Pu,
    closeModal,
    leaveMission,
    nextTour,
    openDemoMode,
    openVideoModal,
    setIsTouring,
    setTour,
    t,
    updateAccountTutorial,
  ]);

  const handleVideoEnded = useCallback(() => {
    setIsVideoEnded(true);
  }, []);

  const handleVideoModalConfirm = useCallback(() => {
    setIsShowVideo(false);
    // 如果已經是最後的步驟，開啟任務 modal
    if (isCompleteOnboarding) {
      openModal(ModalTypesEnum.LEVEL2_LESSON1);
      updateAccountTutorial({
        marketingGuru: {
          customer: {
            completedAt: dayjs().toISOString(),
          },
        },
      });
    } else {
      setIsTouring(true);
      nextTour();
    }
  }, [
    isCompleteOnboarding,
    nextTour,
    openModal,
    setIsTouring,
    updateAccountTutorial,
  ]);

  const goToCommentReply = () => {
    leaveMission();
    navigateToProjectPage({ path: PAGE_COMMENT_AUTO_REPLY });
    openModal(ModalTypesEnum.LEVEL2_LESSON2);
  };

  if (isShowVideo)
    return (
      <VideoModal
        size="m"
        confirmButtonText={t('people.onboarding.next')}
        onConfirm={handleVideoModalConfirm}
        onEnded={handleVideoEnded}
        confirmButtonDisabled={!isVideoEnded}
        open={isShowVideo}
        videoLink={videoUrl}
      />
    );

  return (
    <Dialog
      size="md"
      open={modalType === ModalTypesEnum.LEVEL2_LESSON1}
      closeBtn={false}
      autoClose={false}
      title={
        isCompleteOnboarding
          ? t('people.onboardingModal.finish.title')
          : t('people.onboardingModal.start.title')
      }
      confirmBtnText={
        isCompleteOnboarding
          ? t('people.onboardingModal.nextStep')
          : t('people.onboardingModal.startTour')
      }
      cancelBtnText={t('people.onboardingModal.later')}
      handleClose={handleCloseOnboardingModal}
      handleConfirm={isCompleteOnboarding ? goToCommentReply : startMission}
      confirmButtonID={
        isCompleteOnboarding ? '' : 'level2_mission1_start_tutorial'
      }
    >
      <PeopleOnboardingModalContainerStyled>
        {!isCompleteOnboarding && (
          <Typography variant="body3" color="grey.500" alignSelf="end">
            {t('people.onboardingModal.start.estimate')}
          </Typography>
        )}
        {isCompleteOnboarding ? (
          <>
            <Typography variant="body1" color="grey.800">
              {t('people.onboardingModal.finish.description1')}
            </Typography>
            <Typography variant="body1" color="grey.800">
              {t('people.onboardingModal.finish.description2')}
            </Typography>
          </>
        ) : (
          <Typography variant="body1" color="grey.800">
            {t('people.onboardingModal.start.description')}
          </Typography>
        )}

        <ImageRowWrapperStyled>
          <StepWrapperStyled>
            <img
              src={isCompleteOnboarding ? Step1After : Step1Before}
              alt="step1-before"
            />
            <Typography variant="body1" color="grey.800">
              {t('people.onboardingModal.step1')}
            </Typography>
          </StepWrapperStyled>
          <StepWrapperStyled>
            <img
              src={isCompleteOnboarding ? Step2After : Step2Before}
              alt="step1-before"
            />
            <Typography variant="body1" color="grey.800">
              {t('people.onboardingModal.step2')}
            </Typography>
          </StepWrapperStyled>
          <StepWrapperStyled>
            <img
              src={isCompleteOnboarding ? Step3After : Step3Before}
              alt="step1-before"
            />
            <Typography variant="body1" color="grey.800">
              {t('people.onboardingModal.step3')}
            </Typography>
          </StepWrapperStyled>
        </ImageRowWrapperStyled>
      </PeopleOnboardingModalContainerStyled>
    </Dialog>
  );
};

export default PeopleOnboardingModal;
