import { globalTheme } from '@frontend/components/external-providers';
import { Button } from '@frontend/components/ui';
import {
  useCanvasAdd,
  useCanvasGet,
  useCanvasUpdate,
  useCanvasView,
  useProjectPermission,
} from '@frontend/editor/data-access';
import { EditorCtx } from '@frontend/editor/external-providers';
import { BaseElement, ElementTypesEnum } from '@frontend/editor/interface';
import AddIcon from '@mui/icons-material/Add';
import MessageIcon from '@mui/icons-material/Message';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
} from 'react-sortable-hoc';
import { NodeProps, Position, useViewport } from 'reactflow';
import EditorBlockBorder from '../editor-block-border/editor-block-border';
import EditorBlockContainer from '../editor-block-container/editor-block-container';
import EditorCollectUserAnswerV2 from '../editor-collect-user-answer-v2/editor-collect-user-answer-v2';
import EditorNote from '../editor-note/editor-note';
import { HandlePoint } from '../handle-point/handle-point';
import { NodeHeader } from '../node-header/node-header';
import { useNodeInstagramCommentReplyBlock } from '../node-instagram-comment-reply-block/use-node-instagram-comment-reply-block';

interface SortableListType {
  items: string[];
  draggable: boolean;
}

interface SortableItemType {
  value: BaseElement;
  draggable: boolean;
  setIsHoverMenu: Dispatch<SetStateAction<boolean>>;
  itemIndex: number;
  nodeLength: number;
}

const BodyStyled = styled(Box)(({ theme }) => ({
  width: '316px',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  justifyContent: 'end',
  flexDirection: 'column',
  padding: '12px',
}));

const SortableItemStyled = styled(Box)(({ theme }) => ({
  zIndex: 20,
}));

const SortableItem: React.ComponentClass<
  SortableElementProps & SortableItemType
> = SortableElement(
  ({
    value,
    draggable,
    itemIndex,
    setIsHoverMenu,
    nodeLength,
  }: {
    value: BaseElement;
    draggable: boolean;
    setIsHoverMenu: Dispatch<SetStateAction<boolean>>;
    itemIndex: number;
    nodeLength: number;
  }) => {
    const { isTemplateEditor } = useProjectPermission();

    const { onHoverElement } = useCanvasView();
    let element = <div></div>;

    switch (value.elementType) {
      case ElementTypesEnum.COLLECT_USER_ANSWER_V2_ELEMENT: {
        element = (
          <EditorCollectUserAnswerV2
            key={value.id}
            id={value.id}
            index={itemIndex}
            draggable={draggable && nodeLength > 1}
            label={value.label}
            setIsHoverMenu={setIsHoverMenu}
            parentID={value.parentID as string}
            isEnableDelete={nodeLength > 1}
          />
        );
        break;
      }
      case ElementTypesEnum.NOTE_ELEMENT: {
        element = (
          <EditorNote
            key={value.id}
            id={value.id}
            index={itemIndex}
            draggable={isTemplateEditor && draggable}
            label={value.label}
            setIsHoverMenu={setIsHoverMenu}
            parentID={value.parentID as string}
          />
        );
        break;
      }
    }

    return (
      <SortableItemStyled
        mb={2}
        key={value.id}
        onMouseEnter={() => onHoverElement(value.id, 2)}
        onMouseLeave={() => onHoverElement(value.id, 1)}
        style={{
          cursor: 'pointer',
        }}
      >
        {element}
      </SortableItemStyled>
    );
  },
);

const SortableList: React.ComponentClass<
  SortableContainerProps & SortableListType
> = SortableContainer(
  ({ items, draggable }: { items: string[]; draggable: boolean }) => {
    const { getTargetElement } = useCanvasGet();
    // 檢查是否 hover 在側邊的選單來決定是否開放 sort event
    const [isHoverMenu, setIsHoverMenu] = useState(false);

    return (
      <Box>
        {items.map((item, index) => {
          const childItem = getTargetElement(item);

          if (!childItem) return null;

          return (
            <SortableItem
              key={childItem.id}
              value={childItem}
              index={index}
              itemIndex={index}
              draggable={draggable}
              disabled={!isHoverMenu}
              setIsHoverMenu={setIsHoverMenu}
              nodeLength={items.length - 1}
            />
          );
        })}
      </Box>
    );
  },
);

export const NodeCollectUserAnswerV2 = ({ id, selected }: NodeProps) => {
  const [t] = useTranslation();
  const { commentAutoReplyID } = useParams();
  const { zoom } = useViewport();
  const { readonly } = useContext(EditorCtx);
  const { isTemplateEditor } = useProjectPermission();
  const { getTargetElement } = useCanvasGet();
  const { addCollectUserAnswerV2Element } = useCanvasAdd();
  const { canvasUpdateLabel, canvasSortElement } = useCanvasUpdate();
  const { handleBlockClick } = useNodeInstagramCommentReplyBlock(
    commentAutoReplyID as string,
  );

  const { header, inputID, outputID, targetID, children } = useMemo(() => {
    const elementData = getTargetElement(id);
    const header = get(elementData, 'label', '');
    const inputID = get(elementData, 'inputID', '');
    const outputID = get(elementData, 'outputID', '');
    const targetID = get(elementData, 'targetID', '');
    const children = get(elementData, 'children', []) as string[];
    return {
      header,
      inputID,
      outputID,
      targetID,
      children,
    };
  }, [getTargetElement, id]);

  const onAddButtonClick = useCallback(() => {
    addCollectUserAnswerV2Element(id);
  }, [id, addCollectUserAnswerV2Element]);

  const onTextButtonSortEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      canvasSortElement(id, oldIndex, newIndex);
    },
    [canvasSortElement, id],
  );

  return (
    <EditorBlockContainer nodeID={id} onClick={handleBlockClick}>
      {inputID && (
        <HandlePoint
          id={inputID}
          type="target"
          position={Position.Left}
          styles={{
            top: '5%',
          }}
          isConnected={false}
        />
      )}
      {(isTemplateEditor || targetID) && (
        <HandlePoint
          id={outputID}
          type="source"
          position={Position.Right}
          styles={{
            top: 'calc(100% - 30px)',
          }}
          isConnected={!!targetID}
          isFocus={selected}
        />
      )}
      <EditorBlockBorder
        zoom={zoom}
        nodeID={id}
        selected={selected}
        color={get(globalTheme, 'palette.info.main', '')}
      >
        <NodeHeader
          readonly={readonly}
          color={globalTheme?.palette?.['primary']?.['main'] as string}
          background={globalTheme.palette?.['blue'][50]}
          icon={<MessageIcon fontSize="small" />}
          title={header}
          onBlur={(val: string) => canvasUpdateLabel(id, val)}
        />

        <BodyStyled>
          {/* 包含所有 Elements 的 container */}
          <Box>
            {children && (
              <SortableList
                items={children}
                lockAxis="y"
                useDragHandle
                draggable={
                  isTemplateEditor ? children.length > 1 : children.length > 2
                }
                onSortEnd={onTextButtonSortEnd}
                // 避免縮放時拖曳會改變大小
                helperClass="node_item_dragged_style"
              />
            )}
          </Box>
          {(!readonly || isTemplateEditor) && (
            <Box>
              <Button
                onClick={onAddButtonClick}
                startIcon={<AddIcon fontSize="small" />}
                variant="outlined"
                color={'bluegrey300'}
                dash
                fullWidth
              >
                {t('canvas.addElement')}
              </Button>
            </Box>
          )}
        </BodyStyled>
      </EditorBlockBorder>
    </EditorBlockContainer>
  );
};

export default memo(NodeCollectUserAnswerV2);
