import { Button, Typography } from '@frontend/components/ui';
import {
  useGetProjectID,
  useProjectPermission,
} from '@frontend/editor/data-access';
import {
  useGetContactsCount,
  useGetProject,
} from '@frontend/sorghum/data-access';
import { ProjectStatus, SocialTypeEnum } from '@frontend/sorghum/interface';
import {
  INSTAGRAM_PRICING_PAGE_EN,
  INSTAGRAM_PRICING_PAGE_ZH,
  THREADS_PRICING_PAGE_EN,
  THREADS_PRICING_PAGE_ZH,
  useCustomizedTranslation,
  usePath,
} from '@frontend/sorghum/utils';
import { Box, styled } from '@mui/material';
import i18next from 'i18next';
import { useCallback } from 'react';
import { Trans } from 'react-i18next';

const ContainerStyled = styled(Box)<{ $status: boolean }>(
  ({ theme, $status }) => ({
    padding: '24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    background: $status
      ? theme.palette['background']['primary']['5']
      : theme.palette['background']['error']['5'],
  }),
);

const LeftWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

export function CustomerCountBlock() {
  const { navigateToUpgrade } = usePath();
  const { data: projectID } = useGetProjectID();
  const { data: project } = useGetProject(projectID as string);
  const { data: contact } = useGetContactsCount();
  const { isStatusViewOnly } = useProjectPermission();

  const { t } = useCustomizedTranslation(project?.socialType);

  const userCount = contact?.count ?? 0;
  const trialLimit = project?.trialLimit ?? 0;

  const handleLearnMore = useCallback(() => {
    switch (project?.socialType) {
      case SocialTypeEnum.THREADS: {
        window.open(
          i18next.language.includes('en')
            ? THREADS_PRICING_PAGE_EN
            : THREADS_PRICING_PAGE_ZH,
          '_blank',
        );
        break;
      }
      case SocialTypeEnum.INSTAGRAM: {
        window.open(
          i18next.language.includes('en')
            ? INSTAGRAM_PRICING_PAGE_EN
            : INSTAGRAM_PRICING_PAGE_ZH,
          '_blank',
        );
      }
    }
  }, [project?.socialType]);

  const handleUpgrade = useCallback(() => {
    navigateToUpgrade({
      socialType: project?.socialType,
      projectID,
      source: 'limit warning module',
    });
  }, [navigateToUpgrade, project?.socialType, projectID]);

  const Description = () =>
    !isStatusViewOnly ? (
      <Typography variant="body2" color="grey.900">
        {t('component.customerCountBlock.normal.description', {
          count: trialLimit,
        })}
      </Typography>
    ) : (
      <Typography variant="body2" color="grey.900">
        {t('component.customerCountBlock.reached.description')}
      </Typography>
    );

  return (
    <ContainerStyled $status={!isStatusViewOnly}>
      <LeftWrapperStyled>
        {!isStatusViewOnly ? (
          <Typography component="span" variant="h6" color="grey.900">
            <Trans
              t={t}
              i18nKey="component.customerCountBlock.normal.title"
              values={{
                count: userCount,
              }}
              components={[
                <Typography
                  sx={{ fontWeight: '700 !important' }}
                  component="span"
                  variant="h5"
                  color="primary"
                />,
              ]}
            />
          </Typography>
        ) : (
          <Typography variant="h6" color="grey.900">
            <Trans
              t={t}
              i18nKey="component.customerCountBlock.reached.title"
              values={{
                count: trialLimit,
              }}
              components={[
                <Typography component="span" variant="h5" color="error" />,
              ]}
            />
          </Typography>
        )}

        {project?.status !== ProjectStatus.ADVANCE && <Description />}
      </LeftWrapperStyled>
      {!isStatusViewOnly ? (
        <Button
          id="learn_more"
          onClick={handleLearnMore}
          variant="outlined"
          color="primary"
        >
          {t('common.learnMore')}
        </Button>
      ) : (
        <Button
          id="upgrade"
          onClick={handleUpgrade}
          variant="contained"
          color="error"
        >
          {t('component.upgrade')}
        </Button>
      )}
    </ContainerStyled>
  );
}

export default CustomerCountBlock;
