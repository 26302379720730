import { Typography } from '@frontend/components/ui';
import { Alert } from '@frontend/components/utils';
import { useGetProjectID } from '@frontend/editor/data-access';
import { formatNumberWithCommas } from '@frontend/editor/utils';
import {
  useGetBilling,
  useGetFacebookPages,
  useGetProject,
  useProjectDisconnect,
  useProjectLinked,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  GetBillingPlanEnum,
  ModalTypesEnum,
  PaymentStatus,
  ProjectDisconnectRequest,
  SocialTypeEnum,
} from '@frontend/sorghum/interface';
import { FacebookIcon } from '@frontend/sorghum/ui';
import {
  FACEBOOK_FAN_PAGE_READ_MORE_EN,
  FACEBOOK_FAN_PAGE_READ_MORE_ZH,
  useDocumentTitle,
  useFacebook,
  VIEWER_PERMISSION_EN,
  VIEWER_PERMISSION_ZH,
} from '@frontend/sorghum/utils';
import { Link } from '@mui/material';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const useMessengerSetting = (
  facebookAppID: string,
  permission: string,
  mode: string,
) => {
  const [t, i18next] = useTranslation();
  const {
    modalType,
    openCommonModal,
    addLoadingStatus,
    removeLoadingStatus,
    openModal,
  } = useContext(UICtx);

  const { data: projectID, isFetching: isFetchingProject } = useGetProjectID();
  const { mutate: disconnect } = useProjectDisconnect();

  const {
    LoginStatus,
    token: fbToken,
    requestPermission,
    login,
  } = useFacebook({
    appId: facebookAppID,
    mode,
  });

  const { data: linked } = useProjectLinked(projectID as string);
  const { data: project } = useGetProject(projectID);
  const { data: billing } = useGetBilling();

  const { data: pages, refetch } = useGetFacebookPages(fbToken);

  const openConnectModal = useCallback(() => {
    login(permission, (response) => {
      openModal(ModalTypesEnum.CONNECT_FACEBOOK_PAGE);
    });
  }, [login, openModal, permission]);

  const refreshPage = useCallback(() => {
    requestPermission();
    refetch();
  }, [refetch, requestPermission]);

  const disconnectPages = useCallback(() => {
    if (linked?.fb?.id) {
      const props: ProjectDisconnectRequest = {
        projectId: projectID as string,
        target: 'fb',
        targetId: linked?.fb?.id.toString(),
      };

      const disconnectFn = () =>
        disconnect(props, {
          onSuccess: (res) => {
            if (res.code === 20000) {
              Alert.success(t('setting.disconnectedMessage'));
            }
          },
        });

      openCommonModal(
        t('setting.disconnectModal.title'),
        t('setting.disconnectModal.description'),
        disconnectFn,
        t('setting.disconnect'),
        'error',
      );
    }
  }, [disconnect, linked?.fb?.id, projectID, t, openCommonModal]);

  const { viewerPermissionUrl } = useMemo(() => {
    if (i18next.language === 'en_us') {
      return {
        readMoreUrl: FACEBOOK_FAN_PAGE_READ_MORE_EN,
        viewerPermissionUrl: VIEWER_PERMISSION_EN,
      };
    } else {
      return {
        readMoreUrl: FACEBOOK_FAN_PAGE_READ_MORE_ZH,
        viewerPermissionUrl: VIEWER_PERMISSION_ZH,
      };
    }
  }, [i18next.language]);

  const connectedPageLink = `https://www.facebook.com/profile.php?id=${linked?.fb?.linkId}`;

  const connectedPageName = linked?.fb?.name;
  const accountName = linked?.fb?.accountName;

  const Icon = () => <FacebookIcon size="xl" />;

  const TeamMemberInviteDescription = () => (
    <Typography variant="body1" color="grey.600">
      <Trans i18nKey="setting.inviteDescription">
        You can invite teammates to manage or view your project, learn more
        about the permission access
        <Link
          sx={{ color: 'info.main' }}
          onClick={() => window.open(viewerPermissionUrl, '_blank')}
        >
          {t('setting.inviteLink')}
        </Link>
      </Trans>
    </Typography>
  );

  const isDisabledInviteButton = false;

  const socialType = SocialTypeEnum.FACEBOOK;
  const socialTypeName = t('setting.facebook');

  const hasBusinessAccountDescription = false;

  const { planTitle, price, period } = useMemo(() => {
    let planTitle = '';
    let price = '-';
    let period = '';

    if (billing) {
      switch (billing.plan) {
        case GetBillingPlanEnum.TRIAL: {
          planTitle = t('setting.payment.trial');
          price = `${billing.country === 'TW' ? 'NT$' : 'US$'} 0`;
          period = `/${t('setting.payment.month')}`;
          break;
        }
        case GetBillingPlanEnum.ADVANCE: {
          planTitle = t('setting.payment.advance');
          price = `${
            billing.country === 'TW' ? 'NT$' : 'US$'
          } ${formatNumberWithCommas(billing?.amount ?? 0)}`;
          period = `/${
            billing?.period === 1
              ? t('setting.payment.month')
              : t('setting.payment.year')
          }`;
          break;
        }
        case GetBillingPlanEnum.BETA: {
          planTitle = t('setting.payment.betaVersion');
          price = t('setting.payment.free');
          period = `/${t('setting.payment.month')}`;
          break;
        }
        default: {
          planTitle = t('setting.payment.expired');
          price = '-';
          period = '';
        }
      }
      if (project?.paymentStatus === PaymentStatus.ABNORMAL) {
        planTitle = t('setting.payment.trial');
        price = `${billing.country === 'TW' ? 'NT$' : 'US$'} 0`;
        period = `/${t('setting.payment.month')}`;
      }
    }

    return {
      price,
      period,
      planTitle,
    };
  }, [billing, project?.paymentStatus, t]);

  useLayoutEffect(() => {
    if (isFetchingProject) {
      addLoadingStatus('fetch-project');
    } else {
      removeLoadingStatus('fetch-project');
    }
  }, [isFetchingProject, addLoadingStatus, removeLoadingStatus]);

  useEffect(() => {
    if (
      pages?.error === 'TOKEN_EXPIRED' &&
      modalType === ModalTypesEnum.CONNECT_FACEBOOK_PAGE
    ) {
      login();
    }
  }, [login, modalType, pages]);

  useDocumentTitle(t('title.setting'));

  return {
    linked,
    isConnect: !!linked?.fb?.id,
    refreshPage,
    disconnectPages,
    openModal: openConnectModal,
    TeamMemberInviteDescription,
    isDisabledInviteButton,
    Icon,
    connectedPageLink,
    connectedPageName,
    accountName,
    socialType,
    socialTypeName,
    planTitle,
    price,
    period,
    billing,
    hasBusinessAccountDescription,
  };
};
