import { Checkbox, Dialog } from '@frontend/components/ui';
import { UICtx } from '@frontend/editor/external-providers';
import { ModalTypesEnum } from '@frontend/editor/interface';
import {
  useGetCommentAutoReplyPost,
  useGetCommentReply,
} from '@frontend/sorghum/data-access';
import {
  CommentReplyPostType,
  GetCommentAutoReplyPost,
  SocialTypeEnum,
} from '@frontend/sorghum/interface';
import {
  PAGE_COMMENT_AUTO_REPLY,
  dayjs,
  useCustomizedTranslation,
  usePath,
} from '@frontend/sorghum/utils';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  InputAdornment,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PostImage from '../../../images/post-image.svg';

interface CommentAutoReplyPostModalProps {
  commentAutoReplyID: string;
  title: string;
  selectedValue: GetCommentAutoReplyPost[];
  handleSubmit: (posts: GetCommentAutoReplyPost[]) => void;
  socialType: SocialTypeEnum;
}

const ModalContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

const PostContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '300px',
  '& > div:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette['grey'][200]}`,
  },
  overflow: 'auto',
}));

const EmptyWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
}));

const PostRowStyled = styled(Box)<{
  $selected?: boolean;
  $disabled?: boolean;
}>(({ theme, $selected, $disabled }) => ({
  position: 'relative',
  display: 'inline-flex',
  width: '100%',
  alignItems: 'center',
  padding: '12px 16px',
  gap: '24px',
  background: $selected
    ? theme.palette['background']['primary'][5]
    : 'transparent',

  '& > img': {
    width: '44px',
    height: '44px',
    minWidth: '44px',
    minHeight: '44px',
    borderRadius: '4px',
  },
}));

const PostMaskStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: theme.palette['grey'][100],
  left: 0,
  top: 0,
  opacity: 0.4,
  borderRadius: '4px',
}));

const PostMaskTooltipWrapperStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  opacity: 1,
}));

const PostRowDescriptionStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 16px',
  justifyContent: 'center',
  width: '100%',
  overflow: 'hidden',

  '& > div': {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const PostSelectedDescriptionStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'end',
}));

export const CommentReplyPostModal: FC<CommentAutoReplyPostModalProps> = ({
  commentAutoReplyID,
  title,
  selectedValue = [],
  handleSubmit,
  socialType,
}) => {
  const { t, convertSocialTypeEnumToSocialName } =
    useCustomizedTranslation(socialType);
  const { navigateToProjectPage } = usePath();
  const { closeModal, modalType } = useContext(UICtx);

  const [selectedPost, setSelectedPost] = useState<GetCommentAutoReplyPost[]>(
    [],
  );
  const [searchText, setSearchText] = useState('');

  const { data: commentAutoReply } = useGetCommentReply(commentAutoReplyID);
  const { data: postList } = useGetCommentAutoReplyPost(
    commentAutoReply?.postType || CommentReplyPostType.POST,
  );

  const handleConfirm = useCallback(() => {
    handleSubmit(selectedPost);
    closeModal();
  }, [handleSubmit, selectedPost, closeModal]);

  const handleCheckPost = useCallback(
    (target: GetCommentAutoReplyPost, checked: boolean) => {
      if (checked) {
        setSelectedPost([...selectedPost, target]);
      } else {
        setSelectedPost(selectedPost.filter((item) => item.id !== target.id));
      }
    },
    [selectedPost],
  );

  const filterPostList = useMemo(() => {
    if (!postList) return [];

    return searchText
      ? postList.filter(
          (i) =>
            i.message?.includes(searchText) ||
            i.permalinkUrl?.includes(searchText),
        )
      : postList;
  }, [postList, searchText]);

  const goToOtherCommentAutoReply = useCallback(
    (id: string) => {
      navigateToProjectPage({ path: `${PAGE_COMMENT_AUTO_REPLY}/${id}` });
      closeModal();
    },
    [navigateToProjectPage, closeModal],
  );

  useEffect(() => {
    if (selectedValue.length > 0) {
      setSelectedPost(selectedValue);
    }
  }, [selectedValue]);

  return (
    <Dialog
      size="md"
      title={title}
      confirmBtnText={t('common.save')}
      cancelBtnText={t('common.cancel')}
      open={modalType === ModalTypesEnum.COMMENT_REPLY_POST}
      handleClose={closeModal}
      handleConfirm={handleConfirm}
      disableConfirmButton={!selectedPost.length}
    >
      <ModalContainerStyled>
        <Box>
          <TextField
            size="small"
            placeholder={t('common.search')}
            sx={{ width: '100%' }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <PostContainerStyled>
          {filterPostList.map((post) => (
            <PostRowStyled
              key={post.id}
              $selected={selectedPost.findIndex((i) => i.id === post.id) >= 0}
              $disabled={
                !!post.selectedPost &&
                post.selectedPost.commentReplyId !== commentAutoReplyID
              }
            >
              {!!post.selectedPost &&
                post.selectedPost.commentReplyId !== commentAutoReplyID && (
                  <PostMaskStyled>
                    <Tooltip
                      title={
                        <Typography variant="notoSans" color="grey.white">
                          {t(
                            'commentAutoReply.content.firstStep.selectedTooltipPrefix',
                          )}
                          <Link
                            variant="notoSans"
                            color="info.main"
                            onClick={() =>
                              goToOtherCommentAutoReply(
                                post.selectedPost?.commentReplyId || '',
                              )
                            }
                          >
                            {post.selectedPost.commentReplyName}
                          </Link>
                          {t(
                            'commentAutoReply.content.firstStep.selectedTooltipSuffix',
                          )}
                        </Typography>
                      }
                    >
                      <PostMaskTooltipWrapperStyled />
                    </Tooltip>
                  </PostMaskStyled>
                )}
              <Checkbox
                color="primary"
                checked={selectedPost.findIndex((i) => i.id === post.id) >= 0}
                onChange={(e) => handleCheckPost(post, e.target.checked)}
                disabled={
                  !!post.selectedPost &&
                  post.selectedPost.commentReplyId !== commentAutoReplyID
                }
              />
              <img src={post.fullPicture || PostImage} alt="" />
              <PostRowDescriptionStyled
                onClick={() => window.open(post.permalinkUrl, '_blank')}
              >
                <Typography component="div" color="grey.900" variant="body3">
                  {post.message
                    ? post.message
                    : t('commentAutoReply.modal.post.postNoText')}
                </Typography>
                <Typography color="grey.500" variant="notoSans">
                  {dayjs(post.createdTime)
                    .tz()
                    .format('MMMM D, YYYY [at] h:mm A')}
                </Typography>
              </PostRowDescriptionStyled>
            </PostRowStyled>
          ))}
          {filterPostList.length === 0 && (
            <EmptyWrapperStyled>
              <Typography variant="body1" color="grey.500">
                {t('commentAutoReply.content.firstStep.postFilterNoResult')}
              </Typography>
              <Typography variant="body1" color="grey.500">
                {t('commentAutoReply.content.firstStep.postEmptyDescription', {
                  socialType: convertSocialTypeEnumToSocialName(),
                })}
              </Typography>
            </EmptyWrapperStyled>
          )}
        </PostContainerStyled>
        {selectedPost.length > 0 && (
          <PostSelectedDescriptionStyled>
            <Typography variant="body1" color="grey.500">
              {t('commentAutoReply.modal.post.postSelected', {
                count: selectedPost.length,
              })}
            </Typography>
          </PostSelectedDescriptionStyled>
        )}
      </ModalContainerStyled>
    </Dialog>
  );
};

export default CommentReplyPostModal;
