import {
  useGetAccountInfo,
  useGetProject,
} from '@frontend/sorghum/data-access';
import { AccountPermission, ProjectStatus } from '@frontend/sorghum/interface';
import { useGetProjectID } from '../use-get-project-id/use-get-project-id';

const TEMPLATE_EDITOR_EMAIL = 'template@goskyai.com';

export function useProjectPermission() {
  const { data: projectID } = useGetProjectID();
  const { data: project } = useGetProject(projectID);
  const { data: account } = useGetAccountInfo();

  return {
    // 不用特別區分時使用
    isViewer:
      project?.status === ProjectStatus.VIEW_ONLY ||
      account?.permission === AccountPermission.VIEWER,

    // 專案權限是否為 Viewer （目前只有 Messenger）
    isPermissionViewer: account?.permission === AccountPermission.VIEWER,

    // 專案是否過期 (Messenger)
    // 專案是否超過人數上限（Threads / IG）
    isStatusViewOnly: project?.status === ProjectStatus.VIEW_ONLY,
    // template 編輯者
    isTemplateEditor: account?.userName === TEMPLATE_EDITOR_EMAIL,
  };
}

export default useProjectPermission;
