import { globalTheme } from '@frontend/components/external-providers';
import { useCanvasAdd } from '@frontend/editor/data-access';
import { EditorCtx } from '@frontend/editor/external-providers';
import { ElementTypesEnum } from '@frontend/editor/interface';
import AddIcon from '@mui/icons-material/Add';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkRemoveOutlinedIcon from '@mui/icons-material/BookmarkRemoveOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import CommentBankOutlinedIcon from '@mui/icons-material/CommentBankOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import TextIcon from '@mui/icons-material/FormatColorText';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import LabelOffOutlinedIcon from '@mui/icons-material/LabelOffOutlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { Popover } from '@mui/material';
import { Box, BoxProps, styled } from '@mui/system';
import {
  RefObject,
  SetStateAction,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SortableHandle } from 'react-sortable-hoc';
import EditorMenu from '../editor-menu/editor-menu';

export interface EditorFloatPanelProps {
  index?: number;
  parentID?: string;
  allowAdd?: boolean;
  draggable?: boolean;
  direction?: 'row' | 'column';
  backgroundColor?: string | undefined;
  floatPanelType?: ElementTypesEnum;
  handleDelete?: () => void;
  handleSwitchRatio?: () => void;
  handleAdd?: () => void;
  handleMore?: () => void;
  type?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  handleHover?: (target: string) => void;
  isCenter?: boolean;
  position?: { x: number; y: number };
  isByCoordinate?: boolean;
  handleCopy?: () => void;
}

interface ContainerStyledProps extends BoxProps {
  direction: 'row' | 'column';
  backgroundColor?: string;
  $isFixedSize?: boolean;
}

const FloatPanelWrapperStyled = styled(Box)<{
  direction: 'row' | 'column';
  $isCenter: boolean;
}>(({ theme, direction, $isCenter }) => ({
  position: 'absolute',
  left: `${direction === 'row' ? '0' : '-1.25em'}`,
  top: `${direction === 'row' ? '-1.25em' : '0'}`,
  paddingRight: '0.42em',
  paddingBottom: `${direction === 'row' ? '1.07em' : '0'}`,
  ...($isCenter && {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  }),
  zIndex: 99,
}));

// 方便縮放整體大小
const BASIC_ZOOM = 1.4;

const ContainerStyled = styled(Box)<ContainerStyledProps>(
  ({ theme, direction, backgroundColor, $isFixedSize }) => ({
    display: 'flex',
    width: `${direction === 'row' ? 'fit-content' : `${1 * BASIC_ZOOM}em`}`,
    color: theme.palette['grey'][600],
    background: theme.palette?.['grey'][200],
    borderRadius: $isFixedSize ? '100px' : `${2.57 * BASIC_ZOOM}em`,
    padding: $isFixedSize ? '4px' : `${0.14 * BASIC_ZOOM}em`,

    '& > div': {
      display: 'flex',
      padding: $isFixedSize ? '4px' : `${0.14 * BASIC_ZOOM}em`,
      borderRadius: '50%',
      cursor: 'pointer',
      '&:hover': {
        background: theme.palette['background']['black'][5],
      },
      '& > svg': {
        fontSize: $isFixedSize ? '16px' : `${0.5 * BASIC_ZOOM}em`,
      },
    },

    flexDirection: direction,
    ...(backgroundColor && { backgroundColor }),
  }),
);

interface DragHandleProps {
  id?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  handleHover?: (target: string) => void;
}

const DragHandle: React.ComponentClass<DragHandleProps> = SortableHandle(
  ({ id, handleHover, onMouseEnter, onMouseLeave }: DragHandleProps) => (
    <Box
      id={id}
      onMouseEnter={() => {
        handleHover && handleHover('drag');
        onMouseEnter && onMouseEnter();
      }}
      onMouseLeave={() => {
        handleHover && handleHover('');
        onMouseLeave && onMouseLeave();
      }}
    >
      <DragIndicatorIcon />
    </Box>
  ),
);

export const EditorFloatPanel = ({
  index = 0,
  parentID = '',
  draggable,
  // 預設的 add function 是開啟 node 選單
  allowAdd,
  direction = 'row',
  backgroundColor,
  floatPanelType,
  handleSwitchRatio,
  handleDelete,
  // 如果需要客製化新增按鈕的 function 可以傳 handleAdd 進來
  handleAdd,
  handleMore,
  type = '',
  onMouseEnter,
  onMouseLeave,
  // gallery 拿來判斷只有 hover + button 的時候才會顯示卡片右側的新增按鈕
  handleHover,
  isCenter = false,
  position = { x: 0, y: 0 },
  isByCoordinate = false, // 是否為選取多個 block 時上方出現的 panel
  handleCopy,
}: EditorFloatPanelProps) => {
  const [t] = useTranslation();
  const state = useContext(EditorCtx);
  const addRef = useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  const {
    addTextButton,
    addRecurringNotification,
    addImage,
    addGallery,
    addActionSetAttribute,
    addActionRemoveAttribute,
    addActionUnsubscribeSequence,
    addActionSubscribeSequence,
    addActionExportGoogleSheet,
    addCollectUserAnswer,
  } = useCanvasAdd();

  const handleAddClick = (ref: RefObject<HTMLDivElement>) => {
    if (handleAdd) {
      handleAdd();
    } else {
      setAnchorEl(ref.current as SetStateAction<HTMLButtonElement | null>);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // 不同 block 可以新增的東西不同
  const menuList = useMemo(() => {
    switch (floatPanelType) {
      case ElementTypesEnum.ACTION: {
        return [
          {
            dropdownItems: [
              {
                onClick: () => addActionSetAttribute(parentID, index + 1),
                icon: (
                  <BookmarkBorderOutlinedIcon
                    sx={{
                      color: globalTheme.palette?.purple?.[600],
                    }}
                  />
                ),
                title: t('canvas.blockMenu.setAttribute'),
              },
              {
                onClick: () => addActionRemoveAttribute(parentID, index + 1),
                icon: (
                  <BookmarkRemoveOutlinedIcon
                    sx={{
                      color: globalTheme.palette?.purple?.[600],
                    }}
                  />
                ),
                title: t('canvas.blockMenu.removeAttribute'),
              },
              {
                onClick: () => addActionSubscribeSequence(parentID, index + 1),
                icon: (
                  <LabelOutlinedIcon
                    sx={{
                      color: globalTheme.palette?.purple?.[600],
                    }}
                  />
                ),
                title: t('canvas.blockMenu.subscribeSequence'),
              },
              {
                onClick: () =>
                  addActionUnsubscribeSequence(parentID, index + 1),
                icon: (
                  <LabelOffOutlinedIcon
                    sx={{
                      color: globalTheme.palette?.purple?.[600],
                    }}
                  />
                ),
                title: t('canvas.blockMenu.unsubscribeSequence'),
              },
              {
                onClick: () => addActionExportGoogleSheet(parentID, index + 1),
                icon: (
                  <TableChartOutlinedIcon
                    sx={{
                      color: globalTheme.palette?.purple?.[600],
                    }}
                  />
                ),
                title: t('canvas.blockMenu.exportGoogleSheet'),
              },
            ],
          },
        ];
      }
      default: {
        return [
          {
            dropdownItems: [
              {
                id: 'option_text_button',
                onClick: () => addTextButton(parentID, index + 1),
                icon: <TextIcon color="primary" />,
                title: t('canvas.blockMenu.contentButton'),
              },
              {
                id: 'option_image',
                onClick: () => addImage(parentID, index + 1),
                icon: <ImageOutlinedIcon color="primary" />,
                title: t('canvas.blockMenu.image'),
              },
              {
                id: 'option_gallery',
                onClick: () => addGallery(parentID, index + 1),
                icon: <CollectionsOutlinedIcon color="primary" />,
                title: t('canvas.blockMenu.gallery'),
                tooltip: <Box>{t('canvas.blockMenu.showGalleryTooltip')}</Box>,
              },
              {
                id: 'option_theme_subscription',
                onClick: () => addRecurringNotification(parentID, index + 1),
                icon: <NotificationsNoneIcon color="primary" />,
                title: t('canvas.blockMenu.recurringNotificationsButton'),
                tooltip: <Box>{t('canvas.blockMenu.rnTooltip')}</Box>,
              },
              {
                id: 'option_collect_user_answer',
                onClick: () => addCollectUserAnswer(parentID, index + 1),
                icon: <CommentBankOutlinedIcon color="primary" />,
                title: t('canvas.blockMenu.collectUserAnswerButton'),
                tooltip: (
                  <Box>
                    {t('canvas.blockMenu.showCollectUserAnswerTooltip')}
                  </Box>
                ),
              },
            ],
          },
        ];
      }
    }
  }, [
    addActionExportGoogleSheet,
    addActionRemoveAttribute,
    addActionSetAttribute,
    addActionSubscribeSequence,
    addActionUnsubscribeSequence,
    addGallery,
    addImage,
    addRecurringNotification,
    addTextButton,
    addCollectUserAnswer,
    floatPanelType,
    index,
    parentID,
    t,
  ]);

  if (!handleDelete && !handleAdd && !handleMore && !draggable) {
    return null;
  }

  return (
    <FloatPanelWrapperStyled
      direction={direction}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      $isCenter={isCenter}
      id="float-panel"
      sx={{
        ...(position &&
          isByCoordinate && {
            top: position.y,
            left: position.x,
          }),
      }}
    >
      <ContainerStyled
        direction={direction}
        backgroundColor={backgroundColor}
        $isFixedSize={isByCoordinate}
      >
        {draggable && (
          <DragHandle
            id={`${type}_drag`}
            handleHover={handleHover}
            onMouseEnter={() => state.setIsFlowDraggable(false)}
            onMouseLeave={() => state.setIsFlowDraggable(true)}
          />
        )}
        {handleCopy && (
          <Box
            id={`${type}_duplicate`}
            onClick={(e) => {
              // 避免繼續觸發 node select
              e.preventDefault();
              e.stopPropagation();
              handleCopy();
            }}
            onMouseEnter={() => {
              handleHover && handleHover('copy');
            }}
            onMouseLeave={() => {
              handleHover && handleHover('');
            }}
          >
            <ContentCopyOutlinedIcon />
          </Box>
        )}
        {handleDelete && (
          <Box
            id={`${type}_delete`}
            onClick={handleDelete}
            onMouseEnter={() => {
              handleHover && handleHover('delete');
            }}
            onMouseLeave={() => {
              handleHover && handleHover('');
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </Box>
        )}
        {handleSwitchRatio && (
          <Box id={`${type}_switch_ratio`} onClick={handleSwitchRatio}>
            <AspectRatioIcon />
          </Box>
        )}
        {allowAdd && (
          <Box
            id={`${type}_add`}
            ref={addRef}
            onClick={() => handleAddClick(addRef)}
            onMouseEnter={() => {
              handleHover && handleHover('add');
            }}
            onMouseLeave={() => {
              handleHover && handleHover('');
            }}
          >
            <AddIcon />
          </Box>
        )}
        {handleMore && (
          <Box>
            <MoreHorizIcon />
          </Box>
        )}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <EditorMenu itemList={menuList} />
        </Popover>
      </ContainerStyled>
    </FloatPanelWrapperStyled>
  );
};

export default EditorFloatPanel;
