import { globalTheme } from '@frontend/components/external-providers';
import { Switch, SwitchProps } from '@frontend/components/ui';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { FC } from 'react';
import EditorLabel from '../editor-label/editor-label';

export interface NodeHeaderProps {
  color?: string;
  background?: string;
  title?: string;
  icon?: JSX.Element;
  readonly?: boolean;
  disabled?: boolean;
  switchProps?: SwitchProps;
  onBlur?: (val: string) => void;
}

const HeaderStyled = styled(Box)<{
  $color?: string;
  $background?: string;
  $readonly?: boolean;
  $disabled?: boolean;
}>(({ theme, $color, $background, $readonly, $disabled }) => ({
  borderRadius: $disabled ? '12px' : '12px 12px 0 0',
  color: $disabled ? get(globalTheme, 'palette.grey.500') : $color,
  minWidth: '316px',
  background: $disabled ? get(globalTheme, 'palette.grey.50') : $background,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 12px',

  '& > div': {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > svg': {
      background: $disabled ? get(globalTheme, 'palette.grey.50') : $background,
      '& > g > path': {
        fill: $color,
      },
    },
  },
}));

export const NodeHeader: FC<NodeHeaderProps> = ({
  color,
  background,
  title = '',
  icon,
  readonly,
  disabled,
  switchProps,
  onBlur,
}: NodeHeaderProps) => {
  return (
    <HeaderStyled
      $color={color}
      $background={background}
      $readonly={readonly}
      $disabled={disabled}
    >
      <Box>
        {icon}
        {readonly ? (
          <Typography
            {...(disabled && { color: get(globalTheme, 'palette.grey.500') })}
            sx={{ marginLeft: '8px' }}
            variant="subtitle1"
          >
            {title}
          </Typography>
        ) : (
          <EditorLabel color={color} onBlur={onBlur} defaultValue={title} />
        )}
      </Box>
      {switchProps && (
        <Switch {...switchProps} onMouseDown={switchProps.onMouseDown} />
      )}
    </HeaderStyled>
  );
};

export default NodeHeader;
