import {
  GetCommentReplyListResponseType,
  OrderByEnum,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY_LIST } from '../apis';

interface Props {
  limit: number;
  channelID?: string;
  keyword?: string;
  offset?: number;
  orderBy?: OrderByEnum | null | undefined;
  sortBy?: string;
}

const defaultData = {
  totalCount: 0,
  filterCount: 0,
  commentReplies: [],
  limit: 0,
  offset: 0,
};

async function getCommentReplyList(
  limit: number,
  channelID?: string,
  keyword?: string,
  offset?: number,
  orderBy?: OrderByEnum | null | undefined,
  sortBy?: string,
): Promise<GetCommentReplyListResponseType> {
  return axios
    .get(
      `${COMMENT_REPLY_LIST}?limit=${limit}${
        channelID ? `&projectChannelId=${channelID}` : ''
      }${keyword ? `&keyword=${keyword}` : ''}${
        offset ? `&offset=${offset}` : ''
      }${orderBy ? `&orderBy=${orderBy}` : ''}${
        sortBy ? `&sortBy=${sortBy}` : ''
      }`,
    )
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function useGetCommentReplyList({
  limit,
  channelID,
  keyword,
  offset,
  orderBy,
  sortBy,
}: Props) {
  return useQuery({
    queryKey: [
      'get-comment-reply-list',
      { limit, projectChannelID: channelID, keyword, offset, orderBy, sortBy },
    ],
    queryFn: () => {
      if (limit) {
        return getCommentReplyList(
          limit,
          channelID,
          keyword,
          offset,
          orderBy,
          sortBy,
        );
      } else {
        return defaultData;
      }
    },
  });
}
