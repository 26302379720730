import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { GetCommentReplyTemplateCategoryResponse } from '@frontend/sorghum/interface';
import { COMMENT_REPLY_TEMPLATE_CATEGORY } from '../apis';

const defaultData = [
  {
    zh_tw: {
      categoryId: '9add2859-de41-4a25-9d3f-1db51cad8e00',
      isDefault: true,
      name: '自動回覆 IG 留言',
      shortDesc: '當用戶在 IG 留言後，Chatbot 立即回覆，提升互動率',
      longDesc: '',
      useCase: '',
      hashTag: [],
      pictureUrl: '',
      template: [
        {
          id: '9add2859-de41-4a25-9d3f-1db51cad8e01',
          triggerType: 1,
          recommend: true,
        },
        {
          id: '9add2859-de41-4a25-9d3f-1db51cad8e02',
          triggerType: 2,
          recommend: true,
        },
        {
          id: '9add2859-de41-4a25-9d3f-1db51cad8e03',
          triggerType: 3,
          recommend: false,
        },
      ],
    },
    en_us: {
      categoryId: '9add2859-de41-4a25-9d3f-1db51cad8e10',
      isDefault: true,
      name: 'Auto-reply to IG comments',
      shortDesc:
        'When users comment on IG, the Chatbot instantly replies to boost engagement',
      longDesc: '',
      useCase: '',
      hashTag: [],
      pictureUrl: '',
      template: [
        {
          id: '9add2859-de41-4a25-9d3f-1db51cad8e11',
          triggerType: 1,
          recommend: true,
        },
        {
          id: '9add2859-de41-4a25-9d3f-1db51cad8e12',
          triggerType: 2,
          recommend: true,
        },
        {
          id: '9add2859-de41-4a25-9d3f-1db51cad8e13',
          triggerType: 3,
          recommend: false,
        },
      ],
    },
  },
  {
    zh_tw: {
      categoryId: '9add2859-de41-4a25-9d3f-1db51cad8e20',
      isDefault: false,
      name: '自動回覆私訊獨家資訊下載',
      shortDesc:
        '當用戶在 IG 留言後，轉私訊填寫信箱或電話，獲得獨家資訊/e-book/線上食譜',
      longDesc:
        '使用『收集信箱/電話獲得獨家資訊』模板，當用戶在 IG Story 或 Reels 留言，Chatbot 會自動私訊請用戶回覆相關資料後，提供 e-book 下載；旅遊 PDF 外連下載 ; 獨家資訊外連下載 ; 分享食譜等',
      useCase: 'https://www.youtube.com/',
      hashTag: ['旅遊', '餐飲', '媒體', 'KOL', '教育課程'],
      pictureUrl: 'https://picture.com',
      template: [
        {
          id: '9add2859-de41-4a25-9d3f-1db51cad8e21',
          triggerType: 1,
          recommend: true,
        },
        {
          id: '9add2859-de41-4a25-9d3f-1db51cad8e22',
          triggerType: 2,
          recommend: true,
        },
        {
          id: '9add2859-de41-4a25-9d3f-1db51cad8e23',
          triggerType: 3,
          recommend: false,
        },
      ],
    },
    en_us: {
      categoryId: '9add2859-de41-4a25-9d3f-1db51cad8e30',
      isDefault: false,
      name: 'Auto-reply & collect user info to get incentive',
      shortDesc:
        'After commenting on IG, reply answer in private reply to get download links',
      longDesc:
        'Use the ‘Auto-reply & collect user info to get incentive’ template to send a DM when users comment on an IG Story or Reel. The chatbot automatically sends private conversation to collect user information such as email or phone and then sent the e-book download link, Travel PDF download, or food recipe',
      useCase: 'https://www.youtube.com/',
      hashTag: ['TravelAgency', 'F&B', 'Media', 'KOL', 'Edtech'],
      pictureUrl: 'https://picture.com',
      template: [
        {
          id: '9add2859-de41-4a25-9d3f-1db51cad8e31',
          triggerType: 1,
          recommend: true,
        },
        {
          id: '9add2859-de41-4a25-9d3f-1db51cad8e32',
          triggerType: 2,
          recommend: true,
        },
        {
          id: '9add2859-de41-4a25-9d3f-1db51cad8e33',
          triggerType: 3,
          recommend: false,
        },
      ],
    },
  },
];

async function getCommentReplyTemplateCategory(): Promise<
  GetCommentReplyTemplateCategoryResponse[]
> {
  return axios
    .get(COMMENT_REPLY_TEMPLATE_CATEGORY)
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function useGetCommentReplyTemplateCategory() {
  return useQuery<GetCommentReplyTemplateCategoryResponse[]>({
    queryKey: ['get-comment-reply-template-category'],
    queryFn: () => getCommentReplyTemplateCategory(),
  });
}

export default useGetCommentReplyTemplateCategory;
