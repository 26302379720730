import {
  NotificationCtx,
  queryClient,
  UICtx,
} from '@frontend/sorghum/external-providers';
import { SocialTypeEnum } from '@frontend/sorghum/interface';
import { isEmpty } from 'lodash';
import { startTransition, useCallback, useContext } from 'react';
import {
  NavigateOptions,
  To,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { sendGAEvent } from '../ga';

export const MAIN = 'home';

export const LOGIN = 'signin';
export const SIGNUP = 'signup';
export const PROJECT = 'project';
export const FACEBOOK = 'facebook';
export const AUTH_CALLBACK = 'authcallback';
export const GOOGLE = 'google';
export const ERROR = 'error';
export const RESET = 'reset';
export const VERIFY = 'verify';
export const EMAIL = 'email';
export const PASSWORD = 'password';
export const ROOT = '/';
export const MICRO = 'micro';
export const SELECT_CHANNEL = 'select-channel';
export const LIMIT = 'limit';
export const PAGES = 'pages';
export const SETTING = 'setting';
export const FLOW = 'flow';
export const FLOW_ENTRY = 'automated-message';
export const PERSISTENT_MENU = 'persistent-menu';
export const DEFAULT_ANSWER = 'default-answer';
export const WELCOME_MESSAGE = 'welcome-message';
export const CONVERSATION_STARTER = 'conversation-starter';
export const VERIFY_UNAVAILABLE = 'verify-unavailable';
export const FLOW_VIEW = 'flow-view';
export const PEOPLE = 'customer';
export const BROADCAST = 'broadcast';
export const DRAFT = 'draft';
export const SENT = 'sent';
export const DASHBOARD = 'dashboard';
export const SEQUENCE = 'follow-up';
export const COUPON = 'coupon';
export const INCENTIVES = 'incentives';
export const GOOGLE_AUTH = 'google-auth';
export const PROJECT_INVITE = 'project-invite';
export const PERMISSION_DENIED = 'permission-denied';
export const PRIVACY_POLICY = 'privacy';
export const COMMENT_AUTO_REPLY = 'comment-auto-reply';
export const LIST = 'list';
export const CONTENT = 'content';
export const PAYMENT_DETAILS = 'payment-details';
export const TAP_PAY_CALLBACK = 'tap-pay-callback';
export const UPGRADE = 'upgrade';
export const TEMPLATE_SELECTION = 'template-selection';
export const AI_GUIDED = 'ai-guided';
export const SELF_GUIDED = 'self-guided';
export const TOUR = 'tour';
export const ONBOARDING = 'onboarding';
export const MESSENGER = 'messenger';
export const IG = 'instagram';
export const THREADS = 'threads';
export const TEMPLATE = 'template';

// before login
export const PAGE_LOGIN = `/${LOGIN}`;
export const PAGE_SIGN_UP = `/${SIGNUP}`;
export const PAGE_FACEBOOK_CALLBACK = `/${FACEBOOK}/${AUTH_CALLBACK}`;
export const PAGE_GOOGLE_CALLBACK = `/${GOOGLE}/${AUTH_CALLBACK}`;
export const PAGE_EMAIL_VERIFY = `/${VERIFY}/${EMAIL}`;
export const PAGE_RESET = `/${RESET}`;
export const PAGE_RESET_PASSWORD = `/${RESET}/${PASSWORD}`;
export const PAGE_PASSWORD_VERIFY = `/${VERIFY}/${PASSWORD}`;
export const PAGE_VERIFY_LIMIT = `/${VERIFY}/${LIMIT}`;
export const PAGE_TAP_PAY_CALLBACK = `/${TAP_PAY_CALLBACK}`;
// 問卷頁
export const PAGE_MICRO = `/${MICRO}`;
// 選擇渠道頁
export const PAGE_SELECT_CHANNEL = `/${SELECT_CHANNEL}`;
// 連結粉專頁
export const PAGE_PAGES = `/${PAGES}`;
export const PAGE_GOOGLE_AUTH = `/${GOOGLE_AUTH}`;
export const PAGE_PROJECT_INVITE = `/${PROJECT_INVITE}`;
export const PAGE_VERIFY_UNAVAILABLE = `/${VERIFY}/${VERIFY_UNAVAILABLE}`;

// dashboard
export const PAGE_MAIN = `/${MAIN}`;
export const PAGE_PROJECT = `/${PROJECT}`;
export const PAGE_IG = `/${IG}`;
export const PAGE_MESSENGER = `/${MESSENGER}`;
export const PAGE_THREADS = `/${THREADS}`;
export const PAGE_SETTING = `/${SETTING}`;
export const PAGE_FLOW = `/${FLOW}`;
export const PAGE_FLOW_ENTRY = `/${FLOW_ENTRY}`;
export const PAGE_FLOW_VIEW = `/${FLOW_VIEW}`;
export const PAGE_PERSISTENT_MENU = `/${PERSISTENT_MENU}`;
export const PAGE_DEFAULT_ANSWER = `/${DEFAULT_ANSWER}`;
export const PAGE_WELCOME_MESSAGE = `/${WELCOME_MESSAGE}`;
export const PAGE_CONVERSATION_STARTER = `/${CONVERSATION_STARTER}`;
export const PAGE_PEOPLE = `/${PEOPLE}`;
export const PAGE_BROADCAST = `/${BROADCAST}`;
export const PAGE_DASHBOARD = `/${DASHBOARD}`;
export const PAGE_DASHBOARD_FLOW_COMPARE = `/${DASHBOARD}/${FLOW}`;
export const PAGE_SEQUENCE = `/${SEQUENCE}`;
export const PAGE_COUPON_LIST = `/${COUPON}`;
export const PAGE_PERMISSION_DENIED = `/${PERMISSION_DENIED}`;
export const PAGE_PRIVACY_POLICY = `/${PRIVACY_POLICY}`;
export const PAGE_COMMENT_AUTO_REPLY = `/${COMMENT_AUTO_REPLY}`;
export const PAGE_TEMPLATE_SELECTION = `/${TEMPLATE_SELECTION}`;
export const PAGE_TEMPLATE = `/${TEMPLATE}`;
export const PAGE_NOT_FOUND = `/404`;
export const PAGE_TOUR_ONBOARDING = `/${TOUR}/${ONBOARDING}`;
export const PAGE_SELF_GUIDED = `/${TEMPLATE_SELECTION}/${SELF_GUIDED}`;
export const PAGE_AI_GUIDED = `/${TEMPLATE_SELECTION}/${AI_GUIDED}`;
// payment
export const PAGE_UPGRADE = `/${UPGRADE}`;
export const PAGE_PAYMENT_DETAILS = `/${SETTING}/${PAYMENT_DETAILS}`;

// google
export const GOOGLE_SHEET = 'https://docs.google.com/spreadsheets/d';

// google api storage
export const COUPON_SAMPLE_CSV = '/static/sample/coupon_sample.csv';

// others
export const PAGE_REDIRECT = 'redirect';
export const PAGE_REDIRECT_THREADS = 'redirect-threads';
export const PAGE_REDIRECT_IG = 'redirect-instagram';

// facebook
export const FACEBOOK_PAGE_CREATE =
  'https://www.facebook.com/pages/creation/?ref_type=launch_point';

export const CONTACT_US_EMAIL = 'customersuccess@goskyai.com';

export const CONTACT_US_MESSENGER =
  'https://m.me/goskyai?ref=chatbot_faq4.0_chatform';

export const PAGE_TERMS_OF_SERVICE_ZH =
  'https://www.goskyai.com/chatbot-platform/terms';

// 教學文章外部連結
export const PERMISSION_CHANGED_ZH =
  'https://industrious-marigold-f2e.notion.site/GoSky-AI-Chatbot-Platform-FAQ-29070a7b43854bc6ad8d9aa2fedce3bd#d1873541ceb9433183ce24d0bb9a74c6';
export const FACEBOOK_TUTORIAL_ZH =
  'https://industrious-marigold-f2e.notion.site/GoSky-AI-Chatbot-Platform-FAQ-38a299724a65496c95e923ae4d2f80e2';
export const FACEBOOK_TUTORIAL_EN =
  'https://industrious-marigold-f2e.notion.site/GoSky-AI-chatbot-platform-Frequently-Asked-Question-3f21117897804aadb94307708dbf1309';
export const THREADS_TUTORIAL_ZH =
  'https://www.notion.so/GoSky-AI-Chatbot-Threads-FAQ-8d19c7fe0ae8441684eb108bdc094c72';
export const THREADS_TUTORIAL_EN =
  'https://www.notion.so/GoSky-AI-Chatbot-Threads-Frequently-Asked-Questions-2fe31d11e64240ed953c1e96a36a79ce';
export const INSTAGRAM_TUTORIAL_ZH =
  'https://www.notion.so/GoSky-AI-Chatbot-Instagram-FAQ-295915941e584e7088fdfb281d5bea98';
export const INSTAGRAM_TUTORIAL_EN =
  'https://www.notion.so/GoSky-AI-Chatbot-Instagram-Frequently-Asked-Questions-763205291d7d45a58f2787d8eb419431';
export const VIEW_TRIGGER_DOCUMENT =
  'https://industrious-marigold-f2e.notion.site/aacef17b9119458fbe98c6619bfb5f8d';
export const VIEW_TRIGGER_DOCUMENT_EN =
  'https://industrious-marigold-f2e.notion.site/How-to-Use-Data-Metrics-to-Understand-and-Analyze-the-Execution-Status-of-Features-or-Project-Activi-0c7a3c6f8e704f618fc6d6ac07aeb27d';
export const GOSKY_USE_CASE = 'https://www.goskyai.com/tw/blog/casestudy';
export const GOSKY_USE_CASE_EN = '//www.goskyai.com/en';
export const HOW_TO_TRACK_WITH_UTM =
  'https://industrious-marigold-f2e.notion.site/UTM-a05dd03450bf4b7898c3cc9a0151f28b';
export const HOW_TO_TRACK_WITH_UTM_EN =
  'https://industrious-marigold-f2e.notion.site/EN-UTM-Urchin-Tracking-Module-0ef34765f0e44301aa0079fe26a4097f';
export const HOW_TO_CONNECT_TO_FACEBOOK_PAGE =
  'https://industrious-marigold-f2e.notion.site/chatbot-FB-fa1de49b55eb44b0bafc3aceb4695261';
export const HOW_TO_CONNECT_TO_FACEBOOK_PAGE_EN =
  'https://industrious-marigold-f2e.notion.site/chatbot-FB-822547ce72864f08bcd0e270acff5cdd?pvs=4';
export const HOW_TO_COPY_POST =
  'https://www.facebook.com/business/help/405841712841961';
export const HOW_TO_USE_ATTRIBUTE_ZH =
  'https://www.notion.so/Attribute-f6bb73d3bab74765ba5472a951540325';
export const HOW_TO_USE_ATTRIBUTE_EN =
  'https://industrious-marigold-f2e.notion.site/Attribute-d14727bc4ebd40549ca140fc147bfe57';
export const HOW_TO_USE_BROADCAST =
  'https://www.notion.so/Broadcast-821385d32edb47168b9b8f3be9b265f9';
export const HOW_TO_USE_BROADCAST_EN =
  'https://industrious-marigold-f2e.notion.site/Broadcast-c8aeccc068b04cad926caab5aa179a5a?pvs=4';
export const HOW_TO_USE_RN_ZH =
  'https://industrious-marigold-f2e.notion.site/Subscription-Invite-f7be887931b54dcda44d70332afbf638';
export const HOW_TO_USE_RN_EN =
  'https://industrious-marigold-f2e.notion.site/Subscription-Invite-80cf4d04e91d405e8edc1951dfe9348e?pvs=74';
export const HOW_TO_USE_COMMENT_REPLY_ZH =
  'https://www.notion.so/Comment-Auto-Reply-Messenger-751ecab16f074488a622f8189afff140?pvs=4#822ee9923a64472994177a022f0ed870';
export const HOW_TO_USE_COMMENT_REPLY_EN =
  'https://www.notion.so/How-to-Use-Comment-Auto-Reply-to-Boost-Social-Interaction-and-Drive-Messenger-Conversions-31cbe68625bc4299b408f494e6431bee?pvs=4#3f3cc5f933994ac6a31bd3b75d22f670';

export const ANIMATION_RN_ZH = '/static/video/RN_demo_video_Chinese.json';
export const ANIMATION_RN_EN = '/static/video/RN_demo_video_English.json';
export const ANIMATION_DRAW_ZH =
  '/static/video/%E5%89%B5%E6%84%8F%E4%BA%92%E5%8B%95_Chinese.json';
export const ANIMATION_DRAW_EN =
  '/static/video/%E5%89%B5%E6%84%8F%E4%BA%92%E5%8B%95_English.json';
export const ANIMATION_AUTO_REPLY_ZH =
  '/static/video/%E8%87%AA%E5%8B%95%E5%9B%9E%E8%A6%86_Chinese.json';
export const ANIMATION_AUTO_REPLY_EN =
  '/static/video/%E8%87%AA%E5%8B%95%E5%9B%9E%E8%A6%86_English.json';

export const FACEBOOK_FAN_PAGE_READ_MORE_ZH =
  'https://www.notion.so/chatbot-FB-fa1de49b55eb44b0bafc3aceb4695261?pvs=4#3e39fd7f8b484acbaa4da44435a230da';
export const FACEBOOK_FAN_PAGE_READ_MORE_EN =
  'https://www.notion.so/chatbot-FB-822547ce72864f08bcd0e270acff5cdd?pvs=4#76b9ef5df9484327b23bd402bc37f1ef';

export const PEOPLE_DETAIL_MODAL_SEQUENCE_SEE_DETAIL_ZH =
  'https://industrious-marigold-f2e.notion.site/Follow-Up-232955c998c54ae492b21c54915cecf3';
export const PEOPLE_DETAIL_MODAL_SEQUENCE_SEE_DETAIL_EN =
  'https://industrious-marigold-f2e.notion.site/How-to-Use-Follow-Up-Messages-to-Re-engage-Lost-Consumers-in-the-Flow-46175a2e24f1423e8cdd287e7ea930bb';
export const PEOPLE_DETAIL_MODAL_TAG_SEE_DETAIL_ZH =
  'https://industrious-marigold-f2e.notion.site/User-Tag-f38b4f2de3824a0195b232bcf3a60b5a';
export const PEOPLE_DETAIL_MODAL_TAG_SEE_DETAIL_EN =
  'https://industrious-marigold-f2e.notion.site/How-to-Use-User-Tag-Settings-to-Group-or-Categorize-Consumers-5af34c3872f544c4ab7b3ea380838f8d';
export const PEOPLE_DETAIL_MODAL_ATTRIBUTE_SEE_DETAIL_ZH =
  'https://industrious-marigold-f2e.notion.site/Attribute-f6bb73d3bab74765ba5472a951540325';
export const PEOPLE_DETAIL_MODAL_ATTRIBUTE_SEE_DETAIL_EN =
  'https://industrious-marigold-f2e.notion.site/How-to-Automatically-Tag-Specific-Consumers-with-Attribute-Components-in-a-Process-d14727bc4ebd40549ca140fc147bfe57';

export const VIEWER_PERMISSION_ZH =
  'https://industrious-marigold-f2e.notion.site/GoSky-AI-Chatbot-Platform-FAQ-29070a7b43854bc6ad8d9aa2fedce3bd#11a34223354780bfb6fbe30d1d384700';
export const VIEWER_PERMISSION_EN =
  'https://www.notion.so/GoSky-AI-Chatbot-Platform-FAQ-12834223354780038ad6ffd72f3d09cc#128342233547805aa3cdfc014dd9e110';
export const THREAD_VIEW_MODE_ZH =
  'https://industrious-marigold-f2e.notion.site/GoSky-AI-Chatbot-Threads-FAQ-8d19c7fe0ae8441684eb108bdc094c72#17d34223354780ab9ad5dd9cc9d55ec1';
export const THREAD_VIEW_MODE_EN =
  'https://industrious-marigold-f2e.notion.site/GoSky-AI-Chatbot-Threads-Frequently-Asked-Questions-2fe31d11e64240ed953c1e96a36a79ce#17d34223354780569c79efd4567c1aaa';
export const INSTAGRAM_VIEW_MODE_ZH =
  'https://www.notion.so/GoSky-AI-Chatbot-Instagram-FAQ-295915941e584e7088fdfb281d5bea98?pvs=4';
export const INSTAGRAM_VIEW_MODE_EN =
  'https://www.notion.so/GoSky-AI-Chatbot-Instagram-Frequently-Asked-Questions-763205291d7d45a58f2787d8eb419431?pvs=4';

export const FACEBOOK_PLAN_DETAILS_ZH =
  'https://www.goskyai.com/chatbot-platform/pricing?utm_source=chatbot_platform&utm_medium=link&utm_campaign=plan_details';
export const FACEBOOK_PLAN_DETAILS_EN =
  'https://www.goskyai.com/chatbot-platform/pricing-en';
export const FACEBOOK_PRICING_PAGE =
  'https://www.goskyai.com/chatbot-platform/pricing?utm_source=chatbot_platform&utm_medium=link&utm_campaign=trial_end';
export const THREADS_PRICING_PAGE_ZH =
  'https://www.goskyai.com/chatbot-platform/threads-comment-auto-reply#pricing';
export const THREADS_PRICING_PAGE_EN =
  'https://www.goskyai.com/chatbot-platform/auto-reply-for-threads-comments-en#pricing-en';
export const INSTAGRAM_PRICING_PAGE_ZH =
  'https://www.goskyai.com/chatbot-platform/instagram#pricing';
export const INSTAGRAM_PRICING_PAGE_EN =
  'https://www.goskyai.com/chatbot-platform/instagram-en#pricing';

export const BUSINESS_ACCOUNT_TUTORIAL_ZH =
  'https://industrious-marigold-f2e.notion.site/343d9d6ec0234dfc8d668298a4a5485e#156f0b6eb3df4389a302649dc26c611a';
export const BUSINESS_ACCOUNT_TUTORIAL_EN =
  'https://industrious-marigold-f2e.notion.site/Setting-cbfa7d2b43454f5ea91b5c833166b48e#765c359c66e14d2c80ac4b9af94f1a15';

export const BLOG_EN = 'https://www.goskyai.com/en/gosky-blog';
export const BLOG_ZH = 'https://www.goskyai.com/tw/blog/casestudy';
export const TUTORIAL_EN =
  'https://www.notion.so/GoSky-AI-Chatbot-Instagram-Frequently-Asked-Questions-763205291d7d45a58f2787d8eb419431';
export const TUTORIAL_ZH =
  'https://www.notion.so/GoSky-AI-Chatbot-Instagram-FAQ-295915941e584e7088fdfb281d5bea98';

interface ParamsType {
  searchFilter: string;
  search?: string;
  operator?: string;
  name?: string;
  flowID?: string;
  blockID?: string;
  status?: string;
  key?: string;
  entryID?: string;
  entryType?: string | number;
}

export const usePath = () => {
  const { projectID } = useParams();
  const { clear: clearNotibarType } = useContext(NotificationCtx);
  const navigate = useNavigate();
  const { closeTour } = useContext(UICtx);
  const [searchParams] = useSearchParams();

  const getQueryParam = (key: string) => {
    return searchParams.get(key) || '';
  };

  const navigateWithTransition = useCallback(
    (to: To, options?: NavigateOptions) => {
      startTransition(() => {
        navigate(to, options);
      });
    },
    [navigate],
  );

  const navigateToProjectPage = useCallback(
    (param?: {
      path?: string;
      options?: NavigateOptions;
      id?: string;
      socialType?: SocialTypeEnum;
      backToMain?: boolean;
    }) => {
      if (!isEmpty(param?.id)) {
        queryClient.clear();
        closeTour();
        clearNotibarType();
      }

      const targetProjectID = param?.id ?? projectID;

      let url = PAGE_MAIN;

      const pathname = window.location.pathname.split('/')?.[1] || '';

      if (param?.socialType) {
        switch (param.socialType) {
          case SocialTypeEnum.FACEBOOK:
            url = `${PAGE_MESSENGER}/${targetProjectID}${param.path || ''}`;
            break;
          case SocialTypeEnum.INSTAGRAM:
            url = `${PAGE_IG}/${targetProjectID}${param.path || ''}`;
            break;
          case SocialTypeEnum.THREADS:
            url = `${PAGE_THREADS}/${targetProjectID}${param.path || ''}`;
            break;
          default:
            break;
        }
      } else if (param?.backToMain && param?.path) {
        url = param.path;
      } else if (
        pathname === 'messenger' ||
        pathname === 'instagram' ||
        pathname === 'threads'
      ) {
        url = `/${pathname}/${targetProjectID}${param?.path || ''}`;
      } else {
        // 若沒有 socialType 就轉到 home
        url = PAGE_MAIN;
      }

      startTransition(() => {
        navigate(url, param?.options);
      });
    },
    [clearNotibarType, closeTour, navigate, projectID],
  );

  const navigateToPeopleWithCondition = useCallback(
    (params: ParamsType) => {
      startTransition(() => {
        navigateToProjectPage({
          path: `${PAGE_PEOPLE}?searchFilter=${params.searchFilter}${
            params.key ? `&key=${params.key}` : ''
          }${params.search ? `&search=${params.search}` : ''}${
            params.operator ? `&operator=${params.operator}` : ''
          }${params.name ? `&name=${params.name}` : ''}${
            params.flowID ? `&flowID=${params.flowID}` : ''
          }${params.blockID ? `&blockID=${params.blockID}` : ''}${
            params.status ? `&status=${params.status}` : ''
          }${params.entryID ? `&entryID=${params.entryID}` : ''}${
            params.entryType ? `&entryType=${params.entryType}` : ''
          }`,
        });
      });
    },
    [navigateToProjectPage],
  );

  const navigateToUpgrade = useCallback(
    (param: {
      socialType?: SocialTypeEnum | undefined;
      projectID?: string;
      source?: 'limit warning module' | 'notibar' | 'setting';
    }) => {
      if (param?.source) {
        sendGAEvent(param.source, 'upgrade', 'upgrade-click', '');
      }

      if (param?.projectID) {
        const url = `${PAGE_PROJECT}/${param.projectID}${PAGE_UPGRADE}`;
        if (param?.socialType) {
          startTransition(() => {
            navigate(
              url +
                `?social_type=${param.socialType}${
                  param.source ? `&source=${param.source}` : ''
                }`,
            );
          });
        } else {
          startTransition(() => {
            navigate(url);
          });
        }
      } else {
        if (param?.socialType) {
          startTransition(() => {
            navigate(
              PAGE_UPGRADE +
                `?social_type=${param.socialType}${
                  param.source ? `&source=${param.source}` : ''
                }`,
            );
          });
        } else {
          startTransition(() => {
            navigate(
              PAGE_UPGRADE + `${param.source ? `&source=${param.source}` : ''}`,
            );
          });
        }
      }
    },
    [navigate],
  );

  // 清除資料並且重新進入專案的首頁
  const redirectToMain = useCallback(() => {
    sessionStorage.removeItem('project-id');
    localStorage.removeItem('project-id');
    queryClient.clear();
    closeTour();
    clearNotibarType();

    window.open(window.location.origin + PAGE_MAIN, '_self');
  }, [clearNotibarType, closeTour]);

  const navigateTo404 = useCallback(() => {
    startTransition(() => {
      navigate(PAGE_NOT_FOUND);
    });
  }, [navigate]);

  return {
    reload: () => startTransition(() => navigate(0)),
    navigate: navigateWithTransition,
    navigateTo404,
    navigateToProjectPage,
    navigateToUpgrade,
    redirectToMain,
    getQueryParam,
    navigateToPeopleWithCondition,
  };
};
