import { Button, SelectItem, TextField } from '@frontend/components/ui';
import { useCanvasUpdate } from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import { DrawerTypesEnum, ElementTypesEnum } from '@frontend/editor/interface';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { EditorDrawer } from '../editor-drawer/editor-drawer';

interface Props {
  updatedOptionValue: number;
}

const ContainerStyled = styled('div')(({ theme }) => ({
  whiteSpace: 'break-spaces',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '24px',
}));

export const DrawerCollectUserAnswerV2 = () => {
  const [t] = useTranslation();
  const {
    onFocusCellID,
    getElement,
    setIsDrawerUpdate,
    setIsDrawerFullyUpdate,
    setOnFocusCellID,
  } = useContext(EditorCtx);
  const { setDrawerType, drawerType, closeModal } = useContext(UICtx);
  const [optionValue, setOptionValue] = useState<string>('');
  const { canvasUpdateData, canvasUpdateLabel } = useCanvasUpdate();

  const element = getElement(onFocusCellID);

  const shouldApplyButtonDisabled = useMemo(() => {
    let shouldDisabled = false;

    if (!optionValue) {
      shouldDisabled = true;
    }
    return shouldDisabled;
  }, [optionValue]);

  const handleApply = useCallback(
    (props?: Props) => {
      const usedOptionValue = props?.updatedOptionValue
        ? props.updatedOptionValue
        : optionValue;

      if (element) {
        canvasUpdateData(element.id, 'data.replyType', usedOptionValue);

        switch (usedOptionValue) {
          case 1: {
            canvasUpdateLabel(
              element.id,
              t('canvas.collectUserAnswerV2.default.phone'),
            );
            break;
          }
          case 2: {
            canvasUpdateLabel(
              element.id,
              t('canvas.collectUserAnswerV2.default.email'),
            );
            break;
          }
          case 3: {
            canvasUpdateLabel(
              element.id,
              t('canvas.collectUserAnswerV2.default.custom'),
            );
            break;
          }
        }
        setIsDrawerUpdate(false);
        setIsDrawerFullyUpdate(true);
        setOnFocusCellID('');
        setDrawerType(DrawerTypesEnum.CLOSE);
        closeModal();
      }
    },
    [
      optionValue,
      element,
      canvasUpdateData,
      setIsDrawerUpdate,
      setIsDrawerFullyUpdate,
      setOnFocusCellID,
      setDrawerType,
      closeModal,
      canvasUpdateLabel,
      t,
    ],
  );

  const options = useMemo(() => {
    return [
      { label: t('canvas.collectUserAnswerV2.type.number'), value: 1 },
      { label: t('canvas.collectUserAnswerV2.type.email'), value: 2 },
      { label: t('canvas.collectUserAnswerV2.type.custom'), value: 3 },
    ];
  }, [t]);

  const handleOptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOptionValue(e.target.value);
      setIsDrawerUpdate(true, () =>
        handleApply({
          updatedOptionValue: parseInt(e.target.value),
        }),
      );
    },
    [setIsDrawerUpdate, handleApply],
  );

  useEffect(() => {
    if (
      element &&
      element.elementType === ElementTypesEnum.COLLECT_USER_ANSWER_V2_ELEMENT &&
      drawerType === DrawerTypesEnum.COLLECT_USER_ANSWER_V2
    ) {
      setOptionValue(element.data?.replyType || 1);
      setIsDrawerUpdate(false);
    }
  }, [element, drawerType, setIsDrawerUpdate]);

  useEffect(() => {
    if (shouldApplyButtonDisabled) {
      setIsDrawerFullyUpdate(false);
    } else {
      setIsDrawerFullyUpdate(true);
    }
  }, [shouldApplyButtonDisabled, setIsDrawerFullyUpdate]);

  return (
    <EditorDrawer drawerType={DrawerTypesEnum.COLLECT_USER_ANSWER_V2}>
      <ContainerStyled>
        <Box>
          <Typography
            sx={{
              mb: '16px',
            }}
            variant="h6"
            color="grey.900"
          >
            {t('canvas.collectUserAnswerV2.drawer.title')}
          </Typography>
          <Typography
            sx={{
              mb: '8px',
            }}
            variant="subtitle2"
            color="grey.900"
          >
            {t('canvas.collectUserAnswerV2.drawer.type')}
          </Typography>
          <TextField
            select
            value={optionValue}
            fullWidth
            size="small"
            onChange={handleOptionChange}
          >
            {options.map((item) => (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </TextField>
        </Box>
        <Button
          onClick={() => handleApply()}
          size="large"
          variant="contained"
          fullWidth
          disabled={shouldApplyButtonDisabled}
          sx={{ marginTop: '24px' }}
        >
          {t('common.apply')}
        </Button>
      </ContainerStyled>
    </EditorDrawer>
  );
};

export default memo(DrawerCollectUserAnswerV2);
