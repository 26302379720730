import {
  GetBillingCouponCheckResponse,
  ResponseWithData,
  SocialTypeEnum,
} from '@frontend/sorghum/interface';

import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { BILLING_COUPON_CHECK } from '../apis';

interface Props {
  code: string;
  socialType: SocialTypeEnum;
}

async function getGetBillingCouponCheck({
  code,
  socialType,
}: Props): Promise<ResponseWithData<GetBillingCouponCheckResponse>> {
  return axios
    .get(`${BILLING_COUPON_CHECK}?code=${code}&socialType=${socialType}`)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function useGetBillingCouponCheck() {
  return useMutation({
    mutationKey: ['get-billing-coupon-check'],
    mutationFn: (props: Props) => getGetBillingCouponCheck(props),
  });
}

export default useGetBillingCouponCheck;
