import { IconSizeType } from '@frontend/components/interface';
import { Button, IconButton, Typography } from '@frontend/components/ui';
import {
  useCanvasCollect,
  useGetProjectID,
  usePatchEditorFlowEntry,
  useProjectPermission,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  AutocompleteOptionType,
  DrawerTypesEnum,
  EntryPointsEnum,
} from '@frontend/editor/interface';
import { REGEX_CHECK_SPECIAL_CHARACTERS } from '@frontend/editor/utils';
import { copyToClipboard } from '@frontend/sorghum/utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TextField, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Autocomplete from '../autocomplete/autocomplete';
import { EditorDrawer } from '../editor-drawer/editor-drawer';
import EditorLabel from '../editor-label/editor-label';

const TitleWrapperStyled = styled('div')(({ theme }) => ({
  marginBottom: '20px',
}));

const BotLinkTitleWrapperStyled = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const UrlWrapperStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.palette['grey'][500],
  marginBottom: '27px',
  marginTop: '8px',
  justifyContent: 'space-between',
}));

const InfoIconWrapperStyled = styled('div')(({ theme }) => ({
  color: theme.palette['grey'][500],
  '& > svg': {
    fontSize: '20px',
  },
}));

const IconWrapperStyled = styled('div')(({ theme }) => ({
  fontSize: '24px',
  marginLeft: '4px',
}));

const ContainerStyled = styled('div')(({ theme }) => ({
  whiteSpace: 'break-spaces',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '24px',
  width: '360px',
}));

const TopWrapperStyled = styled('div')(({ theme }) => ({}));

const ApplyButtonStyled = styled('div')(({ theme }) => ({
  width: '100%',
  bottom: '0',
}));

export const DrawerBotLink = () => {
  const { data: projectID } = useGetProjectID();
  const { id: flowID } = useParams();
  const [t] = useTranslation();
  const {
    onFocusCellID,
    setIsDrawerUpdate,
    setIsDrawerFullyUpdate,
    setOnFocusCellID,
  } = useContext(EditorCtx);
  const { setDrawerType, closeModal, drawerType } = useContext(UICtx);
  const [parameter, setParameter] = useState<string>('');
  const {
    sourceOptions,
    paidOptions,
    mediumOptions,
    getBotLink,
    checkDuplicateBotLinkRef,
  } = useCanvasCollect();
  const [error, setError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [title, setTitle] = useState('');
  const [medium, setMedium] = useState<number>();
  const [source, setSource] = useState<number | null>();

  const botLink = getBotLink(onFocusCellID);

  const { isViewer } = useProjectPermission();

  const { mutate: updateFlowEntryPoint } = usePatchEditorFlowEntry(
    flowID as string,
    1,
    projectID as string,
  );

  const handleApply = useCallback(
    ({
      updatedTitle,
      updatedParameter,
      updatedMedium,
      updatedSource,
    }: {
      updatedTitle?: string;
      updatedParameter?: string;
      updatedMedium?: number;
      updatedSource?: number | null;
    } = {}) => {
      const usedTitle = updatedTitle ?? title;
      const usedParameter = updatedParameter ?? parameter;
      const usedMedium = updatedMedium ?? medium;
      const usedSource = updatedSource;

      if (botLink) {
        updateFlowEntryPoint(
          {
            title: usedTitle,
            id: botLink.id,
            type: EntryPointsEnum.BOT_LINK,
            ref: usedParameter,
            enable: botLink.enable,
            ...(usedMedium && { medium: usedMedium }),
            ...(usedSource && { source: usedSource }),
          },
          {
            onSuccess: (data) => {
              if (data.code === 20000) {
                setIsDrawerUpdate(false);
                setIsDrawerFullyUpdate(true);
                setOnFocusCellID('');
                setDrawerType(DrawerTypesEnum.CLOSE);
                closeModal();
                // 與其他的 flow 的 bot-link ref 重複
              } else if (data.code === 40000) {
                closeModal();
                setError(true);
                setMessage(
                  t('canvas.entryPoint.addMenu.botLink.parameterDuplicate'),
                );
              }
            },
          },
        );
      }
    },
    [
      botLink,
      medium,
      parameter,
      t,
      title,
      updateFlowEntryPoint,
      setIsDrawerUpdate,
      setIsDrawerFullyUpdate,
      setOnFocusCellID,
      setDrawerType,
      closeModal,
    ],
  );

  const handleTitleChange = useCallback(
    (val: string) => {
      setTitle(val);
      setIsDrawerUpdate(true, () => handleApply({ updatedTitle: val }));
    },
    [setIsDrawerUpdate, handleApply],
  );

  const handleParameterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setParameter(e.target.value);
      setIsDrawerUpdate(true, () =>
        handleApply({ updatedParameter: e.target.value }),
      );
    },
    [setIsDrawerUpdate, handleApply],
  );

  const handleMediumChange = useCallback(
    (option: AutocompleteOptionType | null) => {
      setMedium(option?.value as number);
      setSource(null);

      setIsDrawerUpdate(true, () =>
        handleApply({
          updatedMedium: option?.value as number,
          updatedSource: null,
        }),
      );
    },
    [setIsDrawerUpdate, handleApply],
  );

  const handleSourceChange = useCallback(
    (option: AutocompleteOptionType | null) => {
      setSource(option?.value as number);
      setIsDrawerUpdate(true, () =>
        handleApply({ updatedSource: option?.value as number }),
      );
    },
    [setIsDrawerUpdate, handleApply],
  );

  const url = useMemo(() => {
    if (botLink) {
      const temp = botLink?.url.split('?ref=');
      if (temp && temp?.length > 0) {
        return `${temp[0]}?ref=${parameter}`;
      } else {
        return botLink?.url;
      }
    } else {
      return '';
    }
  }, [botLink, parameter]);

  useEffect(() => {
    setMessage(t('canvas.entryPoint.addMenu.botLink.parameterInfo'));
    setError(false);
  }, [parameter, t]);

  useEffect(() => {
    if (botLink) {
      setParameter(botLink.ref);
      setTitle(botLink.title);
      setMedium(botLink.medium);

      if (botLink.source) {
        setSource(botLink.source);
      } else {
        setSource(null);
      }
      setIsDrawerUpdate(false);
    }
  }, [botLink, setIsDrawerUpdate]);

  useEffect(() => {
    if (botLink) {
      if (!parameter) {
        setError(true);
        setMessage(t('canvas.entryPoint.addMenu.botLink.parameterRequired'));
        return;
      }

      const checkSpecialCharacterRegex = new RegExp(
        REGEX_CHECK_SPECIAL_CHARACTERS,
      );

      if (!parameter.match(checkSpecialCharacterRegex)) {
        setError(true);
        setMessage(t('canvas.entryPoint.addMenu.botLink.parameterRequired'));
        return;
      }

      if (isViewer) {
        setDrawerType(DrawerTypesEnum.CLOSE);
        return;
      }
    }
  }, [
    botLink,
    isViewer,
    medium,
    parameter,
    source,
    t,
    title,
    setDrawerType,
    checkDuplicateBotLinkRef,
  ]);

  const isOpen = useMemo(() => {
    return botLink && drawerType === DrawerTypesEnum.BOT_LINK;
  }, [botLink, drawerType]);

  const shouldApplyButtonDisabled = useMemo(() => {
    let shouldDisabled = false;

    if (!parameter || !title) {
      shouldDisabled = true;
    }

    if (parameter && botLink) {
      //檢查 flow 內的 bot-link ref 是否有重複
      const duplicate = checkDuplicateBotLinkRef(parameter);

      // 如果有改名而且是重複的
      if (botLink.ref !== parameter && duplicate) {
        shouldDisabled = true;
      }
    }

    return shouldDisabled;
  }, [parameter, title, botLink, checkDuplicateBotLinkRef]);

  useEffect(() => {
    if (botLink && parameter) {
      //檢查 flow 內的 bot-link ref 是否有重複
      const duplicate = checkDuplicateBotLinkRef(parameter);

      // 如果有改名而且是重複的
      if (botLink.ref !== parameter && duplicate) {
        setError(true);
        setMessage(t('canvas.entryPoint.addMenu.botLink.parameterDuplicate'));
      }
    }
  }, [botLink, parameter, checkDuplicateBotLinkRef, t]);

  useEffect(() => {
    if (shouldApplyButtonDisabled) {
      setIsDrawerFullyUpdate(false);
    } else {
      setIsDrawerFullyUpdate(true);
    }
  }, [shouldApplyButtonDisabled, setIsDrawerFullyUpdate]);

  if (isOpen) {
    return (
      <EditorDrawer drawerType={DrawerTypesEnum.BOT_LINK}>
        <ContainerStyled>
          <TopWrapperStyled>
            <Tooltip title={title} placement="bottom-end">
              <TitleWrapperStyled>
                <EditorLabel
                  size="h6"
                  defaultValue={title}
                  onBlur={handleTitleChange}
                  error={!title ? 'empty' : ''}
                />
              </TitleWrapperStyled>
            </Tooltip>
            <BotLinkTitleWrapperStyled>
              <Typography variant="subtitle2" color="grey.black">
                {t('canvas.entryPoint.addMenu.botLink.info')}
              </Typography>
            </BotLinkTitleWrapperStyled>
            <UrlWrapperStyled>
              <Typography variant="body2">{url}</Typography>
              <IconWrapperStyled
                onClick={() => copyToClipboard(url)}
                sx={{
                  marginLeft: '4px',
                }}
              >
                <IconButton $size={IconSizeType.S}>
                  <ContentCopyIcon
                    sx={{
                      fontSize: '14px',
                    }}
                  />
                </IconButton>
              </IconWrapperStyled>
            </UrlWrapperStyled>
            <TextField
              sx={{
                marginBottom: '40px',
              }}
              onChange={handleParameterChange}
              size="small"
              label={t('canvas.entryPoint.addMenu.botLink.parameter')}
              fullWidth
              value={parameter}
              placeholder={t('canvas.entryPoint.addMenu.botLink.parameter')}
              error={error}
              helperText={message}
            />
            <BotLinkTitleWrapperStyled>
              <Typography variant="subtitle2" color="grey.black">
                {t('canvas.entryPoint.addMenu.botLink.advanceInfo')}
              </Typography>
              <Tooltip
                placement="top"
                title={t('canvas.entryPoint.addMenu.botLink.advanceTooltip')}
              >
                <InfoIconWrapperStyled>
                  <InfoOutlinedIcon />
                </InfoIconWrapperStyled>
              </Tooltip>
            </BotLinkTitleWrapperStyled>
            <Autocomplete
              label={t('canvas.entryPoint.addMenu.botLink.medium')}
              value={medium}
              options={mediumOptions}
              onChange={handleMediumChange}
            />

            <Autocomplete
              label={t('canvas.entryPoint.addMenu.botLink.source')}
              value={source}
              options={medium === 3 ? paidOptions : sourceOptions}
              onChange={handleSourceChange}
            />
          </TopWrapperStyled>
          <ApplyButtonStyled>
            <Button
              onClick={() => handleApply()}
              size="large"
              variant="contained"
              fullWidth
              disabled={shouldApplyButtonDisabled}
              sx={{ marginTop: '24px' }}
            >
              {t('common.save')}
            </Button>
          </ApplyButtonStyled>
        </ContainerStyled>
      </EditorDrawer>
    );
  } else {
    return null;
  }
};

export default memo(DrawerBotLink);
