import { Box, styled } from '@mui/material';

export const HomePageContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100vh',

  [theme.breakpoints.down('md')]: {
    minHeight: '-webkit-fill-available',
    height: '-webkit-fill-available',
  },
}));

export const LeftSideContainerStyled = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.grey[100],

  '&.signUpSignin': {
    padding: '30px 40px',
  },
}));

export const LeftSideLogoContainerStyled = styled(Box)(({ theme }) => ({
  position: 'fixed',
  left: '40px',
  top: '40px',

  '&.signUpSignin': {
    top: '70px',
  },
}));

export const LeftSideTrustBrandContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '75px',
}));

export const LeftSidePictureContainerStyled = styled(Box)(({ theme }) => ({
  width: '60%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  '&.signUpSignin': {
    width: '100%',
  },
}));

export const LeftSidePictureStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '24px',

  '&.signUpSignin': {
    marginBottom: 0,
  },
}));

export const SmallLogoContainerStyled = styled(Box)(({ theme }) => ({
  alignSelf: 'center',
  padding: '12px 0',

  '&.signUpSignin': {
    padding: 0,
  },
}));
