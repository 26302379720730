import { Dialog } from '@frontend/components/ui';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import { useFacebook } from '@frontend/sorghum/utils';
import { Box, Typography } from '@mui/material';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

export interface FacebookErrorModalProps {
  facebookAppID: string;
  mode: string;
}

export const FacebookErrorModal: FC<FacebookErrorModalProps> = ({
  facebookAppID,
  mode,
}) => {
  const [t] = useTranslation();
  const { requestPermission } = useFacebook({
    appId: facebookAppID,
    mode,
  });
  const { modalType, closeModal } = useContext(UICtx);

  const handleClose = () => {
    requestPermission();
    closeModal();
  };

  return (
    <Dialog
      size="xs"
      title={t('facebook.error.title')}
      open={modalType === ModalTypesEnum.FACEBOOK_ERROR}
      handleClose={handleClose}
      closeBtn={false}
      confirmBtnText={t('common.ok')}
      handleConfirm={handleClose}
    >
      <Box>
        <Typography color="grey.800" variant="body1">
          {t('facebook.error.info')}
        </Typography>
      </Box>
    </Dialog>
  );
};

export default FacebookErrorModal;
