import { Dialog, TagOption, TagSelector } from '@frontend/components/ui';
import { toHalfWidth } from '@frontend/components/utils';
import { UICtx } from '@frontend/editor/external-providers';
import { ModalTypesEnum } from '@frontend/editor/interface';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

interface CommentAutoReplyKeywordModalProps {
  replyKeywords: string[];
  handleSubmit: (newValue: string[]) => void;
}

const ModalContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

export const ThreadsCommentReplyKeywordModal: FC<
  CommentAutoReplyKeywordModalProps
> = ({ replyKeywords, handleSubmit }) => {
  const [t] = useTranslation('threads');

  const [typedKeywords, setTypedKeywords] = useState<TagOption[]>([]);

  const { closeModal, modalType } = useContext(UICtx);

  const handleTagSelectorChange = useCallback((tags: TagOption[]) => {
    setTypedKeywords(tags);
  }, []);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = toHalfWidth(e.target.value).toLowerCase();
  }, []);

  const handleConfirm = useCallback(() => {
    const keywordsList = typedKeywords.map((item) => item.name);
    handleSubmit(keywordsList);
    closeModal();
  }, [closeModal, typedKeywords, handleSubmit]);

  useEffect(() => {
    if (replyKeywords.length) {
      setTypedKeywords(
        replyKeywords.map((word) => ({
          id: word,
          name: word,
        })),
      );
    }
  }, [replyKeywords]);

  return (
    <Dialog
      size="s"
      title={t('commentAutoReply.drawer.replyConditions.modal.title')}
      confirmBtnText={t('common.done')}
      cancelBtnText={t('common.cancel')}
      open={modalType === ModalTypesEnum.COMMENT_REPLY_KEYWORD}
      handleClose={closeModal}
      handleConfirm={handleConfirm}
      disableConfirmButton={!typedKeywords.length}
    >
      <ModalContainerStyled>
        <Typography variant="body2" color="grey.900">
          {t('commentAutoReply.drawer.replyConditions.modal.subtitle')}
        </Typography>
        <TagSelector
          enableSearch={false}
          required
          options={[]}
          value={typedKeywords}
          maxLength={100}
          allowNew
          setValue={(tags) => handleTagSelectorChange(tags)}
          placeholder={t(
            'commentAutoReply.drawer.replyConditions.modal.addKeyword',
          )}
          helperText={
            !typedKeywords.length
              ? t('commentAutoReply.drawer.replyConditions.modal.keywordEmpty')
              : ''
          }
          onInputChange={handleInputChange}
          error={!typedKeywords.length}
          minHeight={60}
        />
      </ModalContainerStyled>
    </Dialog>
  );
};

export default ThreadsCommentReplyKeywordModal;
