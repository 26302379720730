import { useAuth } from '@frontend/sorghum/data-access';
import { useThreads as useThreadsPeople } from '@frontend/sorghum/features/people';
import {
  COMMENT_AUTO_REPLY,
  DASHBOARD,
  PAYMENT_DETAILS,
  PEOPLE,
  SETTING,
  THREADS,
  withPageBoundary,
} from '@frontend/sorghum/utils';
import { Navigate, Route } from 'react-router-dom';

import { useThreadsSetting } from '@frontend/sorghum/features/setting';
import { SocialTypeEnum } from '@frontend/sorghum/interface';
import { lazy } from 'react';
import { useRoutes } from './useRoutes';

const Dashboard = lazy(() =>
  import('@frontend/sorghum/features/dashboard').then(
    ({ SorghumFeaturesDashboard: Page }) => ({
      default: Page,
    }),
  ),
);

const CommentReplyList = lazy(() =>
  import('@frontend/sorghum/features/threads-comment-reply-list').then(
    ({ SorghumFeaturesThreadsCommentReplyList: Page }) => ({
      default: Page,
    }),
  ),
);

const Setting = lazy(() =>
  import('@frontend/sorghum/features/setting').then(
    ({ SorghumFeaturesSetting: Page }) => ({
      default: Page,
    }),
  ),
);

const PaymentDetail = lazy(() =>
  import('@frontend/sorghum/features/payment-details').then(
    ({ SorghumFeaturesPaymentDetails: Page }) => ({ default: Page }),
  ),
);

const CommentReplyDetail = lazy(() =>
  import('@frontend/sorghum/features/threads-comment-reply-detail').then(
    ({ SorghumFeaturesThreadsCommentReplyDetail: Page }) => ({
      default: Page,
    }),
  ),
);

const People = lazy(() =>
  import('@frontend/sorghum/features/people').then(
    ({ SorghumFeaturesPeople: Page }) => ({
      default: Page,
    }),
  ),
);

const DataDashboard = lazy(() =>
  import('@frontend/sorghum/features/threads-data-dashboard').then(
    ({ SorghumFeaturesThreadsDataDashboard: Page }) => ({
      default: Page,
    }),
  ),
);

export const ThreadsRoutes = () => {
  const {
    facebookAppID,
    mode,
    permission,
    googleStorageEndpoint,
    tapPayAppID,
    threadsAppID,
  } = useRoutes();
  return (
    <Route
      path={THREADS}
      element={withPageBoundary(
        <Dashboard
          facebookAppID={facebookAppID}
          mode={mode}
          googleStorageEndpoint={googleStorageEndpoint}
          tapPayAppID={tapPayAppID}
          useAuth={useAuth}
          socialType={SocialTypeEnum.THREADS}
        />,
      )}
    >
      <Route path=":projectID">
        <Route index element={<Navigate to={COMMENT_AUTO_REPLY} replace />} />
        <Route path={COMMENT_AUTO_REPLY}>
          <Route index element={<CommentReplyList />} />
          <Route path=":commentAutoReplyID" element={<CommentReplyDetail />} />
        </Route>
        <Route path={DASHBOARD} element={<DataDashboard />} />
        <Route
          path={PEOPLE}
          element={
            <People
              googleStorageEndpoint={googleStorageEndpoint}
              usePages={useThreadsPeople}
            />
          }
        />
        <Route
          path={SETTING}
          element={
            <Setting
              appID={threadsAppID}
              permission={permission}
              mode={mode}
              usePage={useThreadsSetting}
            />
          }
        />
        {/* setting - payment */}
        <Route
          path={`${SETTING}/${PAYMENT_DETAILS}`}
          element={<PaymentDetail />}
        />
      </Route>
    </Route>
  );
};
