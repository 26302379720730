import { useCanvasFlow } from '@frontend/editor/data-access';
import { EditorCtx } from '@frontend/editor/external-providers';
import { Box } from '@mui/material';
import { BoxProps, styled } from '@mui/system';
import { useContext } from 'react';

const FlowWrapperStyled = styled(Box)<{
  $zoom: number;
  $xPosition: number;
  $yPosition: number;
  $isConnecting: boolean;
}>(({ theme, $zoom, $xPosition, $yPosition, $isConnecting }) => ({
  width: '100%',
  position: 'relative',
  flex: 1,
  userSelect: 'none',
  '& .react-flow__node': {
    outline: 'none',
    '& .selected': {
      zIndex: 20,
    },
  },
  '& .react-flow__pane': {
    // 避免新增 node 時超出畫面導致跑版
    overflow: 'hidden',
    ...($isConnecting && {
      cursor: 'grabbing',
    }),
    '.react-flow__nodesselection-rect': {
      border: `2px solid ${theme.palette['primary']['main']}40`,
      borderRadius: '4px',
    },
  },
  '& .react-flow__pane.selection': {
    cursor: 'default',
  },
  '& .react-flow__edge': {
    '&.selected': {
      '& .react-flow__edge-path': {
        stroke: theme.palette['blue']['600'],
      },
    },
  },
  background: theme.palette['grey']['200'],

  '.react-flow__node-CREATE_MENU': {
    transform: `translate(${$xPosition}px, ${$yPosition}px) scale(${
      1 / $zoom
    }) !important`,
  },

  fontSize: `${28 / $zoom}px`,
}));

export function EditorCanvasContainer({ children, ...props }: BoxProps) {
  const { ref } = useContext(EditorCtx);
  const { zoom, isConnecting, menuPosition } = useCanvasFlow();
  // 讓 sortable item 在拖曳的時候不會被 zoom 影響大小
  // Gallery 水平拖曳時要 resize 子層元素的寬度
  // 因為 react-sortable-hoc 會操作 transform 所以不能直接操作同一層元素

  const horizontalDraggedStyle = `
  .node_horizontal_item_dragged_style {
    z-index: 20;
  }
  .node_horizontal_item_dragged_style > div {
    transform: translate(${zoom * 50 - 50}%, ${
    zoom * 50 - 50
  }%) scale(${zoom}) !important;
}`;

  const draggedCellStyle = `
  .cell_dragged_style {
    z-index: 20;
    fontSize: ${28 / zoom}px;
    }

    .cell_dragged_style > div  {
     transform: translate(${zoom * 50 - 50}%, ${
    zoom * 50 - 50
  }%) scale(${zoom}) !important;
  }

  .cell_dragged_style #float-panel {
    left: calc(-${zoom}em - 0.1em) !important;
    }
  `;

  const draggedStyle = `
  .node_item_dragged_style {
    z-index: 20;
    }
    .node_item_dragged_style > span {
      transform: scale(${zoom}) !important;
      left: calc(-15 * ${zoom}px - 20px) !important;
      }

    .node_item_dragged_style > div  {
      transform: translate(${zoom * 50 - 50}%, ${
    zoom * 50 - 50
  }%) scale(${zoom}) !important;
}`;
  return (
    <FlowWrapperStyled
      ref={ref}
      $zoom={zoom}
      $xPosition={menuPosition.x}
      $yPosition={menuPosition.y}
      $isConnecting={isConnecting}
      {...props}
    >
      <style>{draggedStyle}</style>
      <style>{horizontalDraggedStyle}</style>
      <style>{draggedCellStyle}</style>
      {children}
    </FlowWrapperStyled>
  );
}

export default EditorCanvasContainer;
