import { TrendingStatusEnum } from '@frontend/components/interface';
import { TrendingNumber, Typography } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { usePostDataDashboardSourceCommentReply } from '@frontend/sorghum/data-access';
import {
  PostDataDashboardProps,
  PostDataDashboardSourceCommentReplyResponse,
} from '@frontend/sorghum/interface';
import { PAGE_COMMENT_AUTO_REPLY, usePath } from '@frontend/sorghum/utils';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { isNumber } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type DataDashboardSourceCommentAutoReplyTableProps = PostDataDashboardProps;

const InfoIconStyled = styled(InfoIcon)({
  margin: '0 0 -4px 4px',
});

const TableContainerStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette['grey']['white'],
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: '20px',
  padding: '24px',
  boxShadow: theme.shadows[2],
  borderRadius: '8px',
  width: '100%',
  height: '100%',
  minHeight: '380px',
}));

const TitleStyled = styled(Box)(({ theme }) => ({
  height: '32px',
  display: 'flex',
  alignItems: 'center',
}));

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette['grey'][100],
  },
}));

export const DataDashboardSourceCommentAutoReplyTable: FC<
  DataDashboardSourceCommentAutoReplyTableProps
> = ({
  startDate,
  endDate,
  condition,
}: DataDashboardSourceCommentAutoReplyTableProps) => {
  const [t] = useTranslation();
  const { navigateToProjectPage } = usePath();
  const { data: projectID } = useGetProjectID();
  const [data, setData] = useState<
    PostDataDashboardSourceCommentReplyResponse[]
  >([]);

  const { mutate: queryData } = usePostDataDashboardSourceCommentReply();

  const handleCommentAutoReplyClick = useCallback(
    (commentAutoReplyId: string) => {
      navigateToProjectPage({
        path: `${PAGE_COMMENT_AUTO_REPLY}}/${commentAutoReplyId}`,
      });
    },
    [navigateToProjectPage],
  );

  useEffect(() => {
    if (startDate && endDate && projectID) {
      queryData(
        {
          startDate,
          endDate,
        },
        {
          onSuccess: (data: PostDataDashboardSourceCommentReplyResponse[]) => {
            setData(data);
          },
        },
      );
    }
  }, [condition, endDate, projectID, queryData, startDate, t]);

  return (
    <TableContainerStyled>
      <TitleStyled>
        <Typography variant="subtitle2" color="grey.800">
          {t('dataDashboard.sourceAnalysis.commentReplies.list')}
        </Typography>
      </TitleStyled>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="left">
              <Typography variant="body3" color="grey.500">
                {t('dataDashboard.sourceAnalysis.commentReplies.name')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body3" color="grey.500">
                {t('dataDashboard.sourceAnalysis.chancels.users')}
                <Tooltip
                  placement="top"
                  title={t(
                    'dataDashboard.source.table.column.newUsers.tooltip',
                  )}
                >
                  <InfoIconStyled fontSize="small" />
                </Tooltip>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body3" color="grey.500">
                {t('dataDashboard.sourceAnalysis.chancels.period')}
                <Tooltip
                  placement="top"
                  title={t(
                    'dataDashboard.source.table.column.lastPeriod.tooltip',
                  )}
                >
                  <InfoIconStyled fontSize="small" />
                </Tooltip>
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((i, index) => {
            let numberStatus = TrendingStatusEnum.NULL;
            if (isNumber(i.uniqEnterUsersTrend)) {
              if (i.lastUniqEnterUsers > 0) {
                numberStatus = TrendingStatusEnum.UP;
              } else if (i.lastUniqEnterUsers < 0) {
                numberStatus = TrendingStatusEnum.DOWN;
              } else {
                numberStatus = TrendingStatusEnum.TIE;
              }
            }
            return (
              <TableRowStyled
                key={index}
                onClick={() => handleCommentAutoReplyClick(i.commentReplyId)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>
                  <Typography variant="body3" color="grey.500">
                    {index + 1}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant="body3" color="info.main">
                    <Tooltip title={i.commentReplyName} placement="top-start">
                      <Box
                        sx={{
                          maxWidth: '160px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {i.commentReplyName}
                      </Box>
                    </Tooltip>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body3" color="grey.700">
                    {i.uniqEnterUsers}
                  </Typography>
                </TableCell>
                <TableCell>
                  <TrendingNumber
                    status={numberStatus}
                    percentage={i.uniqEnterUsersTrend}
                    total={i.lastUniqEnterUsers}
                  />
                </TableCell>
              </TableRowStyled>
            );
          })}
        </TableBody>
      </Table>
    </TableContainerStyled>
  );
};

export default DataDashboardSourceCommentAutoReplyTable;
