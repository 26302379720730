import CloseIcon from '@mui/icons-material/Close';
import { Breakpoint, Dialog as MuiDialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';
import styled from 'styled-components';
import Button from '../button/button';

/* eslint-disable-next-line */
export interface DialogProps {
  size: Breakpoint;
  color?: 'error' | 'primary';
  title?: string;
  content?: string;
  cancelBtnText?: string;
  confirmBtnText?: string;
  open: boolean;
  children?: React.ReactNode;
  autoClose?: boolean;
  isNegative?: boolean;
  closeBtn?: boolean;
  handleClose: (event?: object, reason?: string) => void;
  handleConfirm?: () => void;
  titleIcon?: (props: SvgIconProps) => JSX.Element;
  uppercase?: boolean;
  id?: string;
  cancelButtonID?: string;
  confirmButtonID?: string;
  disableEscapeKeyDown?: boolean;
  disableConfirmButton?: boolean;
}

interface NegativePrimaryButtonProps {
  cancelBtnText?: string;
  confirmBtnText?: string;
  color?: 'error' | 'primary';
  cancelOnClick: () => void;
  confirmOnClick?: () => void;
  uppercase?: boolean;
  disabled?: boolean;
  cancelButtonID?: string;
  confirmButtonID?: string;
}

export interface DialogTitleProps {
  children?: React.ReactNode;
  onClose?: () => void;
}

const DialogStyled = styled(MuiDialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(3),
    paddingRight: '30px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
  },

  whiteSpace: 'pre-wrap',
}));

const NegativePrimaryButton = ({
  cancelBtnText,
  confirmBtnText,
  color,
  cancelOnClick,
  confirmOnClick,
  uppercase = false,
  disabled = false,
  cancelButtonID,
  confirmButtonID,
}: NegativePrimaryButtonProps) => {
  return (
    <>
      {confirmBtnText && confirmOnClick && (
        <Button
          id={confirmButtonID}
          color={color}
          onClick={confirmOnClick}
          sx={{ ...(uppercase && { textTransform: 'uppercase' }) }}
          disabled={disabled}
        >
          {confirmBtnText}
        </Button>
      )}
      {cancelBtnText && (
        <Button
          id={cancelButtonID}
          variant="contained"
          color={color}
          onClick={cancelOnClick}
          sx={{ ...(uppercase && { textTransform: 'uppercase' }) }}
        >
          {cancelBtnText}
        </Button>
      )}
    </>
  );
};

const DialogTitleWrapper = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, wordBreak: 'break-all' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

//note: 如果要使用 size = "sm" 需傳入 size="s" 才會正常，原因還不知道 lol
export const Dialog: FC<DialogProps> = ({
  size,
  color,
  title,
  content,
  open,
  cancelBtnText,
  confirmBtnText,
  children,
  autoClose = true,
  handleClose,
  handleConfirm,
  // 是否顯示右上角的 close icon
  closeBtn = true,
  // 刪除的 modal 情境為 Negative primary action
  isNegative = false,
  // 按鈕是否轉換成大寫
  uppercase = false,
  id = '',
  disableEscapeKeyDown,
  cancelButtonID = '',
  confirmButtonID = '',
  disableConfirmButton = false,
}: DialogProps) => {
  return (
    <DialogStyled
      maxWidth={size}
      fullWidth
      open={open}
      {...(autoClose && { onClose: handleClose })}
      PaperProps={{ id }}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <DialogTitleWrapper {...(closeBtn && { onClose: handleClose })}>
        {title}
      </DialogTitleWrapper>
      <DialogContent>
        <DialogContentText>
          <span style={{ wordBreak: 'break-word' }}>{content}</span>
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        {isNegative ? (
          <NegativePrimaryButton
            cancelBtnText={cancelBtnText}
            color={color}
            cancelOnClick={handleClose}
            confirmBtnText={confirmBtnText}
            confirmOnClick={handleConfirm}
            uppercase={uppercase}
            disabled={disableConfirmButton}
            cancelButtonID={cancelButtonID}
            confirmButtonID={confirmButtonID}
          />
        ) : (
          <>
            {cancelBtnText && (
              <Button
                id={cancelButtonID}
                sx={{
                  ...(uppercase
                    ? { textTransform: 'uppercase' }
                    : { textTransform: 'capitalize' }),
                }}
                color={color}
                onClick={handleClose}
                size="medium"
                variant="text"
              >
                {cancelBtnText}
              </Button>
            )}
            {confirmBtnText && (
              <Button
                id={confirmButtonID}
                sx={{
                  ...(uppercase
                    ? { textTransform: 'uppercase' }
                    : { textTransform: 'capitalize' }),
                }}
                color={color}
                variant="contained"
                onClick={handleConfirm}
                size="medium"
                disabled={disableConfirmButton}
              >
                {confirmBtnText}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </DialogStyled>
  );
};

export default Dialog;
