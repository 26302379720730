import {
  Dialog,
  Select,
  SelectItem,
  TagOption,
  TagSelector,
} from '@frontend/components/ui';
import { toHalfWidth } from '@frontend/components/utils';
import { UICtx } from '@frontend/editor/external-providers';
import { ModalTypesEnum } from '@frontend/editor/interface';
import { CommentReplyPostType } from '@frontend/sorghum/interface';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  replyKeywords: string[];
  markFriendCount: number;
  keywordCompareType: 1 | 2 | 3 | 4;
}

interface CommentAutoReplyKeywordModalProps extends Props {
  postType: CommentReplyPostType;
  handleSubmit: (newValue: Props) => void;
}

const ModalContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

export const InstagramCommentReplyKeywordModal: FC<
  CommentAutoReplyKeywordModalProps
> = ({
  postType,
  markFriendCount,
  keywordCompareType,
  replyKeywords,
  handleSubmit,
}) => {
  const [t] = useTranslation('instagram');
  const [containType, setContainType] = useState('0');
  const [typedKeywords, setTypedKeywords] = useState<TagOption[]>([]);

  const { closeModal, modalType } = useContext(UICtx);

  const handleTagSelectorChange = useCallback((tags: TagOption[]) => {
    setTypedKeywords(tags);
  }, []);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = toHalfWidth(e.target.value).toLowerCase();
  }, []);

  const handleConfirm = useCallback(() => {
    const keywordsList = typedKeywords.map((item) => item.name);
    const keywordCompareType = containType === '0' ? 2 : 1;
    handleSubmit({
      keywordCompareType,
      replyKeywords: keywordsList,
      markFriendCount: Number(containType),
    });
    closeModal();
  }, [typedKeywords, containType, handleSubmit, closeModal]);

  const handleSelectChange = useCallback((val: string) => {
    setContainType(val);
  }, []);

  useEffect(() => {
    switch (keywordCompareType) {
      case 1: {
        setContainType(markFriendCount.toString());
        break;
      }
      case 2: {
        setContainType('0');
        break;
      }
    }
    if (replyKeywords.length) {
      setTypedKeywords(
        replyKeywords.map((word) => ({
          id: word,
          name: word,
        })),
      );
    }
  }, [keywordCompareType, markFriendCount, replyKeywords]);

  return (
    <Dialog
      size="s"
      title={t('commentAutoReply.drawer.replyConditions.modal.title')}
      confirmBtnText={t('common.done')}
      cancelBtnText={t('common.cancel')}
      open={modalType === ModalTypesEnum.COMMENT_REPLY_KEYWORD}
      handleClose={closeModal}
      handleConfirm={handleConfirm}
      disableConfirmButton={containType === '0' && !typedKeywords.length}
    >
      <ModalContainerStyled>
        {postType === CommentReplyPostType.POST && (
          <>
            <Typography variant="body2" color="grey.900">
              {t('commentAutoReply.drawer.replyConditions.modal.subtitle1')}
            </Typography>
            <Select
              size="small"
              value={containType}
              onChange={(e) => handleSelectChange(e.target.value)}
            >
              <SelectItem value="0">
                {t('commentAutoReply.content.thirdStep.option.any')}
              </SelectItem>
              <SelectItem value="1">
                {t('commentAutoReply.content.thirdStep.option.moreThanOne')}
              </SelectItem>
              <SelectItem value="2">
                {t('commentAutoReply.content.thirdStep.option.moreThanTwo')}
              </SelectItem>
            </Select>
          </>
        )}
        {containType === '0' && (
          <>
            <Typography
              variant="body2"
              color="grey.900"
              sx={{
                mt: '10px',
              }}
            >
              {t('commentAutoReply.drawer.replyConditions.modal.subtitle2')}
            </Typography>
            <TagSelector
              enableSearch={false}
              required
              options={[]}
              value={typedKeywords}
              maxLength={100}
              allowNew
              setValue={(tags) => handleTagSelectorChange(tags)}
              placeholder={t(
                'commentAutoReply.drawer.replyConditions.modal.addKeyword',
              )}
              helperText={
                !typedKeywords.length
                  ? t(
                      'commentAutoReply.drawer.replyConditions.modal.keywordEmpty',
                    )
                  : t(
                      'commentAutoReply.drawer.replyConditions.modal.keywordHelperText',
                    )
              }
              onInputChange={handleInputChange}
              error={!typedKeywords.length}
              minHeight={60}
            />
          </>
        )}
      </ModalContainerStyled>
    </Dialog>
  );
};

export default InstagramCommentReplyKeywordModal;
