import { FlowType } from '@frontend/sorghum/interface';
import { PAGE_FLOW, usePath } from '@frontend/sorghum/utils';
import { Box, Link } from '@mui/material';
import { styled } from '@mui/system';
import { FC, useCallback } from 'react';

interface PersistentMenuItemFlowStatusProps {
  flow: FlowType;
}

interface StatusIconType {
  $status: number;
}

const FlowStatusWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const StatusIcon = styled(Box)<StatusIconType>(({ theme, $status }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '4px',
  backgroundColor: `${
    $status !== 2
      ? theme.palette['warning']['main']
      : theme.palette['success']['main']
  }`,
  marginRight: '8px',
}));

export const PersistentMenuItemFlowStatus: FC<
  PersistentMenuItemFlowStatusProps
> = ({ flow }: PersistentMenuItemFlowStatusProps) => {
  const { navigateToProjectPage } = usePath();

  const handleClick = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLAnchorElement>,
    ) => {
      e.stopPropagation();
      if (flow.id) {
        navigateToProjectPage({ path: `${PAGE_FLOW}/${flow.id}` });
      }
    },
    [flow.id, navigateToProjectPage],
  );

  return (
    <FlowStatusWrapper onClick={handleClick}>
      <StatusIcon $status={flow?.publishStatus ? flow.publishStatus : 0} />
      <Link onClick={handleClick}>{flow?.name}</Link>
    </FlowStatusWrapper>
  );
};
