import i18n from 'i18next';
import Detector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// TODO: 為了翻譯開的小後門，只會在測試機有用
const searchParams = new URLSearchParams(window.location.search);
const paramValue = searchParams.get('lng');
let language = 'en_us';

if (paramValue && process.env['NX_ENV'] !== 'PROD') {
  language = paramValue;
} else {
  // 取得瀏覽器語系為 i18n 預設語系
  if (window.navigator.language.includes('zh')) {
    language = 'zh_tw';
  } else if (window.navigator.language.includes('en')) {
    language = 'en_us';
  }
}

i18n
  .use(Backend)
  .use(Detector)
  .use(initReactI18next)
  .init({
    fallbackLng: language,
    lng: language,
    interpolation: {
      escapeValue: false,
    },
    cleanCode: true,
    react: {
      useSuspense: true,
    },
    ns: ['messenger', 'instagram', 'threads'],
    defaultNS: 'messenger',
    fallbackNS: ['messenger'],
    backend: {
      loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
    },
  });
