import { globalTheme } from '@frontend/components/external-providers';
import { Typography } from '@frontend/components/ui';
import {
  useCanvasGet,
  useCanvasUpdate,
  useProjectPermission,
} from '@frontend/editor/data-access';
import {
  useGetCommentReply,
  useGetCommentReplyConditionV2,
  usePatchCommentReplyConditionV2,
} from '@frontend/sorghum/data-access';
import { SocialTypeEnum } from '@frontend/sorghum/interface';
import { sendGAEvent, useCustomizedTranslation } from '@frontend/sorghum/utils';
import MessageIcon from '@mui/icons-material/Message';
import { Box, styled } from '@mui/material';
import { get } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { NodeProps, Position, useViewport } from 'reactflow';
import EditorBlockBorder from '../editor-block-border/editor-block-border';
import EditorBlockContainer from '../editor-block-container/editor-block-container';
import EditorTextButton from '../editor-text-button/editor-text-button';
import { HandlePoint } from '../handle-point/handle-point';
import NodeHeader from '../node-header/node-header';
import { useNodeInstagramCommentReplyBlock } from '../node-instagram-comment-reply-block/use-node-instagram-comment-reply-block';

const BodyWrapperStyled = styled(Box)(({ theme }) => ({
  width: '316px',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 12px 12px 12px',
  gap: '16px',
}));

const InfoWrapperStyled = styled(Box)(({ theme }) => ({
  padding: '12px',
  borderRadius: '8px',
  background: theme.palette['info']['light'],
}));

export const NodeInstagramPrivateReply = ({ id, selected }: NodeProps) => {
  const { commentAutoReplyID: paramCommentAutoReplyID } = useParams();
  const commentAutoReplyID = paramCommentAutoReplyID || '';
  const { t } = useCustomizedTranslation(SocialTypeEnum.INSTAGRAM);
  const { zoom } = useViewport();
  const { isViewer, isTemplateEditor } = useProjectPermission();
  const { getTargetElement } = useCanvasGet();
  const { canvasSetBlocksVisibility } = useCanvasUpdate();
  const { data: commentAutoReplyData } = useGetCommentReply(commentAutoReplyID);
  const conditionID = get(commentAutoReplyData, 'replyCondition.data.0.id', '');
  const { data: commentAutoReplyCondition } =
    useGetCommentReplyConditionV2(conditionID);
  const { mutate: updateCondition } = usePatchCommentReplyConditionV2();
  const { handleBlockClick } =
    useNodeInstagramCommentReplyBlock(commentAutoReplyID);

  const isActive = commentAutoReplyData?.isActive;

  const isPrivateReplyActive =
    commentAutoReplyCondition?.replyContentType === 1 ||
    commentAutoReplyCondition?.replyContentType === 2;

  const { outputID, targetID, children } = useMemo(() => {
    const blockData = getTargetElement(id);

    return {
      targetID: blockData?.targetID,
      outputID: blockData?.outputID,
      children: blockData?.children,
    };
  }, [getTargetElement, id]);

  const handleSwitchClick = useCallback(() => {
    if (isActive || !commentAutoReplyCondition || isViewer) return;
    let newType = commentAutoReplyData?.postType === 1 ? 1 : 2;

    if (commentAutoReplyData?.postType === 1) {
      switch (commentAutoReplyCondition?.replyContentType) {
        case 1: {
          newType = 3;
          break;
        }
        case 2: {
          newType = 4;
          break;
        }
        case 3: {
          newType = 1;
          break;
        }
        case 4: {
          newType = 2;
          break;
        }
      }
    } else {
      if (commentAutoReplyCondition?.replyContentType === 2) {
        newType = 4;
      } else if (commentAutoReplyCondition?.replyContentType === 4) {
        newType = 2;
      }
    }

    sendGAEvent(
      'comment auto reply',
      'private reply-switch',
      'comment auto reply-private reply-switch-click',
      isActive ? '0' : '1',
    );
    // private reply 後面連接的 block 設定 visible
    canvasSetBlocksVisibility(targetID ?? '', !isPrivateReplyActive);

    updateCondition({
      id: commentAutoReplyID,
      isAutoSave: true,
      replyCondition: [
        {
          ...commentAutoReplyCondition,
          isWarning: false,
          replyContentType: newType,
        },
      ],
    });
  }, [
    canvasSetBlocksVisibility,
    commentAutoReplyCondition,
    commentAutoReplyData?.postType,
    commentAutoReplyID,
    isActive,
    isPrivateReplyActive,
    isViewer,
    updateCondition,
    targetID,
  ]);

  return (
    <EditorBlockContainer nodeID={id} onClick={handleBlockClick}>
      <HandlePoint
        id="1"
        type="target"
        position={Position.Left}
        styles={{
          top: '20px',
        }}
        isConnected={false}
      />
      <EditorBlockBorder
        zoom={zoom}
        nodeID={id}
        selected={selected}
        color={get(globalTheme, 'palette.info.main', '')}
      >
        <NodeHeader
          color={globalTheme?.palette?.['primary']?.['main'] as string}
          background={globalTheme.palette?.['blue'][50]}
          icon={<MessageIcon fontSize="small" />}
          title={t('commentReplyDetail.privateReply.title')}
          disabled={!isPrivateReplyActive}
          readonly
          switchProps={{
            checked: isPrivateReplyActive,
            disabled: isActive || isViewer,
            onChange: handleSwitchClick,
          }}
        />
        {isPrivateReplyActive && (
          <BodyWrapperStyled>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
              <InfoWrapperStyled>
                <Typography variant="body2" color="info.dark">
                  {t('commentReplyDetail.privateReply.info')}
                </Typography>
              </InfoWrapperStyled>
              {children?.map((elementID) => {
                const el = getTargetElement(elementID);
                if (el) {
                  return (
                    <EditorTextButton
                      key={elementID}
                      id={elementID}
                      draggable={false}
                      label={el.label}
                      parentID={id}
                    />
                  );
                }
                return null;
              })}
            </Box>
          </BodyWrapperStyled>
        )}
      </EditorBlockBorder>
    </EditorBlockContainer>
  );
};

export default memo(NodeInstagramPrivateReply);
