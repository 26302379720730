import {
  DndProvider,
  ThemeProvider,
} from '@frontend/components/external-providers';
import { QueryClient } from '@tanstack/react-query';
import { FC, PropsWithChildren } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GoogleAuthProvider } from './GoogleAuthProvider';
import { IGProvider } from './IGProvider';
import { NotificationProvider } from './NotificationProvider';
import { QueryClientProvider } from './QueryClientProvider';
import { ThreadsProvider } from './ThreadsProvider';
import { UIProvider } from './UIProvider';
import { WebSocketProvider } from './WebSocketProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      useErrorBoundary: true,
      retry: 0,
    },
  },
  logger: {
    log: console.log,
    warn: console.warn,
    error: process.env['NODE_ENV'] === 'test' ? () => null : console.error,
  },
});

export const IntegrationProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <IGProvider appID="igAppID">
        <ThreadsProvider appID="threadsAppID">
          <GoogleAuthProvider
            clientID={'googleClientID'}
            redirectTo={'PAGE_GOOGLE_CALLBACK'}
          >
            <WebSocketProvider projectID={'projectID'}>
              <NotificationProvider>
                <ThemeProvider>
                  <DndProvider>
                    <UIProvider>
                      <BrowserRouter>
                        <Routes>
                          {children}
                          <Route path="/" element={null} />
                        </Routes>
                      </BrowserRouter>
                    </UIProvider>
                  </DndProvider>
                </ThemeProvider>
              </NotificationProvider>
            </WebSocketProvider>
          </GoogleAuthProvider>
        </ThreadsProvider>
      </IGProvider>
    </QueryClientProvider>
  );
};
