import { Alert, internalLinkToast } from '@frontend/components/utils';
import {
  useCanvasCollect,
  useCanvasPublish,
  useProjectPermission,
} from '@frontend/editor/data-access';
import {
  useGetCommentReply,
  useGetCommentReplyConditionV2,
  usePostCommentAutoReplyActive,
  usePostCommentReplyActiveV2,
  useProjectLinked,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  CommentReplyPostType,
  FlowChart,
  ModalTypesEnum,
  PublishStatus,
} from '@frontend/sorghum/interface';
import { get, isEmpty } from 'lodash';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const useNodeInstagramCommentReplyBlock = (
  commentAutoReplyID: string,
) => {
  const [t] = useTranslation('instagram');
  const { openModal } = useContext(UICtx);
  const { isTemplateEditor } = useProjectPermission();
  const { data: linked } = useProjectLinked();
  const { mutate: activeCommentV2 } = usePostCommentReplyActiveV2();
  const { mutate: activeComment } = usePostCommentAutoReplyActive();

  const { data } = useGetCommentReply(commentAutoReplyID || '');
  const conditionID = get(data, 'replyCondition.data.0.id', '');
  const { data: conditionData } = useGetCommentReplyConditionV2(conditionID);

  const { getErrors } = useCanvasCollect();
  const { getPublishData } = useCanvasPublish();
  const { isViewer } = useProjectPermission();

  const handleSwitchCommentReply = useCallback(
    (active: boolean) => {
      // 檢查連線
      if (!linked?.ig?.id && !isViewer && !isTemplateEditor) {
        openModal(ModalTypesEnum.CONNECT_INSTAGRAM_MODAL);
        return;
      }

      // switch off
      if (!active) {
        activeCommentV2(
          {
            id: commentAutoReplyID,
            enable: false,
          },
          {
            onSuccess: () => {
              Alert.success(
                t('commentReplyDetail.alert.infoSuccess', {
                  name: data?.name,
                }),
              );
            },
          },
        );
        return;
      }

      // switch on

      // 檢查 schedule / selectPosts
      if (!data?.schedulePeriod?.isComplete || !data?.selectPosts?.isComplete) {
        Alert.error(t('commentReplyDetail.alert.empty'));
        return;
      }

      // 至少開啟一種回覆內容
      if (conditionData?.replyContentType === 4) {
        Alert.error(t('commentReplyDetail.alert.atLeast'));
        return;
      }

      // 檢查 private reply
      if (
        conditionData?.replyContentType === 1 ||
        conditionData?.replyContentType === 2
      ) {
        const errors = getErrors();

        if (errors.length > 0) {
          Alert.error(errors[0].message);
          return;
        }
      }

      if (data.postType === CommentReplyPostType.POST) {
        // 檢查 public reply
        if (
          conditionData?.replyContentType === 1 ||
          conditionData?.replyContentType === 3
        ) {
          const emptyContentList =
            conditionData?.publicReplyContent?.filter((item) =>
              isEmpty(item),
            ) || [];
          if (
            emptyContentList.length > 0 ||
            conditionData?.publicReplyContent?.length === 0
          ) {
            Alert.error(t('commentReplyDetail.alert.empty'));
            return;
          }
        }
      }

      const publishData = getPublishData();
      const chart = {
        name: conditionData?.privateReplyContent?.chart?.name,
        publishStatus: PublishStatus.PUBLISHED,
        updatedAt: new Date().toISOString(),
        chart: {
          data: publishData.chart.data,
          blockSerialNumber: publishData.chart.data.length,
        },
      } as FlowChart;

      activeCommentV2(
        {
          id: commentAutoReplyID as string,
          enable: true,
          flows: [
            {
              flowId: conditionData?.privateReplyContent?.id as string,
              blocks: publishData.blocks,
              chart,
            },
          ],
        },
        {
          onSuccess: () => {
            openModal(ModalTypesEnum.ACTIVE_COMMENT_AUTO_REPLY);
          },
        },
      );
    },
    [
      linked?.ig?.id,
      isViewer,
      isTemplateEditor,
      data?.schedulePeriod?.isComplete,
      data?.selectPosts?.isComplete,
      data?.postType,
      data?.name,
      conditionData?.replyContentType,
      conditionData?.privateReplyContent?.chart?.name,
      conditionData?.privateReplyContent?.id,
      conditionData?.publicReplyContent,
      getPublishData,
      activeCommentV2,
      commentAutoReplyID,
      openModal,
      t,
      getErrors,
    ],
  );

  const handleBlockClick = useCallback(() => {
    if (isViewer) return;
    if (data?.isActive) {
      internalLinkToast(
        t('commentReplyDetail.alert.info'),
        t('commentReplyDetail.alert.infoAction'),
        'info',

        () =>
          activeComment(
            {
              id: commentAutoReplyID,
              enable: false,
            },
            {
              onSuccess: () => {
                Alert.success(
                  t('commentReplyDetail.alert.infoSuccess', {
                    name: data?.name,
                  }),
                );
              },
            },
          ),
      );
    }
  }, [
    isViewer,
    data?.isActive,
    data?.name,
    t,
    activeComment,
    commentAutoReplyID,
  ]);

  return { handleSwitchCommentReply, handleBlockClick };
};
