import { Dialog, Typography } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { useGetBilling, useGetProject } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import {
  FACEBOOK_PLAN_DETAILS_EN,
  FACEBOOK_PRICING_PAGE,
  usePath,
} from '@frontend/sorghum/utils';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Link } from '@mui/material';
import { styled } from '@mui/system';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const ContentContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '20px',
  gap: '20px',
}));

const GrayContainerStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette['bluegrey'][50],
  padding: '20px',
  borderRadius: '8px',
  width: '100%',
  height: '240px',
}));

const UnorderedListStyled = styled('ul')(({ theme }) => ({
  paddingInlineStart: '20px',

  '&.custom-list-icon': {
    listStyleType: 'none',
    paddingInlineStart: 0,

    '& > li': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '22.5px',
    },
  },
}));

const CloseIconStyled = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette['error']['main'],
  width: '16px',
  height: '16px',
  marginRight: '8px',
}));

export function ViewOnlyModeModal() {
  const [t] = useTranslation();
  const uiState = useContext(UICtx);
  const { data: projectID } = useGetProjectID();
  const { data: project } = useGetProject(projectID);
  const { navigateToUpgrade } = usePath();
  const { data: billingData } = useGetBilling();

  return (
    <Dialog
      open={uiState.isOpenViewOnlyModeModal}
      size="md"
      handleClose={() => {
        uiState.openModal(ModalTypesEnum.TRIAL_END);
        uiState.setIsOpenViewOnlyModeModal(false);
      }}
      title={t('modal.paymentSurvey.viewOnlyMode.title')}
      handleConfirm={() => {
        navigateToUpgrade({
          socialType: project?.socialType,
          projectID,
        });
        uiState.setIsOpenViewOnlyModeModal(false);
      }}
      confirmBtnText={t('modal.paymentSurvey.viewOnlyMode.confirmButton')}
      cancelBtnText={t('modal.paymentSurvey.viewOnlyMode.cancelButton')}
      uppercase={false}
      closeBtn={false}
    >
      <Box>
        <Typography variant="body1" color="grey.800">
          <Trans
            i18nKey="modal.paymentSurvey.viewOnlyMode.description"
            components={[
              <strong />,
              <Link
                href={
                  i18next.language.includes('en')
                    ? FACEBOOK_PLAN_DETAILS_EN
                    : FACEBOOK_PRICING_PAGE
                }
                underline="always"
                target="_blank"
                sx={{
                  fontWeight: 700,
                  color: 'grey.800',
                }}
              />,
            ]}
          />
        </Typography>
        <ContentContainerStyled>
          <GrayContainerStyled>
            <Typography variant="subtitle2" color="grey.800">
              {t('modal.paymentSurvey.viewOnlyMode.block1.title')}
            </Typography>
            <UnorderedListStyled>
              <li>
                <Typography variant="body3">
                  {t('modal.paymentSurvey.viewOnlyMode.block1.info1', {
                    date: billingData?.endedAt
                      ? dayjs(billingData?.endedAt).format('YYYY/MM/DD')
                      : '',
                  })}
                </Typography>
              </li>
              <li>
                <Typography variant="body3">
                  {t('modal.paymentSurvey.viewOnlyMode.block1.info2')}
                </Typography>
              </li>
            </UnorderedListStyled>
          </GrayContainerStyled>
          <GrayContainerStyled>
            <Typography variant="subtitle2" color="grey.800">
              {t('modal.paymentSurvey.viewOnlyMode.block2.title')}
            </Typography>
            <UnorderedListStyled className="custom-list-icon">
              <li>
                <CloseIconStyled />
                <Typography variant="body3">
                  {t('modal.paymentSurvey.viewOnlyMode.block2.info1')}
                </Typography>
              </li>
              <li>
                <CloseIconStyled />
                <Typography variant="body3">
                  {t('modal.paymentSurvey.viewOnlyMode.block2.info2')}
                </Typography>
              </li>
              <li>
                <CloseIconStyled />
                <Typography variant="body3">
                  {t('modal.paymentSurvey.viewOnlyMode.block2.info3')}
                </Typography>
              </li>
              <li>
                <CloseIconStyled />
                <Typography variant="body3">
                  {t('modal.paymentSurvey.viewOnlyMode.block2.info4')}
                </Typography>
              </li>
            </UnorderedListStyled>
          </GrayContainerStyled>
        </ContentContainerStyled>
      </Box>
    </Dialog>
  );
}

export default ViewOnlyModeModal;
