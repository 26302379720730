import { globalTheme } from '@frontend/components/external-providers';

import { Button } from '@frontend/components/ui';
import {
  useCanvasGet,
  useCanvasUpdate,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  ActionAttributeType,
  ActionCellBaseType,
  ActionCellTypesEnum,
  ActionSequenceType,
  DrawerTypesEnum,
} from '@frontend/editor/interface';
import { useGetAttributeKey } from '@frontend/sorghum/data-access';
import { CONTENT, PAGE_SEQUENCE, usePath } from '@frontend/sorghum/utils';
import AddIcon from '@mui/icons-material/Add';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { get, isArray } from 'lodash';
import { memo, useContext, useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NodeProps, Position, useViewport } from 'reactflow';
import EditorBlockBorder from '../editor-block-border/editor-block-border';
import EditorBlockContainer from '../editor-block-container/editor-block-container';
import { HandlePoint } from '../handle-point/handle-point';
import { NodeHeader } from '../node-header/node-header';

interface InputFieldStyledType {
  $zoom: number;
}

export const BodyStyled = styled(Box)(({ theme }) => ({
  width: '316px',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  justifyContent: 'end',
  flexDirection: 'column',
  padding: '12px',
}));

export const ItemWrapperStyled = styled(Box)<InputFieldStyledType>(
  ({ theme, $zoom }) => ({
    position: 'relative',
    marginTop: '10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    borderRadius: '8px',
    background: theme.palette['grey']['100'],
    fontSize: `${28 / $zoom}px`,
    cursor: 'pointer',
  }),
);

export const TitleWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 0 8px 8px',
}));

export const GoogleSheetWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 0 8px 8px',
}));

export const AttributeWrapperStyled = styled(Box)(({ theme }) => ({
  background: theme.palette['bluegrey'][50],
  color: theme.palette['primary']['main'],
  padding: '2px 8px',
  borderRadius: '4px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const IconButtonStyled = styled(Box)(({ theme }) => ({
  width: '32px',
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ActionCellItem = ({ id }: { id: string }) => {
  const { navigateToProjectPage } = usePath();
  const [t] = useTranslation();
  const state = useContext(EditorCtx);
  const UIState = useContext(UICtx);
  const { data: projectID } = useGetProjectID();
  const { data: attributeData } = useGetAttributeKey(projectID as string);
  const actionData = state.getElement(id) as ActionCellBaseType;

  const attributeName = useMemo(() => {
    if (isArray(attributeData)) {
      return attributeData.find(
        (data) => data.id === get(actionData, 'attributeID', {}),
      )?.key;
    } else {
      return '';
    }
  }, [attributeData, actionData]);

  switch (actionData.actionType) {
    case ActionCellTypesEnum.SET: {
      const _actionData = actionData as ActionAttributeType;
      return (
        <>
          <TitleWrapperStyled>
            <Typography
              sx={{ marginRight: '12px' }}
              variant="subtitle2"
              color="grey.900"
            >
              <Trans i18nKey="canvas.action.set.title" />
            </Typography>
            {(!_actionData.attributeName || !_actionData.valueName) && (
              <Tooltip
                title={t('canvas.action.set.tooltipSetAttribute')}
                placement="top"
              >
                <Box
                  id={`${actionData.id}-set-action-icon-button`}
                  onClick={() =>
                    UIState.setDrawerType(DrawerTypesEnum.ACTION_ATTRIBUTE)
                  }
                >
                  <IconButtonStyled id={`${actionData.id}-set-action-icon`}>
                    <WarningAmberOutlinedIcon color="error" />
                  </IconButtonStyled>
                </Box>
              </Tooltip>
            )}
          </TitleWrapperStyled>
          <AttributeWrapperStyled>
            {_actionData.attributeName && _actionData.valueName ? (
              <>
                <Typography variant="caption" color="primary">
                  {_actionData.attributeName}
                </Typography>
                &nbsp;
                <Typography variant="caption" color="grey.600">
                  <Trans i18nKey="canvas.condition.drawer.is" />
                </Typography>
                &nbsp;
                <Typography
                  sx={{
                    fontWeight: 700,
                  }}
                  variant="caption"
                  color="grey.700"
                >
                  {_actionData.valueName}
                </Typography>
              </>
            ) : (
              <Typography variant="caption" color="bluegrey.400">
                <Trans i18nKey="canvas.action.set.empty" />
              </Typography>
            )}
          </AttributeWrapperStyled>
        </>
      );
    }
    case ActionCellTypesEnum.REMOVE: {
      const _actionData = actionData as ActionAttributeType;
      return (
        <>
          <TitleWrapperStyled>
            <Typography
              sx={{ marginRight: '12px' }}
              variant="subtitle2"
              color="grey.900"
            >
              <Trans i18nKey="canvas.action.remove.title" />
            </Typography>
            {!_actionData.attributeID && (
              <IconButton size="small" color="error">
                <WarningAmberOutlinedIcon />
              </IconButton>
            )}
          </TitleWrapperStyled>
          <AttributeWrapperStyled>
            {_actionData.attributeID ? (
              <Typography variant="caption" color="primary">
                {attributeName}
              </Typography>
            ) : (
              <Typography variant="caption" color="bluegrey.400">
                <Trans i18nKey="canvas.action.remove.empty" />
              </Typography>
            )}
          </AttributeWrapperStyled>
        </>
      );
    }
    case ActionCellTypesEnum.SUBSCRIBE_SEQUENCE:
    case ActionCellTypesEnum.UNSUBSCRIBE_SEQUENCE: {
      const _actionData = actionData as ActionSequenceType;
      return (
        <>
          <TitleWrapperStyled>
            <Typography
              sx={{ marginRight: '12px' }}
              variant="subtitle2"
              color="grey.900"
            >
              {actionData.actionType ===
              ActionCellTypesEnum.SUBSCRIBE_SEQUENCE ? (
                <Trans i18nKey="canvas.action.subscribe.title" />
              ) : (
                <Trans i18nKey="canvas.action.unsubscribe.title" />
              )}
            </Typography>
            {!_actionData.sequence && (
              <Tooltip
                title={t('canvas.action.subscribe.tooltipSubscribe')}
                placement="top"
              >
                <IconButton size="small" color="error">
                  <WarningAmberOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </TitleWrapperStyled>
          <AttributeWrapperStyled>
            {_actionData.sequence ? (
              <Tooltip placement="bottom-end" title={_actionData.sequence.name}>
                <Typography
                  onClick={() =>
                    navigateToProjectPage({
                      path: `${PAGE_SEQUENCE}/${_actionData.sequence.id}/${CONTENT}`,
                    })
                  }
                  variant="caption"
                  color="primary"
                >
                  {_actionData.sequence.name}
                </Typography>
              </Tooltip>
            ) : (
              <Typography variant="caption" color="bluegrey.400">
                <Trans i18nKey="canvas.action.subscribe.empty" />
              </Typography>
            )}
          </AttributeWrapperStyled>
        </>
      );
    }
    default: {
      return null;
    }
  }
};

export const NodeTourAction = ({ id }: NodeProps) => {
  const [t] = useTranslation();
  const { getTargetElement } = useCanvasGet();
  const { zoom } = useViewport();
  const elementData = getTargetElement(id);
  const header = get(elementData, 'label', '');
  const inputID = get(elementData, 'inputID', '');
  const outputID = get(elementData, 'outputID', '');
  const targetID = get(elementData, 'targetID', '');
  const children = get(elementData, 'children', []) as string[];
  const { canvasUpdateLabel } = useCanvasUpdate();
  const buttonRef = useRef<HTMLDivElement>(null);

  return (
    <EditorBlockContainer nodeID={id}>
      <HandlePoint
        id={inputID}
        type="target"
        position={Position.Left}
        styles={{
          top: '5%',
        }}
        isConnected={false}
      />
      <HandlePoint
        id={outputID}
        type="source"
        position={Position.Right}
        styles={{
          top: 'calc(100% - 30px)',
        }}
        isConnected={!!targetID}
      />
      <EditorBlockBorder
        zoom={zoom}
        nodeID={id}
        color={get(globalTheme, 'palette.purple.600', '')}
      >
        <NodeHeader
          background={get(globalTheme, 'palette.purple.50', '')}
          color={get(globalTheme, 'palette.purple.600', '')}
          icon={<BookmarksIcon fontSize="small" />}
          title={header}
          onBlur={(val) => canvasUpdateLabel(id, val)}
        />

        <BodyStyled>
          {children.map((item, index: number) => (
            <ItemWrapperStyled key={item} $zoom={zoom}>
              <ActionCellItem id={item} />
            </ItemWrapperStyled>
          ))}

          <Box ref={buttonRef}>
            <Button
              sx={{ marginTop: '10px' }}
              startIcon={<AddIcon fontSize="small" />}
              variant="outlined"
              dash
              color="bluegrey300"
              fullWidth
            >
              <Trans i18nKey="canvas.addElement" />
            </Button>
          </Box>
        </BodyStyled>
      </EditorBlockBorder>
    </EditorBlockContainer>
  );
};

export default memo(NodeTourAction);
