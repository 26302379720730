import {
  PostAdminCommentReplyTemplateParams,
  Response,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ADMIN_COMMENT_REPLY_TEMPLATE } from '../apis';

async function postAdminCommentReplyTemplate(
  params: PostAdminCommentReplyTemplateParams,
): Promise<Response> {
  return axios
    .post(ADMIN_COMMENT_REPLY_TEMPLATE, params)
    .then((res) => res.data.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostAdminCommentReplyTemplate() {
  return useMutation({
    mutationKey: ['post-admin-comment-reply-template'],
    mutationFn: postAdminCommentReplyTemplate,
  });
}

export default usePostAdminCommentReplyTemplate;
