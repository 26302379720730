import { useCanvasAdd, useCanvasRemove } from '@frontend/editor/data-access';
import { EditorCtx } from '@frontend/editor/external-providers';
import { ElementTypesEnum } from '@frontend/editor/interface';
import { uuid } from '@frontend/editor/utils';
import CommentBankOutlinedIcon from '@mui/icons-material/CommentBankOutlined';
import TextIcon from '@mui/icons-material/FormatColorText';
import { Box, ClickAwayListener } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NodeProps, Position } from 'reactflow';
import EditorMenu, { EditorMenuItemList } from '../editor-menu/editor-menu';
import { HandlePoint } from '../handle-point/handle-point';

interface NodeCreateBlockMenuProps {
  sourceOutputID: string;
}

const ContainerStyled = styled(Box)(({ theme }) => ({
  background: '#ffffff',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: get('theme.shadows[24]', ''),
  maxHeight: '400px',
  overflow: 'auto',
}));

export const NodeCommentReplyCreateBlockMenu = ({
  data,
  xPos,
  yPos,
}: NodeProps<NodeCreateBlockMenuProps>) => {
  const { sourceOutputID } = data;
  const { blockSerialNumber } = useContext(EditorCtx);

  const [t] = useTranslation();
  const {
    addBlock,
    addTextButton,
    removeCreateBlockMenu,
    addCollectUserAnswerV2Element,
    addCollectUserAnswerV2,
    addNoteElement,
  } = useCanvasAdd();
  const { removeCreateMenu } = useCanvasRemove();

  const handleAddBlockElement = useCallback(
    (type: ElementTypesEnum) => {
      removeCreateBlockMenu();
      switch (type) {
        case ElementTypesEnum.TEXT_BUTTON: {
          const blockID = addBlock(
            sourceOutputID,
            { x: xPos, y: yPos },
            false,
            {
              label: t('canvas.blockDefaultTitle', {
                count: blockSerialNumber,
              }),
            },
          );
          addTextButton(blockID);
          break;
        }
        case ElementTypesEnum.COLLECT_USER_ANSWER_V2: {
          const blockID = addCollectUserAnswerV2(sourceOutputID, {
            x: xPos,
            y: yPos,
          });
          addNoteElement(blockID, 0, {
            label: t('canvas.collectUserAnswerV2.note'),
          });
          addCollectUserAnswerV2Element(blockID);
          break;
        }
      }
    },
    [
      removeCreateBlockMenu,
      addBlock,
      sourceOutputID,
      xPos,
      yPos,
      t,
      blockSerialNumber,
      addTextButton,
      addCollectUserAnswerV2,
      addNoteElement,
      addCollectUserAnswerV2Element,
    ],
  );

  const createBlockMenu: EditorMenuItemList[] = [
    {
      title: t('canvas.contentTitle'),
      dropdownItems: [
        {
          id: 'option_text_button',
          onClick: () => handleAddBlockElement(ElementTypesEnum.TEXT_BUTTON),
          icon: <TextIcon color="primary" />,
          title: t('canvas.blockMenu.contentButton'),
        },
        {
          id: 'option_collect_user_answer',
          icon: <CommentBankOutlinedIcon color="primary" />,
          title: t('canvas.blockMenu.collectUserAnswerButton'),
          onClick: () =>
            handleAddBlockElement(ElementTypesEnum.COLLECT_USER_ANSWER_V2),
          tooltip: (
            <Box>{t('canvas.blockMenu.showCollectUserAnswerTooltip')}</Box>
          ),
        },
      ],
    },
  ];

  return (
    <ClickAwayListener onClickAway={removeCreateMenu}>
      <ContainerStyled className="nowheel nodrag nopan">
        <HandlePoint
          id={uuid()}
          type="target"
          position={Position.Left}
          styles={{
            top: '5%',
          }}
          isConnected={false}
        />
        <EditorMenu itemList={createBlockMenu} />
      </ContainerStyled>
    </ClickAwayListener>
  );
};

export default memo(NodeCommentReplyCreateBlockMenu);
