import { Typography } from '@frontend/components/ui';
import { useGetCommentReply } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum, SocialTypeEnum } from '@frontend/sorghum/interface';
import { useCustomizedTranslation } from '@frontend/sorghum/utils';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/system';
import { FC, useContext } from 'react';
import { Trans } from 'react-i18next';
import ActiveCommentReplyPNG from '../../images/active-comment-reply.png';
import Button from '../button/button';

interface Props {
  id: string;
  socialType: SocialTypeEnum;
}

const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  padding: '20px 24px',
}));

const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({
  padding: '0 24px 20px 24px',
}));

export const CommentReplyActiveModal: FC<Props> = ({ id, socialType }) => {
  const { t } = useCustomizedTranslation(socialType);
  const { modalType, closeModal } = useContext(UICtx);
  const { data } = useGetCommentReply(id);

  if (modalType !== ModalTypesEnum.ACTIVE_COMMENT_AUTO_REPLY) return null;

  return (
    <Dialog open maxWidth="s" fullWidth>
      <img src={ActiveCommentReplyPNG} alt="active-comment-reply" />
      <DialogTitleStyled>
        <Typography variant="h6" component="span">
          <Trans
            t={t}
            i18nKey="commentReplyDetail.alert.success"
            values={{
              name: data?.name,
            }}
            components={[<strong />]}
          />
        </Typography>
      </DialogTitleStyled>
      <DialogContent>
        <Typography variant="body1">
          <Trans
            t={t}
            i18nKey="commentReplyDetail.alert.successInfo"
            components={[<strong />]}
          />
        </Typography>
      </DialogContent>
      <DialogActionsStyled>
        <Button onClick={closeModal} variant="contained">
          {t('commentReplyDetail.alert.successDone')}
        </Button>
      </DialogActionsStyled>
    </Dialog>
  );
};

export default CommentReplyActiveModal;
