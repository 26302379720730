import { Dialog } from '@frontend/components/ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmCloseDrawerModalProps {
  open: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
  disableConfirmButton?: boolean;
}

export const ConfirmCloseDrawerModal: FC<ConfirmCloseDrawerModalProps> = ({
  open,
  handleConfirm,
  handleCancel,
  disableConfirmButton,
}: ConfirmCloseDrawerModalProps) => {
  const [t] = useTranslation();

  return (
    <Dialog
      size="xs"
      color="primary"
      title={t('canvas.modal.closeUnsavedDrawer.title')}
      content={t('canvas.modal.closeUnsavedDrawer.content')}
      open={open}
      handleClose={handleCancel}
      cancelBtnText={t('canvas.modal.closeUnsavedDrawer.cancel')}
      confirmBtnText={t('canvas.modal.closeUnsavedDrawer.confirm')}
      handleConfirm={handleConfirm}
      disableConfirmButton={disableConfirmButton}
    />
  );
};

export default ConfirmCloseDrawerModal;
