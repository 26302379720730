import { globalTheme } from '@frontend/components/external-providers';
import { TextField, Typography } from '@frontend/components/ui';
import {
  useCanvasGet,
  useCanvasRemove,
  useCanvasUpdate,
  useModal,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import { BaseElement, DrawerTypesEnum } from '@frontend/editor/interface';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { isEmpty } from 'lodash';
import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useViewport } from 'reactflow';
import EditorFloatPanel from '../editor-float-panel/editor-float-panel';

interface TextButtonProps {
  id: string;
  label?: string;
  draggable: boolean;
  parentID: string;
  setIsHoverMenu?: Dispatch<SetStateAction<boolean>>;
  index?: number;
  isEnableDelete?: boolean;
}

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  '& fieldset': {
    borderColor: `transparent`,
  },
  '& .MuiOutlinedInput-root.Mui-disabled fieldset': {
    borderColor: `transparent`,
  },

  '& .Mui-disabled': {
    '-webkit-text-fill-color': theme.palette['grey'][900],
  },
}));

const ContainerStyled = styled(Box)<{ $zoom: number }>(({ theme, $zoom }) => ({
  borderRadius: '6px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  fontSize: `${28 / $zoom}px`,
}));

const ElementWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

const SettingWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px',
  gap: '12px',
  background: theme.palette['grey']['100'],
}));

const ReplyTypeWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  padding: '12px',
  background: theme.palette['purple']['50'],

  '& > svg': {
    color: theme.palette['purple'][600],
  },
}));

const InfoWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px',
  background: theme.palette['background']['warning'][5],
}));

export const EditorCollectUserAnswerV2 = ({
  id, // Element ID
  index,
  parentID, // Block ID
  label,
  draggable,
  setIsHoverMenu, // 檢查是否 hover 在側邊的選單來決定是否開放 element 的 sort event
  isEnableDelete,
}: TextButtonProps) => {
  const { elements, readonly, setIsSaving, setOnFocusCellID } =
    useContext(EditorCtx);
  const { hoveredElement, setDrawerType, onHoverElement } = useContext(UICtx);
  const { zoom } = useViewport();
  const [t] = useTranslation();
  const [isTextButtonHover, setIsTextButtonHover] = useState<boolean>(false);
  const [isFocusTextarea, setIsFocusTextarea] = useState<boolean>(false);
  const [value, setValue] = useState(label);

  const { openDeleteElementModal } = useModal();
  const { removeElement } = useCanvasRemove();
  const { canvasUpdateLabel } = useCanvasUpdate();
  const { getTargetElement } = useCanvasGet();

  const element = getTargetElement(id);

  const onRemoveButtonClick = useCallback(() => {
    const textButtonData =
      elements.find((item) => item.id === id) || ({} as BaseElement);
    // 如果有設定任何資料則跳出提示
    const needConfirm =
      !isEmpty(textButtonData) ||
      !isEmpty(textButtonData.data.selectedBlockID) ||
      !isEmpty(textButtonData.data.tel) ||
      !isEmpty(textButtonData.data.url) ||
      !isEmpty(label);

    if (needConfirm) {
      openDeleteElementModal(id);
    } else {
      removeElement(id);
    }
  }, [elements, label, id, openDeleteElementModal, removeElement]);

  const handleOnBlur = useCallback(
    (text: string) => {
      setIsSaving(true);
      setIsFocusTextarea(false);
      canvasUpdateLabel(id, text);
    },
    [canvasUpdateLabel, id, setIsSaving],
  );

  const handleMouseEnterTextButton = useCallback(() => {
    onHoverElement(id, 2);
    setIsTextButtonHover(true);
  }, [id, onHoverElement]);

  const handleMouseLeaveTextButton = useCallback(() => {
    setIsTextButtonHover(false);
  }, []);

  const handleMouseEnterMenu = useCallback(() => {
    setIsHoverMenu && setIsHoverMenu(true);
  }, [setIsHoverMenu]);

  const handleMouseLeaveMenu = useCallback(() => {
    setIsHoverMenu && setIsHoverMenu(false);
  }, [setIsHoverMenu]);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  const openDrawer = useCallback(() => {
    setDrawerType(DrawerTypesEnum.COLLECT_USER_ANSWER_V2);
    setOnFocusCellID(id);
  }, [id, setDrawerType, setOnFocusCellID]);

  const replyTypeText = useMemo(() => {
    switch (element.data?.replyType) {
      case 1: {
        return t('canvas.collectUserAnswerV2.type.number');
      }
      case 2: {
        return t('canvas.collectUserAnswerV2.type.email');
      }
      case 3: {
        return t('canvas.collectUserAnswerV2.type.custom');
      }
    }
    return t('canvas.collectUserAnswerV2.type.number');
  }, [element.data?.replyType, t]);

  useEffect(() => {
    setValue(element.label);
  }, [element.label]);

  return (
    <ContainerStyled
      onMouseEnter={handleMouseEnterTextButton}
      onMouseLeave={handleMouseLeaveTextButton}
      $zoom={zoom}
    >
      {/* Element 左側的刪除、sorting、新增按鈕 */}
      {!readonly &&
        isTextButtonHover &&
        hoveredElement.layer < 3 &&
        !isFocusTextarea && 
        isEnableDelete && (
          <EditorFloatPanel
            onMouseEnter={handleMouseEnterMenu}
            onMouseLeave={handleMouseLeaveMenu}
            type="element"
            index={index}
            parentID={parentID}
            draggable={draggable}
            direction="column"
            handleDelete={onRemoveButtonClick}
            backgroundColor={globalTheme?.palette?.['grey']?.[100]}
          />
        )}

      <ElementWrapperStyled>
        <SettingWrapperStyled>
          <ReplyTypeWrapperStyled onClick={openDrawer}>
            <LocalPhoneIcon fontSize="small" />
            <Typography component="span" color="purple.600" variant="body2">
              {t('canvas.collectUserAnswerV2.replyType')}
            </Typography>
            <Typography
              component="span"
              color="purple.600"
              variant="body2"
              sx={{
                fontWeight: 700,
              }}
            >
              {replyTypeText}
            </Typography>
          </ReplyTypeWrapperStyled>
          <TextFieldStyled
            multiline
            fullWidth
            value={value}
            color="info"
            disabled={readonly}
            inputProps={{
              maxLength: 500,
            }}
            error={!label}
            size="small"
            onChange={onChange}
            onFocus={() => setIsFocusTextarea(true)}
            onBlur={(e) => handleOnBlur(e.target.value)}
          />
        </SettingWrapperStyled>
        <InfoWrapperStyled>
          <Typography variant="body3" color="warning.dark">
            {t('canvas.collectUserAnswerV2.info')}
          </Typography>
        </InfoWrapperStyled>
      </ElementWrapperStyled>
    </ContainerStyled>
  );
};

export default memo(EditorCollectUserAnswerV2);
