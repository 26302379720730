import { queryClient } from '@frontend/sorghum/external-providers';
import {
  PatchCommentReplyConditionRequest,
  Response,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY_CONDITION_V2 } from '../apis';

async function patchCommentReplyCondition(
  props: PatchCommentReplyConditionRequest,
) {
  return axios
    .patch(COMMENT_REPLY_CONDITION_V2, props)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePatchCommentReplyConditionV2() {
  return useMutation({
    mutationKey: ['patch-comment-reply-condition-v2'],
    mutationFn: (props: PatchCommentReplyConditionRequest) =>
      patchCommentReplyCondition(props),
    onSuccess: (
      response: Response,
      props: PatchCommentReplyConditionRequest,
    ) => {
      if (response.code === 20000) {
        queryClient.refetchQueries(['get-comment-reply', { uuid: props.id }]);
        queryClient.invalidateQueries(['get-comment-reply-condition-v2']);
      }
    },
  });
}
