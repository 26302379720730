import { Alert } from '@frontend/components/utils';
import {
  useCanvasView,
  useGetProjectID,
  usePatchEditorFlowEntry,
  useProjectPermission,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  DrawerTypesEnum,
  EntryPointBotLink,
  EntryPointsEnum,
  FlowEntryPoint,
} from '@frontend/editor/interface';
import { copyToClipboard } from '@frontend/sorghum/utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { IconSizeType } from '@frontend/components/interface';
import { IconButton } from '@frontend/components/ui';
import {
  useCanvasCollect,
  useCanvasUpdate,
} from '@frontend/editor/data-access';
import { useGetFlow } from '@frontend/sorghum/data-access';
import {
  PAGE_DEFAULT_ANSWER,
  PAGE_PERSISTENT_MENU,
  PAGE_WELCOME_MESSAGE,
  sendGAEvent,
  usePath,
} from '@frontend/sorghum/utils';
import LinkIcon from '@mui/icons-material/Link';
import { Box, Link, Switch, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MessengerIcon } from '../icons/messenger-icon';
import ExpandPanel from './expand-panel';
import {
  BotLinkWrapperStyled,
  EntryPointItemInlineWrapper,
  EntryPointItemTitleWrapperStyled,
  ToolTipStyled,
} from './styles';

interface NodeEntryPointItemProps {
  item: FlowEntryPoint;
}

const NodeEntryPointItem: FC<NodeEntryPointItemProps> = ({
  item,
}: NodeEntryPointItemProps) => {
  const [t] = useTranslation();
  const state = useContext(EditorCtx);
  const uiState = useContext(UICtx);
  const { setEntryDataType } = uiState;
  const { isViewer } = useProjectPermission();
  const { data: projectID } = useGetProjectID();
  const { id: flowID } = useParams();
  const { data: flow } = useGetFlow(
    flowID as string,
    projectID as string,
    false,
  );
  const { canvasUpdateFlowEntryPoint } = useCanvasUpdate();
  const { handleDrawer } = useCanvasView();

  const openMode = state.readonly || flow?.publishStatus !== 1 ? 'always' : '';

  const { flowEntryPoint: entryPoint } = useCanvasCollect();

  const { mutate: updateEntryPoint } = usePatchEditorFlowEntry(
    flowID as string,
    1,
    projectID as string,
    {
      onSuccess: (_, variables) => {
        if (variables.enable) {
          Alert.success(t('canvas.alert.entryPoint.botlinkOn'));
        } else {
          Alert.success(t('canvas.alert.entryPoint.botlinkOff'));
        }
      },
    },
  );

  const { navigateToProjectPage } = usePath();

  const handleToggleBotLink = useCallback(
    (_item: EntryPointBotLink) => {
      if (!state.readonly && !isViewer) {
        updateEntryPoint({
          id: _item.id,
          type: EntryPointsEnum.BOT_LINK,
          enable: !_item.enable,
          ref: _item.ref,
          source: _item.source,
          medium: _item.medium,
        });
        canvasUpdateFlowEntryPoint(
          entryPoint.map((i) => {
            if (i.id === _item.id) {
              return {
                ...i,
                enable: _item.enable,
              };
            } else {
              return i;
            }
          }),
        );
      }
    },
    [
      canvasUpdateFlowEntryPoint,
      entryPoint,
      isViewer,
      state.readonly,
      updateEntryPoint,
    ],
  );

  const handleFocus = useCallback(() => {
    // 只有在編輯模式才打開 bot-link drawer
    if (!state.readonly) {
      state.setOnFocusCellID(item.id);
      handleDrawer(DrawerTypesEnum.BOT_LINK);
    }
  }, [handleDrawer, item.id, state]);

  switch (item.type) {
    case EntryPointsEnum.WELCOME_MESSAGE: {
      const redirect = () => {
        if (state.readonly) {
          sendGAEvent(
            'Chat Flow View',
            'Welcome message - basic setting',
            'Chat Flow View - Entry point - Created entry - welcome message - basic setting - click',
            '',
          );
        } else {
          sendGAEvent(
            'Chat Flow Edit',
            'Welcome message - basic setting',
            'Chat Flow Edit - Entry point - Created entry - welcome message - basic setting - click',
            '',
          );
        }
        navigateToProjectPage({ path: `${PAGE_WELCOME_MESSAGE}` });
      };

      return (
        <ExpandPanel
          key={`entry-point-item-welcome-message`}
          onClick={() => setEntryDataType(item.id)}
          openMode={openMode}
          title={
            <>
              <EntryPointItemInlineWrapper>
                <Box>
                  <MessengerIcon />
                </Box>
                <Box>
                  <Typography variant="subtitle2">
                    <Trans i18nKey="canvas.entryPoint.addMenu.welcomeMessage.title" />
                  </Typography>
                </Box>
              </EntryPointItemInlineWrapper>
              <Tooltip
                placement="top"
                title={t('canvas.entryPoint.infoTooltip')}
              >
                <Box>
                  <Switch size="small" disabled checked={!!item.enable} />
                </Box>
              </Tooltip>
            </>
          }
          content={
            <Typography variant="notoSans" color="bluegrey.400">
              <Trans i18nKey="canvas.entryPoint.addMenu.welcomeMessage.memo">
                You may go to
                <Link onClick={redirect}>
                  {t('canvas.entryPoint.addMenu.title.basic')}
                </Link>
              </Trans>
            </Typography>
          }
        />
      );
    }
    case EntryPointsEnum.DEFAULT_ANSWER: {
      const redirect = () => {
        if (state.readonly) {
          sendGAEvent(
            'Chat Flow View',
            'Default answer - basic setting',
            'Chat Flow View - Entry point - Created entry - default - basic setting - click',
            '',
          );
        } else {
          sendGAEvent(
            'Chat Flow Edit',
            'Default answer - basic setting',
            'Chat Flow Edit - Entry point - Created entry - default - basic setting -  click',
            '',
          );
        }
        navigateToProjectPage({ path: `${PAGE_DEFAULT_ANSWER}` });
      };

      return (
        <ExpandPanel
          key={`entry-point-item-default-answer`}
          onClick={() => setEntryDataType(item.id)}
          openMode={openMode}
          title={
            <>
              <EntryPointItemInlineWrapper>
                <Box>
                  <MessengerIcon />
                </Box>
                <Box>
                  <Typography variant="subtitle2">
                    <Trans i18nKey="canvas.entryPoint.addMenu.defaultAnswer.title" />
                  </Typography>
                  <Typography variant="body2" color="grey.500">
                    <Trans
                      i18nKey={`canvas.entryPoint.addMenu.defaultAnswer.${
                        item.frequency === 1 ? 'always' : 'once'
                      }`}
                    />
                  </Typography>
                </Box>
              </EntryPointItemInlineWrapper>
              <Tooltip
                placement="top"
                title={t('canvas.entryPoint.infoTooltip')}
              >
                <Box>
                  <Switch size="small" disabled checked={!!item.enable} />
                </Box>
              </Tooltip>
            </>
          }
          content={
            <Typography variant="notoSans" color="bluegrey.400">
              <Trans i18nKey="canvas.entryPoint.addMenu.defaultAnswer.memo">
                You may go to
                <Link onClick={redirect}>
                  {t('canvas.entryPoint.addMenu.title.basic')}
                </Link>
              </Trans>
            </Typography>
          }
        />
      );
    }
    case EntryPointsEnum.PERSISTENT_MENU: {
      const redirect = () => {
        if (state.readonly) {
          sendGAEvent(
            'Chat Flow View',
            'Persistent menu - basic setting',
            'Chat Flow View - Entry point - Created entry - persistent menu - basic setting - click',
            '',
          );
        } else {
          sendGAEvent(
            'Chat Flow Edit',
            'Persistent menu - basic setting',
            'Chat Flow Edit - Entry point - Created entry - persistent menu - basic setting - click',
            '',
          );
        }
        navigateToProjectPage({ path: `${PAGE_PERSISTENT_MENU}` });
      };

      return (
        <ExpandPanel
          key={`entry-point-item-persistent-menu`}
          onClick={() => setEntryDataType(item.id)}
          openMode={openMode}
          title={
            <>
              <EntryPointItemInlineWrapper>
                <Box>
                  <MessengerIcon />
                </Box>
                <Box>
                  <Typography variant="subtitle2">
                    <Trans i18nKey="canvas.entryPoint.addMenu.persistentMenu.title" />
                  </Typography>
                  <Typography variant="body2" color="grey.500">
                    {item.title}
                  </Typography>
                </Box>
              </EntryPointItemInlineWrapper>
              <Tooltip
                placement="top"
                title={t('canvas.entryPoint.infoTooltip')}
              >
                <Box>
                  <Switch size="small" disabled checked={!!item.enable} />
                </Box>
              </Tooltip>
            </>
          }
          content={
            <Typography variant="notoSans" color="bluegrey.400">
              <Trans i18nKey="canvas.entryPoint.addMenu.defaultAnswer.memo">
                You may go to
                <Link onClick={redirect}>
                  {t('canvas.entryPoint.addMenu.title.basic')}
                </Link>
              </Trans>
            </Typography>
          }
        />
      );
    }
    case EntryPointsEnum.BOT_LINK: {
      const ellipsisURL = `https://...ref=${item.ref}`;

      return (
        <ExpandPanel
          key={`entry-point-item-bot-link-${item.id}`}
          onClick={() => setEntryDataType(item.id)}
          openMode={openMode}
          title={
            <>
              <EntryPointItemInlineWrapper onClick={handleFocus}>
                <Box>
                  <LinkIcon color="success" />
                </Box>
                <Tooltip
                  placement="top-start"
                  title={<ToolTipStyled>{item.title}</ToolTipStyled>}
                >
                  <EntryPointItemTitleWrapperStyled variant="subtitle2">
                    {item.title}
                  </EntryPointItemTitleWrapperStyled>
                </Tooltip>
              </EntryPointItemInlineWrapper>
              <Box
                onClick={(e) => {
                  e.preventDefault();
                  handleToggleBotLink(item);
                }}
              >
                <Switch
                  size="small"
                  disabled={state.readonly || isViewer}
                  checked={!!item.enable}
                />
              </Box>
            </>
          }
          content={
            item.enable && state.publishStatus > 1 ? (
              <BotLinkWrapperStyled>
                <Link
                  variant="notoSans"
                  color="info.main"
                  sx={{
                    wordBreak: 'break-all',
                  }}
                  onClick={() => {
                    window.open(item.url);
                  }}
                >
                  {ellipsisURL}
                </Link>
                <IconButton
                  $size={IconSizeType.XXS}
                  onClick={() => copyToClipboard(item.url)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </BotLinkWrapperStyled>
            ) : (
              <Tooltip
                placement="bottom-end"
                title={
                  <ToolTipStyled>
                    <Trans i18nKey="canvas.entryPoint.addMenu.botLink.unpublished" />
                  </ToolTipStyled>
                }
              >
                <Box sx={{ width: '100%', wordBreak: 'break-all' }}>
                  <Typography variant="notoSans" color="bluegrey.400">
                    {ellipsisURL}
                  </Typography>
                </Box>
              </Tooltip>
            )
          }
        />
      );
    }
  }
};

export default NodeEntryPointItem;
