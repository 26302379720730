import { SocialTypeEnum } from '@frontend/sorghum/interface';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useCustomizedTranslation = (type: SocialTypeEnum | undefined) => {
  const [messenger] = useTranslation();
  const [threads] = useTranslation('threads');
  const [instagram] = useTranslation('instagram');

  const t = useMemo(() => {
    switch (type) {
      case SocialTypeEnum.FACEBOOK:
        return messenger;
      case SocialTypeEnum.THREADS:
        return threads;
      case SocialTypeEnum.INSTAGRAM:
        return instagram;
      default:
        return messenger;
    }
  }, [instagram, messenger, threads, type]);

  const convertSocialTypeEnumToSocialName = () => {
    switch (type) {
      case SocialTypeEnum.FACEBOOK:
        return 'Facebook';
      case SocialTypeEnum.THREADS:
        return 'Threads';
      case SocialTypeEnum.INSTAGRAM:
        return 'Instagram';
      default:
        return 'Facebook';
    }
  };

  return {
    t,
    convertSocialTypeEnumToSocialName,
  };
};
