import { Dialog } from '@frontend/components/ui';
import { UICtx } from '@frontend/editor/external-providers';
import { ModalTypesEnum } from '@frontend/editor/interface';
import { SocialTypeEnum } from '@frontend/sorghum/interface';
import { dayjs, useCustomizedTranslation } from '@frontend/sorghum/utils';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface CommentReplyDateModalProps {
  timezoneOffset: number;
  startDate: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
  handleStartSelect: (newValue: dayjs.Dayjs | null) => void;
  handleEndSelect: (newValue: dayjs.Dayjs | null) => void;
  socialType: SocialTypeEnum;
}

const ModalContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

const DatePickerWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
  paddingTop: '16px',
  gap: '24px',

  '& > div': {
    maxWidth: '360px',
  },
}));

export const CommentReplyDateModal: FC<CommentReplyDateModalProps> = ({
  timezoneOffset,
  startDate,
  endDate,
  handleStartSelect,
  handleEndSelect,
  socialType,
}) => {
  const { t } = useCustomizedTranslation(socialType);

  const { closeModal, modalType } = useContext(UICtx);

  const [selectedStartDate, setSelectedStartDate] =
    useState<dayjs.Dayjs | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<dayjs.Dayjs | null>(
    null,
  );

  const startDateError = useMemo(() => {
    if (!selectedStartDate)
      return t('commentAutoReply.content.secondStep.startDateEmpty');
    if (selectedStartDate && selectedEndDate) {
      if (selectedStartDate.isAfter(selectedEndDate)) {
        return t('commentAutoReply.content.secondStep.startDateBeforeEndDate');
      }
    }
    return '';
  }, [selectedStartDate, selectedEndDate, t]);

  const endDateError = useMemo(() => {
    if (!selectedEndDate)
      return t('commentAutoReply.content.secondStep.endDateEmpty');
    if (selectedStartDate && selectedEndDate) {
      if (selectedEndDate.isBefore(selectedStartDate)) {
        return t('commentAutoReply.content.secondStep.endDateAfterStartDate');
      }
    }
    return '';
  }, [selectedStartDate, selectedEndDate, t]);

  const handleConfirm = useCallback(() => {
    handleStartSelect(selectedStartDate);
    handleEndSelect(selectedEndDate);
    closeModal();
  }, [
    handleStartSelect,
    handleEndSelect,
    selectedStartDate,
    selectedEndDate,
    closeModal,
  ]);

  useEffect(() => {
    if (startDate && endDate) {
      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);
    }
  }, [startDate, endDate]);

  return (
    <Dialog
      size="s"
      title={t('commentAutoReply.drawer.replyPeriod.modal.title')}
      confirmBtnText={t('common.done')}
      cancelBtnText={t('common.cancel')}
      open={modalType === ModalTypesEnum.THREADS_COMMENT_REPLY_DATE}
      handleClose={closeModal}
      handleConfirm={handleConfirm}
      disableConfirmButton={!selectedStartDate || !selectedEndDate}
    >
      <ModalContainerStyled>
        <Typography variant="body2" color="grey.900">
          {t('commentAutoReply.content.secondStep.subtitle1', {
            timezone: `${timezoneOffset >= 0 && '+'} ${timezoneOffset}`,
          })}
        </Typography>
        <DatePickerWrapperStyled>
          <DatePicker
            label={t('commentAutoReply.content.secondStep.startDateLabel')}
            disablePast
            value={selectedStartDate}
            onChange={(newValue: dayjs.Dayjs | null) => {
              if (newValue) {
                setSelectedStartDate(newValue);
              } else {
                setSelectedStartDate(null);
              }
            }}
            minDate={dayjs()}
            maxDate={selectedEndDate ? selectedEndDate : dayjs().add(1, 'year')}
            slotProps={{
              textField: {
                fullWidth: true,
                required: true,
                error: !!startDateError,
                helperText: startDateError,
              },
            }}
          />
          <TimePicker
            label={t('commentAutoReply.content.secondStep.startTimeLabel')}
            value={selectedStartDate || dayjs('12:00', 'HH:mm')}
            maxTime={
              selectedStartDate &&
              selectedEndDate &&
              selectedStartDate.diff(selectedEndDate) === 0
                ? selectedEndDate
                : dayjs('23:59', 'HH:mm')
            }
            onChange={(newValue: dayjs.Dayjs | null) => {
              if (newValue) {
                setSelectedStartDate(newValue);
              }
            }}
            slotProps={{
              textField: {
                required: true,
                fullWidth: true,
              },
            }}
          />
        </DatePickerWrapperStyled>
        <DatePickerWrapperStyled>
          <DatePicker
            label={t('commentAutoReply.content.secondStep.endDateLabel')}
            disablePast
            value={selectedEndDate}
            onChange={(newValue: dayjs.Dayjs | null) => {
              if (newValue) {
                setSelectedEndDate(newValue);
              } else {
                setSelectedEndDate(null);
              }
            }}
            minDate={selectedStartDate ? selectedStartDate : dayjs()}
            maxDate={dayjs().add(1, 'year')}
            slotProps={{
              textField: {
                required: true,
                fullWidth: true,
                error: !!endDateError,
                helperText: endDateError,
              },
            }}
          />
          <TimePicker
            label={t('commentAutoReply.content.secondStep.endTimeLabel')}
            minTime={
              selectedStartDate &&
              selectedEndDate &&
              selectedStartDate.diff(selectedEndDate) === 0
                ? selectedStartDate
                : dayjs('00:00', 'HH:mm')
            }
            value={selectedEndDate || dayjs('12:00', 'HH:mm')}
            onChange={(newValue: dayjs.Dayjs | null) => {
              if (newValue) {
                setSelectedEndDate(newValue);
              }
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                required: true,
              },
            }}
          />
        </DatePickerWrapperStyled>
      </ModalContainerStyled>
    </Dialog>
  );
};

export default CommentReplyDateModal;
