import { Dialog } from '@frontend/components/ui';
import { Alert } from '@frontend/components/utils';
import { useProjectLinked } from '@frontend/sorghum/data-access';
import { IGCtx, ThreadsCtx, UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum, SocialTypeEnum } from '@frontend/sorghum/interface';
import { PAGE_COMMENT_AUTO_REPLY, usePath } from '@frontend/sorghum/utils';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const ConnectAccountModal = ({
  socialType,
}: {
  socialType: SocialTypeEnum;
}) => {
  const { linkThreads } = useContext(ThreadsCtx);
  const { linkIG } = useContext(IGCtx);
  const [t] = useTranslation(
    socialType === SocialTypeEnum.INSTAGRAM ? 'instagram' : 'threads',
  );
  const { data, refetch } = useProjectLinked();
  const { navigateToProjectPage } = usePath();
  const { modalType, closeModal } = useContext(UICtx);

  useEffect(() => {
    const bc = new BroadcastChannel(
      socialType === SocialTypeEnum.INSTAGRAM
        ? 'instagram_connect'
        : 'threads_connect',
    );
    bc.onmessage = (event) => {
      if (event.data.isSuccess) {
        Alert.success(t('setting.connectSuccess'));
        refetch();
        closeModal();
      } else {
        Alert.error(t('setting.connectFailed'));
      }
    };

    return () => {
      bc.close();
    };
  }, [closeModal, refetch, socialType, t]);

  const handleCancel = useCallback(() => {
    navigateToProjectPage({ path: PAGE_COMMENT_AUTO_REPLY });
    closeModal();
  }, [closeModal, navigateToProjectPage]);

  if (
    (socialType === SocialTypeEnum.THREADS && data?.threads?.id) ||
    (socialType === SocialTypeEnum.INSTAGRAM && data?.ig?.id)
  )
    return null;

  return (
    <Dialog
      size="xs"
      closeBtn={false}
      title={t('modal.connect.title')}
      open={
        modalType === ModalTypesEnum.CONNECT_THREADS_MODAL ||
        modalType === ModalTypesEnum.CONNECT_INSTAGRAM_MODAL
      }
      content={t('modal.connect.description')}
      cancelBtnText={t('modal.connect.cancel')}
      confirmBtnText={t('modal.connect.confirm')}
      handleClose={handleCancel}
      handleConfirm={
        socialType === SocialTypeEnum.INSTAGRAM ? linkIG : linkThreads
      }
    />
  );
};

export default ConnectAccountModal;
