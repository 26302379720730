import {
  GetDataCollectUserAnswerParams,
  GetDataCollectUserAnswerResponse,
  ResponseWithData,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_COLLECT_USER_ANSWER } from '../apis';

const defaultData: ResponseWithData<GetDataCollectUserAnswerResponse> = {
  code: 400,
  msg: '',
  data: {
    commentReplyName: 'comment reply #1',
    questions: [],
  },
};

async function getDataCollectUserAnswer(
  params: GetDataCollectUserAnswerParams,
): Promise<ResponseWithData<GetDataCollectUserAnswerResponse>> {
  return axios
    .get(DATA_COLLECT_USER_ANSWER, { params })
    .then((res) => res.data)
    .catch((err) => {
      return {
        code: 400,
        msg: err,
        data: defaultData,
      };
    });
}

export function useGetDataCollectUserAnswer(
  params: GetDataCollectUserAnswerParams,
) {
  return useQuery({
    queryKey: ['get-data-collect-user-answer', params],
    queryFn: () => getDataCollectUserAnswer(params),
    enabled: !!params.projectChannelId,
  });
}
