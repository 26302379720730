import { dayjs } from '@frontend/sorghum/utils';
import { FC } from 'react';
import { Checkbox } from '../checkbox/checkbox';
import { Typography } from '../typography/typography';
import { ColumnType } from './table';

export interface CellProps {
  type: ColumnType;
  data: any;
}

export const Cell: FC<CellProps> = ({ type, data }) => {
  switch (type) {
    case 'DATE': {
      return (
        <Typography variant="body2" color="grey.800" sx={{ cursor: 'pointer' }}>
          {dayjs().diff(dayjs(data), 'hour') > 24
            ? dayjs(data).tz().format('YYYY-MM-DD')
            : dayjs(data).fromNow()}
        </Typography>
      );
    }
    case 'CHECKBOX': {
      return <Checkbox />;
    }
    default:
      return data;
  }
};
