import { TextHighlight, Tooltip, Typography } from '@frontend/components/ui';
import { useProjectPermission } from '@frontend/editor/data-access';
import { useGetAccountTutorial } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  CheckListStatusEnum,
  ContactType,
  FilterConditionsType,
  ModalTypesEnum,
  OrderByEnum,
  SocialTypeEnum,
  SortByEnum,
} from '@frontend/sorghum/interface';
import { dayjs, sendGAEvent } from '@frontend/sorghum/utils';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import {
  Box,
  Checkbox,
  Skeleton,
  Table,
  TableBody,
  TableRow,
} from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultPhoto from '../../images/default-headshot.png';
import {
  HeaderTableCellStyled,
  HeaderTableStickyHeaderStyled,
  HighlightContainerStyled,
  PhotoStyled,
  SortHeaderStyled,
  SubscribedTextStyled,
  TableCellStickyStyled,
  TableCellStyled,
  TableHeadContainerStyled,
  TableRowStyled,
  TextOverflowStyled,
} from '../styles';

export const useMessenger = () => {
  const socialType = SocialTypeEnum.FACEBOOK;
  const [t] = useTranslation('messenger');
  const { isViewer } = useProjectPermission();
  const { modalType, openModal } = useContext(UICtx);
  const { data: tutorialData } = useGetAccountTutorial();

  useEffect(() => {
    // 如果第一次進入此頁並且沒有開啟任何任務 modal 則自動開啟教程
    if (tutorialData?.marketingGuru) {
      if (!tutorialData?.marketingGuru.customer.hasInteracted) {
        if (modalType === ModalTypesEnum.CLOSE) {
          openModal(ModalTypesEnum.LEVEL2_LESSON1);
        }
      }
    }
  }, [modalType, openModal, tutorialData?.marketingGuru]);

  const PeopleTable = ({
    data,
    handleTableRowClick,
    filter,
    setFilter,
    checkList,
    setCheckList,
    isFetching,
  }: {
    data: ContactType[];
    handleTableRowClick: (id: string, socialType: SocialTypeEnum) => void;
    isFetching: boolean;
    filter: FilterConditionsType;
    setFilter: (filter: FilterConditionsType) => void;
    checkList: string[];
    setCheckList: (checkList: string[]) => void;
  }) => {
    const [hoverHeader, setHoverHeader] = useState<SortByEnum | undefined>();

    const handleSort = useCallback(
      (sortBy: SortByEnum) => {
        if (filter.sortBy === sortBy) {
          setFilter({
            ...filter,
            sortBy,
            orderBy:
              filter.orderBy === OrderByEnum.DESC
                ? OrderByEnum.ASC
                : OrderByEnum.DESC,
          });
        } else {
          setFilter({ ...filter, sortBy, orderBy: OrderByEnum.DESC });
        }

        switch (sortBy) {
          case SortByEnum.JOINED: {
            sendGAEvent(
              'Customers',
              'Joined - sort',
              'Customers - table - Joined - sort - click',
              '',
            );
            break;
          }
          case SortByEnum.LAST_ACTIVE: {
            sendGAEvent(
              'Customers',
              'Last active - sort',
              'Customers - table - Last active - sort - click',
              '',
            );
            break;
          }
        }
      },
      [filter, setFilter],
    );

    const handleAllCheckBoxChecked = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const checkListSet = new Set(checkList);
        if (checked) {
          data?.forEach((item) => {
            checkListSet.add(item.id);
          });
          sendGAEvent(
            'Customers',
            'Table_checkbox_check',
            'Customers - Table_checkbox_check - click',
            '',
          );
        } else {
          data?.forEach((item) => {
            checkListSet.delete(item.id);
          });
          sendGAEvent(
            'Customers',
            'Table_checkbox_uncheck',
            'Customers - Table_checkbox_uncheck - click',
            '',
          );
        }
        setCheckList([...checkListSet]);
      },
      [checkList, data, setCheckList],
    );

    const handleChecked = useCallback(
      (id: string, checked: boolean) => {
        const checkListSet = new Set(checkList);
        if (checked) {
          checkListSet.add(id);
        } else {
          checkListSet.delete(id);
        }
        setCheckList([...checkListSet]);
      },
      [checkList, setCheckList],
    );

    const checkListStatus = useMemo(() => {
      if (!data) return CheckListStatusEnum.NONE;
      if (data.length <= 0) return CheckListStatusEnum.NONE;
      let isAllChecked = true;
      let isChecked = false;
      const checkListSet = new Set(checkList);
      data.forEach((item) => {
        if (checkListSet.has(item.id)) {
          isChecked = true;
        } else {
          isAllChecked = false;
        }
      });

      if (isAllChecked) {
        return CheckListStatusEnum.ALL;
      } else if (isChecked) {
        return CheckListStatusEnum.PART;
      } else {
        return CheckListStatusEnum.NONE;
      }
    }, [checkList, data]);

    // set chip and condition filter from url
    const SortIcon = filter.orderBy === OrderByEnum.ASC ? NorthIcon : SouthIcon;

    return (
      <Table stickyHeader>
        <TableHeadContainerStyled>
          <TableRow>
            <HeaderTableStickyHeaderStyled $socialType={socialType}>
              <Box>
                {/* 保留 Checkbox，因為這是 Facebook 特有的功能 */}
                <Checkbox
                  color="primary"
                  indeterminate={checkListStatus === CheckListStatusEnum.PART}
                  checked={checkListStatus === CheckListStatusEnum.ALL}
                  onChange={handleAllCheckBoxChecked}
                  disabled={isViewer}
                />
                {/* avatar */}
                <Box width={40} height={40}></Box>
                <Typography>{t('people.table.header.name')}</Typography>
              </Box>
            </HeaderTableStickyHeaderStyled>
            <HeaderTableCellStyled width={250}>
              {t('people.table.header.messengerID')}
            </HeaderTableCellStyled>
            <HeaderTableCellStyled
              width={160}
              onClick={() => handleSort(SortByEnum.JOINED)}
              onMouseEnter={() => setHoverHeader(SortByEnum.JOINED)}
              onMouseLeave={() => setHoverHeader(undefined)}
              className="clickable"
            >
              <SortHeaderStyled>
                {t('people.table.header.subscribed')}
                {/* display sort icon on sort */}
                {filter.sortBy === SortByEnum.JOINED && <SortIcon />}
                {/* display sort icon on hover but inactive */}
                {hoverHeader === SortByEnum.JOINED &&
                  filter.sortBy !== SortByEnum.JOINED && <SouthIcon />}
              </SortHeaderStyled>
            </HeaderTableCellStyled>
            <HeaderTableCellStyled
              width={160}
              onMouseEnter={() => setHoverHeader(SortByEnum.LAST_ACTIVE)}
              onMouseLeave={() => setHoverHeader(undefined)}
              onClick={() => handleSort(SortByEnum.LAST_ACTIVE)}
              className="clickable"
            >
              <SortHeaderStyled>
                {t('people.table.header.lastActive')}
                {/* display sort icon on sort */}
                {filter.sortBy === SortByEnum.LAST_ACTIVE && <SortIcon />}
                {/* display sort icon on hover but inactive */}
                {hoverHeader === SortByEnum.LAST_ACTIVE &&
                  filter.sortBy !== SortByEnum.LAST_ACTIVE && <SouthIcon />}
              </SortHeaderStyled>
            </HeaderTableCellStyled>
            <HeaderTableCellStyled width={160}>
              {t('people.table.header.notification')}
            </HeaderTableCellStyled>
          </TableRow>
        </TableHeadContainerStyled>
        {/* loading */}
        {isFetching && (
          <TableBody>
            <TableRow>
              <TableCellStickyStyled $socialType={socialType}>
                <Box>
                  <Box width={30} height={32} />
                  {/* Avatar */}
                  <Skeleton
                    variant="circular"
                    width={32}
                    height={32}
                    animation="wave"
                  />
                  {/* Name */}
                  <Skeleton variant="text" width={160} animation="wave" />
                </Box>
              </TableCellStickyStyled>
              <TableCellStyled>
                {/* Social ID */}
                <Skeleton variant="text" width={100} animation="wave" />
              </TableCellStyled>
              {/* Subscription */}
              <TableCellStyled>
                <Skeleton variant="text" width={160} animation="wave" />
              </TableCellStyled>
              {/* Joined */}
              <TableCellStyled>
                <Skeleton variant="text" width={160} animation="wave" />
              </TableCellStyled>
              {/* Last Active */}
              <TableCellStyled>
                <Skeleton variant="text" width={160} animation="wave" />
              </TableCellStyled>
            </TableRow>
          </TableBody>
        )}
        {!isFetching && data && (
          <TableBody>
            {data.map((item: ContactType) => {
              if (item) {
                const _checked = !!checkList.find((id) => id === item.id);

                return (
                  <TableRowStyled
                    key={item.id}
                    id={`table-row-${item.id}`}
                    $checked={_checked}
                    onClick={() => {
                      handleTableRowClick(item.id, socialType);
                    }}
                  >
                    <TableCellStickyStyled $socialType={socialType}>
                      <Box>
                        <Box onClick={(e) => e.stopPropagation()}>
                          <Checkbox
                            id={`checkbox-${item.id}`}
                            checked={_checked}
                            color="primary"
                            onChange={(e, checked) => {
                              handleChecked(item.id, checked);
                            }}
                            disabled={isViewer}
                          />
                        </Box>
                        {/* Avatar */}
                        <PhotoStyled
                          sx={{
                            backgroundImage: item.headshot
                              ? `url('${item.headshot}')`
                              : `url('${DefaultPhoto}')`,
                          }}
                        />
                        {/* Name */}
                        <Tooltip
                          placement="top"
                          title={
                            <Typography variant="notoSans">
                              {item.name
                                ? item.name
                                : t('people.userInfo.nameUnauthorizedText')}
                            </Typography>
                          }
                          disabled={!item.name}
                        >
                          <TextOverflowStyled>
                            <TextHighlight
                              text={item.name || '-'}
                              // highlight={filter.search}
                              Container="p"
                              HighlightContainer={({ children }) => (
                                <HighlightContainerStyled
                                  component="span"
                                  variant="body2"
                                >
                                  {children}
                                </HighlightContainerStyled>
                              )}
                              TextContainer={({ children }) => (
                                <Typography
                                  sx={{
                                    border: '1px solid transparent',
                                  }}
                                  component="span"
                                  variant="body2"
                                  color="grey.700"
                                >
                                  {children}
                                </Typography>
                              )}
                            />
                          </TextOverflowStyled>
                        </Tooltip>
                      </Box>
                    </TableCellStickyStyled>
                    {/* social ID */}
                    <TableCellStyled>
                      <Tooltip
                        placement="top"
                        title={
                          <Typography variant="notoSans">
                            {item.socialId}
                          </Typography>
                        }
                      >
                        <TextOverflowStyled>
                          <Typography variant="body2" color="grey.700">
                            {item.socialId}
                          </Typography>
                        </TextOverflowStyled>
                      </Tooltip>
                    </TableCellStyled>
                    {/* Subscription */}
                    <TableCellStyled>
                      <TextOverflowStyled>
                        {item.notification ? (
                          <SubscribedTextStyled $subscribed={true}>
                            <Typography variant="body3" color="primary.main">
                              {t('people.table.cell.subscribed')}
                            </Typography>
                          </SubscribedTextStyled>
                        ) : (
                          <SubscribedTextStyled $subscribed={false}>
                            <Typography variant="body3" color="bluegrey.700">
                              {t('people.table.cell.unsubscribed')}
                            </Typography>
                          </SubscribedTextStyled>
                        )}
                      </TextOverflowStyled>
                    </TableCellStyled>
                    {/* Joined */}
                    <TableCellStyled>
                      <Typography variant="body2" color="grey.500">
                        {item.joined
                          ? dayjs(item.joined).format('YYYY/MM/DD')
                          : '-'}
                      </Typography>
                    </TableCellStyled>
                    {/* Last active */}
                    <TableCellStyled>
                      <Typography variant="body2" color="grey.500">
                        {item.lastActive
                          ? dayjs(item.lastActive).format('YYYY/MM/DD')
                          : '-'}
                      </Typography>
                    </TableCellStyled>
                  </TableRowStyled>
                );
              } else return null;
            })}
          </TableBody>
        )}
      </Table>
    );
  };

  return {
    socialType,
    t,
    PeopleTable,
  };
};
