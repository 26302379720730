import { globalTheme } from '@frontend/components/external-providers';
import { TextField, Typography } from '@frontend/components/ui';
import { uuid } from '@frontend/components/utils';
import { useProjectPermission } from '@frontend/editor/data-access';
import {
  useGetCommentReply,
  useGetCommentReplyConditionV2,
  usePatchCommentReplyCondition,
  usePatchCommentReplyConditionV2,
} from '@frontend/sorghum/data-access';
import { SocialTypeEnum } from '@frontend/sorghum/interface';
import { sendGAEvent, useCustomizedTranslation } from '@frontend/sorghum/utils';
import AddIcon from '@mui/icons-material/Add';
import MessageIcon from '@mui/icons-material/Message';
import { Box, styled } from '@mui/material';
import { get } from 'lodash';
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NodeProps, Position, useViewport } from 'reactflow';
import Button from '../button/button';
import EditorBlockBorder from '../editor-block-border/editor-block-border';
import EditorBlockContainer from '../editor-block-container/editor-block-container';
import EditorFloatPanel from '../editor-float-panel/editor-float-panel';
import { HandlePoint } from '../handle-point/handle-point';
import NodeHeader from '../node-header/node-header';
import { useNodeInstagramCommentReplyBlock } from '../node-instagram-comment-reply-block/use-node-instagram-comment-reply-block';

const BodyWrapperStyled = styled(Box)(({ theme }) => ({
  width: '316px',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 12px 12px 12px',
  gap: '10px',
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  '& fieldset': {
    borderColor: `transparent`,
  },
  '& .MuiOutlinedInput-root.Mui-disabled fieldset': {
    borderColor: `transparent`,
  },

  '& .Mui-disabled': {
    '-webkit-text-fill-color': theme.palette['grey'][900],
  },
}));

const ElementWrapperStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: '12px',
  borderRadius: '8px',
  background: theme.palette['grey'][100],
}));

const InfoWrapperStyled = styled(Box)(({ theme }) => ({
  padding: '12px',
  borderRadius: '8px',
  background: theme.palette['info']['light'],
}));

interface ItemInputProps {
  id: string;
  text: string;
  disabled: boolean;
  handleChange: (id: string, text: string) => void;
  placeholder: string;
}

const ItemInput: FC<ItemInputProps> = ({
  id,
  text,
  disabled,
  placeholder,
  handleChange,
}: ItemInputProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(text);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  return (
    <TextFieldStyled
      multiline
      fullWidth
      defaultValue={text}
      color="info"
      disabled={disabled}
      inputProps={{
        ref,
        maxLength: 500,
      }}
      size="small"
      error={value.length <= 0}
      onChange={onChange}
      onBlur={(e) => handleChange(id, e.target.value)}
      placeholder={placeholder}
    />
  );
};

export const NodePublicReplyBlock = ({ id, selected, data }: NodeProps) => {
  const { t } = useCustomizedTranslation(data.socialType);
  const { commentAutoReplyID } = useParams();
  const { zoom } = useViewport();
  const { data: commentAutoReplyData } = useGetCommentReply(
    commentAutoReplyID as string,
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const { isViewer } = useProjectPermission();
  const conditionID = get(commentAutoReplyData, 'replyCondition.data.0.id', '');
  const { data: commentAutoReplyConditionV2 } =
    useGetCommentReplyConditionV2(conditionID);
  const { handleBlockClick } = useNodeInstagramCommentReplyBlock(
    commentAutoReplyID as string,
  );

  const commentAutoReplyCondition = commentAutoReplyConditionV2;

  const { mutate: updateConditionV1 } = usePatchCommentReplyCondition();
  const { mutate: updateConditionV2 } = usePatchCommentReplyConditionV2();

  const updateCondition =
    data.socialType === SocialTypeEnum.INSTAGRAM
      ? updateConditionV2
      : updateConditionV1;

  const [hoverID, setHoverID] = useState<string | undefined>();
  const [contents, setContents] = useState<{ text: string; id: string }[]>([]);

  const isActive = commentAutoReplyData?.isActive;

  const isPublicReplyActive =
    commentAutoReplyCondition?.replyContentType === 1 ||
    commentAutoReplyCondition?.replyContentType === 3;

  const handleChange = useCallback(
    (id: string, newValue: string) => {
      if (!commentAutoReplyCondition || isViewer) return;

      setContents((prev) => {
        const newContents = prev.map((item) => {
          if (item.id === id) return { ...item, text: newValue };
          return item;
        });
        updateCondition({
          id: commentAutoReplyID as string,
          isAutoSave: true,
          replyCondition: [
            {
              ...commentAutoReplyCondition,
              isWarning: false,
              publicReplyContent: newContents.map((i) => i.text),
            },
          ],
        });
        return newContents;
      });
    },
    [commentAutoReplyCondition, commentAutoReplyID, isViewer, updateCondition],
  );

  const handleAddClick = useCallback(() => {
    if (!commentAutoReplyCondition) return;

    setContents((prev) => {
      const newContents = [...prev, { text: '', id: uuid() }];
      updateCondition({
        id: commentAutoReplyID as string,
        isAutoSave: true,
        replyCondition: [
          {
            ...commentAutoReplyCondition,
            isWarning: false,
            publicReplyContent: newContents.map((i) => i.text),
          },
        ],
      });
      return newContents;
    });
  }, [commentAutoReplyCondition, commentAutoReplyID, updateCondition]);

  const handleRemoveClick = useCallback(
    (index: number) => {
      if (!commentAutoReplyCondition) return;

      setContents((prev) => {
        const newContents = prev.filter((_, i) => i !== index);
        updateCondition({
          id: commentAutoReplyID as string,
          isAutoSave: true,
          replyCondition: [
            {
              ...commentAutoReplyCondition,
              isWarning: false,
              publicReplyContent: newContents.map((i) => i.text),
            },
          ],
        });
        return newContents;
      });
    },
    [commentAutoReplyCondition, commentAutoReplyID, updateCondition],
  );

  const handleSwitchClick = useCallback(() => {
    if (!commentAutoReplyCondition || isViewer) return;
    let newType = 1;

    switch (commentAutoReplyCondition?.replyContentType) {
      case 1: {
        newType = 2;
        break;
      }
      case 2: {
        newType = 1;
        break;
      }
      case 3: {
        newType = 4;
        break;
      }
      case 4: {
        newType = 3;
        break;
      }
    }

    sendGAEvent(
      'comment auto reply',
      'public reply-switch',
      'comment auto reply-public reply-switch-click',
      isActive ? '0' : '1',
    );

    updateCondition({
      id: commentAutoReplyID as string,
      isAutoSave: true,
      replyCondition: [
        {
          ...commentAutoReplyCondition,
          isWarning: false,
          replyContentType: newType,
        },
      ],
    });
  }, [
    commentAutoReplyCondition,
    commentAutoReplyID,
    isViewer,
    isActive,
    updateCondition,
  ]);

  useEffect(() => {
    if (
      !isLoaded &&
      conditionID &&
      commentAutoReplyCondition?.publicReplyContent
    ) {
      setContents(
        commentAutoReplyCondition?.publicReplyContent.map((i) => ({
          text: i,
          id: uuid(),
        })),
      );
      setIsLoaded(true);
    }
  }, [commentAutoReplyCondition, conditionID, isLoaded]);

  return (
    <EditorBlockContainer nodeID={id} onClick={handleBlockClick}>
      <HandlePoint
        id="1"
        type="target"
        position={Position.Left}
        styles={{
          top: '20px',
        }}
        isConnected={false}
      />
      <EditorBlockBorder
        zoom={zoom}
        nodeID={id}
        selected={selected}
        color={
          isPublicReplyActive
            ? get(globalTheme, 'palette.info.main', '')
            : get(globalTheme, 'palette.grey.50', '')
        }
      >
        <NodeHeader
          color={globalTheme?.palette?.['primary']?.['main'] as string}
          disabled={!isPublicReplyActive}
          background={globalTheme.palette?.['blue'][50]}
          icon={<MessageIcon fontSize="small" />}
          title={t('commentReplyDetail.publicReply.title')}
          readonly
          // IG 才能關閉回覆
          {...(data.socialType === SocialTypeEnum.INSTAGRAM && {
            switchProps: {
              checked: isPublicReplyActive,
              disabled: commentAutoReplyData?.isActive || isViewer,
              onMouseDown: handleSwitchClick,
            },
          })}
        />
        {isPublicReplyActive && (
          <BodyWrapperStyled>
            <InfoWrapperStyled>
              <Typography variant="body2" color="info.dark">
                {t('commentReplyDetail.publicReply.info')}
              </Typography>
            </InfoWrapperStyled>
            {contents.map((item, index) => {
              return (
                <ElementWrapperStyled
                  key={`element-${item.id}`}
                  onMouseEnter={() => setHoverID(item.id)}
                  onMouseLeave={() => setHoverID(undefined)}
                >
                  {hoverID === item.id &&
                    !isActive &&
                    !isViewer &&
                    contents.length > 1 && (
                      <EditorFloatPanel
                        index={index}
                        draggable={false}
                        direction="column"
                        handleDelete={() => handleRemoveClick(index)}
                        backgroundColor={globalTheme?.palette?.['grey']?.[100]}
                      />
                    )}
                  <ItemInput
                    id={item.id}
                    disabled={!!isActive || isViewer}
                    text={item.text}
                    handleChange={handleChange}
                    placeholder={t(
                      'commentReplyDetail.publicReply.placeholder',
                    )}
                  />
                </ElementWrapperStyled>
              );
            })}
            {contents.length < 20 && !isActive && !isViewer && (
              <Button
                onClick={handleAddClick}
                startIcon={<AddIcon fontSize="small" />}
                variant="outlined"
                color="bluegrey300"
                dash
                fullWidth
                sx={{ textTransform: 'none' }}
              >
                {t('commentReplyDetail.publicReply.add')}
              </Button>
            )}
          </BodyWrapperStyled>
        )}
      </EditorBlockBorder>
    </EditorBlockContainer>
  );
};

export default memo(NodePublicReplyBlock);
