import { globalTheme } from '@frontend/components/external-providers';
import { AddBlockIcon, TourHighlight } from '@frontend/components/ui';
import {
  TOUR_ONBOARDING_TEMPLATE_PROPS,
  useCanvasFlow,
  useCanvasTemplate,
  useCanvasView,
} from '@frontend/editor/data-access';
import { EditorCtx } from '@frontend/editor/external-providers';
import { CANVAS_ZOOM_LEVEL } from '@frontend/editor/utils';
import { UICtx } from '@frontend/sorghum/external-providers';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkRemoveOutlinedIcon from '@mui/icons-material/BookmarkRemoveOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import CommentBankOutlinedIcon from '@mui/icons-material/CommentBankOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import TextIcon from '@mui/icons-material/FormatColorText';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import LabelOffOutlinedIcon from '@mui/icons-material/LabelOffOutlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { Box, Button, Popper } from '@mui/material';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ReactFlow, { NodeChange } from 'reactflow';
import 'reactflow/dist/style.css';
import styled from 'styled-components';
import ConnectionLineComponent from '../connection-line-component/connection-line-component';
import DrawerTourAction from '../drawer-tour-action/drawer-tour-action';
import CustomEdge, {
  CustomFocusMarker,
  CustomMarker,
} from '../editor-custom-edge/editor-custom-edge';
import EditorMenu from '../editor-menu/editor-menu';
import { RedeemCouponIcon, SendCouponIcon, ShowCouponIcon } from '../icons';
import NodeBlock from '../node-block/node-block';
import NodeCondition from '../node-condition/node-condition';
import NodeCoupon from '../node-coupon/node-coupon';
import NodeCreateBlockMenu from '../node-create-block-menu/node-create-block-menu';
import { NodeJumpToFlow } from '../node-jump-to-flow/node-jump-to-flow';
import NodeNote from '../node-note/node-note';
import NodeShareLink from '../node-share-link/node-share-link';
import NodeTourAction from '../node-tour-action/node-tour-action';
import NodeTourEntryPoint from '../node-tour-entry-point/node-tour-entry-point';

const nodeTypes = {
  BLOCK: NodeBlock,
  ENTRY_POINT: NodeTourEntryPoint,
  CREATE_MENU: NodeCreateBlockMenu,
  SHARE_LINK: NodeShareLink,
  CONDITION: NodeCondition,
  COUPON: NodeCoupon,
  ACTION: NodeTourAction,
  NOTE: NodeNote,
  JUMP_TO_FLOW: NodeJumpToFlow,
};

const edgeTypes = {
  CUSTOM: CustomEdge,
};

const FlowWrapperStyled = styled(Box)<{
  $zoom: number;
  $isConnecting: boolean;
}>(({ theme, $zoom, $isConnecting }) => ({
  width: '100%',
  position: 'relative',
  flex: 1,
  '& .react-flow__node': {
    outline: 'none',
  },
  '& .react-flow__pane': {
    // 避免新增 node 時超出畫面導致跑版
    overflow: 'hidden',
    ...($isConnecting && {
      cursor: 'grabbing',
    }),
  },
  '& .react-flow__edge': {
    '&.selected': {
      '& .react-flow__edge-path': {
        stroke: theme.palette['blue']['600'],
      },
    },
  },
  background: theme.palette['grey']['200'],

  fontSize: `${28 / $zoom}px`,
}));

const AddButtonContainerStyled = styled(Box)(({ theme }) => ({
  top: 0,
  right: '20px',
  display: 'flex',
  width: '36px',
  padding: '0',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'absolute',
  zIndex: 5,
}));

export const TourOnboardingFlow: FC<{
  googleStorageEndpoint: string;
  nodesConnectable: boolean;
}> = ({ googleStorageEndpoint, nodesConnectable }) => {
  const {
    nodes,
    edges,
    zoom,
    isConnecting,
    onConnectStart,
    onConnectEnd,
    onNodesChange,
    handleEdgeDelete,
    handleNodesDelete,
  } = useCanvasFlow();

  const { focusNode } = useCanvasView();
  const { addTourOnboardingRN } = useCanvasTemplate();

  const [t] = useTranslation();
  const state = useContext(EditorCtx);
  const UIState = useContext(UICtx);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const addButtonRef = useRef<HTMLButtonElement>(null);

  const addRN = useCallback(() => {
    setIsMenuOpen(false);
    addTourOnboardingRN(googleStorageEndpoint);
    UIState.setIsTouring(false);
    setTimeout(
      () => focusNode(TOUR_ONBOARDING_TEMPLATE_PROPS.RN_BLOCK_ID),
      300,
    );
    setTimeout(UIState.nextTour, 1200);
    setTimeout(() => UIState.setIsTouring(true), 1200);
  }, [UIState, addTourOnboardingRN, focusNode, googleStorageEndpoint]);

  const handleAddButtonClick = useCallback(() => {
    // 只有任務階段到 add_block 的時候才能點擊
    if (UIState.tour?.targets?.includes('add_block')) {
      setIsMenuOpen(true);
      setTimeout(UIState.nextTour, 0);
    }
  }, [UIState.nextTour, UIState.tour?.targets]);

  const editorMenuItems = [
    {
      title: t('canvas.contentTitle'),
      dropdownItems: [
        {
          id: 'option_text_button',
          icon: <TextIcon color="primary" />,
          title: t('canvas.blockMenu.contentButton'),
        },
        {
          id: 'option_image',
          icon: <ImageOutlinedIcon color="primary" />,
          title: t('canvas.blockMenu.image'),
        },
        {
          id: 'option_gallery',
          icon: <CollectionsOutlinedIcon color="primary" />,
          title: t('canvas.blockMenu.gallery'),
        },
        {
          id: 'option_theme_subscription',
          icon: <NotificationsNoneIcon color="primary" />,
          title: t('canvas.blockMenu.recurringNotificationsButton'),
          onClick: addRN,
        },
        {
          id: 'option_collect_user_answer',
          icon: <CommentBankOutlinedIcon color="primary" />,
          title: t('canvas.blockMenu.collectUserAnswerButton'),
        },
      ],
    },
    {
      title: t('canvas.marketingTool'),
      dropdownItems: [
        {
          id: 'option_send_coupon',
          icon: (
            <SendCouponIcon
              sx={{
                color: globalTheme.palette?.grass?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.sendCoupon'),
        },
        {
          id: 'option_show_coupon',
          icon: (
            <ShowCouponIcon
              sx={{
                color: globalTheme.palette?.grass?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.showCoupon'),
        },
        {
          id: 'option_redeem_coupon',
          icon: (
            <RedeemCouponIcon
              sx={{
                color: globalTheme.palette?.grass?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.redeemCoupon'),
        },
      ],
    },
    {
      title: t('canvas.blockMenu.action'),
      dropdownItems: [
        {
          id: 'option_set_attribute',
          icon: (
            <BookmarkBorderOutlinedIcon
              sx={{
                color: globalTheme.palette?.purple?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.setAttribute'),
        },
        {
          id: 'option_remove_attribute',
          icon: (
            <BookmarkRemoveOutlinedIcon
              sx={{
                color: globalTheme.palette?.purple?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.removeAttribute'),
        },
        {
          id: 'option_subscribe_sequence',
          icon: (
            <LabelOutlinedIcon
              sx={{
                color: globalTheme.palette?.purple?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.subscribeSequence'),
        },
        {
          id: 'option_unsubscribe_sequence',
          icon: (
            <LabelOffOutlinedIcon
              sx={{
                color: globalTheme.palette?.purple?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.unsubscribeSequence'),
        },
        {
          id: 'option_google_sheet',
          icon: (
            <TableChartOutlinedIcon
              sx={{
                color: globalTheme.palette?.purple?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.exportGoogleSheet'),
        },
      ],
    },
    {
      title: t('canvas.condition.title'),
      dropdownItems: [
        {
          id: 'option_condition',
          icon: (
            <FilterAltOutlinedIcon
              sx={{
                color: globalTheme.palette?.orange?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.condition'),
        },
        {
          id: 'option_jump_to_flow',
          icon: (
            <AccountTreeOutlinedIcon
              sx={{
                color: globalTheme.palette?.orange?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.jumpToFlow'),
        },
      ],
    },
  ];

  const handleNodesChange = useCallback(
    (nodeChanges: NodeChange[]) => {
      onNodesChange(nodeChanges);

      // 第一次載入後 focus 至預設的第一個 block
      if (!isLoaded) {
        setIsLoaded(true);
        focusNode(
          TOUR_ONBOARDING_TEMPLATE_PROPS.FIRST_BLOCK_ID,
          CANVAS_ZOOM_LEVEL['0'],
          0,
        );
      }
    },
    [focusNode, isLoaded, onNodesChange],
  );

  const handleConnectEnd = useCallback(
    (event: MouseEvent | TouchEvent) => {
      onConnectEnd(event, false);
      const target = event.target as HTMLElement;
      if (target.id === TOUR_ONBOARDING_TEMPLATE_PROPS.FIRST_BLOCK_ID)
        UIState.nextTour();
    },
    [UIState, onConnectEnd],
  );

  useEffect(() => {
    // 教程模式
    if (!isLoaded) {
      state.setTourMode(true);
    }
  }, [isLoaded, state]);

  return (
    <FlowWrapperStyled
      ref={state.ref}
      $zoom={zoom}
      $isConnecting={isConnecting}
    >
      <DrawerTourAction />
      <ReactFlow
        panActivationKeyCode={null}
        zoomActivationKeyCode={null}
        nodes={nodes}
        edges={edges}
        onConnectEnd={handleConnectEnd}
        onConnectStart={onConnectStart}
        onClickConnectStart={onConnectStart}
        onNodesChange={handleNodesChange}
        onNodesDelete={handleNodesDelete}
        onEdgesDelete={handleEdgeDelete}
        nodeTypes={nodeTypes}
        // 預設的 edge 樣式
        defaultEdgeOptions={{
          type: 'CUSTOM',
        }}
        nodesConnectable={nodesConnectable}
        edgeTypes={edgeTypes}
        // 設定拉線時的樣式
        connectionLineComponent={ConnectionLineComponent}
        draggable={false}
        panOnDrag={false}
        autoPanOnConnect={false}
        zoomOnPinch={false}
        nodesDraggable={false}
        preventScrolling={true}
        zoomOnScroll={false}
        zoomOnDoubleClick={false}
      />
      <AddButtonContainerStyled>
        <Button
          id="add_block"
          ref={addButtonRef}
          sx={{
            minWidth: '36px',
          }}
          color="primary"
          variant="contained"
          onClick={handleAddButtonClick}
        >
          <AddBlockIcon />
        </Button>
      </AddButtonContainerStyled>
      {isMenuOpen && (
        <Popper
          sx={{
            zIndex: 100,
          }}
          open={isMenuOpen}
          anchorEl={addButtonRef.current}
          placement="left-start"
          modifiers={[
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: 'document',
                padding: '8px',
              },
            },
          ]}
        >
          <EditorMenu
            id="add_block_menu"
            itemList={editorMenuItems}
            scrollDisabled={true}
          />
        </Popper>
      )}

      {isConnecting && (
        <TourHighlight target={TOUR_ONBOARDING_TEMPLATE_PROPS.FIRST_BLOCK_ID} />
      )}

      <CustomMarker />
      <CustomFocusMarker />
    </FlowWrapperStyled>
  );
};
