import { globalTheme } from '@frontend/components/external-providers';
import { Button } from '@frontend/components/ui';
import {
  useGetProjectID,
  useProjectPermission,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import { DrawerTypesEnum, ModalTypesEnum } from '@frontend/editor/interface';
import {
  useGetCommentReply,
  useGetCommentReplyConditionV2,
  useGetProject,
  usePatchCommentReplyCondition,
  usePatchCommentReplyConditionV2,
} from '@frontend/sorghum/data-access';
import {
  CommentReplyPostType,
  SocialTypeEnum,
} from '@frontend/sorghum/interface';
import { useCustomizedTranslation } from '@frontend/sorghum/utils';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { get } from 'lodash';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { EditorDrawer } from '../editor-drawer/editor-drawer';
import InstagramCommentReplyKeywordModal from '../instagram-comment-reply-keyword-modal/instagram-comment-reply-keyword-modal';
import ThreadsCommentReplyKeywordModal from '../threads-comment-reply-keyword-modal/threads-comment-reply-keyword-modal';
import {
  ApplyButtonStyled,
  ContainerStyled,
  ContentWrapperStyled,
  KeywordStyled,
  ReplyContentStyled,
  ReplyKeywordsWrapper,
  ReplyTitleWrapper,
  ReplyWrapperStyled,
  TitleWrapper,
} from './style';

export const DrawerCommentReplyConditions = () => {
  const { commentAutoReplyID } = useParams();
  const { setIsDrawerUpdate, setIsDrawerFullyUpdate } = useContext(EditorCtx);
  const { isViewer } = useProjectPermission();
  const { drawerType, openModal, closeModal, setDrawerType } =
    useContext(UICtx);

  const { data: projectID } = useGetProjectID();
  const { data: project } = useGetProject(projectID as string);
  const { t } = useCustomizedTranslation(project?.socialType);

  const { data: commentReplyDataFromAPI } = useGetCommentReply(
    commentAutoReplyID as string,
  );
  const { data: commentReplyConditionDataFromAPI } =
    useGetCommentReplyConditionV2(
      get(commentReplyDataFromAPI, 'replyCondition.data.0.id', ''),
    );

  const { mutate: updateConditionV1 } = usePatchCommentReplyCondition();
  const { mutate: updateConditionV2 } = usePatchCommentReplyConditionV2();

  const updateCondition =
    project?.socialType === SocialTypeEnum.INSTAGRAM
      ? updateConditionV2
      : updateConditionV1;

  const [replyConditionsRadio, setReplyConditionsRadio] = useState(1);
  const [replyKeywords, setReplyKeywords] = useState<string[]>([]);
  const [replyKeywordCompareType, setReplyKeywordCompareType] = useState<
    1 | 2 | 3 | 4
  >(1);
  const [replyMarkFriendCount, setReplyMarkFriendCount] = useState(0);

  const handleApply = useCallback(
    ({
      radio,
      keywords,
      compareType,
      friendCount,
    }: {
      radio?: number;
      keywords?: string[];
      compareType?: 1 | 2 | 3 | 4;
      friendCount?: number;
    } = {}) => {
      if (commentAutoReplyID && commentReplyConditionDataFromAPI) {
        const replyTo = radio ?? replyConditionsRadio;
        const keyword = keywords ?? replyKeywords;
        const keywordCompareType = compareType ?? replyKeywordCompareType;
        const markFriendCount = friendCount ?? replyMarkFriendCount;

        // 只有關鍵字
        if (
          project?.socialType === SocialTypeEnum.THREADS ||
          commentReplyDataFromAPI?.postType === CommentReplyPostType.LIVE ||
          commentReplyDataFromAPI?.postType === CommentReplyPostType.STORY
        ) {
          updateCondition({
            id: commentAutoReplyID,
            isAutoSave: false,
            replyCondition: [
              {
                ...commentReplyConditionDataFromAPI,
                replyTo,
                isWarning: false,
                ...(replyTo === 2 && {
                  condition: [
                    {
                      keywordCompareType: 2,
                      keyword,
                    },
                  ],
                }),
              },
            ],
          });
        } else {
          // 有可能有 tag 朋友數量
          updateCondition({
            id: commentAutoReplyID,
            isAutoSave: false,
            replyCondition: [
              {
                ...commentReplyConditionDataFromAPI,
                replyTo,
                isWarning: false,
                ...(replyTo === 2 && {
                  condition: [
                    {
                      keywordCompareType,
                      keyword,
                      markFriendCount,
                    },
                  ],
                }),
              },
            ],
          });
        }
      }

      setIsDrawerUpdate(false);
      setIsDrawerFullyUpdate(true);
      setDrawerType(DrawerTypesEnum.CLOSE);
      closeModal();
    },
    [
      closeModal,
      commentAutoReplyID,
      commentReplyConditionDataFromAPI,
      commentReplyDataFromAPI?.postType,
      project?.socialType,
      replyConditionsRadio,
      replyKeywordCompareType,
      replyKeywords,
      replyMarkFriendCount,
      setDrawerType,
      setIsDrawerFullyUpdate,
      setIsDrawerUpdate,
      updateCondition,
    ],
  );

  const handleRadioChange = useCallback(
    (val: 1 | 2) => {
      setReplyConditionsRadio(val);
      setIsDrawerUpdate(true, () => handleApply({ radio: val }));
    },
    [setIsDrawerUpdate, handleApply],
  );

  const handleThreadsSubmit = useCallback(
    (newValue: string[]) => {
      setReplyKeywords([...newValue]);
      setIsDrawerUpdate(true, () => handleApply({ keywords: [...newValue] }));
    },
    [setIsDrawerUpdate, handleApply],
  );

  const handleInstagramSubmit = useCallback(
    (newValue: {
      replyKeywords: string[];
      keywordCompareType: 1 | 2 | 3 | 4;
      markFriendCount: number;
    }) => {
      setReplyKeywords([...newValue.replyKeywords]);
      setReplyKeywordCompareType(newValue.keywordCompareType);
      setReplyMarkFriendCount(newValue.markFriendCount);

      setIsDrawerUpdate(true, () =>
        handleApply({
          keywords: [...newValue.replyKeywords],
          compareType: newValue.keywordCompareType,
          friendCount: newValue.markFriendCount,
        }),
      );
    },
    [setIsDrawerUpdate, handleApply],
  );

  const shouldApplyButtonDisabled = useMemo(() => {
    let shouldDisabled = false;

    if (replyConditionsRadio === 2) {
      if (replyKeywordCompareType === 2 && replyKeywords.length === 0)
        shouldDisabled = true;
      if (replyKeywordCompareType === 1 && replyMarkFriendCount <= 0)
        shouldDisabled = true;
    }

    if (isViewer) {
      shouldDisabled = true;
    }

    return shouldDisabled;
  }, [
    replyConditionsRadio,
    replyKeywordCompareType,
    replyMarkFriendCount,
    replyKeywords.length,
    isViewer,
  ]);

  const title = useMemo(() => {
    if (project?.socialType === SocialTypeEnum.THREADS) {
      return t('commentAutoReply.drawer.title');
    } else {
      switch (commentReplyDataFromAPI?.postType) {
        case CommentReplyPostType.POST: {
          return t('commentAutoReply.drawer.title.post');
        }
        case CommentReplyPostType.STORY: {
          return t('commentAutoReply.drawer.title.story');
        }
        case CommentReplyPostType.LIVE:
        default: {
          return t('commentAutoReply.drawer.title.live');
        }
      }
    }
  }, [commentReplyDataFromAPI?.postType, project?.socialType, t]);

  useEffect(() => {
    if (drawerType === DrawerTypesEnum.COMMENT_REPLY_CONDITIONS) {
      const keywords = get(
        commentReplyConditionDataFromAPI,
        'condition.0.keyword',
        [],
      );
      setReplyConditionsRadio(
        commentReplyConditionDataFromAPI
          ? commentReplyConditionDataFromAPI.replyTo
          : 1,
      );
      setReplyKeywords(keywords);
      setReplyMarkFriendCount(
        get(commentReplyConditionDataFromAPI, 'condition.0.markFriendCount', 0),
      );
      if (
        project?.socialType === SocialTypeEnum.INSTAGRAM &&
        commentReplyDataFromAPI?.postType === CommentReplyPostType.POST
      ) {
        setReplyKeywordCompareType(
          get(
            commentReplyConditionDataFromAPI,
            'condition.0.keywordCompareType',
            1,
          ),
        );
      } else {
        setReplyKeywordCompareType(2);
      }
      setIsDrawerUpdate(false);
    }
  }, [
    commentReplyConditionDataFromAPI,
    commentReplyDataFromAPI?.postType,
    drawerType,
    project?.socialType,
    setIsDrawerUpdate,
  ]);

  useEffect(() => {
    if (shouldApplyButtonDisabled) {
      setIsDrawerFullyUpdate(false);
    } else {
      setIsDrawerFullyUpdate(true);
    }
  }, [shouldApplyButtonDisabled, setIsDrawerFullyUpdate]);

  return (
    <EditorDrawer drawerType={DrawerTypesEnum.COMMENT_REPLY_CONDITIONS}>
      <ContainerStyled>
        <ContentWrapperStyled>
          <TitleWrapper>
            <Typography variant="h6" color="grey.900">
              {title}
            </Typography>
          </TitleWrapper>

          <ReplyContentStyled>
            <ReplyWrapperStyled>
              <ReplyTitleWrapper>
                <Typography variant="subtitle2" color="black">
                  {t('commentAutoReply.drawer.replyConditions.title')}
                </Typography>
              </ReplyTitleWrapper>
              <RadioGroup row value={replyConditionsRadio}>
                <FormControlLabel
                  value="1"
                  control={<Radio size="small" />}
                  label={
                    <Typography variant="body2">
                      {t('commentAutoReply.drawer.replyConditions.radio1')}
                    </Typography>
                  }
                  onClick={() => handleRadioChange(1)}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio size="small" />}
                  label={
                    <Typography variant="body2">
                      {t('commentAutoReply.drawer.replyConditions.radio2')}
                    </Typography>
                  }
                  onClick={() => handleRadioChange(2)}
                />
              </RadioGroup>
            </ReplyWrapperStyled>

            {replyConditionsRadio === 2 && (
              <ReplyWrapperStyled>
                <ReplyTitleWrapper>
                  <Typography variant="subtitle2" color="black">
                    {t('commentAutoReply.drawer.replyConditions.subtitle')}
                  </Typography>
                </ReplyTitleWrapper>

                {replyKeywordCompareType === 2 &&
                  replyKeywords &&
                  replyKeywords.length > 0 && (
                    <ReplyKeywordsWrapper>
                      <Typography variant="body3" color="black">
                        {t(
                          'commentAutoReply.drawer.replyConditions.subContext',
                        )}
                      </Typography>
                      {replyKeywords.map((item, index) => (
                        <KeywordStyled
                          key={index}
                          variant="outlined"
                          color="primary"
                          label={item}
                        />
                      ))}
                    </ReplyKeywordsWrapper>
                  )}
                {replyKeywordCompareType === 1 && replyMarkFriendCount > 0 && (
                  <Typography variant="body3" color="grey.900">
                    {t('commentAutoReply.drawer.replyConditions.tag', {
                      count: replyMarkFriendCount,
                    })}
                  </Typography>
                )}

                <Button
                  onClick={() =>
                    openModal(ModalTypesEnum.COMMENT_REPLY_KEYWORD)
                  }
                  size="medium"
                  variant="outlined"
                  fullWidth
                  sx={{
                    color: globalTheme.palette?.['bluegrey']?.[500],
                    border: `1px solid ${globalTheme.palette?.['bluegrey']?.[400]}`,
                  }}
                >
                  {t('commentAutoReply.drawer.replyConditions.button')}
                </Button>
              </ReplyWrapperStyled>
            )}
          </ReplyContentStyled>

          <ApplyButtonStyled
            onClick={() => handleApply()}
            size="large"
            variant="contained"
            fullWidth
            disabled={shouldApplyButtonDisabled}
            sx={{ height: '48px' }}
          >
            {t('commentAutoReply.drawer.saveButton')}
          </ApplyButtonStyled>
        </ContentWrapperStyled>
      </ContainerStyled>

      {project?.socialType === SocialTypeEnum.THREADS && (
        <ThreadsCommentReplyKeywordModal
          replyKeywords={replyKeywords}
          handleSubmit={handleThreadsSubmit}
        />
      )}
      {project?.socialType === SocialTypeEnum.INSTAGRAM && (
        <InstagramCommentReplyKeywordModal
          postType={
            commentReplyDataFromAPI?.postType || CommentReplyPostType.POST
          }
          keywordCompareType={replyKeywordCompareType}
          markFriendCount={replyMarkFriendCount}
          replyKeywords={replyKeywords}
          handleSubmit={handleInstagramSubmit}
        />
      )}
    </EditorDrawer>
  );
};

export default memo(DrawerCommentReplyConditions);
