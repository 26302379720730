import { TextHighlight, Tooltip, Typography } from '@frontend/components/ui';
import {
  ContactType,
  FilterConditionsType,
  SocialTypeEnum,
} from '@frontend/sorghum/interface';
import { dayjs } from '@frontend/sorghum/utils';
import { Box, Skeleton, Table, TableBody, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DefaultPhoto from '../../images/default-headshot.png';
import {
  HeaderTableCellStyled,
  HeaderTableStickyHeaderStyled,
  HighlightContainerStyled,
  PhotoStyled,
  TableCellStickyStyled,
  TableCellStyled,
  TableHeadContainerStyled,
  TableRowStyled,
  TextOverflowStyled,
} from '../styles';

export const useInstagram = () => {
  const socialType = SocialTypeEnum.INSTAGRAM;
  const [t] = useTranslation('instagram');

  const PeopleTable = ({
    data,
    handleTableRowClick,
    filter,
    setFilter,
    checkList,
    setCheckList,
    isFetching,
  }: {
    data: ContactType[];
    handleTableRowClick: (id: string, socialType: SocialTypeEnum) => void;
    isFetching: boolean;
    filter: FilterConditionsType;
    setFilter: (filter: FilterConditionsType) => void;
    checkList: string[];
    setCheckList: (checkList: string[]) => void;
  }) => (
    <Table stickyHeader>
      <TableHeadContainerStyled>
        <TableRow>
          <HeaderTableStickyHeaderStyled $socialType={socialType}>
            <Box>
              {/* avatar */}
              <Box width={40} height={40}></Box>
              <Typography>{t('people.table.header.name')}</Typography>
            </Box>
          </HeaderTableStickyHeaderStyled>
          <HeaderTableCellStyled width={250}>
            {t('people.table.header.instagramID')}
          </HeaderTableCellStyled>

          <HeaderTableCellStyled width={160}>
            {t('people.table.header.subscribed')}
          </HeaderTableCellStyled>
          <HeaderTableCellStyled width={160}>
            {t('people.table.header.lastActive')}
          </HeaderTableCellStyled>
          <HeaderTableCellStyled width={160}>
            {t('people.table.header.phone')}
          </HeaderTableCellStyled>
          <HeaderTableCellStyled width={300}>
            {t('people.table.header.email')}
          </HeaderTableCellStyled>
        </TableRow>
      </TableHeadContainerStyled>
      {/* loading */}
      {isFetching && (
        <TableBody>
          <TableRow>
            <TableCellStickyStyled $socialType={socialType}>
              <Box>
                {/* Avatar */}
                <Skeleton
                  variant="circular"
                  width={32}
                  height={32}
                  animation="wave"
                />
                {/* Name */}
                <Skeleton variant="text" width={160} animation="wave" />
              </Box>
            </TableCellStickyStyled>
            <TableCellStyled>
              {/* Social ID */}
              <Skeleton variant="text" width={100} animation="wave" />
            </TableCellStyled>
            {/* Subscription */}

            {/* Joined */}
            <TableCellStyled>
              <Skeleton variant="text" width={160} animation="wave" />
            </TableCellStyled>
            {/* Last Active */}
            <TableCellStyled>
              <Skeleton variant="text" width={160} animation="wave" />
            </TableCellStyled>
            {/* Phone number */}
            <TableCellStyled>
              <Skeleton variant="text" width={160} animation="wave" />
            </TableCellStyled>
            {/* Email */}
            <TableCellStyled>
              <Skeleton variant="text" width={160} animation="wave" />
            </TableCellStyled>
          </TableRow>
        </TableBody>
      )}
      {!isFetching && data && (
        <TableBody>
          {data.map((item: ContactType) => {
            if (item) {
              const _checked = !!checkList.find((id) => id === item.id);

              return (
                <TableRowStyled
                  key={item.id}
                  id={`table-row-${item.id}`}
                  $checked={_checked}
                  onClick={() => {
                    handleTableRowClick(item.id, socialType);
                  }}
                >
                  <TableCellStickyStyled $socialType={socialType}>
                    <Box>
                      {/* Avatar */}
                      <PhotoStyled
                        sx={{
                          backgroundImage: item.headshot
                            ? `url('${item.headshot}')`
                            : `url('${DefaultPhoto}')`,
                        }}
                      />
                      {/* Name */}
                      <Tooltip
                        placement="top"
                        title={
                          <Typography variant="notoSans">
                            {item.name
                              ? item.name
                              : t('people.userInfo.nameUnauthorizedText')}
                          </Typography>
                        }
                      >
                        <TextOverflowStyled>
                          <TextHighlight
                            text={item.name || '-'}
                            // highlight={filter.search}
                            Container="p"
                            HighlightContainer={({ children }) => (
                              <HighlightContainerStyled
                                component="span"
                                variant="body2"
                              >
                                {children}
                              </HighlightContainerStyled>
                            )}
                            TextContainer={({ children }) => (
                              <Typography
                                sx={{
                                  border: '1px solid transparent',
                                }}
                                component="span"
                                variant="body2"
                                color="grey.700"
                              >
                                {children}
                              </Typography>
                            )}
                          />
                        </TextOverflowStyled>
                      </Tooltip>
                    </Box>
                  </TableCellStickyStyled>
                  {/* social ID */}
                  <TableCellStyled>
                    <Tooltip
                      placement="top"
                      title={
                        <Typography variant="notoSans">
                          {item.socialId}
                        </Typography>
                      }
                    >
                      <TextOverflowStyled>
                        <Typography variant="body2" color="grey.700">
                          {item.socialId}
                        </Typography>
                      </TextOverflowStyled>
                    </Tooltip>
                  </TableCellStyled>

                  {/* Joined */}
                  <TableCellStyled>
                    <Typography variant="body2" color="grey.500">
                      {item.joined
                        ? dayjs(item.joined).format('YYYY/MM/DD')
                        : '-'}
                    </Typography>
                  </TableCellStyled>
                  {/* Last active */}
                  <TableCellStyled>
                    <Typography variant="body2" color="grey.500">
                      {item.lastActive
                        ? dayjs(item.lastActive).format('YYYY/MM/DD')
                        : '-'}
                    </Typography>
                  </TableCellStyled>
                  {/* Phone number */}
                  <TableCellStyled>
                    <Typography variant="body2" color="grey.500">
                      {item.phone ?? '-'}
                    </Typography>
                  </TableCellStyled>
                  {/* Email */}
                  <TableCellStyled>
                    <Typography variant="body2" color="grey.500">
                      {item.email ?? '-'}
                    </Typography>
                  </TableCellStyled>
                </TableRowStyled>
              );
            } else return null;
          })}
        </TableBody>
      )}
    </Table>
  );

  return {
    socialType,
    t,
    PeopleTable,
  };
};
