import { TFunction } from 'react-i18next';
import { OrderByEnum, SocialTypeEnum } from './Global.interface';
import { ContactType } from './PostPeopleList.interface';

export enum SortByEnum {
  JOINED = 'joined',
  LAST_ACTIVE = 'lastActive',
}

export interface FilterConditionsType {
  filterCriteria: 1 | 2;
  limit: number;
  sortBy?: SortByEnum | undefined;
  orderBy?: OrderByEnum;
  offset?: number;
}

export interface UsePagePeopleReturn {
  socialType: SocialTypeEnum;
  t: TFunction;
  PeopleTable: React.FC<{
    data: ContactType[];
    handleTableRowClick: (id: string, socialType: SocialTypeEnum) => void;
    isFetching: boolean;
    filter: FilterConditionsType;
    setFilter: (filter: FilterConditionsType) => void;
    checkList: string[];
    setCheckList: (checkList: string[]) => void;
  }>;
}
