import {
  CommentReplyPostType,
  GetCommentAutoReplyPost,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY_POSTS } from '../apis';

async function getCommentAutoReplyPost(
  postType: CommentReplyPostType,
): Promise<GetCommentAutoReplyPost[]> {
  return axios
    .get(`${COMMENT_REPLY_POSTS}`, { params: { postType } })
    .then((res) => {
      if (res.data.code === 20000) {
        return res.data.data;
      } else {
        return [];
      }
    })
    .catch((err) => {
      return [];
    });
}

export function useGetCommentAutoReplyPost(postType: CommentReplyPostType) {
  return useQuery<GetCommentAutoReplyPost[]>({
    queryKey: ['get-comment-auto-reply-post', { postType }],
    queryFn: () => getCommentAutoReplyPost(postType),
  });
}
