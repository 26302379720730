import { Tooltip, Typography } from '@frontend/components/ui';
import {
  convertNumberToPercentage,
  formatNumberWithCommas,
} from '@frontend/editor/utils';
import { usePostDataOperationBroadcastList } from '@frontend/sorghum/data-access';
import { dayjs, DRAFT, PAGE_BROADCAST, usePath } from '@frontend/sorghum/utils';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TableContainerWithPagination } from '../table-container/table-container';
import { ReactComponent as ChatBotIcon } from './images/icon-bot.svg';
import { ReactComponent as FacebookIcon } from './images/icon-facebook.svg';

interface Props {
  startDate: string;
  endDate: string;
}

const ContainerStyled = styled(Box)(({ theme }) => ({
  padding: '24px',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '20px',
  background: theme.palette['grey']['white'],
  boxShadow: theme.shadows[2],
}));

const AlignCenterContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  color: theme.palette['grey'][700],
}));

const TitleWithTooltipStyled = styled(Box)({
  display: 'inline-flex',
  alignItems: 'center',
});

const InfoIconStyled = styled(InfoIcon)(({ theme }) => ({
  width: '16px',
  height: '16px',
  marginLeft: '10px',
  color: theme.palette['grey'][700],
}));

export const Broadcast: FC<Props> = ({ startDate, endDate }: Props) => {
  const { navigateToProjectPage } = usePath();
  const [t] = useTranslation();
  const [page, setPage] = useState(0);
  const { data } = usePostDataOperationBroadcastList({
    startDate,
    endDate,
    offset: page * 3,
    limit: 5,
  });

  return (
    <ContainerStyled>
      <Typography component="div" variant="subtitle2" color="grey.800">
        {t('dataDashboard.operationAnalysis.broadcast.analysis.title')}
      </Typography>
      <TableContainerWithPagination
        pagination={{
          offset: data?.offset,
          totalCount: data?.totalCount,
          rowsPerPage: 3,
          setPage: setPage,
        }}
        searchText={
          data?.broadcasts && data?.broadcasts?.length > 0
            ? undefined
            : t('broadcast.sentEmptyText')
        }
        showPagination={data?.broadcasts && data?.broadcasts?.length > 0}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={80}></TableCell>
              <TableCell width={200}>
                <Typography color="bluegrey.700">
                  {t(
                    'dataDashboard.operationAnalysis.broadcast.analysis.table.name',
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="bluegrey.700">
                  {t(
                    'dataDashboard.operationAnalysis.broadcast.analysis.table.time',
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <TitleWithTooltipStyled>
                  <Typography color="bluegrey.700">
                    {t(
                      'dataDashboard.operationAnalysis.broadcast.analysis.table.sent.title',
                    )}
                  </Typography>

                  <Tooltip
                    placement="top"
                    title={t(
                      'dataDashboard.operationAnalysis.broadcast.analysis.table.sent.tooltip',
                    )}
                  >
                    <InfoIconStyled fontSize="small" />
                  </Tooltip>
                </TitleWithTooltipStyled>
              </TableCell>

              <TableCell>
                <TitleWithTooltipStyled>
                  <Typography color="bluegrey.700">
                    {t(
                      'dataDashboard.operationAnalysis.broadcast.analysis.table.delivered.title',
                    )}
                  </Typography>

                  <Tooltip
                    placement="top"
                    title={t(
                      'dataDashboard.operationAnalysis.broadcast.analysis.table.delivered.tooltip',
                    )}
                  >
                    <InfoIconStyled fontSize="small" />
                  </Tooltip>
                </TitleWithTooltipStyled>
              </TableCell>
              <TableCell>
                <TitleWithTooltipStyled>
                  <Typography color="bluegrey.700">
                    {t(
                      'dataDashboard.operationAnalysis.broadcast.analysis.table.opened.title',
                    )}
                  </Typography>

                  <Tooltip
                    placement="top"
                    title={t(
                      'dataDashboard.operationAnalysis.broadcast.analysis.table.opened.tooltip',
                    )}
                  >
                    <InfoIconStyled fontSize="small" />
                  </Tooltip>
                </TitleWithTooltipStyled>
              </TableCell>
              <TableCell>
                <TitleWithTooltipStyled>
                  <Typography color="bluegrey.700">
                    {t(
                      'dataDashboard.operationAnalysis.broadcast.analysis.table.clicked.title',
                    )}
                  </Typography>

                  <Tooltip
                    placement="top"
                    title={t(
                      'dataDashboard.operationAnalysis.broadcast.analysis.table.clicked.tooltip',
                    )}
                  >
                    <InfoIconStyled fontSize="small" />
                  </Tooltip>
                </TitleWithTooltipStyled>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.broadcasts?.map((item) => (
              <TableRow
                key={item.id}
                onClick={() => {
                  navigateToProjectPage({
                    path: `${PAGE_BROADCAST}/${item.id}/${DRAFT}`,
                  });
                }}
              >
                <TableCell>
                  <FacebookIcon />
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="bluegrey.700">
                    {item.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <AlignCenterContainerStyled>
                    <Tooltip
                      title={
                        item.timezoneType === 2
                          ? t('broadcast.timezoneUser')
                          : t('broadcast.timezoneBot')
                      }
                      placement="top"
                    >
                      {item.timezoneType === 2 ? (
                        <AccountCircleOutlinedIcon
                          sx={{
                            width: '16px',
                            height: '16px',
                            color: 'grey.500',
                          }}
                        />
                      ) : (
                        <ChatBotIcon />
                      )}
                    </Tooltip>
                    <Typography
                      sx={{
                        marginLeft: '10px',
                      }}
                      variant="body2"
                      color="grey.500"
                    >
                      {dayjs(item.sentAt).tz().format('YYYY/MM/DD, HH:mm')}
                    </Typography>
                  </AlignCenterContainerStyled>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="grey.500">
                    {item.uniqSent
                      ? formatNumberWithCommas(item.uniqSent)
                      : '-'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="grey.500">
                    {convertNumberToPercentage(item.uniqDeliveredP)}
                    {`(${formatNumberWithCommas(item.uniqDelivered)})`}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="grey.500">
                    {convertNumberToPercentage(item.uniqOpenedP)}
                    {`(${formatNumberWithCommas(item.uniqOpened)})`}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color="grey.500">
                    {convertNumberToPercentage(item.uniqClickedP)}
                    {`(${formatNumberWithCommas(item.uniqClicked)})`}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerWithPagination>
    </ContainerStyled>
  );
};

export default Broadcast;
