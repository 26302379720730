import { TextHighlight, Tooltip, Typography } from '@frontend/components/ui';
import {
  ContactType,
  FilterConditionsType,
  SocialTypeEnum,
} from '@frontend/sorghum/interface';
import { Box, Skeleton, Table, TableBody, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  HighlightContainerStyled,
  TableCellStyled,
  TableHeadContainerStyled,
  TableRowStyled,
  TextOverflowStyled,
} from '../styles';

export const useThreads = () => {
  const socialType = SocialTypeEnum.THREADS;
  const [t] = useTranslation('threads');

  const PeopleTable = ({
    data,
    handleTableRowClick,
    filter,
    setFilter,
    checkList,
    setCheckList,
    isFetching,
  }: {
    data: ContactType[];
    handleTableRowClick: (id: string, socialType: SocialTypeEnum) => void;
    isFetching: boolean;
    filter: FilterConditionsType;
    setFilter: (filter: FilterConditionsType) => void;
    checkList: string[];
    setCheckList: (checkList: string[]) => void;
  }) => {
    return (
      <Table stickyHeader>
        <TableHeadContainerStyled>
          <TableRow>
            <TableCellStyled>
              <Typography>{t('people.table.account')}</Typography>
            </TableCellStyled>
          </TableRow>
        </TableHeadContainerStyled>
        {/* loading */}
        {isFetching && (
          <TableBody>
            <TableRow>
              {/* account */}
              <TableCellStyled>
                <Skeleton variant="text" width={160} animation="wave" />
              </TableCellStyled>
            </TableRow>
          </TableBody>
        )}
        {!isFetching && data && (
          <TableBody>
            {data.map((item: ContactType) => {
              if (item) {
                return (
                  <TableRowStyled key={item.id} id={`table-row-${item.id}`}>
                    <TableCellStyled>
                      {/* account */}
                      <Box>
                        <Tooltip
                          placement="top"
                          title={
                            <Typography variant="notoSans">
                              {item.name}
                            </Typography>
                          }
                          disabled={!item.name}
                        >
                          <TextOverflowStyled>
                            <TextHighlight
                              text={item.name || '-'}
                              // highlight={filter.search}
                              Container="p"
                              HighlightContainer={({ children }) => (
                                <HighlightContainerStyled
                                  component="span"
                                  variant="body2"
                                >
                                  {children}
                                </HighlightContainerStyled>
                              )}
                              TextContainer={({ children }) => (
                                <Typography
                                  sx={{
                                    border: '1px solid transparent',
                                  }}
                                  component="span"
                                  variant="body2"
                                  color="grey.700"
                                >
                                  {children}
                                </Typography>
                              )}
                            />
                          </TextOverflowStyled>
                        </Tooltip>
                      </Box>
                    </TableCellStyled>
                  </TableRowStyled>
                );
              } else return null;
            })}
          </TableBody>
        )}
      </Table>
    );
  };

  return {
    socialType,
    t,
    PeopleTable,
  };
};
