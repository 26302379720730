import {
  Dialog,
  Select,
  SelectItem,
  TagOption,
  TagSelector,
  TextField,
} from '@frontend/components/ui';
import { Alert } from '@frontend/components/utils';
import { UICtx } from '@frontend/editor/external-providers';
import { ModalTypesEnum } from '@frontend/editor/interface';
import {
  useGetCommentReply,
  useGetCommentReplyConditionV2,
  useGetCommentReplyTemplateCategory,
  usePostAdminCommentReplyTemplate,
} from '@frontend/sorghum/data-access';
import { CommentReplyPostType } from '@frontend/sorghum/interface';
import { styled } from '@mui/system';
import { get } from 'lodash';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '../box/box';
import { Textarea } from '../textarea/textarea';

const ModalContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

interface TemplateType {
  categoryID: string;
  isDefault: boolean;
  shortDesc: string;
  longDesc: string;
  useCase: string;
  hashTag: string[];
  triggerType: CommentReplyPostType;
  recommend: boolean;
}

export const InstagramTemplateModal: FC = () => {
  const { closeModal, modalType } = useContext(UICtx);

  const { commentAutoReplyID } = useParams();
  const { data: commentReplyData } = useGetCommentReply(
    commentAutoReplyID || '',
  );
  const { data: conditionData } = useGetCommentReplyConditionV2(
    get(commentReplyData, 'replyCondition.data.0.id', ''),
  );

  const { data: templateCategoryData } = useGetCommentReplyTemplateCategory();
  const { mutate: saveTemplate } = usePostAdminCommentReplyTemplate();

  const [template, setTemplate] = useState<TemplateType>({
    categoryID: '',
    isDefault: false,
    shortDesc: '',
    longDesc: '',
    useCase: '',
    hashTag: [],
    triggerType: CommentReplyPostType.POST,
    recommend: true,
  });

  const [categoryName, setCategoryName] = useState<string>('');
  const [typedHashTag, setTypedHashTag] = useState<TagOption[]>([]);

  const categoryNameList = useMemo(() => {
    if (templateCategoryData) {
      return templateCategoryData.flatMap((item) => [
        item.zh_tw.name,
        item.en_us.name,
      ]);
    }
    return [];
  }, [templateCategoryData]);

  const triggerTypeList = useMemo(() => {
    return [
      { type: 1, name: 'Post/Reels' },
      { type: 2, name: 'Story' },
      { type: 3, name: 'Live' },
    ];
  }, []);

  const handleConfirm = useCallback(() => {
    const {
      shortDesc,
      longDesc,
      recommend,
      triggerType,
      categoryID,
      hashTag,
      useCase,
    } = template;

    saveTemplate(
      {
        shortDesc,
        longDesc,
        recommend,
        triggerType,
        categoryId: categoryID,
        conditions: [
          {
            chart: get(conditionData, 'privateReplyContent.chart', {}),
            publicReply: get(conditionData, 'publicReplyContent', []),
          },
        ],
        useCase,
        hashTag,
      },
      {
        onSuccess: () => {
          Alert.success('Template saved successfully');
        },
      },
    );

    closeModal();
  }, [template, saveTemplate, conditionData, closeModal]);

  const findZhCategoryByName = useCallback(
    (name: string) => {
      const foundCategory = templateCategoryData?.find(
        (item) => item.zh_tw?.name === name,
      );
      return foundCategory ? foundCategory.zh_tw : null;
    },
    [templateCategoryData],
  );

  const findEnCategoryByName = useCallback(
    (name: string) => {
      const foundCategory = templateCategoryData?.find(
        (item) => item.en_us?.name === name,
      );
      return foundCategory ? foundCategory.en_us : null;
    },
    [templateCategoryData],
  );

  const findSpecificCategoryByName = useCallback(
    (name: string) => {
      const zhResult = findZhCategoryByName(name);
      const enResult = findEnCategoryByName(name);

      return zhResult || enResult;
    },
    [findEnCategoryByName, findZhCategoryByName],
  );

  useEffect(() => {
    if (categoryName) {
      const result = findSpecificCategoryByName(categoryName);

      if (result) {
        setTemplate((prevTemplate) => ({
          ...prevTemplate,
          categoryID: result.categoryId,
          isDefault: result.isDefault,
          shortDesc: result.shortDesc,
          longDesc: result.longDesc,
          useCase: result.useCase,
          hashTag: result.hashTag,
          triggerType: result.template[0].triggerType,
          recommend: result.template[0].recommend,
        }));

        if (result.hashTag.length) {
          setTypedHashTag(
            result.hashTag.map((word) => ({
              id: word,
              name: word,
            })),
          );
        }
      }
    }
  }, [categoryName, findSpecificCategoryByName]);

  useEffect(() => {
    if (categoryName && template.triggerType) {
      const result = findSpecificCategoryByName(categoryName);

      if (result) {
        const specificTemplate = result.template.find(
          (item) => item.triggerType === template.triggerType,
        );
        if (specificTemplate) {
          setTemplate((prevTemplate) => ({
            ...prevTemplate,
            recommend: specificTemplate.recommend,
          }));
        }
      }
    }
  }, [categoryName, findSpecificCategoryByName, template.triggerType]);

  return (
    <Dialog
      size="s"
      title="Save template"
      open={modalType === ModalTypesEnum.SAVE_TEMPLATE}
      confirmBtnText="Save"
      handleClose={closeModal}
      handleConfirm={handleConfirm}
    >
      <ModalContainerStyled>
        <Select
          size="small"
          label="Name"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
        >
          <SelectItem value="" />

          {categoryNameList?.map((name) => (
            <SelectItem key={name} value={name}>
              {name}
            </SelectItem>
          ))}
        </Select>

        <TextField
          size="small"
          label="Short description"
          value={template.shortDesc}
          onChange={(e) =>
            setTemplate({ ...template, shortDesc: e.target.value })
          }
        />

        {!template.isDefault && (
          <Textarea
            label="Long description"
            rows={3}
            value={template.longDesc}
            onChange={(e) =>
              setTemplate({ ...template, longDesc: e.target.value })
            }
          />
        )}

        {!template.isDefault && (
          <TextField
            size="small"
            label="Use case"
            value={template.useCase}
            onChange={(e) =>
              setTemplate({ ...template, useCase: e.target.value })
            }
          />
        )}

        {!template.isDefault && (
          <TagSelector
            enableSearch={false}
            options={[]}
            value={typedHashTag}
            maxLength={100}
            allowNew
            setValue={(tags) => {
              setTypedHashTag(tags);
              setTemplate({
                ...template,
                hashTag: tags.map((tag) => tag.name),
              });
            }}
            minHeight={60}
            placeholder={'+ Add hash tag'}
          />
        )}

        <Select
          size="small"
          label="Trigger type"
          value={template.triggerType}
          onChange={(e) =>
            setTemplate({
              ...template,
              triggerType: Number(e.target.value) as CommentReplyPostType,
            })
          }
        >
          <SelectItem value="" />

          {triggerTypeList?.map((item) => (
            <SelectItem key={item.type} value={item.type}>
              {item.name}
            </SelectItem>
          ))}
        </Select>

        <Select
          size="small"
          label="Recommend"
          value={template.recommend ? 'true' : 'false'}
          onChange={(e) =>
            setTemplate({
              ...template,
              recommend: Boolean(e.target.value === 'true'),
            })
          }
        >
          <SelectItem value="" />
          <SelectItem value={'true'}>{'True'}</SelectItem>
          <SelectItem value={'false'}>{'False'}</SelectItem>
        </Select>
      </ModalContainerStyled>
    </Dialog>
  );
};

export default InstagramTemplateModal;
