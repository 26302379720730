import { NoticeBar } from '@frontend/components/ui';
import {
  useGetProjectID,
  useGetProjectNotibarInfo,
} from '@frontend/editor/data-access';
import {
  useGetAccountInfo,
  useGetBilling,
  useGetProject,
  usePostNewBroadcast,
  useProjectLinked,
} from '@frontend/sorghum/data-access';
import {
  NotificationCtx,
  UICtx,
  WebSocketCtx,
} from '@frontend/sorghum/external-providers';
import {
  AccountPermission,
  GetBillingPlanEnum,
  ModalTypesEnum,
  NotificationTypeEnum,
  ResponseWithData,
  SocialTypeEnum,
  WebSocketEventNameEnum,
  WebSocketResponse,
  WebSocketTypeEnum,
} from '@frontend/sorghum/interface';
import {
  calculateRemainDays,
  dayjs,
  DRAFT,
  PAGE_BROADCAST,
  PAGE_FLOW_ENTRY,
  PAGE_SETTING,
  PERMISSION_CHANGED_ZH,
  sendGAEvent,
  useCustomizedTranslation,
  usePath,
  VIEWER_PERMISSION_EN,
  VIEWER_PERMISSION_ZH,
} from '@frontend/sorghum/utils';
import i18next from 'i18next';
import { FC, useCallback, useContext, useEffect } from 'react';
import { Trans } from 'react-i18next';

interface DashboardNoticeBarProps {
  redirectTo: (page?: string) => void;
  socialType: SocialTypeEnum;
}

const sendGAEventWhenNoticeBarIsClicked = () => {
  sendGAEvent(
    'Automated Messaging',
    'notibar Hyperlink',
    'Automated Messaging - notibar - send first broadcast - click',
    '',
  );
};

export const DashboardNoticeBar: FC<DashboardNoticeBarProps> = ({
  redirectTo,
  socialType,
}) => {
  const { t } = useCustomizedTranslation(socialType);

  const {
    noticeBarType,
    clear: clearNotibarType,
    addNoticeBarType,
    removeNoticeBarType,
  } = useContext(NotificationCtx);
  const { addMessageEventListener, removeMessageEventListener } =
    useContext(WebSocketCtx);
  const { navigateToProjectPage, navigateToUpgrade } = usePath();
  const { openRenameBroadcastModal, openModal } = useContext(UICtx);

  const { data: projectID, isFetched: isFetchedProjectID } = useGetProjectID();
  const { data: account, isFetched: isFetchedAccount } = useGetAccountInfo();
  const { data: project, isFetched: isFetchedProject } =
    useGetProject(projectID);
  const { data: notibar, isFetched: isFetchedNotibar } =
    useGetProjectNotibarInfo(projectID as string);
  const { data: linked, isFetched: isFetchedProjectLinked } = useProjectLinked(
    projectID as string,
  );
  const { data: billing, isFetched: isFetchedBilling } = useGetBilling();

  const { mutate: addNewBroadcast } = usePostNewBroadcast(projectID as string);

  const handleAddButtonClick = useCallback(() => {
    sendGAEventWhenNoticeBarIsClicked();
    addNewBroadcast(t('broadcast.defaultBroadcastName'), {
      onSuccess: (data) => {
        if (data.code === 20000) {
          navigateToProjectPage({
            path: `${PAGE_BROADCAST}/${data.data.broadcastId}/${DRAFT}`,
          });
          openRenameBroadcastModal(data.data.broadcastId);
        }
      },
    });
  }, [addNewBroadcast, navigateToProjectPage, t, openRenameBroadcastModal]);

  const handleMessageListener = useCallback(() => {
    const handleMessage = (data: ResponseWithData<WebSocketResponse>) => {
      switch (data.data.notificationType) {
        case WebSocketTypeEnum.NEW_RN_RECEIVER:
          addNoticeBarType(NotificationTypeEnum.NEW_RN_RECEIVER);
          sendGAEvent(
            'Automated Messaging',
            'notibar view',
            'Automated Messaging - notibar - send first broadcast - view',
            '',
          );
          break;
        case WebSocketTypeEnum.SEND_FIRST_RN:
          removeNoticeBarType(NotificationTypeEnum.NEW_RN_RECEIVER);
          break;
        case WebSocketTypeEnum.SEND_RN_UNPUBLISHED_FLOW:
          addNoticeBarType(NotificationTypeEnum.FLOW_UNPUBLISHED);
          sendGAEvent(
            'Automated Messaging',
            'notibar view',
            'Automated Messaging - notibar - send first broadcast - view',
            '',
          );
          break;
        case WebSocketTypeEnum.PUBLISHED_FIRST_FLOW:
          removeNoticeBarType(NotificationTypeEnum.FLOW_UNPUBLISHED);
          break;
        default:
          break;
      }
    };
    const callback = addMessageEventListener(
      WebSocketEventNameEnum.NOTIBAR,
      handleMessage,
    );

    return () => {
      removeMessageEventListener(WebSocketEventNameEnum.NOTIBAR, callback);
    };
  }, [
    addMessageEventListener,
    addNoticeBarType,
    removeMessageEventListener,
    removeNoticeBarType,
  ]);

  const setProjectLinked = useCallback(() => {
    let target = null;
    switch (project?.socialType) {
      case SocialTypeEnum.FACEBOOK: {
        target = linked?.fb;
        break;
      }
      case SocialTypeEnum.THREADS: {
        target = linked?.threads;
        break;
      }
      case SocialTypeEnum.INSTAGRAM: {
        target = linked?.ig;
        break;
      }
    }

    // 沒有綁定 fb 粉專 or threads 帳號
    if (!target?.id) {
      addNoticeBarType(NotificationTypeEnum.FAN_PAGE_DISCONNECT);
    } else {
      removeNoticeBarType(NotificationTypeEnum.FAN_PAGE_DISCONNECT);
    }
    // Admin 切換導致權限出現錯誤
    if (target?.isError) {
      addNoticeBarType(NotificationTypeEnum.PERMISSION_CHANGED);
    } else {
      removeNoticeBarType(NotificationTypeEnum.PERMISSION_CHANGED);
    }
  }, [project, linked, addNoticeBarType, removeNoticeBarType]);

  const setNotibar = useCallback(() => {
    // 累積了至少 1 個 rn 用戶，但未曾發過推播
    if (notibar?.notificationType === 1) {
      addNoticeBarType(NotificationTypeEnum.NEW_RN_RECEIVER);
      sendGAEvent(
        'Automated Messaging',
        'notibar view',
        'Automated Messaging - notibar - send first broadcast - view',
        '',
      );
    } else {
      removeNoticeBarType(NotificationTypeEnum.NEW_RN_RECEIVER);
    }
    if (notibar?.notificationType === 3) {
      //3: 專案已成功發過至少 1 則推播以及未曾發布過 Flow
      addNoticeBarType(NotificationTypeEnum.FLOW_UNPUBLISHED);
    } else {
      removeNoticeBarType(NotificationTypeEnum.FLOW_UNPUBLISHED);
    }
  }, [addNoticeBarType, notibar?.notificationType, removeNoticeBarType]);

  const setProjectPaymentStatus = useCallback(() => {
    // 付款失敗
    if (project && project.paymentStatus === 2) {
      addNoticeBarType(NotificationTypeEnum.PAYMENT_ERROR);
    } else {
      removeNoticeBarType(NotificationTypeEnum.PAYMENT_ERROR);
    }
    // Trial Plan 倒數
    if (billing && billing.plan === GetBillingPlanEnum.TRIAL) {
      switch (project?.socialType) {
        case SocialTypeEnum.FACEBOOK: {
          addNoticeBarType(NotificationTypeEnum.TRIAL_PLAN_COUNTDOWN);
          break;
        }
      }
    } else {
      removeNoticeBarType(NotificationTypeEnum.TRIAL_PLAN_COUNTDOWN);
    }

    // Trial 結束 / 人數抵達上限
    if (billing && billing.plan === GetBillingPlanEnum.EXPIRY) {
      switch (project?.socialType) {
        case SocialTypeEnum.FACEBOOK: {
          addNoticeBarType(NotificationTypeEnum.TRIAL_END);
          break;
        }
        case SocialTypeEnum.THREADS:
        case SocialTypeEnum.INSTAGRAM: {
          addNoticeBarType(NotificationTypeEnum.TRIAL_REACHED_LIMIT);
          break;
        }
      }
    } else {
      removeNoticeBarType(NotificationTypeEnum.TRIAL_END);
      removeNoticeBarType(NotificationTypeEnum.TRIAL_REACHED_LIMIT);
    }
  }, [project, billing, addNoticeBarType, removeNoticeBarType]);

  const setAccountPermission = useCallback(() => {
    if (account?.permission === AccountPermission.VIEWER) {
      addNoticeBarType(NotificationTypeEnum.VIEWER);
    } else {
      removeNoticeBarType(NotificationTypeEnum.VIEWER);
    }
  }, [account?.permission, addNoticeBarType, removeNoticeBarType]);

  useEffect(() => {
    // 等待所有狀態都確定後才顯示，避免層級低的狀態先出現又被清除
    if (
      isFetchedAccount &&
      isFetchedProject &&
      isFetchedProjectLinked &&
      isFetchedBilling &&
      isFetchedNotibar &&
      isFetchedProjectID
    ) {
      setProjectLinked();
      setNotibar();
      setProjectPaymentStatus();
      setAccountPermission();
    }
  }, [
    isFetchedAccount,
    isFetchedBilling,
    isFetchedNotibar,
    isFetchedProject,
    isFetchedProjectID,
    isFetchedProjectLinked,
    setAccountPermission,
    setNotibar,
    setProjectLinked,
    setProjectPaymentStatus,
  ]);

  useEffect(() => {
    handleMessageListener();
  }, [handleMessageListener]);

  useEffect(() => {
    // 切換專案時清空通知
    clearNotibarType();
  }, [projectID, clearNotibarType]);

  const viewerLearnMoreUrl =
    i18next.language === 'en_us' ? VIEWER_PERMISSION_EN : VIEWER_PERMISSION_ZH;

  switch (noticeBarType) {
    case NotificationTypeEnum.VIEWER: {
      return (
        <NoticeBar
          message={t('alert.viewer.title')}
          type="info"
          linkText={t('alert.viewer.link')}
          onLinkClick={() => {
            window.open(viewerLearnMoreUrl, '_blank');
          }}
        />
      );
    }
    case NotificationTypeEnum.PAYMENT_ERROR: {
      return (
        <NoticeBar
          message={t('alert.paymentError.title')}
          type="error"
          linkText={t('alert.paymentError.link')}
          onLinkClick={() => {
            openModal(ModalTypesEnum.CHANGE_PAYMENT_METHOD);
          }}
        />
      );
    }
    case NotificationTypeEnum.TRIAL_PLAN_COUNTDOWN: {
      return (
        <NoticeBar
          message={
            <Trans
              i18nKey="alert.trialPlanCountdown.title"
              values={{
                day: calculateRemainDays({
                  endDate: dayjs(billing?.endedAt),
                }),
              }}
            />
          }
          linkText={t('alert.trialPlanCountdown.link')}
          type="info"
          onLinkClick={() => {
            navigateToUpgrade({
              socialType: project?.socialType,
              projectID,
              source: 'notibar',
            });
          }}
        />
      );
    }
    case NotificationTypeEnum.TRIAL_END: {
      return (
        <NoticeBar
          message={<Trans i18nKey="alert.trialEnd.title" />}
          linkText={t('alert.trialEnd.link')}
          type="info"
          onLinkClick={() => {
            navigateToUpgrade({
              socialType: project?.socialType,
              projectID,
              source: 'notibar',
            });
          }}
        />
      );
    }
    case NotificationTypeEnum.TRIAL_REACHED_LIMIT: {
      return (
        <NoticeBar
          message={t('alert.viewMode.title')}
          linkText={t('alert.viewMode.link')}
          type="info"
          onLinkClick={() => {
            navigateToUpgrade({
              socialType: project?.socialType,
              projectID,
              source: 'notibar',
            });
          }}
        />
      );
    }
    case NotificationTypeEnum.FAN_PAGE_DISCONNECT: {
      return (
        <NoticeBar
          message={t('alert.fanPageDisconnected')}
          linkText={t('alert.connect')}
          onLinkClick={() => {
            sendGAEventWhenNoticeBarIsClicked();
            redirectTo(PAGE_SETTING);
          }}
        />
      );
    }
    case NotificationTypeEnum.NEW_RN_RECEIVER: {
      return (
        <NoticeBar
          message={t('alert.firstSubscribeRN')}
          linkText={t('common.GO')}
          onLinkClick={handleAddButtonClick}
        />
      );
    }
    case NotificationTypeEnum.FLOW_UNPUBLISHED: {
      return (
        <NoticeBar
          message={t('alert.publishFirstFlow')}
          linkText={t('common.GO')}
          onLinkClick={() => {
            sendGAEventWhenNoticeBarIsClicked();
            redirectTo(PAGE_FLOW_ENTRY);
          }}
        />
      );
    }
    case NotificationTypeEnum.PERMISSION_CHANGED: {
      return (
        <NoticeBar
          message={t('alert.permissionChanged.title')}
          type="error"
          linkText={t('alert.permissionChanged.link')}
          onLinkClick={() => {
            window.open(PERMISSION_CHANGED_ZH, '_blank');
          }}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default DashboardNoticeBar;
