import {
  useCanvasAdd,
  useCanvasCollect,
  useCanvasFlow,
  useCanvasGet,
  useCanvasRemove,
  useCanvasView,
  useModal,
  useProjectPermission,
} from '@frontend/editor/data-access';
import {
  EditorCtx,
  UICtx as EditorUICtx,
} from '@frontend/editor/external-providers';
import {
  DrawerTypesEnum,
  ModalTypesEnum as EditorModalTypesEnum,
  ElementCollectType,
  ElementTypesEnum,
  TextButtonCellTypesEnum,
} from '@frontend/editor/interface';
import {
  useGetCommentReply,
  useGetCommentReplyConditionV2,
  useMutateGetCommentReplyCondition,
  usePatchCommentReplyConditionV2,
  useProjectLinked,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  CommentReplyPostType,
  FlowChart,
  GetCommentAutoReplyConditionResponse,
  ModalTypesEnum,
  PublishStatus,
  SocialTypeEnum,
  TextButtonCellType,
  TextButtonElementType,
} from '@frontend/sorghum/interface';
import {
  useCustomizedTranslation,
  useTimer,
  uuid,
} from '@frontend/sorghum/utils';
import { get } from 'lodash';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactFlow, { SelectionMode, useStoreApi } from 'reactflow';
import 'reactflow/dist/style.css';
import ConfirmCloseDrawerModal from '../confirm-close-drawer-modal/confirm-close-drawer-modal';
import ConnectionLineComponent from '../connection-line-component/connection-line-component';
import Control from '../control/control';
import Dialog from '../dialog/dialog';
import DrawerCollectUserAnswerV2 from '../drawer-collect-user-answer-v2/drawer-collect-user-answer-v2';
import DrawerCommentReplyConditions from '../drawer-comment-reply-conditions/drawer-comment-reply-conditions';
import DrawerInstagramCommentReplyPeriod from '../drawer-instagram-comment-reply-period/drawer-instagram-comment-reply-period';
import DrawerInstagramCommentReplyTo from '../drawer-instagram-comment-reply-to/drawer-instagram-comment-reply-to';
import EditorCanvasContainer from '../editor-canvas-container/editor-canvas-container';
import {
  CustomFocusMarker,
  CustomMarker,
} from '../editor-custom-edge/editor-custom-edge';
import EditorLoadingModal from '../editor-loading-modal/editor-loading-modal';
import NodeBlock from '../node-block/node-block';
import { NodeCollectUserAnswerV2 } from '../node-collect-user-answer-v2/node-collect-user-answer-v2';
import NodeCommentReplyCreateBlockMenu from '../node-create-block-menu/node-comment-reply-create-block-menu';
import NodeFloatPanel from '../node-float-panel/node-float-panel';
import NodeInstagramCommentAutoReplyBlock from '../node-instagram-comment-reply-block/node-instagram-comment-reply-block';
import NodeInstagramPrivateReply from '../node-instagram-private-reply/node-instagram-private-reply';
import NodePublicReplyBlock from '../node-public-reply-block/node-public-reply-block';
import NodeCommentAutoReplyBlock from '../node-threads-comment-reply-block/node-threads-comment-reply-block';

const nodeTypes = {
  BLOCK: NodeBlock,
  THREADS_BLOCK: NodeCommentAutoReplyBlock,
  PUBLIC_REPLY: NodePublicReplyBlock,
  COMMENT_REPLY_BLOCK: NodeInstagramCommentAutoReplyBlock,
  INSTAGRAM_COMMENT_REPLY_BLOCK: NodeCommentAutoReplyBlock,
  INSTAGRAM_PRIVATE_REPLY: NodeInstagramPrivateReply,
  COLLECT_USER_ANSWER_V2: NodeCollectUserAnswerV2,
  CREATE_MENU: NodeCommentReplyCreateBlockMenu,
};

interface Props {
  commentAutoReplyID: string;
  mode?: 'template';
  postType?: CommentReplyPostType;
}

export const FlowInstagramCommentAutoReply = ({
  mode,
  commentAutoReplyID,
}: Props) => {
  const isTemplateMode = mode === 'template';
  const socialType = SocialTypeEnum.INSTAGRAM;
  const { t } = useCustomizedTranslation(socialType);
  const store = useStoreApi();
  const { nodeInternals } = store.getState();
  const {
    drawerType,
    openModal: openEditorModal,
    modalType,
    closeDrawer,
    closeModal,
    setDrawerType,
  } = useContext(EditorUICtx);
  const {
    elements,
    isFlowDraggable,
    isFlowPreventScrolling,
    isDrawerUpdate,
    isFlowZoomOnScroll,
    isDrawerFullyUpdate,
    checkDataUpdated,
    setBlockSerialNumber,
    restoreElement,
    setIsUpdated,
    onFocusCellID,
    setOnFocusCellID,
    drawerCallback,
  } = useContext(EditorCtx);
  const {
    openModal: editorModalOpen,
    modalState,
    closeModal: closeEditorModal,
  } = useModal();
  const { openModal } = useContext(UICtx);
  const { generateNewElement } = useCanvasAdd();
  const { isViewer } = useProjectPermission();
  const { getTargetElement } = useCanvasGet();

  const {
    nodes,
    edges,
    onConnectStart,
    onConnectEnd,
    onNodesChange,
    onEdgesChange,
    handleEdgeDelete,
    handleNodesDelete,
    handleEdgeUpdate,
    handleNodesDragEnd,
    handleOnSelectionContextMenu,
    handleOnNodeContextMenu,
    targetElement,
  } = useCanvasFlow();

  const { selectedNodes } = useCanvasCollect();
  const { isTemplateEditor } = useProjectPermission();

  const { data: commentAutoReplyData } = useGetCommentReply(commentAutoReplyID);

  const commentReplyConditionID = get(
    commentAutoReplyData,
    'replyCondition.data.0.id',
    '',
  );

  // 舊的 comment reply 打 v1
  const { mutate: getCommentReplyConditionV1 } =
    useMutateGetCommentReplyCondition(commentReplyConditionID);

  // 新建立的都打 v2
  const {
    data: commentAutoReplyConditionV2,
    isFetched: isCommentAutoReplyConditionV2Fetched,
  } = useGetCommentReplyConditionV2(commentReplyConditionID);

  const { mutate: updateConditionV2 } = usePatchCommentReplyConditionV2();
  const { data: linked } = useProjectLinked();
  const { focusNodeByReactFlowID } = useCanvasView();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFirstFocus, setIsFirstFocus] = useState(false);
  const [panOnDrag, setPanOnDrag] = useState(true);

  const { removeTargetBlock, removeSelectedBlocks, removeElement } =
    useCanvasRemove();

  const isActive = commentAutoReplyData?.isActive;

  //如果沒有連線會阻擋點擊行為並開啟 modal
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (!linked?.ig?.id && !isTemplateEditor) {
        e.preventDefault();
        e.stopPropagation();
        openModal(ModalTypesEnum.CONNECT_INSTAGRAM_MODAL);
      }
      // 點擊畫布會解除 focus 進而關閉 text-cell-button-modal
      if (
        getTargetElement(onFocusCellID)?.elementType ===
        ElementTypesEnum.TEXT_BUTTON_CELL
      )
        setOnFocusCellID('');
    },
    [
      getTargetElement,
      linked?.ig?.id,
      onFocusCellID,
      openModal,
      setOnFocusCellID,
      isTemplateEditor,
    ],
  );

  const handleCanvasClick = useCallback(
    (e: React.MouseEvent) => {
      if (isDrawerUpdate) {
        e.preventDefault();
        e.stopPropagation();
        openEditorModal(EditorModalTypesEnum.CLOSE_UNSAVED_DRAWER);
      } else {
        closeDrawer();
      }
    },
    [closeDrawer, isDrawerUpdate, openEditorModal],
  );

  const handleCloseAndSaveDrawer = useCallback(() => {
    if (drawerCallback) {
      drawerCallback();
    }
  }, [drawerCallback]);

  const Drawer = useMemo(() => {
    if (isActive) return null;
    switch (drawerType) {
      case DrawerTypesEnum.INSTAGRAM_COMMENT_REPLY_TO:
        return DrawerInstagramCommentReplyTo;
      case DrawerTypesEnum.INSTAGRAM_COMMENT_REPLY_PERIOD:
        return DrawerInstagramCommentReplyPeriod;
      case DrawerTypesEnum.COMMENT_REPLY_CONDITIONS:
        return DrawerCommentReplyConditions;
      case DrawerTypesEnum.COLLECT_USER_ANSWER_V2:
        return DrawerCollectUserAnswerV2;
    }
    return null;
  }, [drawerType, isActive]);

  const updateFlowChart = useCallback(() => {
    if (isViewer || !isLoaded) return;
    const chart = {
      name: commentAutoReplyData?.name,
      publishStatus: PublishStatus.UNPUBLISH,
      updatedAt: new Date().toISOString(),
      chart: {
        data: elements,
        blockSerialNumber: elements.length,
      },
    } as FlowChart;
    if (commentAutoReplyID && commentAutoReplyConditionV2 && elements) {
      updateConditionV2(
        {
          id: commentAutoReplyID,
          isAutoSave: true,
          replyCondition: [
            {
              ...commentAutoReplyConditionV2,
              isWarning: false,
              privateReplyContent: {
                id: commentAutoReplyConditionV2.privateReplyContent?.id || '',
                chart,
              },
            },
          ],
        },
        {
          onSuccess: () => setIsUpdated(false),
        },
      );
    }
  }, [
    commentAutoReplyConditionV2,
    commentAutoReplyData?.name,
    commentAutoReplyID,
    elements,
    isLoaded,
    isViewer,
    setIsUpdated,
    updateConditionV2,
  ]);

  const handleDeleteBlockModal = useCallback(() => {
    removeTargetBlock();
    closeModal();
  }, [removeTargetBlock, closeModal]);

  const handleDeleteBlocksModal = useCallback(() => {
    removeSelectedBlocks();
    closeModal();
  }, [removeSelectedBlocks, closeModal]);

  const handleDeleteTextButtonModal = useCallback(() => {
    removeElement();
    closeModal();
  }, [removeElement, closeModal]);

  const handleDeleteElementModal = useCallback(() => {
    removeElement();
    closeModal();
  }, [removeElement, closeModal]);

  useEffect(() => {
    if (isLoaded || !commentReplyConditionID) return;

    if (commentAutoReplyConditionV2?.privateReplyContent?.chart?.chart) {
      restoreElement(
        commentAutoReplyConditionV2?.privateReplyContent.chart.chart.data,
      );
      setBlockSerialNumber(
        commentAutoReplyConditionV2?.privateReplyContent.chart.chart
          .blockSerialNumber,
      );
      setIsLoaded(true);
    } else if (commentAutoReplyConditionV2?.privateReplyContent) {
      getCommentReplyConditionV1(undefined, {
        onSuccess: (data: GetCommentAutoReplyConditionResponse) => {
          if (data?.privateReplyContent?.block) {
            const commentAutoReplyConditionV1: GetCommentAutoReplyConditionResponse =
              data;
            const blockID =
              commentAutoReplyConditionV1?.privateReplyContent?.block?.blockId;

            const newElements: ElementCollectType[] = [];

            const commentReplyBlocks: ElementCollectType[] = [
              {
                id: ElementTypesEnum.COMMENT_REPLY_BLOCK,
                elementType: ElementTypesEnum.COMMENT_REPLY_BLOCK,
                data: {
                  commentAutoReplyID,
                  socialType: SocialTypeEnum.INSTAGRAM,
                },
                position: {
                  x: 0,
                  y: 0,
                },
                outputID: 'comment-reply',
                children: [
                  ElementTypesEnum.INSTAGRAM_PRIVATE_REPLY,
                  ElementTypesEnum.PUBLIC_REPLY,
                ],
                draggable: false,
              },
              {
                id: `${ElementTypesEnum.COMMENT_REPLY_CONDITION}-private`,
                elementType: ElementTypesEnum.COMMENT_REPLY_CONDITION,
                data: {},
                outputID: 'private',
                children: [],
                nodeID: ElementTypesEnum.COMMENT_REPLY_BLOCK,
                parentID: ElementTypesEnum.COMMENT_REPLY_BLOCK,
                targetID: blockID,
              },
            ];

            if (commentAutoReplyData?.postType === CommentReplyPostType.POST) {
              commentReplyBlocks.push(
                {
                  id: `${ElementTypesEnum.COMMENT_REPLY_CONDITION}-public`,
                  elementType: ElementTypesEnum.COMMENT_REPLY_CONDITION,
                  data: {},
                  outputID: 'public',
                  children: [],
                  targetID: ElementTypesEnum.PUBLIC_REPLY,
                  nodeID: ElementTypesEnum.COMMENT_REPLY_BLOCK,
                  parentID: ElementTypesEnum.COMMENT_REPLY_BLOCK,
                },
                {
                  id: ElementTypesEnum.PUBLIC_REPLY,
                  elementType: ElementTypesEnum.PUBLIC_REPLY,
                  data: {
                    commentAutoReplyID,
                    socialType: SocialTypeEnum.INSTAGRAM,
                  },
                  position: {
                    x: 400,
                    y: 600,
                  },
                  outputID: 'output-3',
                  children: [],
                },
              );
            }

            newElements.push(...commentReplyBlocks);

            const defaultTextButtonID = uuid();

            const currentElements =
              commentAutoReplyConditionV1?.privateReplyContent?.block
                ?.elements ?? [];

            const block = generateNewElement(
              ElementTypesEnum.INSTAGRAM_PRIVATE_REPLY,
              {
                id: blockID,
                data: { commentAutoReplyID },
                position: {
                  x: 400,
                  y: -100,
                },
                children:
                  currentElements.length > 0
                    ? currentElements.map((i) => i.elementId)
                    : [defaultTextButtonID],
              },
            );
            newElements.push(block);

            if (currentElements.length > 0) {
              currentElements.forEach((el: TextButtonElementType) => {
                const textButton = generateNewElement(
                  ElementTypesEnum.TEXT_BUTTON,
                  {
                    id: el.elementId,
                    parentID: blockID,
                    nodeID: blockID,
                    children: el?.cells?.map((i) => i.cellId) || [],
                    label: el?.data.text,
                  },
                );
                newElements.push(textButton);

                if (el.cells && el.cells.length > 0) {
                  el.cells?.forEach((cell: TextButtonCellType) => {
                    const textCellButton = generateNewElement(
                      ElementTypesEnum.TEXT_BUTTON_CELL,
                      {
                        id: cell.cellId,
                        parentID: el.elementId,
                        nodeID: blockID,
                        label: cell.data.title,
                        buttonType: TextButtonCellTypesEnum.URL,
                        data: cell.data,
                      },
                    );
                    newElements.push(textCellButton);
                  });
                }
              });
            } else {
              // 預設有一顆 text-cell-button
              const defaultCellID = uuid();
              const textButton = generateNewElement(
                ElementTypesEnum.TEXT_BUTTON,
                {
                  id: defaultTextButtonID,
                  parentID: blockID,
                  nodeID: blockID,
                  children: [defaultCellID],
                  label: '',
                },
              );
              newElements.push(textButton);
              const textCellButton = generateNewElement(
                ElementTypesEnum.TEXT_BUTTON_CELL,
                {
                  id: defaultCellID,
                  parentID: defaultTextButtonID,
                  nodeID: blockID,
                  buttonType: TextButtonCellTypesEnum.URL,
                  buttonData: {
                    url: '',
                  },
                },
              );
              newElements.push(textCellButton);
            }

            const chart = {
              name: commentAutoReplyData?.name,
              publishStatus: PublishStatus.UNPUBLISH,
              updatedAt: new Date().toISOString(),
              chart: {
                data: newElements,
                blockSerialNumber: newElements.length,
              },
            } as FlowChart;
            updateConditionV2({
              id: commentAutoReplyID,
              isAutoSave: true,
              replyCondition: [
                {
                  ...commentAutoReplyConditionV1,
                  isWarning: false,
                  privateReplyContent: {
                    id:
                      commentAutoReplyConditionV1.privateReplyContent?.id || '',
                    chart,
                  },
                },
              ],
            });
            restoreElement(newElements);
          }
        },
      });
    }
  }, [
    commentAutoReplyConditionV2?.privateReplyContent,
    commentAutoReplyData?.name,
    commentAutoReplyData?.postType,
    commentAutoReplyID,
    commentReplyConditionID,
    generateNewElement,
    getCommentReplyConditionV1,
    isCommentAutoReplyConditionV2Fetched,
    isLoaded,
    restoreElement,
    setBlockSerialNumber,
    updateConditionV2,
  ]);

  useEffect(() => {
    if (!commentAutoReplyData?.isActive && !isLoaded) {
      setDrawerType(DrawerTypesEnum.INSTAGRAM_COMMENT_REPLY_TO);
    }
  }, [commentAutoReplyData?.isActive, isLoaded, setDrawerType]);

  useEffect(() => {
    const nodes = Array.from(nodeInternals).map(([, node]) => node);

    if (nodes.length > 0 && !isFirstFocus) {
      setTimeout(() => {
        focusNodeByReactFlowID('1');
      });
      setIsFirstFocus(true);
    }
  }, [focusNodeByReactFlowID, isFirstFocus, nodeInternals]);

  useTimer(2, checkDataUpdated(), updateFlowChart);

  return (
    <Fragment>
      <EditorCanvasContainer onClickCapture={handleClick}>
        <EditorLoadingModal />
        {Drawer && <Drawer />}
        {isTemplateMode && <NodeFloatPanel />}
        <ReactFlow
          onClickCapture={handleCanvasClick}
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          minZoom={0.1}
          connectionLineComponent={ConnectionLineComponent}
          nodesDraggable={false}
          panOnDrag={panOnDrag}
          onNodeMouseEnter={() => setPanOnDrag(false)}
          onNodeMouseLeave={() => setPanOnDrag(true)}
          preventScrolling={true}
          deleteKeyCode={null}
          zoomOnDoubleClick={false}
          {...(isTemplateMode && {
            selectionMode: SelectionMode.Partial,
            onConnectEnd: onConnectEnd,
            onConnectStart: onConnectStart,
            onClickConnectStart: onConnectStart,
            onNodesChange: onNodesChange,
            onEdgesChange: onEdgesChange,
            nodesDraggable: isFlowDraggable,
            preventScrolling: isFlowPreventScrolling,
            zoomOnScroll: isFlowZoomOnScroll,
            onNodeContextMenu: handleOnNodeContextMenu,
            onSelectionContextMenu: handleOnSelectionContextMenu,
            onSelectionDragStop: (_, nodes) => handleNodesDragEnd(nodes),
            onNodesDelete: handleNodesDelete,
            onNodeDragStop: (_, node, nodes) => handleNodesDragEnd(nodes),
            onEdgesDelete: handleEdgeDelete,
            onEdgeUpdate: handleEdgeUpdate,
          })}
        >
          <Control showSelectionMode={false} />
        </ReactFlow>
        <CustomMarker />
        <CustomFocusMarker />
      </EditorCanvasContainer>
      <ConfirmCloseDrawerModal
        open={modalType === EditorModalTypesEnum.CLOSE_UNSAVED_DRAWER}
        handleCancel={closeModal}
        handleConfirm={handleCloseAndSaveDrawer}
        disableConfirmButton={!isDrawerFullyUpdate}
      />

      {/* modal 刪除 block 確認 */}
      <Dialog
        size="xs"
        color="error"
        title={t('canvas.modal.deleteBlock.title', {
          title: targetElement?.label,
        })}
        content={t('canvas.modal.deleteBlock.info1')}
        open={modalType === EditorModalTypesEnum.DELETE_BLOCK}
        handleClose={closeModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.delete')}
        handleConfirm={handleDeleteBlockModal}
      >
        {t('canvas.modal.deleteBlock.info2')}
      </Dialog>
      {/* modal 刪除 element 確認 */}
      <Dialog
        size="xs"
        color="error"
        title={t('canvas.modal.deleteElement.title', {
          title: targetElement?.elementName,
        })}
        content={t('canvas.modal.deleteElement.info1')}
        open={modalType === EditorModalTypesEnum.DELETE_ELEMENT}
        handleClose={closeModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.delete')}
        handleConfirm={handleDeleteElementModal}
      >
        {t('canvas.modal.deleteElement.info2')}
      </Dialog>
      {/* modal 刪除 text-button 確認 */}
      <Dialog
        size="xs"
        color="error"
        title={t('canvas.modal.deleteTextButton.title', {
          title: targetElement?.label,
        })}
        content={t('canvas.modal.deleteTextButton.info1')}
        open={modalType === EditorModalTypesEnum.DELETE_TEXT_BUTTON}
        handleClose={closeModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.delete')}
        handleConfirm={handleDeleteTextButtonModal}
      >
        {t('canvas.modal.deleteTextButton.info2')}
      </Dialog>
      {/* modal 刪除 blocks 確認 */}
      <Dialog
        size="xs"
        color="error"
        title={t('canvas.modal.deleteBlocks.title', {
          number: selectedNodes.filter(
            (item) => item.type !== ElementTypesEnum.ENTRY_POINT,
          ).length,
        })}
        content={t('canvas.modal.deleteBlocks.info1')}
        open={modalType === EditorModalTypesEnum.DELETE_BLOCKS}
        handleClose={closeModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.delete')}
        handleConfirm={handleDeleteBlocksModal}
      >
        {t('canvas.modal.deleteBlocks.info2')}
      </Dialog>
      {/* delete 共用 modal*/}
      <Dialog
        size="xs"
        color="error"
        title={modalState.title}
        content={modalState.content}
        open={editorModalOpen === EditorModalTypesEnum.DELETE}
        handleClose={closeEditorModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.delete')}
        handleConfirm={modalState.handleConfirm}
      >
        {modalState.info}
      </Dialog>
    </Fragment>
  );
};
