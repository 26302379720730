import { Typography } from '@frontend/components/ui';
import { Alert } from '@frontend/components/utils';
import { useGetProjectID } from '@frontend/editor/data-access';
import { formatNumberWithCommas } from '@frontend/editor/utils';
import {
  useGetBilling,
  useProjectDisconnect,
  useProjectLinked,
} from '@frontend/sorghum/data-access';
import { ThreadsCtx, UICtx } from '@frontend/sorghum/external-providers';
import {
  GetBillingPlanEnum,
  ProjectDisconnectRequest,
  SocialTypeEnum,
} from '@frontend/sorghum/interface';
import { ThreadsIcon } from '@frontend/sorghum/ui';
import { useDocumentTitle } from '@frontend/sorghum/utils';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useThreadsSetting = () => {
  const [t] = useTranslation('threads');
  const { openCommonModal } = useContext(UICtx);
  const { linkThreads } = useContext(ThreadsCtx);
  const { data: projectID } = useGetProjectID();
  const { mutate: disconnect } = useProjectDisconnect();
  const { data: billing } = useGetBilling();

  const { data: linked, refetch: refetchLinked } = useProjectLinked(
    projectID as string,
  );

  const openModal = () => linkThreads();

  const disconnectPages = useCallback(() => {
    if (linked?.threads?.id && projectID) {
      const props: ProjectDisconnectRequest = {
        projectId: projectID,
        target: 'threads',
        targetId: linked.threads.id,
      };

      const disconnectThreads = () =>
        disconnect(props, {
          onSuccess: (res) => {
            if (res.code === 20000) {
              Alert.success(t('setting.disconnectModal.successMessage'));
            }
          },
        });

      openCommonModal(
        t('setting.disconnectModal.title'),
        t('setting.disconnectModal.description'),
        disconnectThreads,
        t('setting.disconnectModal.disconnect'),
        'error',
      );
    }
  }, [disconnect, linked?.threads?.id, projectID, t, openCommonModal]);

  const TeamMemberInviteDescription = () => (
    <Typography variant="body1" color="grey.600">
      {t('dataDashboard.teamMemberDescription')}
    </Typography>
  );

  const isDisabledInviteButton = true;

  const connectedPageLink = `https://www.threads.net/@${linked?.threads?.linkId}`;

  const connectedPageName = linked?.threads?.name;
  const accountName = linked?.threads?.accountName;

  const Icon = () => <ThreadsIcon size="xl" />;

  const socialType = SocialTypeEnum.THREADS;
  const socialTypeName = t('dataDashboard.threads');

  const hasBusinessAccountDescription = false;

  const { planTitle, price, period } = useMemo(() => {
    let planTitle = '';
    let price = '-';
    let period = '';

    if (billing) {
      switch (billing.plan) {
        case GetBillingPlanEnum.TRIAL: {
          planTitle = t('setting.payment.trial');
          price = t('setting.payment.free');
          period = `/${t('setting.payment.month')}`;
          break;
        }
        case GetBillingPlanEnum.ADVANCE: {
          planTitle = t('setting.payment.advance');
          price = `${
            billing.country === 'TW' ? 'NT$' : 'US$'
          } ${formatNumberWithCommas(billing?.amount ?? 0)}`;
          period = `/${
            billing?.period === 1
              ? t('setting.payment.month')
              : t('setting.payment.year')
          }`;
          break;
        }
        case GetBillingPlanEnum.BETA: {
          planTitle = t('setting.payment.betaVersion');
          price = t('setting.payment.free');
          period = `/${t('setting.payment.month')}`;
          break;
        }
        default: {
          planTitle = t('setting.payment.viewMode');
          price = '-';
          period = '';
        }
      }
    }

    return {
      price,
      period,
      planTitle,
    };
  }, [billing, t]);

  useEffect(() => {
    if (projectID) {
      const bc = new BroadcastChannel('threads_connect');
      bc.onmessage = (event) => {
        refetchLinked();
        if (event.data.isSuccess) {
          Alert.success(t('setting.connectSuccess'));
        } else {
          Alert.error(t('setting.connectFailed'));
        }
      };

      return () => {
        bc.close();
      };
    }
    return;
  }, [t, projectID, refetchLinked]);

  useDocumentTitle(t('title.setting'));

  return {
    linked,
    isConnect: !!linked?.threads?.id,
    refreshPage: () => null,
    disconnectPages,
    openModal,
    TeamMemberInviteDescription,
    isDisabledInviteButton,
    Icon,
    accountName,
    connectedPageLink,
    connectedPageName,
    socialType,
    socialTypeName,
    planTitle,
    price,
    period,
    billing,
    hasBusinessAccountDescription,
  };
};
