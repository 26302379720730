import { DataDashboardOperationAnalysisChipEnum } from '@frontend/sorghum/interface';
import {
  HOW_TO_USE_BROADCAST,
  HOW_TO_USE_BROADCAST_EN,
  PAGE_AI_GUIDED,
  PAGE_BROADCAST,
  PAGE_COMMENT_AUTO_REPLY,
  usePath,
} from '@frontend/sorghum/utils';
import { Box, Link, Typography } from '@mui/material';
import i18next from 'i18next';
import { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

const EmptyStateContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '360px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',
}));

interface Props {
  type: DataDashboardOperationAnalysisChipEnum;
}

export const DataDashboardOperationEmptyState: FC<Props> = ({
  type,
}: Props) => {
  const [t] = useTranslation();
  const { navigateToProjectPage } = usePath();

  const { title, description, components } = useMemo(() => {
    switch (type) {
      case DataDashboardOperationAnalysisChipEnum.FLOW:
        return {
          title: t('dataDashboard.operationAnalysis.flow.empty.title'),
          description: 'dataDashboard.operationAnalysis.flow.empty.description',
          components: [
            <Link
              onClick={() => navigateToProjectPage({ path: PAGE_AI_GUIDED })}
              underline="always"
              sx={{
                color: 'alert.main',
              }}
            />,
          ],
        };
      case DataDashboardOperationAnalysisChipEnum.BROADCAST:
        return {
          title: t('dataDashboard.operationAnalysis.broadcast.empty.title'),
          description:
            'dataDashboard.operationAnalysis.broadcast.empty.description',
          components: [
            <Link
              onClick={() => navigateToProjectPage({ path: PAGE_BROADCAST })}
              underline="always"
              sx={{
                color: 'alert.main',
              }}
            />,
            <Link
              onClick={() =>
                window.open(
                  i18next.language.includes('en')
                    ? HOW_TO_USE_BROADCAST_EN
                    : HOW_TO_USE_BROADCAST,
                  '_blank',
                )
              }
              underline="always"
              sx={{
                color: 'alert.main',
              }}
            />,
          ],
        };
      case DataDashboardOperationAnalysisChipEnum.AUTO_REPLY:
        return {
          title: t('dataDashboard.operationAnalysis.autoReply.empty.title'),
          description:
            'dataDashboard.operationAnalysis.autoReply.empty.description',
          components: [
            <Link
              onClick={() =>
                navigateToProjectPage({ path: PAGE_COMMENT_AUTO_REPLY })
              }
              underline="always"
              sx={{
                color: 'alert.main',
              }}
            />,
          ],
        };
      default:
        return {
          title: '',
          description: '',
          components: [],
        };
    }
  }, [type, t, navigateToProjectPage]);

  return (
    <EmptyStateContainer>
      <Typography variant="h6" color="grey.600">
        {title}
      </Typography>
      <Typography variant="body1" color="grey.600">
        <Trans i18nKey={description} components={components} />
      </Typography>
    </EmptyStateContainer>
  );
};

export default DataDashboardOperationEmptyState;
