import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
import { globalTheme } from '@frontend/components/external-providers';
import { Typography } from '@frontend/components/ui';
import { findWithRegex } from '@frontend/components/utils';
import {
  useCanvasGet,
  useCanvasRemove,
  useCanvasUpdate,
  useProjectPermission,
} from '@frontend/editor/data-access';
import { UICtx } from '@frontend/editor/external-providers';
import { REGEX_CHECK_HYPER_LINK } from '@frontend/editor/utils';
import { Box, styled } from '@mui/material';
import { CompositeDecorator, ContentState, EditorState } from 'draft-js';
import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useViewport } from 'reactflow';
import EditorFloatPanel from '../editor-float-panel/editor-float-panel';
import { ContainerStyled } from './styles';

interface NoteProps {
  id: string;
  label?: string;
  draggable: boolean;
  parentID: string;
  setIsHoverMenu?: Dispatch<SetStateAction<boolean>>;
  index?: number;
}

const TextareaWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette['info']['dark'],
  fontSize: '16px',
}));

const compositeDecorator = new CompositeDecorator([
  {
    strategy: function (contentBlock, callback) {
      findWithRegex(
        new RegExp(REGEX_CHECK_HYPER_LINK, 'g'),
        contentBlock,
        callback,
      );
    },
    component: (props: { decoratedText: string }) => {
      const url = props.decoratedText
        .match(new RegExp(/\((.*?)\)/))
        ?.pop()
        ?.replace('(', '')
        ?.replace(')', '');

      const text = props.decoratedText
        .match(new RegExp(/\[(.*?)\]/))
        ?.pop()
        ?.replace('[', '')
        ?.replace(']', '');

      const handleClick = () => {
        window.open(url, '_blank');
      };

      return (
        <Typography
          onClick={handleClick}
          variant="overline"
          color="info.main"
          sx={{
            cursor: 'pointer',
          }}
        >
          {text}
        </Typography>
      );
    },
  },
]);

export const EditorNote = ({
  id, // element ID
  index,
  parentID, // Block ID
  draggable,
  setIsHoverMenu, // 檢查是否 hover 側邊的選單，來決定是否開放 element 的 sort event
}: NoteProps) => {
  const isTemplatePage = window.location.pathname.includes('template');
  const ref = useRef<Editor>(null);
  const [editorState, setEditorState] = useState(() => {
    return createEditorStateWithText('');
  });
  const [isLoaded, setIsLoaded] = useState(false);

  const { hoveredElement, onHoverElement } = useContext(UICtx);
  const { isTemplateEditor } = useProjectPermission();
  const canEdit = isTemplatePage && isTemplateEditor;
  const { zoom } = useViewport();
  const [isNoteHover, setIsNoteHover] = useState<boolean>(false);
  const [isFocusTextarea, setIsFocusTextarea] = useState<boolean>(false);
  const { canvasUpdateLabel } = useCanvasUpdate();
  const { removeElement } = useCanvasRemove();

  const { getTargetElement } = useCanvasGet();

  const element = getTargetElement(id);

  const handleMouseEnterNote = useCallback(() => {
    onHoverElement(id, 2);
    setIsNoteHover(true);
  }, [id, onHoverElement]);

  const handleMouseLeaveNote = useCallback(() => {
    setIsNoteHover(false);
  }, []);

  const handleMouseEnterMenu = useCallback(() => {
    setIsHoverMenu && setIsHoverMenu(true);
  }, [setIsHoverMenu]);

  const handleMouseLeaveMenu = useCallback(() => {
    setIsHoverMenu && setIsHoverMenu(false);
  }, [setIsHoverMenu]);

  const handleChange = useCallback(
    (newState: EditorState) => {
      // 取得內文內容的 JSON 格式
      const content = newState.getCurrentContent();
      // 將 JSON 格式的內容轉換成純文字內容
      const contentText = content.getPlainText();
      if (isLoaded) {
        setEditorState(newState);
        canvasUpdateLabel(id, contentText);
      }
    },
    [canvasUpdateLabel, id, isLoaded],
  );

  const handleOnBlur = useCallback(() => {
    setIsFocusTextarea(false);
    const content = editorState.getCurrentContent();
    const contentText = content.getPlainText();

    canvasUpdateLabel(id, contentText);
  }, [canvasUpdateLabel, editorState, id]);

  useEffect(() => {
    if (!isLoaded) {
      const initialContent = ContentState.createFromText(element.label ?? '');
      const initialEditorState = EditorState.createWithContent(initialContent);
      const newEditorState = EditorState.set(initialEditorState, {
        decorator: compositeDecorator,
      });
      setEditorState(newEditorState);
      setIsLoaded(true);
    }
  }, [element?.label, isLoaded]);

  return (
    <ContainerStyled
      onMouseEnter={handleMouseEnterNote}
      onMouseLeave={handleMouseLeaveNote}
      $isHover={isNoteHover}
      $zoom={zoom}
    >
      {/* Element 左側 delete 和 sort 的按鈕 */}
      {canEdit &&
        isNoteHover &&
        hoveredElement.layer < 3 &&
        !isFocusTextarea && (
          <EditorFloatPanel
            onMouseEnter={handleMouseEnterMenu}
            onMouseLeave={handleMouseLeaveMenu}
            type="element"
            index={index}
            parentID={parentID}
            draggable={draggable}
            direction="column"
            handleDelete={() => removeElement(id)}
            backgroundColor={globalTheme?.palette?.['grey']?.[100]}
          />
        )}

      <TextareaWrapper className={canEdit ? 'nodrag' : ''}>
        <Editor
          ref={ref}
          readOnly={!canEdit}
          onFocus={() => setIsFocusTextarea(true)}
          onBlur={handleOnBlur}
          editorState={editorState}
          onChange={handleChange}
        />
      </TextareaWrapper>
    </ContainerStyled>
  );
};

export default memo(EditorNote);
