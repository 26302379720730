import React, { PropsWithChildren, createContext } from 'react';

interface IGContext {
  linkIG: () => void;
}

interface IGProviderProps {
  appID: string;
}

export const IGCtx = createContext<IGContext>({
  linkIG: () => null,
});

export const IGProvider: React.FC<PropsWithChildren<IGProviderProps>> = ({
  appID,
  children,
}) => {
  const scope = [
    'instagram_business_basic',
    'instagram_business_content_publish',
    'instagram_business_manage_messages',
    'instagram_business_manage_comments',
  ];

  const domain = window.location.origin;
  const redirectURI = `${domain}/redirect-instagram`;
  const link = `https://www.instagram.com/oauth/authorize?client_id=${appID}&redirect_uri=${redirectURI}&response_type=code&scope=${scope.join(
    ',',
  )}`;

  const linkIG = () => {
    window.open(link, '_blank', 'width=500,height=800');
  };

  return (
    <IGCtx.Provider
      value={{
        linkIG,
      }}
    >
      {children}
    </IGCtx.Provider>
  );
};
