import { Box, TextField, TextFieldProps, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';

interface TextareaProps {
  value?: string;
  defaultValue?: string;
  limit?: number;
  styles?: CSSProperties;
  height?: number;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: () => void;
}

const TextareaTooltipStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '14px',
  bottom: theme.spacing(-3),
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 99,
}));

const TextCountWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '12px',
  width: 'fit-content',
}));

const EMOJI_STRING_LENGTH = 3;

export const getTextareaContentLength = (content: string) => {
  // emoji replace 時會多一個字元
  const replacement = ' '.repeat(EMOJI_STRING_LENGTH - 1);
  content = content.replace(
    // 找 emoji 的正則
    /[\uD83C-\uDBFF\uDC00-\uDFFF\u2600-\u26FF\u2700-\u27BF]/u,
    replacement,
  );

  // 回傳修改後的 content 的字數
  return content.length;
};

export const Textarea = forwardRef<
  HTMLTextAreaElement,
  TextareaProps & TextFieldProps
>(
  (
    {
      label = '',
      required = false,
      disabled = false,
      variant = 'outlined',
      placeholder = '',
      helperText = '',
      multiline = true,
      rows = 2,
      defaultValue,
      value = '',
      fullWidth = true,
      limit = 99999,
      onChange,
    },
    ref: ForwardedRef<HTMLTextAreaElement>,
  ) => {
    const [textLength, setTextLength] = useState(value.length ?? 0);
    const [isError, setIsError] = useState<boolean>(false);
    const [isTextLengthExceedLimit, setIsTextLengthExceedLimit] =
      useState<boolean>(false);

    const checkIsTextLengthExceedLimit = useCallback(
      (length: number) => {
        if (length > limit) {
          setIsError(true);
          setIsTextLengthExceedLimit(true);
        } else {
          setIsError(false);
          setIsTextLengthExceedLimit(false);
        }
      },
      [limit],
    );

    const handleOnChange = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        const length = getTextareaContentLength(value);

        if (length > limit) {
          return;
        }

        setTextLength(length);
        checkIsTextLengthExceedLimit(length);

        // Call the external onChange handler if provided
        if (onChange) {
          onChange(event);
        }
      },
      [checkIsTextLengthExceedLimit, limit, onChange],
    );

    const handleOnBlur = useCallback(() => {
      if (textLength === 0) {
        setIsError(true);
      }
    }, [textLength]);

    useEffect(() => {
      setTextLength(value.length ?? 0);
      checkIsTextLengthExceedLimit(value.length ?? 0);
    }, [value, checkIsTextLengthExceedLimit]);

    return (
      <Box
        component="form"
        sx={{ width: '100%', position: 'relative' }}
        noValidate
        autoComplete="off"
      >
        <TextField
          inputRef={ref}
          label={label}
          required={required}
          disabled={disabled}
          error={isError}
          variant={variant}
          placeholder={placeholder}
          helperText={helperText}
          rows={rows}
          multiline={multiline}
          fullWidth={fullWidth}
          value={value}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
        />
        <TextareaTooltipStyled onMouseDown={(e) => e.preventDefault()}>
          <TextCountWrapper>
            <Typography
              variant="caption"
              color={isTextLengthExceedLimit ? 'error' : 'grey.500'}
            >
              {`${textLength}/${limit}`}
            </Typography>
          </TextCountWrapper>
        </TextareaTooltipStyled>
      </Box>
    );
  },
);

export default Textarea;
