import { globalTheme } from '@frontend/components/external-providers';
import { useCanvasAdd, useCanvasRemove } from '@frontend/editor/data-access';
import {
  ActionCellTypesEnum,
  ElementTypesEnum,
} from '@frontend/editor/interface';
import { uuid } from '@frontend/editor/utils';
import { HOW_TO_USE_ATTRIBUTE_EN } from '@frontend/sorghum/utils';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkRemoveOutlinedIcon from '@mui/icons-material/BookmarkRemoveOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import CommentBankOutlinedIcon from '@mui/icons-material/CommentBankOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import TextIcon from '@mui/icons-material/FormatColorText';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import LabelOffOutlinedIcon from '@mui/icons-material/LabelOffOutlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { Box, ClickAwayListener, Link } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NodeProps, Position } from 'reactflow';
import EditorMenu, { EditorMenuItemList } from '../editor-menu/editor-menu';
import { HandlePoint } from '../handle-point/handle-point';
import { RedeemCouponIcon, SendCouponIcon, ShowCouponIcon } from '../icons';

interface NodeCreateBlockMenuProps {
  sourceOutputID: string;
}

const ContainerStyled = styled(Box)(({ theme }) => ({
  background: '#ffffff',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: get('theme.shadows[24]', ''),
  maxHeight: '400px',
  overflow: 'auto',
}));

export const NodeCreateBlockMenu = ({
  data,
  xPos,
  yPos,
}: NodeProps<NodeCreateBlockMenuProps>) => {
  const { sourceOutputID } = data;

  const [t] = useTranslation();
  const {
    addBlock,
    addTextButton,
    addRecurringNotification,
    removeCreateBlockMenu,
    addSendCoupon,
    addShowCoupon,
    addRedeemCoupon,
    addCondition,
    addAction,
    addActionSetAttribute,
    addActionRemoveAttribute,
    addActionSubscribeSequence,
    addActionUnsubscribeSequence,
    addActionExportGoogleSheet,
    addImage,
    addGallery,
    addCollectUserAnswer,
    addJumpToFlow,
  } = useCanvasAdd();
  const { removeCreateMenu } = useCanvasRemove();

  const handleAddBlockElement = useCallback(
    (type: ElementTypesEnum) => {
      removeCreateBlockMenu();
      const blockID = addBlock(sourceOutputID, { x: xPos, y: yPos });
      switch (type) {
        case ElementTypesEnum.TEXT_BUTTON: {
          addTextButton(blockID);
          break;
        }
        case ElementTypesEnum.RECURRING_NOTIFICATION: {
          addRecurringNotification(blockID);
          break;
        }
        case ElementTypesEnum.IMAGE: {
          addImage(blockID);
          break;
        }
        case ElementTypesEnum.GALLERY: {
          addGallery(blockID);
          break;
        }
        case ElementTypesEnum.ACTION: {
          addImage(blockID);
          break;
        }
        case ElementTypesEnum.COLLECT_USER_ANSWER: {
          addCollectUserAnswer(blockID);
          break;
        }
      }
    },
    [
      removeCreateBlockMenu,
      addBlock,
      addCollectUserAnswer,
      addGallery,
      addImage,
      addRecurringNotification,
      addTextButton,
      sourceOutputID,
      xPos,
      yPos,
    ],
  );

  // 暫時隱藏這個功能
  // const onShareLinkItemClick = useCallback(() => {
  //   addShareLink(sourceOutputID, { x: xPos, y: yPos });
  // }, [addShareLink, sourceOutputID, xPos, yPos]);

  const onSendCouponItemClick = useCallback(() => {
    removeCreateBlockMenu();
    addSendCoupon(sourceOutputID, { x: xPos, y: yPos });
  }, [addSendCoupon, removeCreateBlockMenu, sourceOutputID, xPos, yPos]);

  const onShowCouponItemClick = useCallback(() => {
    removeCreateBlockMenu();
    addShowCoupon(sourceOutputID, { x: xPos, y: yPos });
  }, [addShowCoupon, removeCreateBlockMenu, sourceOutputID, xPos, yPos]);

  const onRedeemCouponItemClick = useCallback(() => {
    removeCreateBlockMenu();
    addRedeemCoupon(sourceOutputID, { x: xPos, y: yPos });
  }, [addRedeemCoupon, removeCreateBlockMenu, sourceOutputID, xPos, yPos]);

  const onConditionItemClick = useCallback(() => {
    removeCreateBlockMenu();
    addCondition(sourceOutputID, { x: xPos, y: yPos });
  }, [addCondition, removeCreateBlockMenu, sourceOutputID, xPos, yPos]);

  const onJumpToFlowItemClick = useCallback(() => {
    removeCreateBlockMenu();
    addJumpToFlow(sourceOutputID, { x: xPos, y: yPos });
  }, [addJumpToFlow, removeCreateBlockMenu, sourceOutputID, xPos, yPos]);

  const onActionItemClick = useCallback(
    (cellType: ActionCellTypesEnum) => {
      removeCreateBlockMenu();
      const actionID = addAction(sourceOutputID, { x: xPos, y: yPos });
      switch (cellType) {
        case ActionCellTypesEnum.SET: {
          addActionSetAttribute(actionID);
          break;
        }
        case ActionCellTypesEnum.REMOVE: {
          addActionRemoveAttribute(actionID);
          break;
        }
        case ActionCellTypesEnum.SUBSCRIBE_SEQUENCE: {
          addActionSubscribeSequence(actionID);
          break;
        }
        case ActionCellTypesEnum.UNSUBSCRIBE_SEQUENCE: {
          addActionUnsubscribeSequence(actionID);
          break;
        }
        case ActionCellTypesEnum.EXPORT_GOOGLE_SHEET: {
          addActionExportGoogleSheet(actionID);
          break;
        }
      }
    },
    [
      addAction,
      addActionExportGoogleSheet,
      addActionRemoveAttribute,
      addActionSetAttribute,
      addActionSubscribeSequence,
      addActionUnsubscribeSequence,
      removeCreateBlockMenu,
      sourceOutputID,
      xPos,
      yPos,
    ],
  );

  const createBlockMenu: EditorMenuItemList[] = [
    {
      title: t('canvas.contentTitle'),
      dropdownItems: [
        {
          id: 'option_text_button',
          onClick: () => handleAddBlockElement(ElementTypesEnum.TEXT_BUTTON),
          icon: <TextIcon color="primary" />,
          title: t('canvas.blockMenu.contentButton'),
        },
        {
          id: 'option_image',
          icon: <ImageOutlinedIcon color="primary" />,
          title: t('canvas.blockMenu.image'),
          onClick: () => handleAddBlockElement(ElementTypesEnum.IMAGE),
        },
        {
          id: 'option_gallery',
          icon: <CollectionsOutlinedIcon color="primary" />,
          title: t('canvas.blockMenu.gallery'),
          onClick: () => handleAddBlockElement(ElementTypesEnum.GALLERY),
          tooltip: <Box>{t('canvas.blockMenu.showGalleryTooltip')}</Box>,
        },
        {
          id: 'option_theme_subscription',
          icon: <NotificationsNoneIcon color="primary" />,
          title: t('canvas.blockMenu.recurringNotificationsButton'),
          onClick: () =>
            handleAddBlockElement(ElementTypesEnum.RECURRING_NOTIFICATION),
          tooltip: <Box>{t('canvas.blockMenu.rnTooltip')}</Box>,
        },
        {
          id: 'option_collect_user_answer',
          icon: <CommentBankOutlinedIcon color="primary" />,
          title: t('canvas.blockMenu.collectUserAnswerButton'),
          onClick: () =>
            handleAddBlockElement(ElementTypesEnum.COLLECT_USER_ANSWER),
          tooltip: (
            <Box>{t('canvas.blockMenu.showCollectUserAnswerTooltip')}</Box>
          ),
        },
      ],
    },
    {
      title: t('canvas.marketingTool'),
      dropdownItems: [
        // {
        //   onClick: onShareLinkItemClick,
        //   icon: (
        //     <ShareIcon
        //       sx={{
        //         color: globalTheme.palette?.grass?.[600],
        //       }}
        //     />
        //   ),
        //   title: t('canvas.blockMenu.shareLink'),
        // },
        {
          id: 'option_send_coupon',
          onClick: onSendCouponItemClick,
          icon: (
            <SendCouponIcon
              sx={{
                color: globalTheme.palette?.grass?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.sendCoupon'),
          tooltip: <Box>{t('canvas.blockMenu.sendCouponTooltip')}</Box>,
        },
        {
          id: 'option_show_coupon',
          onClick: onShowCouponItemClick,
          icon: (
            <ShowCouponIcon
              sx={{
                color: globalTheme.palette?.grass?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.showCoupon'),
          tooltip: <Box>{t('canvas.blockMenu.showCouponTooltip')}</Box>,
        },
        {
          id: 'option_redeem_coupon',
          onClick: onRedeemCouponItemClick,
          icon: (
            <RedeemCouponIcon
              sx={{
                color: globalTheme.palette?.grass?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.redeemCoupon'),
          tooltip: <Box>{t('canvas.blockMenu.redeemCouponTooltip')}</Box>,
        },
      ],
    },
    {
      title: t('canvas.blockMenu.action'),
      dropdownItems: [
        {
          id: 'option_set_attribute',
          onClick: () => onActionItemClick(ActionCellTypesEnum.SET),
          icon: (
            <BookmarkBorderOutlinedIcon
              sx={{
                color: globalTheme.palette?.purple?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.setAttribute'),
          tooltip: (
            <Box>
              {t('canvas.blockMenu.setAttributeTooltip')}{' '}
              <Link
                sx={{
                  color: get(globalTheme, 'palette.infoOnTooltips.main', ''),
                  cursor: 'pointer',
                }}
                onClick={() => window.open(HOW_TO_USE_ATTRIBUTE_EN, '_blank')}
              >
                {t('canvas.blockMenu.setAttributeTooltipLink')}
              </Link>
            </Box>
          ),
        },
        {
          id: 'option_remove_attribute',
          onClick: () => onActionItemClick(ActionCellTypesEnum.REMOVE),
          icon: (
            <BookmarkRemoveOutlinedIcon
              sx={{
                color: globalTheme.palette?.purple?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.removeAttribute'),
        },
        {
          id: 'option_subscribe_sequence',
          onClick: () =>
            onActionItemClick(ActionCellTypesEnum.SUBSCRIBE_SEQUENCE),
          icon: (
            <LabelOutlinedIcon
              sx={{
                color: globalTheme.palette?.purple?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.subscribeSequence'),
        },
        {
          id: 'option_unsubscribe_sequence',
          onClick: () =>
            onActionItemClick(ActionCellTypesEnum.UNSUBSCRIBE_SEQUENCE),
          icon: (
            <LabelOffOutlinedIcon
              sx={{
                color: globalTheme.palette?.purple?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.unsubscribeSequence'),
        },
        {
          id: 'option_google_sheet',
          onClick: () =>
            onActionItemClick(ActionCellTypesEnum.EXPORT_GOOGLE_SHEET),
          icon: (
            <TableChartOutlinedIcon
              sx={{
                color: globalTheme.palette?.purple?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.exportGoogleSheet'),
          tooltip: <Box>{t('canvas.blockMenu.exportGoogleSheetTooltip')}</Box>,
        },
      ],
    },
    {
      title: t('canvas.condition.title'),
      dropdownItems: [
        {
          id: 'option_condition',
          onClick: onConditionItemClick,
          icon: (
            <FilterAltOutlinedIcon
              sx={{
                color: globalTheme.palette?.orange?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.condition'),
        },
        {
          id: 'option_jump_to_flow',
          onClick: onJumpToFlowItemClick,
          icon: (
            <AccountTreeOutlinedIcon
              sx={{
                color: globalTheme.palette?.orange?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.jumpToFlow'),
          tooltip: <Box>{t('canvas.blockMenu.jumpToFlowTooltip')}</Box>,
        },
      ],
    },
  ];

  return (
    <ClickAwayListener onClickAway={removeCreateMenu}>
      <ContainerStyled className="nowheel nodrag nopan">
        <HandlePoint
          id={uuid()}
          type="target"
          position={Position.Left}
          styles={{
            top: '5%',
          }}
          isConnected={false}
        />
        <EditorMenu itemList={createBlockMenu} />
      </ContainerStyled>
    </ClickAwayListener>
  );
};

export default memo(NodeCreateBlockMenu);
