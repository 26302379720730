import { Divider, Typography } from '@frontend/components/ui';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  GetBillingPlanEnum,
  GetBillingResponseType,
  ModalTypesEnum,
  PaymentStatus,
  Project,
  SocialTypeEnum,
} from '@frontend/sorghum/interface';
import {
  dayjs,
  FACEBOOK_PLAN_DETAILS_EN,
  FACEBOOK_PLAN_DETAILS_ZH,
  INSTAGRAM_PRICING_PAGE_EN,
  INSTAGRAM_PRICING_PAGE_ZH,
  PAYMENT_DETAILS,
  SETTING,
  THREADS_PRICING_PAGE_EN,
  THREADS_PRICING_PAGE_ZH,
  usePath,
} from '@frontend/sorghum/utils';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import i18next from 'i18next';
import { get } from 'lodash';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export interface BillingInformationProps {
  planTitle: string;
  billing: GetBillingResponseType | undefined;
  price: string;
  period: string;
  project: Project | undefined;
  handleUpgradePlan: () => void;
  isViewer: boolean;
  handleCancelPlan: () => void;
}

const PaymentErrorWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  background: theme.palette['background']['error'][5],
  padding: '12px 16px',
  gap: '8px',
  borderRadius: '4px',

  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    gap: '9.5px',
  },
}));

const CardWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '16px 24px',
  background: theme.palette['grey'][100],
  borderRadius: '12px',
}));

const PlanWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 0',
}));

const PlanSpanWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  background: theme.palette['background']['primary'][5],
  padding: '4px 12px',
  borderRadius: '4px',
}));

const DetailButtonStyled = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 24px',
  borderRadius: '12px',
  background: theme.palette['grey'][100],
  cursor: 'pointer',
  textTransform: 'none',
  color: theme.palette['grey'][900],

  '&:hover': {
    background: theme.palette['grey'][100],
  },

  '&:disabled': {
    color: theme.palette['grey'][500],
  },
}));

export function BillingInformation({
  planTitle,
  billing,
  price,
  period,
  project,
  handleUpgradePlan,
  isViewer,
  handleCancelPlan,
}: BillingInformationProps) {
  const [t] = useTranslation();
  const UIState = useContext(UICtx);
  const { navigateToProjectPage } = usePath();

  const inPlan =
    billing?.plan === GetBillingPlanEnum.TRIAL ||
    billing?.plan === GetBillingPlanEnum.ADVANCE;

  const isBeta = billing?.plan === GetBillingPlanEnum.BETA;

  const paymentStatus = get(project, 'paymentStatus', PaymentStatus.NORMAL);

  return (
    <>
      {paymentStatus === PaymentStatus.ABNORMAL && (
        <PaymentErrorWrapperStyled>
          <ErrorOutlineOutlinedIcon color="error" fontSize="small" />
          <Box>
            <Typography variant="subtitle2" color="error.dark">
              {t('setting.payment.errorTitle')}
            </Typography>
            <Typography variant="body2" color="error.dark">
              {t('setting.payment.errorMessage')}
            </Typography>
          </Box>
        </PaymentErrorWrapperStyled>
      )}
      <CardWrapperStyled>
        <PlanWrapperStyled>
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography variant="h5" color="grey.800">
              {planTitle}
            </Typography>
            {inPlan && billing?.startedAt && billing?.endedAt && !isBeta && (
              <Typography variant="body2" color="grey.600">
                {dayjs(billing?.startedAt).tz().format('YYYY/MM/DD')} -{' '}
                {dayjs(billing?.endedAt).tz().format('YYYY/MM/DD')}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'end',
            }}
          >
            {inPlan || isBeta ? (
              <>
                <Typography variant="h5" color="grey.900">
                  {price}
                </Typography>
                <Typography variant="body2" color="grey.600">
                  {period}
                </Typography>
              </>
            ) : (
              <Typography variant="body2" color="grey.900" alignSelf="center">
                -
              </Typography>
            )}
          </Box>
        </PlanWrapperStyled>
        {billing?.plan === GetBillingPlanEnum.ADVANCE && (
          <>
            {billing?.subscribed && billing?.endedAt && (
              <PlanSpanWrapperStyled>
                <Typography variant="caption" color="grey.800">
                  {t('setting.payment.renew', {
                    date: dayjs(billing?.endedAt).tz().format('YYYY/MM/DD'),
                  })}
                </Typography>
              </PlanSpanWrapperStyled>
            )}
            {!billing?.subscribed && billing?.cancelledAt && !isBeta && (
              <PlanSpanWrapperStyled>
                <Typography variant="caption" color="grey.800">
                  {t('setting.payment.cancelOn', {
                    date: dayjs(billing?.cancelledAt).tz().format('YYYY/MM/DD'),
                  })}
                </Typography>
              </PlanSpanWrapperStyled>
            )}
          </>
        )}
        {!isBeta && (
          <>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                gap: '16px',
              }}
            >
              {paymentStatus === 2 ? (
                <>
                  <Button
                    id="upgrade"
                    onClick={handleUpgradePlan}
                    disabled={isViewer}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {t('setting.payment.updatePlan')}
                  </Button>
                  <Button
                    id="change_payment"
                    variant="contained"
                    onClick={() =>
                      UIState.openModal(ModalTypesEnum.CHANGE_PAYMENT_METHOD)
                    }
                    disabled={isViewer}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {t('setting.payment.changePayment')}
                  </Button>
                </>
              ) : (
                <Button
                  id={billing?.subscribed ? 'cancel' : 'upgrade'}
                  sx={{ textTransform: 'capitalize' }}
                  variant={billing?.subscribed ? 'text' : 'contained'}
                  onClick={
                    billing?.subscribed ? handleCancelPlan : handleUpgradePlan
                  }
                  disabled={isViewer}
                >
                  {billing?.subscribed
                    ? t('common.cancel')
                    : t('setting.payment.upgrade')}
                </Button>
              )}
            </Box>
          </>
        )}
      </CardWrapperStyled>
      {!isBeta && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '16px',
          }}
        >
          <DetailButtonStyled
            onClick={() => {
              navigateToProjectPage({ path: `/${SETTING}/${PAYMENT_DETAILS}` });
            }}
            disableRipple
            disabled={isViewer}
          >
            <Typography variant="body2">
              {t('setting.payment.detail.payment')}
            </Typography>
            <ArrowForwardIosIcon fontSize="tiny" />
          </DetailButtonStyled>
          <DetailButtonStyled
            onClick={() => {
              if (project) {
                switch (project.socialType) {
                  case SocialTypeEnum.FACEBOOK: {
                    window.open(
                      i18next.language.includes('en')
                        ? FACEBOOK_PLAN_DETAILS_EN
                        : FACEBOOK_PLAN_DETAILS_ZH,
                      '_blank',
                    );
                    break;
                  }
                  case SocialTypeEnum.THREADS: {
                    window.open(
                      i18next.language.includes('en')
                        ? THREADS_PRICING_PAGE_EN
                        : THREADS_PRICING_PAGE_ZH,
                      '_blank',
                    );
                    break;
                  }
                  case SocialTypeEnum.INSTAGRAM: {
                    window.open(
                      i18next.language.includes('en')
                        ? INSTAGRAM_PRICING_PAGE_EN
                        : INSTAGRAM_PRICING_PAGE_ZH,
                      '_blank',
                    );
                    break;
                  }
                }
              }
            }}
            disableRipple
          >
            <Typography variant="body2">
              {t('setting.payment.detail.plan')}
            </Typography>
            <ArrowForwardIosIcon fontSize="tiny" />
          </DetailButtonStyled>
        </Box>
      )}
    </>
  );
}

export default BillingInformation;
