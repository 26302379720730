import { Checkbox, Dialog, Tab, Tabs } from '@frontend/components/ui';
import { useGetCommentAutoReplyPost } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  CommentReplyPostType,
  GetCommentAutoReplyPost,
  ModalTypesEnum,
  SocialTypeEnum,
} from '@frontend/sorghum/interface';
import {
  HOW_TO_COPY_POST,
  PAGE_COMMENT_AUTO_REPLY,
  dayjs,
  useCustomizedTranslation,
  usePath,
} from '@frontend/sorghum/utils';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  InputAdornment,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PostImage from '../../images/post-image.svg';

interface MessengerCommentAutoReplyPostModalProps {
  commentAutoReplyID: string;
  selectedValue: GetCommentAutoReplyPost[];
  handleSubmit: (posts: GetCommentAutoReplyPost[]) => void;
  facebookID: string;
}

const ModalContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

const PostContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '300px',
  '& > div:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette['grey'][200]}`,
  },
  overflow: 'auto',
}));

const EmptyWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
}));

const PostRowStyled = styled(Box)<{
  $selected?: boolean;
  $disabled?: boolean;
}>(({ theme, $selected, $disabled }) => ({
  position: 'relative',
  display: 'inline-flex',
  width: '100%',
  alignItems: 'center',
  padding: '12px 16px',
  gap: '24px',
  background: $selected
    ? theme.palette['background']['primary'][5]
    : 'transparent',

  '& > img': {
    width: '44px',
    height: '44px',
    minWidth: '44px',
    minHeight: '44px',
    borderRadius: '4px',
  },
}));

const PostMaskStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: theme.palette['grey'][100],
  left: 0,
  top: 0,
  opacity: 0.4,
  borderRadius: '4px',
}));

const PostMaskTooltipWrapperStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  opacity: 1,
}));

const PostRowDescriptionStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 16px',
  justifyContent: 'center',
  width: '100%',
  overflow: 'hidden',

  '& > div': {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const PostSelectedDescriptionStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'end',
}));

export const MessengerCommentAutoReplyPostModal: FC<
  MessengerCommentAutoReplyPostModalProps
> = ({ commentAutoReplyID, selectedValue = [], handleSubmit, facebookID }) => {
  const [t] = useTranslation();
  const { convertSocialTypeEnumToSocialName } = useCustomizedTranslation(
    SocialTypeEnum.FACEBOOK,
  );
  const { navigateToProjectPage } = usePath();
  const uiState = useContext(UICtx);
  const [tabValue, setTabValue] = useState(0);
  const [selectedPost, setSelectedPost] = useState<GetCommentAutoReplyPost[]>(
    [],
  );
  const [searchText, setSearchText] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  const { data: postList } = useGetCommentAutoReplyPost(
    CommentReplyPostType.POST,
  );

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleTabChange = useCallback(
    (_: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue);
    },
    [],
  );

  const handleConfirm = useCallback(() => {
    handleSubmit(selectedPost);
    uiState.closeModal();
  }, [handleSubmit, selectedPost, uiState]);

  const handleCheckPost = useCallback(
    (target: GetCommentAutoReplyPost, checked: boolean) => {
      if (checked) {
        setSelectedPost([...selectedPost, target]);
      } else {
        setSelectedPost(selectedPost.filter((item) => item.id !== target.id));
      }
    },
    [selectedPost],
  );

  const filterPostList = useMemo(() => {
    if (!postList) return [];
    const filterResult: GetCommentAutoReplyPost[] = [];

    postList.forEach((i) => {
      if (tabValue === 0) {
        filterResult.push(i);
      } else if (tabValue === 1 && !i.isPromoted) {
        filterResult.push(i);
      } else if (tabValue === 2 && i.isPromoted) {
        filterResult.push(i);
      }
    });

    if (searchText) {
      return filterResult.filter(
        (i) =>
          i.message?.includes(searchText) ||
          i.permalinkUrl?.includes(searchText),
      );
    }

    return filterResult;
  }, [postList, searchText, tabValue]);

  const gotoOtherCommentAutoReply = useCallback(
    (id: string) => {
      navigateToProjectPage({
        path: `${PAGE_COMMENT_AUTO_REPLY}/${id}`,
      });
      uiState.closeModal();
    },
    [navigateToProjectPage, uiState],
  );

  useEffect(() => {
    if (!isLoaded) {
      setSelectedPost(selectedValue);
      setIsLoaded(true);
    }
  }, [isLoaded, selectedValue]);

  useEffect(() => {
    if (uiState.modalType === ModalTypesEnum.EDIT_COMMENT_AUTO_REPLY_POST) {
      setIsLoaded(true);
    } else {
      setIsLoaded(false);
    }
  }, [uiState.modalType]);

  return (
    <Dialog
      size="md"
      title={t('commentAutoReply.modal.post.title')}
      confirmBtnText={t('common.save')}
      cancelBtnText={t('common.cancel')}
      open={uiState.modalType === ModalTypesEnum.EDIT_COMMENT_AUTO_REPLY_POST}
      handleClose={uiState.closeModal}
      handleConfirm={handleConfirm}
    >
      <ModalContainerStyled>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab
              label={t('commentAutoReply.modal.post.tabs.all')}
              {...a11yProps(0)}
            />
            <Tab
              label={t('commentAutoReply.modal.post.tabs.published')}
              {...a11yProps(1)}
            />
            {/* 此階段暫時隱藏 */}
            {/* <Tab
              label={t('commentAutoReply.modal.post.tabs.promotable')}
              {...a11yProps(2)}
            /> */}
          </Tabs>
        </Box>
        <Box>
          <TextField
            size="small"
            placeholder={t('common.search')}
            sx={{ width: '100%' }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={
              <Typography variant="caption" color="grey.500">
                {t('commentAutoReply.modal.post.searchInfo')}
                <Link
                  target="_blank"
                  href={HOW_TO_COPY_POST}
                  color="info.main"
                  variant="overline"
                  sx={{ fontSize: '12px' }}
                  textTransform={'none'}
                >
                  {t('commentAutoReply.modal.post.howToCopy')}
                </Link>
              </Typography>
            }
          />
        </Box>
        <PostContainerStyled>
          {filterPostList.map((post) => (
            <PostRowStyled
              key={post.id}
              $selected={selectedPost.findIndex((i) => i.id === post.id) >= 0}
              $disabled={
                !!post.selectedPost &&
                post.selectedPost.commentReplyId !== commentAutoReplyID
              }
            >
              {!!post.selectedPost &&
                post.selectedPost.commentReplyId !== commentAutoReplyID && (
                  <PostMaskStyled>
                    <Tooltip
                      title={
                        <Typography variant="notoSans" color="grey.white">
                          {t(
                            'commentAutoReply.content.firstStep.selectedTooltipPrefix',
                          )}
                          <Link
                            variant="notoSans"
                            color="info.main"
                            onClick={() =>
                              gotoOtherCommentAutoReply(
                                post.selectedPost?.commentReplyId || '',
                              )
                            }
                          >
                            {post.selectedPost.commentReplyName}
                          </Link>
                          {t(
                            'commentAutoReply.content.firstStep.selectedTooltipSuffix',
                          )}
                        </Typography>
                      }
                    >
                      <PostMaskTooltipWrapperStyled />
                    </Tooltip>
                  </PostMaskStyled>
                )}
              <Checkbox
                color="primary"
                checked={selectedPost.findIndex((i) => i.id === post.id) >= 0}
                onChange={(e) => handleCheckPost(post, e.target.checked)}
                disabled={
                  !!post.selectedPost &&
                  post.selectedPost.commentReplyId !== commentAutoReplyID
                }
              />
              <img src={post.fullPicture || PostImage} alt="" />
              <PostRowDescriptionStyled
                onClick={() => window.open(post.permalinkUrl, '_blank')}
              >
                <Typography component="div" color="grey.900" variant="body3">
                  {post.message
                    ? post.message
                    : t('commentAutoReply.modal.post.postNoText')}
                </Typography>
                <Typography color="grey.500" variant="notoSans">
                  {dayjs(post.createdTime)
                    .tz()
                    .format('MMMM D, YYYY [at] h:mm A')}
                </Typography>
              </PostRowDescriptionStyled>
            </PostRowStyled>
          ))}
          {filterPostList.length === 0 && (
            <EmptyWrapperStyled>
              <Typography variant="body1" color="grey.500">
                {t('commentAutoReply.content.firstStep.postFilterNoResult')}
              </Typography>
              <Typography variant="body1" color="grey.500">
                {t('commentAutoReply.content.firstStep.postEmptyDescription', {
                  socialType: convertSocialTypeEnumToSocialName(),
                })}
              </Typography>
            </EmptyWrapperStyled>
          )}
        </PostContainerStyled>
        {selectedPost.length > 0 && (
          <PostSelectedDescriptionStyled>
            <Typography variant="body1" color="grey.500">
              {t('commentAutoReply.modal.post.postSelected', {
                count: selectedPost.length,
              })}
            </Typography>
          </PostSelectedDescriptionStyled>
        )}
      </ModalContainerStyled>
    </Dialog>
  );
};

export default MessengerCommentAutoReplyPostModal;
