import { queryClient } from '@frontend/sorghum/external-providers';
import {
  ProjectDisconnectRequest,
  Response,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT_DISCONNECT } from '../apis';

async function postProjectDisconnect(
  props: ProjectDisconnectRequest,
): Promise<Response> {
  return axios.post(PROJECT_DISCONNECT, props).then((res) => res.data);
}

export function useProjectDisconnect() {
  return useMutation({
    mutationKey: ['project-disconnect'],
    mutationFn: (props: ProjectDisconnectRequest) =>
      postProjectDisconnect(props),
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries(['facebook-pages']);
      queryClient.invalidateQueries(['project-linked']);
      queryClient.invalidateQueries(['get-project']);
      queryClient.invalidateQueries(['get-project-list']);
    },
  });
}

export default useProjectDisconnect;
