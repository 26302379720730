import { Chip } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { useGetProject } from '@frontend/sorghum/data-access';
import { ProjectStatus } from '@frontend/sorghum/interface';
import { useCustomizedTranslation } from '@frontend/sorghum/utils';

export function ProjectTag() {
  const { data: projectID } = useGetProjectID();
  const { data: project } = useGetProject(projectID);

  const { t } = useCustomizedTranslation(project?.socialType);

  switch (project?.status) {
    case ProjectStatus.TRIAL:
      return <Chip label={t('badge.trial')} size="small" />;
    case ProjectStatus.ADVANCE:
      return <Chip label={t('badge.advanced')} size="small" />;
    case ProjectStatus.BETA:
      return <Chip label={t('badge.beta')} size="small" />;
    case ProjectStatus.VIEW_ONLY:
    default:
      return <Chip color="error" label={t('badge.limit')} size="small" />;
  }
}

export default ProjectTag;
