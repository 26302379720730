import { IconSizeType } from '@frontend/components/interface';
import {
  Box,
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material';
import { styled } from '@mui/system';

interface IconButtonProps extends MuiIconButtonProps {
  $size: IconSizeType;
}

// 因 Material UI 元件多處有用到 IconButton，故在不影響原有元件 UI 的狀況下另外設定客製化 size 大小的 IconButton
const IconButtonStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
}));

export function IconButton({ children, $size, ...props }: IconButtonProps) {
  let fontSize = '12px';
  switch ($size) {
    case IconSizeType.XXS: {
      fontSize = '12px';
      break;
    }
    case IconSizeType.XS: {
      fontSize = '16px';
      break;
    }
    case IconSizeType.S: {
      fontSize = '20px';
      break;
    }
    case IconSizeType.M: {
      fontSize = '24px';
      break;
    }
    case IconSizeType.L: {
      fontSize = '36px';
      break;
    }
    case IconSizeType.XL: {
      fontSize = '48px';
      break;
    }
  }

  return (
    <IconButtonStyled>
      <MuiIconButton
        {...props}
        sx={{
          ...(props?.sx && props?.sx),
          '& svg': {
            fontSize,
          },
        }}
      >
        {children}
      </MuiIconButton>
    </IconButtonStyled>
  );
}

export default IconButton;
