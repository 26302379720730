import { GetDataCommentReplySummary } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY_DATA_SUMMARY } from '../apis';

const defaultValue = {
  publicReply: 0,
  uniqPublicReply: 0,
  replyUsers: 0,
  replyTimes: 0,
  privateReply: 0,
  replyCreated: 0,
};

async function getGetDataCommentReplySummary(): Promise<GetDataCommentReplySummary> {
  return axios
    .get(COMMENT_REPLY_DATA_SUMMARY)
    .then((res) => res.data.data)
    .catch(() => {
      return defaultValue;
    });
}

export function useGetDataCommentReplySummary() {
  return useQuery<GetDataCommentReplySummary>({
    queryKey: ['get-data-comment-reply-summary'],
    queryFn: () => getGetDataCommentReplySummary(),
  });
}

export default useGetDataCommentReplySummary;
