import { ChartDataType } from '@frontend/components/interface';
import {
  Button,
  ChartLine,
  Tooltip,
  Typography,
} from '@frontend/components/ui';
import { formatNumberWithCommas } from '@frontend/editor/utils';
import {
  usePostDataDashboardOperationFlowChart,
  usePostDataDashboardOperationFlowList,
} from '@frontend/sorghum/data-access';
import { PostDataDashboardProps } from '@frontend/sorghum/interface';
import {
  PAGE_DASHBOARD_FLOW_COMPARE,
  PAGE_FLOW_VIEW,
  usePath,
} from '@frontend/sorghum/utils';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import { set } from 'lodash';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DataDashboardChartContainer from '../data-dashboard-chart-container/data-dashboard-chart-container';

interface Props {
  startDate: string;
  endDate: string;
}

const TableContainerStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette['grey']['white'],
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: '20px',
  padding: '24px',
  boxShadow: theme.shadows[2],
  borderRadius: '8px',
  width: '100%',
  height: '100%',
  minHeight: '380px',
}));

const TitleStyled = styled(Box)(({ theme }) => ({
  height: '32px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const InfoIconStyled = styled(InfoIcon)({
  margin: '0 0 -4px 4px',
});

const DetailButtonStyled = styled(Button)(({ theme }) => ({
  height: '32px',
  borderRadius: '4px',
  border: `1px solid ${theme.palette['bluegrey']['400']}`,
}));

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette['grey'][100],
  },
}));

export const Chart: FC<PostDataDashboardProps> = ({
  startDate,
  endDate,
  period,
}: PostDataDashboardProps) => {
  const [t] = useTranslation();
  const { data, isFetching } = usePostDataDashboardOperationFlowChart(
    startDate,
    endDate,
    period as number,
  );

  const chartData = useMemo(() => {
    const newChartData: ChartDataType[] = [];
    data?.forEach((i) => {
      const newData = {
        name: dayjs(i.startDate).format('MM/DD'),
      };
      i.flows.forEach((flow) => {
        set(newData, flow.flowName, flow.count);
      });

      newChartData.push(newData);
    });
    return newChartData;
  }, [data]);

  return (
    <ChartLine
      data={chartData}
      title={t('dataDashboard.numberOfUniqueUsers')}
      emptyDisplayName={t('dataDashboard.deletedFlow')}
      labelLimit={21}
      isLoading={isFetching}
    />
  );
};

export const Flow: FC<Props> = ({ startDate, endDate }: Props) => {
  const { navigateToProjectPage } = usePath();
  const [t] = useTranslation();
  const { data } = usePostDataDashboardOperationFlowList({
    startDate,
    endDate,
    offset: 0,
    limit: 5,
  });

  const handleButtonClick = useCallback(() => {
    navigateToProjectPage({ path: `${PAGE_DASHBOARD_FLOW_COMPARE}` });
  }, [navigateToProjectPage]);

  const handleRowClick = useCallback(
    (flowID: string) => {
      navigateToProjectPage({ path: `${PAGE_FLOW_VIEW}/${flowID}` });
    },
    [navigateToProjectPage],
  );

  return (
    <>
      <TableContainerStyled>
        <TitleStyled>
          <Typography variant="subtitle2" color="grey.800">
            {t('dataDashboard.operationAnalysis.flow.analysis.1.title')}
          </Typography>
          <DetailButtonStyled onClick={handleButtonClick}>
            <Typography variant="buttonSmall" color="bluegrey.500">
              {t('dataDashboard.operationAnalysis.flow.analysis.1.button')}
            </Typography>
          </DetailButtonStyled>
        </TitleStyled>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="left">
                <Typography variant="body3" color="grey.500">
                  {t(
                    'dataDashboard.operationAnalysis.flow.analysis.1.column.title',
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body3" color="grey.500">
                  {t(
                    'dataDashboard.operationAnalysis.flow.analysis.1.column.entries',
                  )}
                  <Tooltip
                    placement="top"
                    title={t(
                      'dataDashboard.operationAnalysis.flow.analysis.1.column.entriesTooltip',
                    )}
                  >
                    <InfoIconStyled fontSize="small" />
                  </Tooltip>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body3" color="grey.500">
                  {t(
                    'dataDashboard.operationAnalysis.flow.analysis.1.column.enteringUsers',
                  )}
                  <Tooltip
                    placement="top"
                    title={t(
                      'dataDashboard.operationAnalysis.flow.analysis.1.column.enteringUsersTooltip',
                    )}
                  >
                    <InfoIconStyled fontSize="small" />
                  </Tooltip>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body3" color="grey.500">
                  {t(
                    'dataDashboard.operationAnalysis.flow.analysis.1.column.exchanges',
                  )}
                  <Tooltip
                    placement="top"
                    title={t(
                      'dataDashboard.operationAnalysis.flow.analysis.1.column.exchangesTooltip',
                    )}
                  >
                    <InfoIconStyled fontSize="small" />
                  </Tooltip>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.flows?.map((i, index) => {
              return (
                <TableRowStyled
                  key={index}
                  onClick={() => handleRowClick(i.id)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <Typography variant="body3" color="grey.500">
                      {index + 1}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Box
                      sx={{
                        maxWidth: '160px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <Typography variant="body3" color="grey.700">
                        {i.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body3" color="grey.700">
                      {i.enterUsers
                        ? formatNumberWithCommas(i.enterUsers)
                        : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body3" color="grey.700">
                      {i.uniqEnterUsers
                        ? formatNumberWithCommas(i.uniqEnterUsers)
                        : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body3" color="grey.700">
                      {i.avgConversation
                        ? formatNumberWithCommas(i.avgConversation)
                        : '-'}
                    </Typography>
                  </TableCell>
                </TableRowStyled>
              );
            })}
          </TableBody>
        </Table>
      </TableContainerStyled>
      <DataDashboardChartContainer
        title={t('dataDashboard.operationAnalysis.flow.analysis.2.title')}
        tooltip={t('dataDashboard.operationAnalysis.flow.analysis.2.tooltip')}
        startDate={startDate}
        endDate={endDate}
        condition={[]}
        ChartContent={Chart}
        hasTimeIntervalSelector
      />
    </>
  );
};

export default Flow;
