import { GetCommentReplyResponseType } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY } from '../apis';

const defaultData = {
  isActive: false,
  name: '',
  postType: 1,
};

async function getCommentReply(
  uuid: string,
): Promise<GetCommentReplyResponseType> {
  return axios
    .get(`${COMMENT_REPLY}?id=${uuid}`)
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function useGetCommentReply(uuid: string) {
  return useQuery<GetCommentReplyResponseType>({
    queryKey: ['get-comment-reply', { uuid }],
    queryFn: () => {
      if (uuid) {
        return getCommentReply(uuid);
      } else {
        return defaultData;
      }
    },
  });
}
