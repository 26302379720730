import { MaterialStyledProps } from '@frontend/editor/interface';
import { Box, Checkbox } from '@mui/material';
import { styled } from '@mui/system';

export const ContainerWrapper = styled(Box)<MaterialStyledProps>(
  ({ theme }) => ({
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    width: '480px',
    backgroundColor: theme.palette['grey']['white'],
    marginLeft: '24px',
    borderRadius: '12px',
    boxShadow: theme.shadows[1],
  }),
);

export const CloseButtonWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
  padding: theme.spacing(1),
  color: theme.palette['grey'][500],
  paddingRight: 0,
  '& > button': {
    minWidth: 0,
  },
}));

export const ToggleWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'inline-flex',
  marginBottom: theme.spacing(2),
  '& > div': {
    marginRight: theme.spacing(2),
  },
  '& > div:nth-last-child(1)': {
    marginRight: 0,
  },
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette['grey'][200]}`,
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center`',
  borderRadius: '4px',
}));

export const SelectedIconWrapper = styled(IconWrapper)(({ theme }) => ({
  position: 'relative',
  flex: 2,
  '& > svg > g > path': {
    fill: theme.palette['primary']['main'],
    stroke: theme.palette['primary']['main'],
  },
  borderColor: theme.palette['primary']['main'],
  color: theme.palette['primary']['main'],
}));

export const CheckWrapper = styled(Checkbox)(({ theme }) => ({
  '&:hover': {
    background: 'transparent',
  },
}));
