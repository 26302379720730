import {
  ProjectListItem,
  ResponseWithData,
  SocialTypeEnum,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT_LIST } from '../apis';

const defaultValue = [
  {
    projectId: '',
    projectName: '',
    isLinked: false,
    shortCode: '',
    permission: 3,
    socialType: SocialTypeEnum.FACEBOOK,
    status: 1,
    linkName: 'xxx粉專',
    linkId: '23456789',
    expiryDate: '2024/09/12',
  },
];

async function getProjectList(): Promise<ResponseWithData<ProjectListItem[]>> {
  return axios
    .get(PROJECT_LIST)
    .then((res) => res.data)
    .catch(() => defaultValue);
}

export function useProjectList() {
  return useQuery<ResponseWithData<ProjectListItem[]>>({
    queryKey: ['get-project-list'],
    queryFn: () => getProjectList(),
  });
}

export default useProjectList;
