import { Button, Divider, Tooltip } from '@frontend/components/ui';
import { internalLinkToast } from '@frontend/components/utils';
import { useGetProjectID } from '@frontend/editor/data-access';
import {
  useGetFacebookPages,
  usePostProjectConnect,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { FacebookPage, ModalTypesEnum } from '@frontend/sorghum/interface';
import {
  FACEBOOK_FAN_PAGE_READ_MORE_EN,
  FACEBOOK_FAN_PAGE_READ_MORE_ZH,
  FACEBOOK_PAGE_CREATE,
  sendGAEvent,
  useFacebook,
  usePath,
} from '@frontend/sorghum/utils';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import { IconButton, Link, Modal, styled } from '@mui/material';
import { FC, useCallback, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Avatar, Box, Typography } from '../../index';

export interface FacebookPagesModalProps {
  // 判斷是在 flow 還是 setting 頁用到這個 modal
  openedPage: 'flow' | 'setting';
  facebookAppID: string;
  mode: string;
}

const ModalStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: theme.palette['grey']['white'],
  padding: '20px 24px',
  width: '550px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  border: '4px',
}));

const TitleWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '20px',
}));

const ButtonWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const LEARN_MORE_ZH =
  'https://industrious-marigold-f2e.notion.site/chatbot-FB-fa1de49b55eb44b0bafc3aceb4695261';

const ConnectErrorWrapperStyled = styled(Box)(({ theme }) => ({
  padding: '12px 16px',
  borderRadius: '4px',
  background: theme.palette['background']['error'][5],
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '8px',
}));

export const FacebookPagesModal: FC<FacebookPagesModalProps> = ({
  openedPage,
  facebookAppID,
  mode,
}) => {
  const [t, i18next] = useTranslation();
  const { closeModal, modalType } = useContext(UICtx);
  const { token: fbToken, requestPermission } = useFacebook({
    appId: facebookAppID,
    mode,
  });
  const { navigateToProjectPage } = usePath();
  const { data: projectID } = useGetProjectID();
  const { data: pages, refetch } = useGetFacebookPages(fbToken);
  const { mutate: connect, isLoading: isConnecting } = usePostProjectConnect();

  const [activeID, setActiveID] = useState<string>('');
  const [errorPageID, setErrorPageID] = useState<string | undefined>();
  const [connectionErrorMessage, setConnectionErrorMessage] = useState<
    string | undefined
  >();

  const refresh = useCallback(() => {
    requestPermission();
    refetch();
  }, [refetch, requestPermission]);

  const connectPages = useCallback(
    (id: string) =>
      connect(
        { target: 'fb', targetId: id, projectId: projectID },
        {
          onSuccess: (data, variables) => {
            if (data.code === 20000) {
              if (openedPage === 'setting') {
                sendGAEvent(
                  'Setting',
                  'Toast Hyperlink',
                  'Setting - toast - Back to the last flow link - click',
                  '',
                );
              }
              closeModal();
              internalLinkToast(
                t('setting.successMessage'),
                t('setting.start'),
                'success',
                () => navigateToProjectPage(),
                'toast_flow',
              );
            } else {
              setErrorPageID(variables?.targetId);
              setConnectionErrorMessage(data?.msg);
            }
          },
        },
      ),
    [closeModal, connect, navigateToProjectPage, openedPage, projectID, t],
  );

  const handleButtonClick = useCallback(
    (pageID: string) => {
      setActiveID(pageID);
      connectPages(pageID);
    },
    [connectPages],
  );

  const readMoreUrl =
    i18next.language === 'en_us'
      ? FACEBOOK_FAN_PAGE_READ_MORE_EN
      : FACEBOOK_FAN_PAGE_READ_MORE_ZH;

  return (
    <Modal
      open={modalType === ModalTypesEnum.CONNECT_FACEBOOK_PAGE}
      onClose={() => closeModal()}
    >
      <ModalStyled>
        <TitleWrapperStyled>
          <Typography variant="h6" color="grey.black">
            {t('pages.title')}
          </Typography>
          <IconButton
            onClick={() => closeModal()}
            size="small"
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </TitleWrapperStyled>
        <Typography variant="body1" color="grey.800">
          {t('pages.info')}
          &nbsp;
          <Link onClick={() => window.open(readMoreUrl, '_blank')}>
            {t('pages.readMore')}
          </Link>
        </Typography>
        <Divider />
        <Box height="192px" overflow="auto">
          <Box p="16px">
            {pages?.data &&
              pages?.data.map((page: FacebookPage) => {
                const isConnected = page.isLinked;
                return (
                  <Box key={`pages_page_${page.pageId}`}>
                    <Box
                      p="20px"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box flex="1">
                        <Avatar alt={page.pageId} src={page.picture} />
                      </Box>
                      <Box flex="2">
                        <Typography
                          variant="body2"
                          sx={{ textDecoration: 'underline' }}
                        >
                          {page.pageName}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ textDecoration: 'underline' }}
                        >
                          {page.projectName}
                        </Typography>
                      </Box>
                      <Tooltip
                        disabled={!isConnected && page.pageId !== errorPageID}
                        placement="bottom-start"
                        title={
                          <Box>
                            {errorPageID === page.pageId ? (
                              <Trans i18nKey="pages.failTooltip">
                                You may go to
                                <Link
                                  color="infoOnTooltips.main"
                                  href={LEARN_MORE_ZH}
                                >
                                  {t('pages.learnMore')}
                                </Link>
                              </Trans>
                            ) : (
                              t('pages.isConnected')
                            )}
                          </Box>
                        }
                      >
                        <div
                          style={{
                            ...((isConnected ||
                              page.pageId === errorPageID) && {
                              cursor: 'not-allowed',
                            }),
                          }}
                        >
                          {/* 使用 box 會導致 tooltip 失效，原因不明 */}
                          <Button
                            loading={activeID === page.pageId && isConnecting}
                            loadingColor="white"
                            variant="contained"
                            color="primary"
                            disabled={
                              isConnected ||
                              isConnecting ||
                              errorPageID === page.pageId
                            }
                            onClick={() => {
                              handleButtonClick(page.pageId);
                            }}
                          >
                            {t('pages.connect')}
                          </Button>
                        </div>
                      </Tooltip>
                    </Box>
                    {errorPageID === page.pageId && (
                      <ConnectErrorWrapperStyled>
                        <Box
                          display="inline-flex"
                          alignItems="center"
                          gap="8px"
                        >
                          <ErrorOutlineIcon color="error" />
                          <Typography variant="subtitle2" color="error.dark">
                            {t('pages.fail')}
                          </Typography>
                        </Box>
                        <Box pl="32px">
                          <Typography variant="body2" color="error.dark">
                            {connectionErrorMessage}
                            &nbsp;
                            <Link
                              fontWeight="400"
                              variant="body2"
                              color="error.dark"
                              href={LEARN_MORE_ZH}
                            >
                              {t('pages.learnMore')}...
                            </Link>
                          </Typography>
                        </Box>
                      </ConnectErrorWrapperStyled>
                    )}
                  </Box>
                );
              })}
          </Box>
        </Box>
        <ButtonWrapperStyled>
          <Button
            onClick={refresh}
            startIcon={<ReplayIcon />}
            variant="outlined"
            color="bluegrey500"
          >
            {t('pages.refresh')}
          </Button>
          <Button
            color="primary"
            variant="text"
            onClick={() => window.open(FACEBOOK_PAGE_CREATE)}
            size="medium"
          >
            {t('pages.create')}
          </Button>
        </ButtonWrapperStyled>
      </ModalStyled>
    </Modal>
  );
};

export default FacebookPagesModal;
