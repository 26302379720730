import {
  GetDataCollectUserAnswerParams,
  GetDataCollectUserAnswerResponse,
  ResponseWithData,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_COLLECT_USER_ANSWER } from '../apis';

const defaultData = {
  commentReplyName: 'comment reply #1',
  questions: [],
};

async function getDataCollectUserAnswer(
  params: GetDataCollectUserAnswerParams,
): Promise<ResponseWithData<GetDataCollectUserAnswerResponse>> {
  return axios
    .get(DATA_COLLECT_USER_ANSWER, { params })
    .then((res) => res.data)
    .catch((err) => {
      return {
        code: 400,
        msg: err,
        data: defaultData,
      };
    });
}

export function useGetDataCollectUserAnswerSingle(
  params: GetDataCollectUserAnswerParams,
) {
  return useQuery({
    queryKey: ['get-data-collect-user-answer-single', params],
    queryFn: () => getDataCollectUserAnswer(params),
  });
}
