import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const ContainerStyled = styled(Box)<{
  $isHover: boolean;
  $zoom: number;
}>(({ theme, $isHover, $zoom }) => ({
  background: theme.palette['info']['light'],
  borderRadius: '4px',
  border: `2px solid ${
    $isHover ? theme.palette['info']['light'] : 'transparent'
  }`,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  position: 'relative',
  fontSize: `${28 / $zoom}px`,
}));

export const TextareaWrapperStyled = styled(Box)(({ theme }) => ({
  '& .DraftEditor-root': {
    color: theme.palette['info']['dark'],
    fontFamily: 'Noto Sans',
    fontWeight: '400',
    lineHeight: '150%',
    fontSize: 14,
    letterSpacing: 0.25,
  },
}));
