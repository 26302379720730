import { EditorCtx } from '@frontend/editor/external-providers';
import {
  BaseElement,
  ModalTypesEnum,
  NodeTypesEnum,
} from '@frontend/editor/interface';
import { getEdge, uuid } from '@frontend/editor/utils';
import { sendGAEvent } from '@frontend/sorghum/utils';
import { useCallback, useContext, useMemo, useState } from 'react';
import {
  Connection,
  Edge,
  EdgeChange,
  MarkerType,
  Node,
  NodeChange,
  OnConnectStartParams,
  XYPosition,
  applyEdgeChanges,
  updateEdge,
  useReactFlow,
  useViewport,
} from 'reactflow';
import useModal from '../use-modal/use-modal';

export interface UseCanvasFlow {
  nodes: Node[];
  edges: Edge[];
  zoom: number;
  targetElement?: BaseElement;
  isConnecting: boolean;
  menuPosition: XYPosition;
  onConnectEnd: (event: MouseEvent | TouchEvent, allowAdd?: boolean) => void;
  onEdgesChange: (edgeChanges: EdgeChange[]) => void;
  onNodesChange: (nodeChanges: NodeChange[]) => void;
  handleNodesDragEnd: (nodes: Node[]) => void;
  handleNodesDelete: (deleteNodes: Node[]) => void;
  handleEdgeDelete: (deleteEdges: Edge[]) => void;
  handleEdgeUpdate: (oldEdge: Edge, newConnection: Connection) => void;
  handleOnSelectionContextMenu: (
    e: React.MouseEvent<Element, MouseEvent>,
    nodes: Node[],
  ) => void;
  handleOnNodeContextMenu: (
    e: React.MouseEvent<Element, MouseEvent>,
    nodes: Node,
  ) => void;
  onConnectStart: (
    event: React.MouseEvent<Element, MouseEvent> | React.TouchEvent<Element>,
    { nodeId }: OnConnectStartParams,
  ) => void;
}

const MENU_WIDTH = 314;
const MENU_HEIGHT = 400;
const CANVAS_PADDING = 24;

export function useCanvasFlow(): UseCanvasFlow {
  const state = useContext(EditorCtx);
  const { openContextMenu } = useModal();
  const [activeNode, setActiveNode] = useState<OnConnectStartParams>();
  const { project } = useReactFlow();
  const [isConnecting, setIsConnecting] = useState(false);

  const {
    nodes,
    edges,
    targetElementID,
    setNodes,
    setEdges,
    onEdgesChange,
    onNodesChange,
    getElement,
    getElementByOutputID,
    updateElementData,
    resetElementsSelectedStatus,
  } = state;
  const { zoom } = useViewport();

  const handleOnSelectionContextMenu = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>, nodes: Node[]) => {
      e.preventDefault();
      if (nodes.length <= 0) return;
      if (
        nodes.length === 1 &&
        nodes[0].type === NodeTypesEnum.ENTRY_POINT_NODE
      )
        return;
      openContextMenu(e, ModalTypesEnum.SELECTION_CONTEXT_MENU);
    },
    [openContextMenu],
  );

  const handleOnNodeContextMenu = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>, node: Node) => {
      e.preventDefault();
      if (
        node.type === NodeTypesEnum.ENTRY_POINT_NODE ||
        node.type === NodeTypesEnum.CREATE_MENU_NODE
      )
        return;
      openContextMenu(e, ModalTypesEnum.NODE_CONTEXT_MENU, node);
    },
    [openContextMenu],
  );

  const onConnectStart = useCallback(
    (
      e: React.MouseEvent<Element, MouseEvent> | React.TouchEvent<Element>,
      props: OnConnectStartParams,
    ) => {
      setNodes((nodes) =>
        nodes.filter((node) => node.type !== NodeTypesEnum.CREATE_MENU_NODE),
      );
      setIsConnecting(true);
      setActiveNode(props);
    },
    [setNodes],
  );

  const onConnectEnd = useCallback(
    (event: MouseEvent | TouchEvent, allowAdd = true) => {
      const mouseEvent = event as MouseEvent;
      const target = event.target as HTMLElement;
      setIsConnecting(false);
      if (target.classList.contains('react-flow__pane') && allowAdd) {
        if (state.ref && state.ref.current) {
          const sourceElement = activeNode?.handleId
            ? getElementByOutputID(activeNode.handleId)
            : getElement(activeNode?.nodeId as string);

          if (!sourceElement) return;

          const { width, height, top, left } =
            state.ref.current.getBoundingClientRect();
          const id = uuid();

          // 314 和 400 分別為 menu 選單的寬度和高度，24 為畫布預留的 padding
          let positionX = mouseEvent.clientX;
          let positionY = mouseEvent.clientY;

          if (mouseEvent.clientX < CANVAS_PADDING) {
            positionX += CANVAS_PADDING;
          } else if (mouseEvent.clientX > width - MENU_WIDTH) {
            positionX = width - MENU_WIDTH - CANVAS_PADDING;
          }

          if (mouseEvent.clientY < CANVAS_PADDING) {
            positionY += CANVAS_PADDING;
          } else if (mouseEvent.clientY > height - MENU_HEIGHT) {
            positionY = height - MENU_HEIGHT - CANVAS_PADDING;
          }

          // menu of create block
          const newNode = {
            id,
            position: project({
              x: positionX - left,
              y: positionY - top,
            }),
            data: { sourceOutputID: sourceElement.outputID as string },
            type: NodeTypesEnum.CREATE_MENU_NODE,
            zIndex: 99,
            selected: true,
          } as Node;

          resetElementsSelectedStatus();
          updateElementData(sourceElement.id, 'targetID', '');

          setNodes((nds) => nds.concat(newNode));
          setEdges((prev) =>
            applyEdgeChanges(
              [
                {
                  id: activeNode?.handleId
                    ? activeNode.handleId
                    : sourceElement.outputID,
                  type: 'remove',
                },
                {
                  item: {
                    id: activeNode?.handleId as string,
                    source: activeNode?.nodeId as string,
                    sourceHandle: activeNode?.handleId,
                    target: id,
                    updatable: 'target',
                    markerEnd: {
                      type: MarkerType.ArrowClosed,
                    },
                  },
                  type: 'add',
                },
              ],
              prev,
            ),
          );

          // 判斷是從 block 還是 cell 呼叫出選單
          if (activeNode?.handleId) {
            if (
              state.getElementByOutputID(activeNode.handleId)?.elementType ===
              'BLOCK'
            ) {
              sendGAEvent(
                'Chat Flow Edit',
                'Block connector',
                'Chat Flow Edit - connector- block - click',
                '',
              );
            } else {
              sendGAEvent(
                'Chat Flow Edit',
                'Cell connector',
                'Chat Flow Edit - connector- cell - drag',
                '',
              );
            }
          }
        }
      } else {
        // 尋找正在被 hover 的 block
        const onHoverNodeID = state.onHoverNode?.id;
        if (activeNode?.nodeId && onHoverNodeID) {
          if (activeNode?.nodeId === onHoverNodeID) {
            // 不能自己連線自己
            return;
          }

          const sourceElement = activeNode?.handleId
            ? getElementByOutputID(activeNode.handleId)
            : getElement(activeNode.nodeId);

          if (sourceElement) {
            const targetElement = getElement(onHoverNodeID);
            if (!sourceElement || !targetElement) return;
            const newEdge = getEdge(sourceElement, targetElement);
            state.connectElement(sourceElement.id, onHoverNodeID);
            setEdges((prev) =>
              applyEdgeChanges(
                [
                  {
                    id: newEdge.id,
                    type: 'remove',
                  },
                  {
                    item: newEdge,
                    type: 'add',
                  },
                ],
                prev,
              ),
            );
          }
        }
      }
    },
    [
      state,
      activeNode?.handleId,
      activeNode?.nodeId,
      getElementByOutputID,
      getElement,
      project,
      resetElementsSelectedStatus,
      updateElementData,
      setNodes,
      setEdges,
    ],
  );

  const handleNodesDragEnd = useCallback(
    (nodeChanges: Node[]) => {
      if (nodeChanges.length <= 0) return;
      state.updateElementPosition(nodeChanges);
    },
    [state],
  );

  /** 選取 edge 並按下 backspace (已被停用) */
  const handleEdgeDelete = useCallback(
    (edges: Edge[]) => {
      const targets = edges.map((e) => {
        if (e.sourceHandle) {
          return e.sourceHandle;
        } else {
          return e.source;
        }
      });
      state.removeConnects(targets);
    },
    [state],
  );

  const handleNodesDelete = useCallback(
    (nodes: Node[]) => {
      nodes.forEach((n) => {
        state.removeElement(n.id);
      });
    },
    [state],
  );

  const handleEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection) => {
      // 相同 node 的 element 不能互相連接
      if (newConnection.source === newConnection.target) {
        return;
      }
      // 改變連接線段的事件
      setEdges((els) => updateEdge(oldEdge, newConnection, els));

      // 移除線段
      if (oldEdge.sourceHandle) {
        state.removeConnects([oldEdge.sourceHandle]);
      } else {
        state.removeConnects([oldEdge.source]);
      }

      // 從子層或是外層連線傳送的參數不同，子層的 source 會繼承父層 node 的 id，所以必須傳 sourceHandle 才會是內層的 handle id = element input id
      if (newConnection.sourceHandle && newConnection.target) {
        state.connectElement(newConnection.sourceHandle, newConnection.target);
        // 從 node 外層的連線只會有 source = element id
      } else if (newConnection.source && newConnection.target) {
        state.connectElement(newConnection.source, newConnection.target);
      }
    },
    [setEdges, state],
  );

  const handleEdgesChange = useCallback(
    (edgeChange: EdgeChange[]) => {
      onEdgesChange(edgeChange);
    },
    [onEdgesChange],
  );

  const handleNodesChange = useCallback(
    (nodeChange: NodeChange[]) => {
      onNodesChange(nodeChange);
    },
    [onNodesChange],
  );

  const targetElement = useMemo(
    () => getElement(targetElementID ?? ''),
    [getElement, targetElementID],
  );

  const menuPosition = useMemo(() => {
    if (
      nodes.filter((item) => item.type === NodeTypesEnum.CREATE_MENU_NODE)
        .length > 0
    ) {
      const menuNode = nodes.filter(
        (item) => item.type === NodeTypesEnum.CREATE_MENU_NODE,
      )[0];

      return { x: menuNode.position.x, y: menuNode.position.y };
    } else {
      return { x: 0, y: 0 };
    }
  }, [nodes]);

  return {
    nodes,
    edges,
    zoom,
    targetElement,
    isConnecting,
    menuPosition,
    onConnectStart,
    onConnectEnd,
    handleNodesDragEnd: handleNodesDragEnd,
    onNodesChange: handleNodesChange,
    onEdgesChange: handleEdgesChange,
    handleOnSelectionContextMenu,
    handleOnNodeContextMenu,
    handleNodesDelete,
    handleEdgeDelete,
    handleEdgeUpdate,
  };
}

export default useCanvasFlow;
